import {
  Typography,
  Box,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Person,
} from "@material-ui/icons";
import { autorun } from "mobx";
import { useObserver } from "mobx-react-lite";
import { useEffect, useState } from "react";
import DeleteModal from "../../../components/deleteModal/DeleteModal";
import { useStore } from "../../../stores/StoreContext";
import ContactModal from "./contactModal";
import distributorsAPI from "../../../api/distributors.js";
import { useParams } from "react-router";
import { Link } from "@material-ui/core";

function ContactCard({
  contact,
  index,
  handleEditContact,
  handleDeleteContact,
}) {
  const handleEdit = () => {
    handleEditContact(contact.contact_id);
  };
  const handleDelete = () => {
    handleDeleteContact(contact.contact_id);
  };

  return (
    <>
      {index === 0 && <Divider inset />}
      <Box display="flex" justifyContent="space-between" p={1}>
        <Box display="flex">
          <Person fontSize="large" />
          <Box ml={2}>
            <Typography>{contact.name}</Typography>
            <Typography>{contact.phone}</Typography>
            <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
          </Box>
        </Box>
        <Box>
          <IconButton aria-label="Edit" color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Delete"
            color="primary"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider variant="inset" />
    </>
  );
}
const DistributorContactList = () => {
  const [isOpenCreateContact, setisOpenCreateContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isContactEdit, setIsContactEdit] = useState(false);
  const { id } = useParams();
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    autorun(() => {
      setContacts([...store.distributorContactList]);
    });
  }, [store.distributorContactList]);

  const handleCreateContact = () => {
    setSelectedContact({});
    setIsContactEdit(false);
    setisOpenCreateContact(true);
  };

  const handleCreateContactClose = () => {
    setisOpenCreateContact(false);
    setSelectedContact({});
  };
  const handleEditContact = (contactId) => {
    let _contactData = contacts.find(
      (contact) => contact.contact_id === contactId
    );
    setSelectedContact(_contactData);
    setisOpenCreateContact(true);
    setIsContactEdit(true);
  };
  const handleDeleteContact = (contactId) => {
    let _contactData = contacts.find(
      (contact) => contact.contact_id === contactId
    );
    setSelectedContact(_contactData);
    setIsOpenDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    const data = await distributorsAPI.deleteDistributorContact(
      userInfo.mid,
      id,
      selectedContact.contact_id
    );
    setIsLoading(false);

    if (data.status < 400) {
      let _contacts = contacts.filter(
        (contact) => contact.contact_id !== selectedContact.contact_id
      );
      setSelectedContact({});
      store.setDistributorContact([..._contacts]);
    }
    setIsOpenDeleteModal(false);
  };
  return useObserver(() => (
    <>
      {contacts.map((contact, index) => (
        <ContactCard
          key={contact.contact_id}
          contact={contact}
          index={index}
          handleEditContact={handleEditContact}
          handleDeleteContact={handleDeleteContact}
        />
      ))}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateContact}
        >
          Add Contacts
        </Button>
      </Box>
      <ContactModal
        isEdit={isContactEdit}
        open={isOpenCreateContact}
        contact={selectedContact}
        setContact={setSelectedContact}
        onClose={handleCreateContactClose}
      />
      <DeleteModal
        title={`Delete ${selectedContact?.name}`}
        open={isOpenDeleteModal}
        description={"Are you sure you want to delete this contact?"}
        deleteButtonLoading={isLoading}
        handleConfirmDelete={handleConfirmDelete}
        onClose={() => setIsOpenDeleteModal(false)}
      />
    </>
  ));
};
export default DistributorContactList;
