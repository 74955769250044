import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  ButtonGroup,
  InputAdornment,
  SvgIcon,
  Snackbar,
  Grid,
  MenuItem,
  Popper,
  Grow,
  MenuList,
  Paper,
} from "@material-ui/core";
import LuxonUtils from "@date-io/luxon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Pagination } from "@material-ui/lab";
import Page from "../../../components/Page";
import { API } from "aws-amplify";
import { Search as SearchIcon } from "react-feather";
import MuiAlert from "@material-ui/lab/Alert";
import { trackEvent } from "../../../api/analytics";
import useApi from "../../../hooks/useApi";
import usersApi from "../../../api/users";
import TeamCard from "./TeamCard";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import PeopleIcon from "@material-ui/icons/People";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import TeamActivityTracker from "./TeamActivityTracker";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TeamScorecardView from "../TeamScorecardView";

const filterTitles = [
  "All",
  "account_checkin",
  "contact_created",
  "media_upload",
  "form_response",
  "image_analysis",
];

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TeamListView = () => {
  const getUsersApi = useApi(usersApi.getUsers);
  const navigate = useNavigate();
  const store = useStore();
  const { userInfo } = store;
  const [listType, setListType] = React.useState("ACTIVITY");
  const anchorRef = useRef(null);
  const anchorRefDate = useRef(null);
  const anchorRef2 = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterDate, setOpenFilterDate] = useState(false);
  const [dataFilter, setDataFilter] = useState("All");
  const [memberFilter, setMemberFilter] = useState("Everyone");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toDate, setToDate] = useState(new Date());
  const dateFilterButtons = ["All", "This Month", "This Week", "Today"];
  const [activeDateAction, setActiveDateAction] = useState("This Month");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activityData, setActivityData] = useState([]);
  const [dateChanged, setDateChanged] = useState(false);

  const reset = () => {
    setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setToDate(new Date());
    setActiveDateAction("This Month");
    setMemberFilter("Everyone");
    setDataFilter("All");
    setDateChanged(false);
  };

  const formatInitialData = (res) => {
    setFormattedData(
      res.filter(
        (item) =>
          new Date(item.created) >= new Date(fromDate) &&
          new Date(item.created) <= new Date(toDate)
      )
    );
  };

  useEffect(() => {
    setActivityLoading(true);
    getListOfAllData().then((res) => {
      formatInitialData(res);
      setActivityLoading(false);
    });
  }, []);

  const getListOfAllData = async () => {
    try {
      const data = await API.get(
        "backendGateway",
        "/teams/activities/withformid",
        {
          queryStringParameters: {
            mid: userInfo.mid,
          },
        }
      );
      setActivityData(data.results);
      console.log(data.results);
      return data.results;
    } catch (err) {
      console.log("error fetching visits data..", err);
    }
  };

  const quickDateActions = (action) => {
    setDateChanged(true);
    setActiveDateAction(action);
    const now = new Date();
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    );

    if (action === "This Month") {
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      setFromDate(startOfMonth);
      setToDate(endOfDay);
    } else if (action === "This Week") {
      setFromDate(startOfWeek);
      setToDate(endOfDay);
    } else if (action === "Today") {
      const startOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      setFromDate(startOfDay);
      setToDate(endOfDay);
    } else if (action === "All") {
      setFromDate(new Date(0));
      setToDate(endOfDay);
    }
  };

  // /teams/activities

  const handleDateChange = (type, date) => {
    setDateChanged(true);
    setActiveDateAction("Custom");
    if (type === "to") {
      setToDate(date);
    } else if (type === "from") {
      setFromDate(date);
    }
  };

  const handleChangeListType = (event, newType) => {
    setListType(newType);
  };
  const isAdmin = userInfo.roles
    ? userInfo.roles.includes("ADMIN")
      ? true
      : false
    : false;
  const classes = useStyles();
  const [activityLoading, setActivityLoading] = React.useState(true);
  const [formattedData, setFormattedData] = useState([]);
  const [openMemberFilter, setOpenMemberFilter] = useState(false);

  useEffect(() => {
    if (dataFilter === "All" && memberFilter === "Everyone") {
      setFormattedData(
        activityData.filter(
          (event) =>
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate)
        )
      );
    } else if (dataFilter !== "All" && memberFilter === "Everyone") {
      setFormattedData(
        activityData.filter(
          (event) =>
            event.type === dataFilter &&
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate)
        )
      );
    } else if (dataFilter === "All" && memberFilter !== "Everyone") {
      setFormattedData(
        activityData.filter(
          (event) =>
            event.user_id === memberFilter &&
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate)
        )
      );
    } else {
      setFormattedData(
        activityData.filter(
          (event) =>
            event.user_id === memberFilter &&
            event.type === dataFilter &&
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate)
        )
      );
    }
    setOpenFilter(false);
  }, [memberFilter, dataFilter, fromDate, toDate]);

  const handleToggle = (param) => {
    if (param === "member") {
      setOpenMemberFilter(true);
    } else if (param === "filter") {
      setOpenFilter((prevOpen) => !prevOpen);
    } else {
      setOpenFilterDate((prevOpen) => !prevOpen);
    }
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenFilter(false);
    }
  }
  async function getData() {
    getUsersApi.request({ mid: userInfo.mid, limit: 1000 });
  }

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Team");
    getData();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenFilterDate(false);
    setOpenFilter(false);
    setOpenMemberFilter(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [sbOpen, setOpen] = React.useState(false);

  const handleSBClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const openManagerView = () => {
    //console.log(list_id)
    navigate("/app/team/manager", {
      state: { team: getUsersApi.data },
    });
  };

  return (
    <Page className={classes.root} title="Team">
      <Snackbar open={sbOpen} autoHideDuration={6000} onClose={handleSBClose}>
        <Alert onClose={handleSBClose} severity="success">
          Sent!
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <div
          className="toolbar"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <ToggleButtonGroup
            size="large"
            exclusive
            value={listType}
            onChange={handleChangeListType}
          >
            <ToggleButton value="ACTIVITY">
              <FormatListBulletedIcon />
              <Box ml={1}>Team Activity</Box>
            </ToggleButton>
            <ToggleButton value="MEMBERS">
              <PeopleIcon />
              <Box ml={1}>Team Members</Box>
            </ToggleButton>
            {userInfo.mid === 585 && (
              <ToggleButton value="SCORECARD">
                <ScatterPlotIcon />
                <Box ml={1}>Team Scorecard</Box>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {isAdmin && getUsersApi.currentItems?.length > 0 && (
            <Button
              aria-describedby={id}
              variant="contained"
              color="primary"
              style={{
                margin: "5px",
              }}
              onClick={openManagerView}
            >
              Manager View
            </Button>
          )}
        </div>
        {listType === "SCORECARD" ? (
          <TeamScorecardView users={getUsersApi.data} />
        ) : listType === "MEMBERS" ? (
          <>
            <Box>
              <Card>
                <CardContent>
                  <Box maxWidth={500}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search Team"
                      variant="outlined"
                      onChange={(e) =>
                        getUsersApi.filter("user_displayname", e.target.value)
                      }
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <div>
                {getUsersApi.loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "80px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </div>
              {getUsersApi.currentItems?.length > 0 && (
                <Grid container spacing={3}>
                  {getUsersApi.currentItems.map((member) => (
                    <Grid item key={member.user_id} lg={4} md={6} xs={12}>
                      <TeamCard member={member} key={member.user_id} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                count={getUsersApi.pageCount}
                page={getUsersApi.page}
                onChange={(event, value) => {
                  getUsersApi.paginationWeb(value);
                }}
                size="small"
              />
            </Box>
          </>
        ) : (
          <>
            <Paper
              style={{
                display: "flex",
                width: "100%",
                padding: "10px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "26px",
                  fontWeight: "500",
                  marginLeft: "10px",
                }}
              >
                Team Activity
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <ButtonGroup>
                  <Button
                    ref={anchorRef2}
                    aria-controls={openFilter ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle("member")}
                    title={memberFilter}
                    size={"large"}
                  >
                    {getUsersApi.data.find(
                      (member) => member.user_id === memberFilter
                    )?.user_displayname || "Everyone"}
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    disabled
                    style={{
                      cursor: "unset",
                      color: "black",
                      borderColor: "1px solid rgba(0, 0, 0, 0.23)",
                    }}
                    onClick={() => handleToggle("filter")}
                  >
                    Filter Type:
                  </Button>
                  <Button
                    ref={anchorRef}
                    aria-controls={openFilter ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle("filter")}
                    title={dataFilter}
                    size={"large"}
                  >
                    {dataFilter}
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>

                <div
                  style={{
                    borderLeft: "1px solid lightGrey",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
                <Popper
                  open={openFilter}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={openFilter}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {filterTitles.map((title) => (
                              <MenuItem
                                key={title}
                                onClick={() => setDataFilter(title)}
                              >
                                {title.replace("_", " ").toUpperCase()}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Popper
                  open={openMemberFilter}
                  anchorEl={anchorRef2.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={openMemberFilter}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                            style={{
                              maxHeight: 200,
                              overflowY: "auto",
                              zIndex: 200,
                            }}
                          >
                            <MenuItem
                              onClick={() => setMemberFilter("Everyone")}
                            >
                              Everyone
                            </MenuItem>
                            {getUsersApi.data.map((member) => (
                              <MenuItem
                                key={member.user_id}
                                onClick={() => setMemberFilter(member.user_id)}
                              >
                                {member.user_displayname}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <DatePicker
                    label="START DATE"
                    inputVariant="outlined"
                    style={{ width: "150px" }}
                    size="small"
                    disableFuture="true"
                    value={fromDate}
                    onChange={(e) => handleDateChange("from", e)}
                  />
                  <div style={{ marginTop: "10px" }}>-</div>

                  <DatePicker
                    label="END DATE"
                    inputVariant="outlined"
                    style={{ width: "150px" }}
                    fullWidth={false}
                    size="small"
                    disableFuture="true"
                    value={toDate}
                    onChange={(e) => handleDateChange("to", e)}
                  />
                </MuiPickersUtilsProvider>
                <div
                  style={{
                    borderLeft: "1px solid lightGrey",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
                <Button
                  style={{
                    border: "1px solid lightGrey",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                  }}
                  ref={anchorRefDate}
                  aria-controls={openFilterDate ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={() => handleToggle("date")}
                  title={dataFilter}
                  size={"large"}
                >
                  {activeDateAction}
                  <ArrowDropDownIcon />
                </Button>

                {dateChanged ||
                dataFilter !== "All" ||
                memberFilter !== "Everyone" ? (
                  <Button
                    style={{
                      border: "1px solid lightGrey",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      color: "#1A4399",
                    }}
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset
                  </Button>
                ) : (
                  ""
                )}

                <Popper
                  open={openFilterDate}
                  anchorEl={anchorRefDate.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={openFilterDate}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {dateFilterButtons.map((title) => (
                              <MenuItem
                                key={title}
                                onClick={() => quickDateActions(title)}
                              >
                                {title}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Paper>

            {formattedData.length && formattedData.length > 0 ? (
              <TeamActivityTracker
                hideDateColumn={openMemberFilter}
                members={getUsersApi.data}
                data={formattedData.sort(
                  (a, b) => new Date(b.created) - new Date(a.created)
                )}
              />
            ) : (
              <>
                {!activityLoading ? (
                  <Paper>
                    <div
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        padding: "20px",
                      }}
                    >
                      No data to show, check filters and date ranges
                    </div>
                  </Paper>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "80px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default TeamListView;
