import { Storage } from "aws-amplify";
Storage.configure({ level: "protected" });

const download = async (key, identityId, download = true) => {
  const response = Storage.get(key, {
    download: download,
    identityId: identityId,
  });
  return response;
};

// TODO: Remove and Copy implemented but need to upgrade Storage Library to test & use.

const remove = async (key) => {
  /**
   * We don't allow delete of files directly from App
   * Files will be copied to rubbish bin
   */
  const trash = "trash/";
  const response = Storage.copy({ key: key }, { key: trash + key });
  return response;
};
const removeHard = async (key) => {
  /**
   * We don't allow delete of files directly from App
   * Files will be copied to rubbish bin
   */
  const response = Storage.remove(key, { level: "protected" });
  return response;
};

const upload = async (key, object, contentType = "text/plain") => {
  const response = Storage.put(key, object, {
    level: "protected",
    contentType: contentType,
  });
  return response;
};

const list = async (path) => {
  const response = Storage.list(path, {
    level: "protected",
  });
  return response;
};

const copy = async (copyFrom, copyTo) => {
  const response = Storage.copy({ key: copyFrom }, { key: copyTo });
  return response;
};

export default {
  download,
  upload,
  remove,
  removeHard,
  list,
};
