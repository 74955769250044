/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "backendGateway",
            "endpoint": "https://lm4pwxbz8f.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:c1a4fa6e-7345-4a31-9686-ed2c541b9ab9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_00RjTDnwV",
    "aws_user_pools_web_client_id": "3i5cqm8010mf7b1p10o40poe9i",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "webapp-hosting-bucket-main",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3k6p6dcu8ohmx.cloudfront.net",
    "aws_user_files_s3_bucket": "ggimages12e12iejh12132hi2h2191529-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
