import React from "react";
import { MapView, LocationSearch } from "@aws-amplify/ui-react";
import { Geo } from "@aws-amplify/geo";
import awsconfig from "../../aws-exports";

Geo.configure(awsconfig);

export default function SpikeView(props) {
  if (process.env.NODE_ENV === "production") {
    window.location.href = "/";
    return;
  }

  return (
    <>
      <h1>Spike home</h1>
      <p>
        this is the page used for spiking research work. It's available at
        /spike (only in dev though!)
      </p>
      <>
        <MapView
          initialViewState={{
            latitude: 36.45780681307485,
            longitude: -91.97102838626085,
            zoom: 4,
          }}
        >
          <LocationSearch position="top-left" />
        </MapView>
      </>
    </>
  );
}
