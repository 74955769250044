import React, { useEffect, useState, useMemo } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { TextField, FormControl } from "@material-ui/core";
import LuxonUtils from "@date-io/luxon";
import StLabel from "./StLabel";
import { DateTime } from "luxon";

const StDate = ({
  required,
  label,
  answer,
  changeAnswer = () => {},
  error,
  helperText,
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (answer.value && DateTime.fromISO(answer.value).ts !== selectedDate.ts) {
      setSelectedDate(DateTime.fromISO(answer.value));
    }
  }, [answer, selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    changeAnswer(date.toISODate());
  };

  const formattedDate = useMemo(() => {
    if (!selectedDate || selectedDate.invalid) return "";

    const formattedDay = selectedDate.toFormat("d");
    const suffix = ["st", "nd", "rd", "th"][(formattedDay % 10) - 1] || "th";
    const formattedDate = `${formattedDay}${suffix} of ${selectedDate.toFormat(
      "MMMM, yyyy"
    )}`;

    return formattedDate;
  }, [selectedDate]);

  return (
    <FormControl fullWidth error={!!error}>
      <StLabel required={required} text={label} active={active} />
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <DatePicker
          fullWidth
          value={selectedDate ? selectedDate.toJSDate() : ""}
          onChange={handleDateChange}
          inputVariant="outlined"
          disableFuture
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              error={!!error}
              helperText={helperText}
              value={formattedDate}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

export default StDate;
