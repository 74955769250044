import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, ListItem, makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    minWidth: 0,
    width: "100%",
    "&.active": {
      color: theme.palette.primary.main,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& $icon": {
        color: theme.palette.primary.main,
        transition: "transform 0.3s",
      },
    },
  },
  title: {
    marginRight: "auto",
    marginLeft: theme.spacing(1),
  },
  miniTitle: {
    marginRight: "auto",
    marginLeft: theme.spacing(1),
    fontSize: "0.6em",
  },
  icon: {},
}));

const NavItem = ({
  className,
  href,
  special,
  icon: Icon,
  mini,
  toggleMini,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Tooltip title={mini ? title : ""} placement="right">
        <Button
          className={classes.button}
          component={RouterLink}
          to={href}
          onClick={() => {
            if (special === "toggleMini") toggleMini();
            return false;
          }}
          style={{
            justifyContent: mini ? "space-around" : "flex-start",
          }}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
              style={{
                transform: `rotate(${
                  mini && special === "toggleMini" ? 180 : 0
                }deg)`,
              }}
            />
          )}
          {!mini && (
            <span
              className={
                special === "toggleMini" ? classes.miniTitle : classes.title
              }
            >
              {title}
            </span>
          )}
        </Button>
      </Tooltip>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
