import React, { useEffect, useState } from "react";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import _ from "lodash";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Card,
  Typography,
} from "@material-ui/core";
import { Storage } from "aws-amplify";
import { ExpandMore } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/StoreContext";
import statesGeo from "../../../helpers/data/geoJSON";
import DistributorBrandGrid from "./DistributorBrandGrid";

const styles = {
  card: {
    padding: "8px",
    marginBottom: "12px",
  },
};

const stateColours = [
  "#2a9d8f",
  "#e9c46a",
  "#f4a261",
  "#e76f51",
  "#61a0af",
  "#96c9dc",
  "#f06c9b",
  "#f9b9b7",
  "#f5d491",
  "#bf4e30",
  "#c6ccb2",
  "#093824",
  "#e5eafa",
  "#78fecf",
  "#fff8f0",
  "#9e2b25",
  "#51355a",
  "#2a0c4e",
  "#f5f8de",
  "#3a3042",
  "#db9d47",
  "#ff784f",
  "#ffe19c",
  "#edffd9",
  "#264653",
];

const buildTip = ({ name, brands, distributors, externalBrands }) => {
  const brandSample =
    brands.length < 4
      ? brands
      : [...brands.slice(0, 3), `+ ${brands.length - 3} more`];
  const externalBrandSample =
    externalBrands.length < 4
      ? externalBrands
      : [...externalBrands.slice(0, 3), `+ ${externalBrands.length - 3} more`];
  const distributorSample =
    distributors.length < 4
      ? distributors
      : [...distributors.slice(0, 3), `+ ${distributors.length - 3} more`];
  const brandMarkup =
    brands.length > 0
      ? `<strong>Brands</strong> - ${brands.length}<br>
	${brandSample.map((b) => `<em>${b}</em><br>`).join("")}`
      : "";
  const externalBrandMarkup =
    externalBrands.length > 0
      ? `<strong>Ext Brands</strong> - ${externalBrands.length}<br>
	${externalBrandSample.map((b) => `<em>${b.brand}</em><br>`).join("")}`
      : "";
  const distributorMarkup =
    distributors.length > 0
      ? `<strong>Distributors</strong> - ${distributors.length}<br>
	${distributorSample.map((b) => `<em>${b}</em><br>`).join("")}`
      : "";
  return `<strong>${name}</strong> <br><hr style="margin: 4px 0;">
	${brandMarkup}
	${externalBrandMarkup}
	${distributorMarkup}
 `;
};

const legendStyle = {
  background: `linear-gradient(90deg, hsl(30 50% 60%) 0%, hsl(115 50% 60%) 100%)`,
  display: "block",
  height: "20px",
  marginBottom: "12px",
};

function getColor(d, brands) {
  const h = (d / brands) * 85 + 30;
  const colour = `hsl(${h} 50% 60%)`;
  return colour;
}

const DistributorBrandMap = observer(({ externalBrandList }) => {
  const [center, setCenter] = useState([40.832, -100.62]);
  const [map, bindMap] = useState(null);
  const [brands, setBrands] = useState([]);
  const [manuStates, setManuStates] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [statesToRender, setStatesToRender] = useState({ features: [] });
  const [brandStatesToRender, setBrandStatesToRender] = useState({
    features: [],
  });
  const [clickedStateExternals, setClickedStateExternals] = useState([]);
  const [activeBrands, setActiveBrands] = useState([]);
  const [activeDistributors, setActiveDistributors] = useState([]);
  const [baseGeoLayer, setBaseGeoLayer] = useState([]);
  const [activeStatesForMap, setActiveStatesForMap] = useState(null);
  const [clickedState, setClickedState] = useState({});
  const [brandStates, setBrandStates] = useState([]);
  const [specialColors, setSpecialColors] = useState([]);
  const [mapGradients, setMapGradients] = useState([]);
  const [zoom, setZoom] = useState(3);
  const [brandMax, setBrandMax] = useState(0);
  const [stateNameObj, setStateNameObj] = useState({});

  const {
    selectedStates,
    selectedBrands,
    allBrands,
    allDistributors,
    selectedDistributors,
    completeDistBrandData,
    regions,
  } = useStore();

  const brandSelect = (brand) => {
    if (brand === null) {
      return setActiveBrands([]);
    }
    const matchInd = activeBrands.indexOf(brand);
    if (matchInd > -1) {
      let brands = [...activeBrands];
      brands.splice(matchInd, 1);
      setActiveBrands(brands);
    } else {
      setActiveBrands([...activeBrands, brand]);
    }
    // setBrandStates(completeDistBrandData.filter(d => d.product_brand === brand))
  };

  useEffect(() => {
    if (clickedState && clickedState.properties) {
      const stateName = clickedState.properties.name;
      const matchingExBrands = externalBrandList.filter((eb) => {
        const matchingRegion = regions.find(
          (r) => r.code === eb.dist_report_state_key
        );
        return matchingRegion;
      });
      setClickedStateExternals(matchingExBrands);
    }
  }, [clickedState, externalBrandList]);

  useEffect(() => {
    // Make an object for quickly mapping eg "DE" to "Delaware" - as brands only have code
    if (regions && regions.length > 0) {
      const sObj = {};
      regions.forEach((region) => {
        sObj[region.code.toUpperCase()] = region.long;
      });
      setStateNameObj(sObj);
    }
  }, [regions]);

  useEffect(() => {
    setActiveBrands([...brands, ...externalBrandList]);
  }, [brands, externalBrandList]);

  const goodStateFor = (state, prop, collection) => {
    state[prop].forEach((member) => {
      if (collection.includes(member)) {
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    if (brands.length > -1) {
      const brandStates = completeDistBrandData
        .filter(
          (d) =>
            brands.includes(d.product_brand) &&
            manuStates.includes(d.state) &&
            distributors.includes(d.dist_displayname)
        )
        .map((b) => b.state);
      let combinedBrands = [...brandStates];
      if (regions.length && externalBrandList) {
        combinedBrands = [
          ...combinedBrands,
          ...externalBrandList.map(
            (eb) =>
              regions.find((r) => r.code === eb.dist_report_state_key).long
          ),
        ];
      }
      const prebakeStates = {
        type: "FeatureCollection",
        features: statesGeo.features
          // .filter((s) => brandStates.includes(s.properties.name))
          .filter((s) => combinedBrands.includes(s.properties.name))
          .map((s) => {
            const matches = completeDistBrandData.filter(
              (d) => d.state === s.properties.name
            );
            return {
              ...s,
              properties: {
                ...s.properties,
                brands: [...new Set(matches.map((b) => b.product_brand))],
                distributors: [
                  ...new Set(matches.map((b) => b.dist_displayname)),
                ],
                externalBrands: externalBrandList.filter(
                  (eb) =>
                    Object.hasOwn(stateNameObj, eb.dist_report_state_key) &&
                    s.properties.name === stateNameObj[eb.dist_report_state_key]
                ),
              },
            };
          }),
      };
      setBrandStatesToRender(prebakeStates);
    }
  }, [brands, distributors, mapGradients, manuStates, externalBrandList]);

  const storeMapGradient = (patternName, gradient) => {
    if (!mapGradients.find((p) => p.patternName === patternName)) {
      setMapGradients([
        ...mapGradients,
        {
          patternName,
          gradient,
        },
      ]);
    }
  };
  const getPatternName = () => {
    let gradientColors = allBrands
      .map((b) => {
        if (activeBrands.includes(b)) {
          return stateColours[allBrands.indexOf(b)];
        }
        return null;
      })
      .filter((x) => x !== null);
    let patternName = gradientColors.sort().join("-").replace(/#/g, "");
    return {
      patternName,
      gradientColors,
    };
  };

  const stateSpecialColours = (brands) => {
    const { patternName, gradientColors } = getPatternName(brands);
    let gradientString = `<linearGradient id="col-${patternName}" x1="0%" y1="0%" x2="100%" y2="100%">
	${gradientColors
    .map((gc, ind, arr) => {
      return `<stop offset=${Math.round(
        (100 / arr.length) * ind
      )}% stop-color=${gc} />
		<stop offset=${Math.round(
      (100 / arr.length) * (ind + 1)
    )}% stop-color=${gc} />`;
    })
    .join("")}
</linearGradient>`;

    storeMapGradient("col-" + patternName, gradientString);
    let defaultColor = "#fff";
    brands.forEach((brand) => {
      // console.log(brands, activeBrands, stateColours)
      if (activeBrands.includes(brand)) {
        defaultColor = stateColours[activeBrands.indexOf(brand)];
      }
    });
    return `url(#col-${patternName})`;
  };
  // TODO: make this a resetter
  const gradientBuilder = (list) => {
    let mapSvg = document.querySelector("#gm-map svg");
    let defEl = mapSvg.querySelector("defs");
    if (defEl !== null) {
      defEl.innerHTML = "";
    } else {
      defEl = document.createElementNS("http://www.w3.org/2000/svg", "defs");
      mapSvg.appendChild(defEl);
    }
    list.forEach(({ patternName, gradient }) => {
      let inPlace = document.querySelector(`#gm-map svg #${patternName}`);
      if (!inPlace) {
        defEl.insertAdjacentHTML("afterbegin", gradient);
      }
    });
  };
  // useEffect(() => {
  //   gradientBuilder(mapGradients);
  // }, [mapGradients]);

  const ebColor = ({ brands, externalBrands }) => {
    if (brands.length > 0 && externalBrands.length === 0) {
      return "#cc0000";
    }
    if (brands.length === 0 && externalBrands.length > 0) {
      return "#00cc00";
    }
    return "#ffff00";
  };

  useEffect(() => {
    function style(feature) {
      const { brands, externalBrands } = feature.properties;
      return {
        // fillColor: stateSpecialColours(feature.properties.brands),
        fillColor: ebColor(feature.properties),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7,
        interactive: true,
      };
    }
    if (activeStatesForMap) {
      activeStatesForMap.clearLayers();
    }
    if (map && brandStatesToRender.features.length > -1) {
      setActiveStatesForMap(
        L.geoJSON(brandStatesToRender, { style }).on("click", regionClick)
      );
      // setBaseGeoLayer(base)
    }
  }, [brandStatesToRender]);

  useEffect(() => {
    if (map && activeStatesForMap && activeStatesForMap !== null) {
      activeStatesForMap.bindTooltip(
        function (layer) {
          return buildTip(layer.feature.properties);
        },
        { opacity: 1 } //then add your options
      );
      activeStatesForMap.addTo(map);
      const bounds = activeStatesForMap.getBounds();
      if (Object.keys(bounds).length > 0) {
        map.fitBounds(bounds);
      }
    }
  }, [activeStatesForMap]);

  useEffect(() => {
    if (!completeDistBrandData.length) return;
    const brands = new Set();
    const states = new Set();
    const collectDistributors = new Set();
    const bs = {};
    completeDistBrandData.forEach((row, ind) => {
      brands.add(row.product_brand);
      states.add(row.state);
      collectDistributors.add(row.dist_displayname);
      if (bs[row.state]) {
        if (bs[row.state][row.brand]) {
          bs[row.state][row.brand] += row.sold;
        } else {
          bs[row.state][row.brand] = row.sold;
        }
      } else {
        bs[row.state] = {
          [row.brand]: row.sold,
        };
      }
    });
    setBrands([...brands].filter((s) => selectedBrands.includes(s)).sort());
    setManuStates([...states].filter((s) => selectedStates.includes(s)).sort());
    setDistributors(
      [...collectDistributors]
        .filter((s) => selectedDistributors.includes(s))
        .sort()
    );
  }, [
    completeDistBrandData,
    selectedStates,
    selectedBrands,
    selectedDistributors,
  ]);

  useEffect(() => {
    setStatesToRender({
      ...statesGeo,
      features: statesGeo.features
        .filter((s) => manuStates.includes(s.properties.name))
        .map((s) => {
          const matches = completeDistBrandData.filter(
            (d) => d.state === s.properties.name
          );
          return {
            ...s,
            properties: {
              ...s.properties,
              brands: [...new Set(matches.map((b) => b.product_brand))],
              distributors: [
                ...new Set(matches.map((b) => b.dist_displayname)),
              ],
              externalBrands: externalBrandList,
            },
          };
        }),
    });
  }, [manuStates, brands, distributors]);

  const regionClick = (event) => {
    const { layer } = event;
    const { feature } = layer;
    setClickedState({
      ...feature,
      bounds: layer.getBounds(),
    });
    setTimeout(() => {
      // map.fitBounds(event.layer.getBounds());
    }, 300);
  };

  const noState = () => {
    setClickedState({});
    map.fitBounds(baseGeoLayer[baseGeoLayer.length - 1].getBounds());
  };

  const stateHasFilteredBrand = (state) => {
    state.brands.forEach((b) => {
      if (brands.includes(b)) {
        return true;
      }
      return false;
    });
  };
  const stateHasFilteredDistributor = (state) => {
    state.distributors.forEach((b) => {
      if (distributors.includes(b)) {
        return true;
      }
      return false;
    });
  };

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={clickedState.properties ? 6 : 12}> */}
      <Grid item xs={6}>
        {/* <div>
    {brands.map((brand) => `.MuiCheckbox-colorSecondary.Mui-checked.mf-check-${brand.replace(/\W/g, '')} { color: ${stateColours[activeBrands.indexOf(brand)]}}`
        // defaultColor = stateColours[activeBrands.indexOf(brand)];
    )}
        </div> */}
        <style>
          {allBrands.map(
            (brand) =>
              `.MuiCheckbox-colorSecondary.Mui-checked.mf-check-${brand.replace(
                /\W/g,
                ""
              )} { color: ${stateColours[allBrands.indexOf(brand)]}}`
            // defaultColor = stateColours[activeBrands.indexOf(brand)];
          )}
        </style>
        <Map
          id="gm-map"
          center={center}
          zoom={zoom}
          whenCreated={(mapInstance) => {
            bindMap(mapInstance);
          }}
          style={{ height: "500px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </Map>
      </Grid>

      {clickedState.properties && (
        <Grid item xs={6}>
          <Card style={styles.card}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  State: {clickedState.properties.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ fontSize: "14px", paddingBottom: "8px" }}>
                  <strong>Brands:</strong>{" "}
                  {_.uniq(
                    completeDistBrandData
                      .filter((d) => d.state === clickedState.properties.name)
                      .map((d) => d.product_brand)
                  ).join(", ")}
                </div>
                {clickedStateExternals.length > 0 && (
                  <div style={{ fontSize: "14px", paddingBottom: "8px" }}>
                    <strong>External brands:</strong>{" "}
                    {_.uniq(clickedStateExternals.map((cse) => cse.brand)).join(
                      ", "
                    )}
                  </div>
                )}
                <div style={{ fontSize: "14px", paddingBottom: "8px" }}>
                  <strong>Distributors:</strong>{" "}
                  {_.uniq(
                    [
                      ...completeDistBrandData,
                      ...clickedStateExternals.map((cse) => ({
                        ...cse,
                        state: stateNameObj[cse.dist_report_state_key],
                      })),
                    ]
                      .filter((d) => d.state === clickedState.properties.name)
                      .map((d) => d.dist_displayname)
                  ).join(", ")}
                </div>
                <Button onClick={() => setClickedState({})}>Hide</Button>
              </Grid>
              <Grid item xs={6}>
                <DistributorBrandGrid
                  data={completeDistBrandData}
                  state={clickedState.properties.name}
                  stateNameObj={stateNameObj}
                  activeBrand={[]}
                  setActiveBrand={() => {}}
                  {...{
                    externalBrandList,
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  );
});

export default DistributorBrandMap;
