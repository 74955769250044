import { useCallback, useState } from "react";

const useApi = (apiFunc) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  /**
   * Next Designed for scroll pagination
   * For Web uses pages
   */
  const nextMobile = useCallback(() => {
    const currentPg = page + 1;
    if (currentPg <= pageCount) {
      setLoading(true);
      setCurrentItems(data.slice(0, itemsPerPage * currentPg));
      setPage(currentPg);
      setLoading(false);
    }
  }, [data, itemsPerPage, page, pageCount]);
  const paginationWeb = useCallback(
    (currentPg) => {
      setPage(currentPg);
      const offset = (currentPg - 1) * itemsPerPage;
      setLoading(true);
      setCurrentItems(data.slice(offset, offset + itemsPerPage));
      setLoading(false);
    },
    [data, itemsPerPage]
  );
  const filter = useCallback(
    (field, value) => {
      if (value !== "") {
        let filtered = data.filter((x) =>
          x[field].toLowerCase().includes(value.toLowerCase())
        );
        setCurrentItems(filtered);
      } else {
        //Goes Back to Previous
        const offset = (page - 1) * itemsPerPage;
        setLoading(true);
        setCurrentItems(data.slice(offset, offset + itemsPerPage));
        setLoading(false);
      }
    },
    [data, itemsPerPage, page]
  );

  const request = useCallback(
    async (...args) => {
      setLoading(true);
      const response = await apiFunc(...args);
      setLoading(false);
      const success = [200, 201, 204];
      setError(!success.includes(response.status));

      console.log("API Response:", response);

      if (response.status === 200) {
        if (response.results) {
          console.log("Response Results:", response.results);
          setData(response.results);
          setCurrentItems(response.results.slice(0, itemsPerPage));
          setPageCount(Math.ceil(response.results.length / itemsPerPage));
        } else {
          console.warn("Response status is 200, but results are undefined.");
        }
      } else if (response.status === 201 || response.status === 204) {
        if (response.results) {
          setData(response.results);
        } else {
          console.warn(
            `Response status is ${response.status}, but results are undefined.`
          );
        }
      }

      return response;
    },
    [apiFunc, itemsPerPage]
  );

  return {
    data,
    error,
    filter,
    loading,
    request,
    page,
    pageCount,
    currentItems,
    nextMobile,
    paginationWeb,
    setItemsPerPage,
    setData,
  };
};
export default useApi;
