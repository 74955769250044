import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
} from "@material-ui/core";

const DeleteModal = ({
  title,
  onClose,
  open,
  deleteButtonLoading,
  description,
  handleConfirmDelete,
  altDeleteText = "Delete",
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="product-dialog"
      open={open}
      PaperProps={{
        style: {
          width: "540px",
          height: 250,
          padding: 10,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={"100%"}
      >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <Typography variant="h5" component="h2" align="center">
          {description}
        </Typography>
        <Box display="flex" gridColumnGap={10}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClose}
            type="button"
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            style={{ borderColor: "#ed1515", color: "#ed1515" }}
            fullWidth
            type="button"
            disabled={deleteButtonLoading}
            onClick={handleConfirmDelete}
          >
            {altDeleteText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default DeleteModal;
