import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { useStore } from "../../stores/StoreContext";
import { MegaFilterSection } from "./MegaFilterSection";
import MegaFilterGlobalBar from "./MegaFilterGlobalBar";
import BookMark from "./BookMark";
import { stateCodeToState } from "../../helpers/data/usStates";
const tabButtonStyle = {
  float: "right",
  boxShadow: "0 2px 2px rgba(0,0,0,0.5)",
  backgroundColor: "#fff",
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  marginRight: "0.5rem",
};

const MegaFilter = observer(({ mini }) => {
  const [display, setDisplay] = useState(true);
  const [openSection, setOpenSection] = useState(null);
  const [simpleMode, setSimpleMode] = useState(true);
  const [enabledDistributors, setEnabledDistributors] = useState([]);
  const [enabledBrands, setEnabledBrands] = useState([]);
  const location = useLocation();
  const mfWrapper = useRef();

  const {
    userInfo,
    allowPaths,
    allStates,
    allBrands,
    allDistributors,
    quantities,
    completeDistBrandData,
    selectedStates,
    selectedBrands,
    selectedDistributors,
    selectedQuantity,
    filteringProducts,
    premiseTypes,
    setAllStates,
    setAllDistributors,
    setAllMarketsObjects,
    setAllBrands,
    depletionList,
    setSelectedStates,
    setSelectedDistributors,
    setSelectedBrands,
    setCompleteDistBrandData,
    setAllDistributorsObjects,
    dateRange,
    dateRangeName,
    productJoiner,
    bookmarkUUID,
    productSold,
  } = useStore();

  const allowed =
    allowPaths.findIndex((ap) => location.pathname.match(ap)) > -1;

  const globalMinimal = useMemo(() => {
    return /(app\/lists)/.test(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setDisplay(allowed);
  }, [allowed]);

  useEffect(() => {
    setOpenSection(null);
  }, [location]);

  const showSelectRow = useMemo(() => {
    return {
      states: allStates.length > 1 && !globalMinimal,
      brands: allBrands.length > 1 && !globalMinimal,
      distributors: allDistributors.length > 1 && !globalMinimal,
    };
  }, [allStates, allDistributors, allBrands, location.pathname]);

  useEffect(() => {
    const uniqueEntriesSet = new Set();
    const dStates = new Set();
    const dDists = new Set();
    const dBrands = new Set();

    depletionList.forEach((item) => {
      const entry = {
        manufacturer_id: item.MANUFACTURER_ID,
        distributor_id: item.DISTRIBUTOR_ID,
        dist_displayname: item.DISTRIBUTOR_NAME,
        state: stateCodeToState(item.STATE || ""),
        code: item.STATE,
        product_id: item.PRODUCT_ID,
        product_displayname: item.PRODUCTNAME,
        product_brand: item.BRAND,
      };

      dStates.add(stateCodeToState(item.STATE));
      dDists.add(item.DISTRIBUTOR_NAME);
      dBrands.add(item.BRAND);
      uniqueEntriesSet.add(JSON.stringify(entry));
    });

    const uniqueEntries = Array.from(uniqueEntriesSet, (entry) =>
      JSON.parse(entry)
    );

    if (uniqueEntries.length > 0) {
      setCompleteDistBrandData(uniqueEntries);
      const completeDistBrandData = uniqueEntries;
      setAllStates([...dStates]);
      setAllDistributors([...dDists]);

      setAllBrands([...dBrands]);
      // don't override bookmarks
      // only set these defaults if the url is not a bookmark uuid one
      if (!(/\/bm\/[\d\w-]{36}/.test(location.pathname) || bookmarkUUID)) {
        setSelectedStates([...dStates]);
        setSelectedDistributors(
          completeDistBrandData.map((d) => d.dist_displayname)
        );
        setSelectedBrands(completeDistBrandData.map((d) => d.product_brand));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, depletionList]);

  const rangeToText = (range) => {
    const years = range.map((r) => r.getFullYear());
    const twoYears = years[0] !== years[1];
    return [
      range[0].toLocaleString("default", { month: "short" }),
      twoYears ? ` ${years[0]}` : "",
      " - ",
      range[1].toLocaleString("default", { month: "short" }),
      " ",
      years[1],
    ].join("");
  };

  // rebuild arrays of enabled, selected items in this method
  // probably doesn't need to be an closure any more

  const toggleInList = (action, propName) => {
    return (list, items) => {
      if (items.length === 0) {
        return action([]);
      }
      let newList = list;
      items.forEach((item) => {
        if (newList.includes(item)) {
          newList.splice(newList.indexOf(item), 1);
        } else {
          newList.push(item);
        }
      });
      // update each of the three lists here, not just the current one
      // 1. generate the three lists from the main data
      // 2. set up map of each list complete with active, enabled properties
      // 3. send this along to each section
      // 4. differentiate on multi or single
      // 5. multi = filter through states, distriubtors, brands
      // 6. single = enable anything that matches the selection which is passed, regardless of position in the hierarchy
      // action(newList);

      if (
        simpleMode &&
        ((items.length === 1 && propName === "state") || propName !== "state")
      ) {
        const item = items[0];
        const matchedSet = completeDistBrandData.filter(
          (bd) => bd[propName] === item
        );
        const matchingStates = new Set();
        const matchingDists = new Set();
        const matchingBrands = new Set();
        matchedSet.forEach((ms) => {
          matchingStates.add(ms.state);
          matchingDists.add(ms.dist_displayname);
          matchingBrands.add(ms.product_brand);
        });
        setSelectedStates([...matchingStates]);
        setSelectedDistributors([...matchingDists]);
        setSelectedBrands([...matchingBrands]);
      } else {
        if (propName === "state") {
          const matchedSet = [];
          const disabledSet = [];
          completeDistBrandData.forEach((bd) => {
            if (newList.includes(bd[propName])) {
              matchedSet.push(bd);
            } else {
              disabledSet.push(bd);
            }
          });
          const matchingDists = new Set();
          const matchingBrands = new Set();
          matchedSet.forEach((ms) => {
            matchingDists.add(ms.dist_displayname);
            matchingBrands.add(ms.product_brand);
          });
          setSelectedStates(newList);
          setSelectedDistributors([...matchingDists]);
          setSelectedBrands([...matchingBrands]);
          const dd = matchedSet.map((d) => d.dist_displayname);
          const db = matchedSet.map((d) => d.product_brand);
          setEnabledDistributors(dd);
          setEnabledBrands(db);
        }
        if (propName === "dist_displayname") {
          const brands = new Set([
            ...completeDistBrandData
              .filter((bd) => newList.includes(bd[propName]))
              .map((f) => f.product_brand),
          ]);
          setSelectedDistributors(newList);
          setSelectedBrands([...brands]);
          setEnabledBrands(
            completeDistBrandData
              .filter((c) => newList.includes(c.dist_displayname))
              .map((d) => d.product_brand)
          );
        }
        if (propName === "product_brand") {
          setSelectedBrands(newList);
        }
      }
    };
  };

  const toggleSimpleMode = (v) => {
    if (!v) {
      const justInState = completeDistBrandData.filter((db) =>
        selectedStates.includes(db.state)
      );
      const dd = justInState.map((d) => d.dist_displayname);
      const db = justInState.map((d) => d.product_brand);
      setEnabledDistributors(dd);
      setEnabledBrands(db);
    } else {
      setEnabledDistributors(
        completeDistBrandData.map((d) => d.dist_displayname)
      );
      setEnabledBrands(completeDistBrandData.map((d) => d.product_brand));
    }
    setSimpleMode(v);
  };

  const toggleEditing = () => setDisplay(!display);

  const quantity = quantities.find((q) => q.value === selectedQuantity);
  const quantityText = quantity ? quantity.text : "";
  const quickBarText = `
      Showing data for ${selectedStates.length}/${allStates.length} States \
      ${selectedDistributors.length}/${allDistributors.length} Distributors \
      ${selectedBrands.length}/${allBrands.length} Brands \
      over ${dateRangeName} - ${rangeToText(dateRange)}. \
      Units: ${quantityText}
    `;

  return (
    <div className={`mega-filter-wrapper`} ref={mfWrapper}>
      <>
        {!display && (
          <Grid
            container
            alignItems="center"
            style={{
              boxShadow: "0 2px 2px rgba(0,0,0,0.5)",
              backgroundColor: "#fff",
            }}
          >
            <Grid item xs={10}>
              <Box
                p={2}
                onClick={() => allowed && setDisplay(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: allowed ? "#000" : "#999",
                  }}
                >
                  {quickBarText}
                  {allowed && (
                    <span
                      className="floating-link"
                      style={{
                        color: "#24c",
                        fontSize: "0.8rem",
                        textDecoration: "underline",
                      }}
                    >
                      Edit
                    </span>
                  )}
                  {!allowed && (
                    <span style={{ color: "#999" }}>
                      (Inactive on this page)
                    </span>
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        <div
          className="mega-filter-inner"
          style={{
            display: display ? "block" : "none",
          }}
        >
          {showSelectRow.states && (
            <MegaFilterSection
              title={"States"}
              completeFilter={completeDistBrandData}
              data={allStates}
              data-arrlen={selectedStates.length}
              allFields={{
                propName: "state",
                list: allStates,
              }}
              activeFields={selectedStates}
              mfType="states"
              toggleBox={toggleInList(setSelectedStates, "state")}
              filterParent={{
                name: "none",
                list: [],
              }}
              {...{
                openSection,
                setOpenSection,
                simpleMode,
                setSimpleMode: toggleSimpleMode,
              }}
            ></MegaFilterSection>
          )}
          {showSelectRow.distributors && (
            <MegaFilterSection
              title={"Distributors"}
              completeFilter={completeDistBrandData}
              allFields={{
                propName: "dist_displayname",
                list: allDistributors,
              }}
              enabledList={enabledDistributors}
              data-arrlen={selectedDistributors.length}
              activeFields={selectedDistributors}
              mfType="distributors"
              toggleBox={toggleInList(
                setSelectedDistributors,
                "dist_displayname"
              )}
              filterParent={{
                propName: "state",
                list: selectedStates,
              }}
              {...{
                openSection,
                setOpenSection,
                simpleMode,
                setSimpleMode: toggleSimpleMode,
              }}
            ></MegaFilterSection>
          )}
          {showSelectRow.brands && (
            <MegaFilterSection
              title={"Brands"}
              completeFilter={completeDistBrandData}
              data-arrlen={selectedBrands.length}
              allFields={{
                propName: "product_brand",
                list: allBrands,
              }}
              enabledList={enabledBrands}
              activeFields={selectedBrands}
              mfType="brands"
              toggleBox={toggleInList(setSelectedBrands, "product_brand")}
              filterParent={{
                propName: "dist_displayname",
                list: selectedDistributors,
              }}
              {...{
                openSection,
                setOpenSection,
                simpleMode,
                setSimpleMode: toggleSimpleMode,
              }}
            ></MegaFilterSection>
          )}
          <MegaFilterGlobalBar
            location={location}
            globalMinimal={globalMinimal}
          />
        </div>
        {allowed && (
          <div>
            <Button
              onClick={toggleEditing}
              variant="contained"
              size="small"
              style={tabButtonStyle}
            >
              {display ? "Hide Quick Select" : "Show Quick Select"}
            </Button>
            <BookMark
              {...{
                selectedBrands,
                selectedStates,
                selectedDistributors,
                dateRange,
                dateRangeName,
                selectedQuantity,
                filteringProducts,
                premiseTypes,
                userInfo,
                productJoiner,
                productSold,
                tabButtonStyle,
              }}
            />
          </div>
        )}
      </>
    </div>
  );
});

export default MegaFilter;
