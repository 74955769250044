import { Parser } from "json2csv";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { cloneDeep } from "lodash";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatForExport = (headers, rows) => {
  const clonedRows = cloneDeep(rows);

  //const colNames = this.state.localPivot.columns.map((c) => c.field);
  const cleanNumber = (val) => {
    if (typeof val === "string" || !val) {
      return val;
    }
    // return an integer unchanged
    if (Number.isInteger(val)) {
      return val;
    }
    // return a float capped to 2 decimal points
    if (typeof val === "number") {
      return parseFloat(val.toFixed(2));
    }
    // if it's a Set, return size of Set
    if (val && val.size) {
      return val.size;
    }
    // return non-numeric unchanged
    return val;
  };
  return clonedRows.map((dr) => {
    let row = {};
    headers.forEach((header) => {
      row[header.headerName] = cleanNumber(dr[header.field]);
    });
    return row;
  });
};

const exportDataCSV = async (headers, rows, preset) => {
  const data = formatForExport(headers, rows);
  if (data.length === 0) {
    this.setState({
      modalOpen: true,
    });
    return;
  }
  const parser = new Parser();
  const csv = parser.parse(data);
  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);

  fetch(url)
    .then((response) => {
      // Trigger the download
      response.blob().then((blob) => {
        const downloadUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.download = `report_${preset}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    })
    .finally(() => {
      // Dispatch the "exportComplete" event after the download is finished
      setTimeout(() => {
        window.dispatchEvent(new Event("exportComplete"));
      }, 0);
    });
};

const exportDataXLSX = async (headers, rows, preset) => {
  const data = formatForExport(headers, rows);
  if (data.length === 0) {
    this.setState({
      modalOpen: true,
    });
    return;
  }

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const fileContent = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const fileExtension = "xlsx";

  const downloadLink = document.createElement("a");
  const url = URL.createObjectURL(fileContent);
  downloadLink.href = url;
  downloadLink.download = `report_${preset}.${fileExtension}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  // Dispatch the "exportComplete" event
  window.dispatchEvent(new Event("exportComplete"));
};

const exportTypesNotRecommendedPDF = [
  "account_product",
  "brand_accounts_sold",
  "brand_totals",
  "product_accounts_sold",
  "product_totals",
  "region_brand",
  "region_product",
];

const exportDataPDF = async (headers, rows, preset) => {
  if (exportTypesNotRecommendedPDF.includes(preset)) {
    this.setState({
      notReccomendedModalOpen: true,
    });
  } else {
    accepted(headers, rows, preset);
  }
};

const getImageDataUrl = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const accepted = async (inputHeaders, rows, preset) => {
  const data = formatForExport(inputHeaders, rows);
  if (data.length === 0) {
    this.setState({
      modalOpen: true,
    });
    return;
  }

  const pageSize = {
    width: 842,
    height: 595,
  };

  const imageUrl = "/static/images/logos/logo.png";

  const imageDataUrl = await getImageDataUrl(imageUrl);

  const documentDefinition = {
    pageSize: pageSize,
    content: [{ text: preset, style: "header" }, { text: "\n" }],
    footer: {
      columns: [
        { image: imageDataUrl, width: 80, margin: [20, 0, 0, 0] },
        {
          text: window.location.href,
          alignment: "center",
          fontSize: 8,
          color: "#0C45A6",
          margin: [0, 15, 0, 0],
        },
      ],
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        color: "#0C45A6",
      },
    },
  };

  const headers = ["Index", ...Object.keys(data[0])].map((header) => {
    return { text: header, bold: true };
  });

  pdfMake.tableLayouts = {
    customTable: {
      hLineColor: (i) => (i === 0 || i === 1 ? "black" : "#aaa"),
      vLineColor: (i) => (i === 0 || i === headers.length ? "black" : "#aaa"),
    },
  };
  let index = 0;
  const cellData = data.reduce(
    (acc, curr) => {
      index += 1;
      return [...acc, [index, ...Object.values(curr)]];
    },
    [headers]
  );
  documentDefinition.content.push({
    layout: "customTable",
    table: {
      headerRows: 1,
      body: cellData,
    },
  });

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  // Download the PDF using pdfmake's download method
  pdfDocGenerator.download(`report_${preset}.pdf`, () => {
    // Dispatch the "exportComplete" event after the download is finished
    window.dispatchEvent(new Event("exportComplete"));
  });
};

export { exportDataCSV, exportDataXLSX, exportDataPDF };
