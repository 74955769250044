import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataTable from "./DataTable";
import {
  CircularProgress,
  Dialog,
  Switch,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import MarketSummaryChart from "./MarketsSummaryChart";
import { API } from "aws-amplify";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import moment from "moment/moment";
import { useStore } from "../../../stores/StoreContext";
const createEmptyPremisesData = () => {
  const premisesData = {};
  ["ON-PREMISE", "OFF-PREMISE", "SAMPLE", "DISTRIBUTOR", "UNKNOWN"].forEach(
    (premise) => {
      premisesData[premise] = {
        "Accounts Sold (QTD) Items": new Set(),
        "Accounts Sold (YTD) Items": new Set(),
        "Accounts Sold (LQ) Items": new Set(),
        "Accounts Sold (LY) Items": new Set(),
        "Accounts New (QTD) Items": new Set(),
        "Accounts New (YTD) Items": new Set(),
        "Accounts New (LQ) Items": new Set(),
        "Accounts New (LY) Items": new Set(),
      };
    }
  );
  return premisesData;
};

const createEmptyFormattedPremisesData = () => {
  const premisesData = {};
  ["ON-PREMISE", "OFF-PREMISE", "SAMPLE", "DISTRIBUTOR", "UNKNOWN"].forEach(
    (premise) => {
      premisesData[premise] = {
        "Accounts New (QTD)": 0,
        "Accounts New (YTD)": 0,
        "Accounts New (LQ)": 0,
        "Accounts New (LY)": 0,
        "Accounts Sold (QTD)": 0,
        "Accounts Sold (YTD)": 0,
        "Accounts Sold (LQ)": 0,
        "Accounts Sold (LY)": 0,
        "Physical (QTD)": 0,
        "Physical (YTD)": 0,
        "Physical (LQ)": 0,
        "Physical (LY)": 0,
      };
    }
  );
  return premisesData;
};

const MarketSummaryStats = () => {
  const [pretty, setPretty] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [itemsToBeFilteredOut, setItemsToBeFilteredOut] = useState([]);
  const [error, setError] = useState(false);
  const [allAccountSetData] = useState(createEmptyPremisesData());

  const [formattedData] = useState(createEmptyFormattedPremisesData());
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterArray, setFilterArray] = useState([]);

  const { id } = useParams();

  const columnNames = {
    "accounts_sold_new-QTD": "Accounts New (QTD)",
    "accounts_sold_new-QTD-items": "Accounts New (QTD) Items",
    "accounts_sold_new-YTD": "Accounts New (YTD)",
    "accounts_sold_new-YTD-items": "Accounts New (YTD) Items",
    "accounts_sold_new-LQ": "Accounts New (LQ)",
    "accounts_sold_new-LQ-items": "Accounts New (LQ) Items",
    "accounts_sold_new-LY": "Accounts New (LY)",
    "accounts_sold_new-LY-items": "Accounts New (LY) Items",

    "accounts_sold_all-QTD": "Accounts Sold (QTD)",
    "accounts_sold_all-QTD-items": "Accounts Sold (QTD) Items",
    "accounts_sold_all-YTD": "Accounts Sold (YTD)",
    "accounts_sold_all-YTD-items": "Accounts Sold (YTD) Items",
    "accounts_sold_all-LQ": "Accounts Sold (LQ)",
    "accounts_sold_all-LQ-items": "Accounts Sold (LQ) Items",
    "accounts_sold_all-LY": "Accounts Sold (LY)",
    "accounts_sold_all-LY-items": "Accounts Sold (LY) Items",

    "physical_sold-QTD": "Physical (QTD)",
    "physical_sold-YTD": "Physical (YTD)",
    "physical_sold-LQ": "Physical (LQ)",
    "physical_sold-LY": "Physical (LY)",

    "unit_sold-QTD": "Units Sold (QTD)",
    "unit_sold-YTD": "Units Sold (YTD)",
    "unit_sold-LQ": "Units Sold (LY)",
    "unit_sold-YQR": "Units Sold (LQ)",
  };

  const findLastQuarter = () => {
    if (currentQuarter === 1) {
      return 4;
    } else {
      return currentQuarter - 1;
    }
  };
  const { userInfo } = useStore();
  var summaryAccounts = [];
  var summarySold = [];
  var currentQuarter = moment().quarter();
  var currentYear = moment().year();
  var lastYear = moment().year() - 1;
  var lastQuarter = findLastQuarter();

  useEffect(() => {
    getMarketSummaryDetails().then(() => {
      setIsLoaded(true);
    });
  }, []);

  const getMarketSummaryDetails = async () => {
    const accountRes = await API.get(
      "backendGateway",
      "/accounts/list/summary/marketgraph",
      {
        queryStringParameters: {
          mid: userInfo.mid, //27
          listId: id, // 59
        },
      }
    );

    if (accountRes && accountRes.results.market) {
      let filtered = accountRes.results.market.filter((x) =>
        [
          "ON-PREMISE",
          "OFF-PREMISE",
          "SAMPLE",
          "DISTRIBUTOR",
          "UNKNOWN",
        ].includes(x.premise)
      );
      summaryAccounts.push(...filtered);
      let filteredSold = accountRes.results.sold.filter((x) =>
        [
          "ON-PREMISE",
          "OFF-PREMISE",
          "SAMPLE",
          "DISTRIBUTOR",
          "UNKNOWN",
        ].includes(x.premise)
      );
      summarySold.push(...filteredSold);
    }

    if (!isLoaded) {
      createFormattedData();
      setData({ ...formattedData });
      setFilterArray(Object.keys(formattedData[Object.keys(formattedData)[0]]));
    }
  };

  const createFormattedData = () => {
    summarySold.map((object) => {
      Object.keys(object).map((key) => {
        if (
          ![
            "premise",
            "quarter",
            "year",
            "unit_sold",
            "nine_sold",
            "bbl",
          ].includes(key)
        ) {
          if (object.quarter == currentQuarter && object.year == currentYear) {
            formattedData[object.premise][
              columnNames[key.toLocaleLowerCase() + "-QTD"]
            ] += Math.round(object[key]);
          }
          if (object.year === currentYear) {
            formattedData[object.premise][
              columnNames[key.toLocaleLowerCase() + "-YTD"]
            ] += Math.round(object[key]);
          }
          if (currentQuarter === 1) {
            if (object.year === lastYear && object.quarter === lastQuarter) {
              formattedData[object.premise][
                columnNames[key.toLocaleLowerCase() + "-LQ"]
              ] += Math.round(object[key]);
            }
          } else {
            if (object.year === currentYear && object.quarter === lastQuarter) {
              formattedData[object.premise][
                columnNames[key.toLocaleLowerCase() + "-LQ"]
              ] += Math.round(object[key]);
            }
          }

          if (object.year === lastYear) {
            formattedData[object.premise][
              columnNames[key.toLocaleLowerCase() + "-LY"]
            ] += Math.round(object[key]);
          }
        }
      });
    });
    summaryAccounts.map((object) => {
      if (
        object.quarter === currentQuarter &&
        object.year === currentYear &&
        object.type === "NEW"
      ) {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_new" + "-QTD-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_new" + "-QTD-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_new" + "-QTD"]
        ] = Math.round(currentSet.size);
      }
      if (object.year === currentYear && object.type === "NEW") {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_new" + "-YTD-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_new" + "-YTD-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_new" + "-YTD"]
        ] = Math.round(currentSet.size);
      }
      if (
        object.year === lastYear &&
        object.quarter === lastQuarter &&
        object.type === "NEW"
      ) {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_new" + "-LQ-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_new" + "-LQ-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_new" + "-LQ"]
        ] = Math.round(currentSet.size);
      }
      if (object.year === lastYear && object.type === "NEW") {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_new" + "-LY-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_new" + "-LY-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_new" + "-LY"]
        ] = Math.round(currentSet.size);
      }
      if (
        object.quarter === currentQuarter &&
        object.year === currentYear &&
        object.type === "ALL"
      ) {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_all" + "-QTD-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_all" + "-QTD-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_all" + "-QTD"]
        ] = Math.round(currentSet.size);
      }
      if (object.year === currentYear && object.type === "ALL") {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_all" + "-YTD-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_all" + "-YTD-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_all" + "-YTD"]
        ] = Math.round(currentSet.size);
      }
      if (
        object.year === lastYear &&
        object.quarter === lastQuarter &&
        object.type === "ALL"
      ) {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_all" + "-LQ-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_all" + "-LQ-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_all" + "-LQ"]
        ] = Math.round(currentSet.size);
      }
      if (object.year === lastYear && object.type === "ALL") {
        let currentSet =
          allAccountSetData[object.premise][
            columnNames["accounts_sold_all" + "-LY-items"]
          ];
        object.distinctAccountIds.forEach((item) =>
          currentSet.add(Number(item))
        );
        allAccountSetData[object.premise][
          columnNames["accounts_sold_all" + "-LY-items"]
        ] = currentSet;
        formattedData[object.premise][
          columnNames["accounts_sold_all" + "-LY"]
        ] = Math.round(currentSet.size);
      }
    });
  };

  const handleFilter = (key) => () => {
    if (key === -1) {
      setData({ ...formattedData });
      setItemsToBeFilteredOut([]);
    } else if (key === -2) {
      setData({
        "ON-PREMISE": {},
        "OFF-PREMISE": {},
        DISTRIBUTOR: {},
      });
      setItemsToBeFilteredOut(
        Object.keys(data[Object.keys(formattedData)[0] || []]) || []
      );
    } else if (key === -3) {
      setData({ ...formattedData });
      setItemsToBeFilteredOut([]);
    } else {
      if (itemsToBeFilteredOut.includes(key)) {
        setItemsToBeFilteredOut(
          itemsToBeFilteredOut.filter((string) => string !== key)
        );
        data[Object.keys(data)[0] || []][key] =
          formattedData[Object.keys(data)[0] || []][key];
        data[Object.keys(data)[1] || []][key] =
          formattedData[Object.keys(data)[1] || []][key];
        setData({ ...data });
      } else {
        setItemsToBeFilteredOut([...itemsToBeFilteredOut, key]);
        data[Object.keys(data)[0] || []] = removeKey(
          key,
          data[Object.keys(data)[0] || []]
        );
        setData({ ...data });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setData({ ...data });
    if (
      JSON.stringify(itemsToBeFilteredOut.sort()) ===
      JSON.stringify(
        Object.keys(formattedData[Object.keys(formattedData)[0]]).sort()
      )
    ) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const removeKey = (key, { [key]: _, ...rest }) => rest;

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          width: "95%",
          height: "95%",
          borderRadius: "20px",
          padding: "15px",
          marginBottom: "10px",
        }}
      >
        <header style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ fontWeight: "500", marginBottom: "20px" }}>
            Market Summary Stats
          </div>
          <div style={{ marginLeft: "auto" }}>
            <div
              style={{
                marginRight: "5px",
                fontSize: "14px",
                fontWeight: "400",
                display: "inline-block",
              }}
            >
              Pretty Data?{" "}
            </div>
            <Switch
              checked={pretty}
              onChange={() => setPretty(!pretty)}
              size={"small"}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Button onClick={() => setOpen(true)}>
              <FilterListOutlinedIcon />
            </Button>
          </div>

          <Dialog
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Select items you wish to filter out"}
            </DialogTitle>
            <List>
              {itemsToBeFilteredOut.length === 0 ? (
                <ListItem
                  style={{
                    paddingRight: "0px",
                    flex: "auto",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    style={{
                      flex: "auto",
                      flexDirection: "row",
                      width: "100%",
                    }}
                    role={undefined}
                    onClick={handleFilter(-2)}
                    dense
                  >
                    <div
                      style={{
                        marginRight: "auto",
                        fontWeight: 600,
                        width: "100%",
                        fontSize: 16,
                      }}
                    >
                      Select All
                    </div>
                    <ListItemIcon style={{ marginLeft: "auto" }}>
                      <Checkbox
                        style={{ marginLeft: "auto", paddingRight: 5 }}
                        checked={true}
                        disableRipple
                      />
                    </ListItemIcon>
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  style={{
                    paddingRight: "0px",
                    flex: "auto",
                    flexDirection: "row",
                  }}
                  disablePadding
                >
                  <Button
                    style={{
                      flex: "auto",
                      flexDirection: "row",
                      width: "100%",
                    }}
                    role={undefined}
                    onClick={handleFilter(-1)}
                    dense
                  >
                    <div
                      style={{
                        marginRight: "auto",
                        fontWeight: 600,
                        width: "100%",
                        fontSize: 16,
                      }}
                    >
                      Select All
                    </div>
                    <ListItemIcon style={{ marginLeft: "auto" }}>
                      <Checkbox
                        style={{ marginLeft: "auto", paddingRight: 5 }}
                        checked={false}
                        disableRipple
                      />
                    </ListItemIcon>
                  </Button>
                </ListItem>
              )}

              {filterArray.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    style={{
                      paddingRight: "0px",
                      flex: "auto",
                      flexDirection: "row",
                    }}
                    key={value}
                    disablePadding
                  >
                    <Button
                      style={{
                        flex: "auto",
                        flexDirection: "row",
                        width: "100%",
                      }}
                      role={undefined}
                      onClick={handleFilter(value)}
                      dense
                    >
                      <ListItemText id={labelId} primary={value} />
                      <ListItemIcon style={{ marginLeft: "auto" }}>
                        <Checkbox
                          style={{ marginLeft: "auto", paddingRight: 5 }}
                          checked={!itemsToBeFilteredOut.includes(value)}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                    </Button>
                  </ListItem>
                );
              })}
            </List>
            <Button
              style={{
                width: "95%",
                color: "white",
                backgroundColor: "rgb(33, 96, 204)",
                marginLeft: "2.5%",
                marginBottom: 10,
                outline: "none",
              }}
              color="primary"
              onClick={handleClose}
              autoFocus
            >
              Done
            </Button>
          </Dialog>
        </header>
        {!error ? (
          <>
            {!isLoaded && (
              <>
                <CircularProgress />
              </>
            )}
            {!pretty && isLoaded && <DataTable data={data} />}
            {pretty && isLoaded && <MarketSummaryChart data={data} />}
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              src="/static/images/SpaceMan.svg"
              width={"70%"}
              style={{ maxHeight: "350px" }}
              alt=""
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MarketSummaryStats;
