import {
  Box,
  Breadcrumbs,
  CardContent,
  Grid,
  Card,
  Typography,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import { useEffect, useState, useMemo } from "react";
import MediaCard from "./MediaCard";
import { Replay, Delete } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import InfoCard from "./InfoCard";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GenericModal from "../modals/GenericModal";
import mediaAPI from "../../api/media";
import { useStore } from "../../stores/StoreContext";
import { useNavigate } from "react-router";

const backFolder = {
  isBack: true,
  name: "Go Back",
  icon: <Replay style={{ fontSize: "40", color: "black" }} />,
};

const MediaFolder = observer(
  ({ folder_id, processedFolders, files, setFiles }) => {
    const [deleteFolderDialog, setDeleteFolderDialog] = useState(false);
    const { setFolders, userInfo } = useStore();
    const { folders, manufacturer } = useStore();
    const navigate = useNavigate();

    const folder = useMemo(() => {
      const match = processedFolders.find(
        (f) => f.folder_id === parseInt(folder_id, 10)
      );
      return (
        match || {
          files: [],
          name: "",
        }
      );
    }, [processedFolders, folder_id]);

    const [modifiedFolder, setModifiedFolder] = useState({ ...folder });
    useEffect(() => {
      setModifiedFolder({ ...folder });
    }, [folder]);

    const { manufacturer_displayname } = manufacturer;
    const crumbs = [
      {
        text: `${manufacturer_displayname} files`,
        link: "marketingFiles",
      },
      { text: folder.name, link: "folder", folder_id: folder_id },
    ];
    const infoCards = [backFolder];

    const handleBack = (item) => {
      navigate(-1);
    };

    const handleCard = (item) => {
      navigate(`/app/media/folder/${folder_id}/${item.gg_storage_id}`);
    };

    const handleBreadCrums = (c) => {
      switch (c.link) {
        case "marketingFiles":
          navigate(`/app/media/`);
          break;
        case "folder":
          navigate(`/app/media/folder/${c.folder_id}`);
          break;
        default:
          break;
      }
    };
    const deleteCurrentFolder = async (folder) => {
      console.log(folder.folder_id);
      const { folder_id } = folder;

      await mediaAPI.deleteFolder(folder_id).then(() => {
        setFolders(folders.filter((fold) => fold.folder_id !== folder_id));
        setDeleteFolderDialog(false);
        navigate(`/app/media/`);
      });
    };
    const updateCurrentFolder = async () => {
      const { folder_id, files } = modifiedFolder;
      const { uid, manufacturer_id } = userInfo;
      const newFolderData = {
        manufacturer_id,
        user_id: uid,
        folder_id,
        folder_name: modifiedFolder.name,
        files,
      };

      await mediaAPI.updateFolder(newFolderData);
      const newFolderList = folders.map((fold) =>
        fold.folder_id === folder.folder_id
          ? {
              ...fold,
              name: modifiedFolder.name,
            }
          : fold
      );

      setFolders(newFolderList);
    };

    const folderNameChange = (event) => {
      const name = event.target.value;
      setModifiedFolder({
        ...modifiedFolder,
        name,
      });
    };
    return (
      <>
        <Box mb={3}>
          <Breadcrumbs separator={<ChevronRightIcon />}>
            {crumbs.map((c, index) => {
              return (
                <Link onClick={() => handleBreadCrums(c)} key={index}>
                  {c.text}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
        <Card style={{ marginBottom: "1rem" }}>
          <Box p={1} display="flex" alignItems={"center"}>
            <TextField
              value={modifiedFolder.name}
              onChange={folderNameChange}
              label="Folder name"
            />
            <Box pl={1}>
              <Button
                onClick={updateCurrentFolder}
                variant="contained"
                disabled={
                  modifiedFolder.name === folder.name ||
                  modifiedFolder.name.length === 0
                }
              >
                Rename
              </Button>
            </Box>
          </Box>
        </Card>
        <Grid container spacing={3}>
          {infoCards.map((item, index) => (
            <Grid key={index} item lg={2} md={4} xs={12}>
              <InfoCard handleCard={handleBack} item={item} />
            </Grid>
          ))}
          <Grid item lg={2} md={4} xs={12}>
            <Card>
              <Box p={1}>
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <Delete onClick={() => setDeleteFolderDialog(true)} />
                  </Box>
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="body1"
                    style={{
                      marginTop: "5px",
                      overflowWrap: "break-word",
                    }}
                    onClick={() => setDeleteFolderDialog(true)}
                  >
                    Delete folder
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container spacing={3}>
            {folder.files.map((file, index) => (
              <Grid key={file.gg_storage_id} item lg={2} md={4} xs={12}>
                <MediaCard
                  handleCard={handleCard}
                  item={file}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <GenericModal
          title={`Delete ${folder.name} ?`}
          open={deleteFolderDialog}
          onClose={() => setDeleteFolderDialog(false)}
          confirmAction={() => deleteCurrentFolder(folder)}
        ></GenericModal>
      </>
    );
  }
);
export default MediaFolder;
