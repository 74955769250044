import { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import bookmarks from "../../api/bookmarks";
import {
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "../../components/Page";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minWidth: "100%",
    padding: theme.spacing(3),
  },
  button: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const BookMarks = observer(() => {
  const {
    userInfo,
    allStates,
    setSelectedStates,
    allDistributors,
    setSelectedDistributors,
    allBrands,
    setSelectedBrands,
    useBookmark,
    bookmarkUUID,
    completeDistBrandData,
  } = useStore();

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { uuid } = useParams();
  const [marks, setMarks] = useState([]);
  const [copied, setCopied] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookmark, setBookmark] = useState({});
  const [notFound, setNotFound] = useState(false);

  const classes = useStyles();

  const badUuid = useMemo(() => {
    const s = searchParams;
    const bad = s.get("uuid");
    if (!bad) return "";
    return bad;
  }, [searchParams]);

  const loadBookMarks = () => {
    setLoading(true);
    const { user_id: uid, manufacturer_id: mid } = userInfo;
    bookmarks
      .getBookMarks({
        mid,
        uid,
      })
      .then(({ results }) => setMarks(results))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const loadBookMark = () => {
    bookmarks
      .getBookMark(uuid)
      .then(({ result }) => {
        setBookmark(result);
      })
      .catch((error) => {
        navigate("/app/bm/404?uuid=" + uuid);
        console.error(error);
      });
  };

  const deleteMark = (bookmark) => {
    bookmarks
      .deleteBookMark(bookmark.uuid)
      .then(() => {
        setMarks(marks.filter((m) => m.uuid !== bookmark.uuid));
        setDeleted(true);
      })
      .catch((error) => console.error(error));
  };

  const copyBookmarkUrl = (bookmark) => {
    const bookmarkUrl = window.location.origin + "/app/bm/" + bookmark.uuid;
    console.log({ bookmarkUrl });
    navigator.clipboard.writeText(bookmarkUrl).then(
      () => {
        setCopied(true);
        /* success */
      },
      () => {
        /* failure */
      }
    );
  };

  useEffect(() => {
    if ((userInfo.user_id && !uuid) || badUuid.length) {
      loadBookMarks();
    } else if (uuid !== "404") {
      loadBookMark(uuid);
    }
  }, [userInfo, uuid, notFound]);

  useEffect(() => {
    if (!Object.hasOwn(bookmark, "qs_state") || allStates.length === 0) {
      return;
    }
    useBookmark(bookmark);
  }, [bookmark, allStates]);

  useEffect(() => {
    if (completeDistBrandData.length > 0 && bookmarkUUID.length) {
      if (bookmark.qs_state?.reportPreset) {
        navigate(
          bookmark.page_path + "?preset=" + bookmark.qs_state.reportPreset
        );
      } else {
        navigate(bookmark.page_path);
      }
    }
  }, [bookmarkUUID, bookmark, completeDistBrandData]);

  return (
    <Page className={classes.root} title="Bookmarks">
      <div align="center">
        {loading && (
          <div>
            {" "}
            <CircularProgress />{" "}
          </div>
        )}
      </div>
      <Grid container spacing={3} className={classes.root}>
        <Grid item lg={12} md={12} xs={12}>
          <Card className={classes.button}>
            <CardHeader title="Bookmarks">Bookmarks</CardHeader>

            <CardContent className={classes.root}>
              {uuid === "404" && (
                <Alert severity="error">
                  Could not find a bookmark matching id: {badUuid}
                </Alert>
              )}
              <ul>
                {marks.map((m) => (
                  <li>
                    {m.page_name} - <Link to={m.uuid}>Open Bookmark</Link>-
                    <Button onClick={() => copyBookmarkUrl(m)}>
                      Copy Link
                    </Button>
                    <Button onClick={() => deleteMark(m)}>Delete</Button>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
          <Snackbar
            open={deleted}
            autoHideDuration={6000}
            severity="success"
            onClose={() => {
              setDeleted(false);
            }}
          >
            <MuiAlert severity="success">Deleted bookmark</MuiAlert>
          </Snackbar>
          <Snackbar
            open={copied}
            autoHideDuration={6000}
            severity="success"
            onClose={() => {
              setCopied(false);
            }}
          >
            <MuiAlert severity="success">Copied link</MuiAlert>
          </Snackbar>
        </Grid>
      </Grid>
    </Page>
  );
});

export default BookMarks;
