import { useEffect } from "react";
import LogoWhite from "./branding/LogoWhite";

const Splash = ({ message, concise }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    document.documentElement.style.overflowY = "clip";
    return function free() {
      document.documentElement.style.overflowY = "auto";
    };
  }, []);
  return (
    <div className={`splash${concise ? " concise" : ""}`}>
      <div className="splash-inner">
        <LogoWhite />
        <div className="splash-message">{message}</div>
      </div>
    </div>
  );
};

export default Splash;
