import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import distributorsAPI from "../../../api/distributors.js";
import { useStore } from "../../../stores/StoreContext";

const FinanceView = () => {
  const [financialData, setFinancialData] = useState({});
  const [quarters, setQuarters] = useState([]);
  const store = useStore();
  const { userInfo } = store;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await distributorsAPI.getDistributorFinancialComparison(
          userInfo.mid
        );
        console.log("data from Lambda REST API: ", data);
        if (data.results.length) {
          const financeResults = JSON.parse(data.results[0].financial_data);
          setFinancialData(financeResults);
          setQuarters(Object.keys(financeResults).sort());
        }
      } catch (err) {
        console.log("error fetching data..", err);
      }
    };

    fetchData();
  }, []);
  const currencyFormatter = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(value);
  };
  const renderSection = (sectionKey, title) => (
    <>
      <TableRow>
        <TableCell
          style={{ background: "#eee", fontWeight: "bold" }}
          colSpan={quarters.length + 2}
        >
          {title}
        </TableCell>
      </TableRow>
      {Object.keys(financialData[quarters[0]]?.[sectionKey] || {}).map(
        (key) => (
          <TableRow key={key}>
            <TableCell component="th" scope="row">
              {key.replace(/_/g, " ").toUpperCase()}
            </TableCell>
            {quarters.map((quarter) => (
              <TableCell key={quarter}>
                {financialData[quarter]?.[sectionKey]?.[key] || "-"}
              </TableCell>
            ))}
          </TableRow>
        )
      )}
    </>
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="financial data table">
        <TableHead>
          <TableRow>
            <TableCell>IDN Aggregated Dashboard</TableCell>
            {quarters.map((quarter) => (
              <TableCell key={quarter}>{quarter}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderSection("growthMetrics", "Growth Metrics")}
          {renderSection("incomeStatement", "Income Statement")}
          {renderSection(
            "commonSizeIncomeStatement",
            "Common Size Income Statement"
          )}
          {renderSection("kpis", "KPIs")}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinanceView;
