import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RegionCompare from "../../../components/distributor/regions/RegionCompare";
import Page from "../../../components/Page";

const DistributorRegionsView = ({ route }) => {
  const paramList = useParams().regionList;
  const regions = paramList ? paramList.split(",") : [];
  return (
    <div>
      <Page style={{ padding: "24px" }}>
        <RegionCompare />
      </Page>
    </div>
  );
};

export default DistributorRegionsView;
