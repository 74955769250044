import _ from "lodash";

const locations = {
  locations: [],
  addLocation(location) {
    this.locations.push(location);
  },
  addLocations(locations) {
    this.locations = _.uniqBy([...this.locations, ...locations], "name").sort(
      (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
    );
  },
  resetLocations() {
    this.locations = [];
    this.gMarkers.forEach((marker) => {
      google.maps.event.clearInstanceListeners(marker.marker); // eslint-disable-line
      marker.marker.setMap(null);
    });
    this.gMarkers = [];
  },
  searchRadius: 1000,
  coords: { lat: 40.756795, lng: -73.954298 },
  setCoords(coords) {
    this.coords = coords;
  },
  zoom: 16,
  setZoom(zoom) {
    this.zoom = zoom;
  },
  showMap: false,
  setShowMap(bool) {
    this.showMap = bool;
  },
  lastSearch: "",
  setLastSearch(text) {
    this.lastSearch = text;
  },
  redMarkers: [],
  setRedMarkers(arr) {
    this.redMarkers = arr;
  },
  gMarkers: [],
  setGMarkers(markers) {
    this.gMarkers = markers;
  },
  customers: [],
  setCustomers(customers) {
    this.customers = customers.filter((customer) => !!customer);
  },
  addCustomer(customer) {
    if (customer) {
      this.customers = [...this.customers, customer];
    }
  },
  addCustomers(customers) {
    this.customers = [...this.customers, ...customers];
  },
  schedules: [],
  setSchedules(schedules) {
    this.schedules = schedules;
  },
  listId: null,
  setListId(listId) {
    this.listId = listId;
  },
};

export default locations;
