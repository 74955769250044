import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "../../../stores/StoreContext";
import distributorsAPI from "../../../api/distributors.js";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    padding: theme.spacing(2),
    overflow: "hidden",
  },
}));

const ContactModal = ({
  onClose,
  contact,
  setContact,
  open,
  isEdit,
  ...rest
}) => {
  const classes = useStyles();

  const { id } = useParams();
  const [submitInProgress, setSubmitButton] = useState(false);
  const [buttonText, setButtonText] = useState("CREATE");
  const [titleText, setTitleText] = useState("Add");
  const store = useStore();
  const { userInfo } = store;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    reset({});
    onClose();
  };
  useEffect(() => {
    if (isEdit) {
      setButtonText("UPDATE");
      setTitleText("Edit");
    } else {
      setButtonText("CREATE");
      setTitleText("Add");
    }
  }, [isEdit]);
  useEffect(() => {
    reset(contact);
  }, [contact]);

  const handleChange = (event) => {
    console.log(event);
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };

  const addContact = async () => {
    setSubmitButton(true);
    contact.user_id = userInfo.user_id;
    const data = await distributorsAPI.addDistributorContact(
      userInfo.mid,
      id,
      contact
    );
    setSubmitButton(false);
    if (data.status < 400) {
      let _contact = {
        contact_id: data.results.contact_id,
        email: contact.email,
        name: contact.name,
        phone: contact.phone,
        title: contact.title,
        user_id: contact.user_id,
      };
      let _contacts = [...store.distributorContactList];
      _contacts.push(_contact);
      store.setDistributorContact([..._contacts]);
    }
  };
  const updateContact = async () => {
    setSubmitButton(true);
    const data = await distributorsAPI.updateDistributorContact(
      userInfo.mid,
      id,
      contact.contact_id,
      contact
    );
    setSubmitButton(false);
    if (data) {
      let _contacts = [...store.distributorContactList];
      let _contact = _contacts.find(
        (contactData) => contactData.contact_id === contact.contact_id
      );
      if (_contact) {
        _contact.email = contact.email;
        _contact.name = contact.name;
        _contact.phone = contact.phone;
        _contact.title = contact.title;
        _contact.user_id = contact.user_id;
        store.setDistributorContact([..._contacts]);
      }
    }
  };

  const onSubmit = async () => {
    if (isEdit) {
      await updateContact();
    } else {
      await addContact();
    }
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <DialogTitle id="simple-dialog-title">{titleText} Contact</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formControl}>
        <Grid container spacing={5}>
          <Grid item md={12} lg={12} xs={12}>
            <Controller
              name="name"
              // defaultValue sets the value of child component
              defaultValue={contact?.name || ""}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  // handleChange must be on the child component to work as normal
                  onChange={handleChange}
                  fullWidth
                  error={errors.name ? true : false}
                  helperText={`${
                    errors.name?.type === "required" ? "Name is required" : ""
                  }
                      ${
                        errors.name?.type === "minLength"
                          ? "Name must be at least 2 characters"
                          : ""
                      }`}
                />
              )}
              rules={{
                required: true,
                minLength: 2,
              }}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Controller
              name="title"
              // defaultValue sets the value of child component
              defaultValue={contact?.title || ""}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title"
                  variant="outlined"
                  // handleChange must be on the child component to work as normal
                  onChange={handleChange}
                  fullWidth
                  error={errors.title ? true : false}
                  helperText={`
                      ${
                        errors.title?.type === "minLength"
                          ? "Title must be at least 2 characters"
                          : ""
                      }`}
                />
              )}
              rules={{
                minLength: 2,
              }}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Controller
              name="email"
              // defaultValue sets the value of child component
              defaultValue={contact?.email || ""}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  // handleChange must be on the child component to work as normal
                  onChange={handleChange}
                  fullWidth
                  error={errors.email ? true : false}
                  helperText={`
                      ${
                        errors.email?.type === "validate" ? "Invalid email" : ""
                      }`}
                />
              )}
              rules={{
                required: false,
                validate: (value) => {
                  let _flag;
                  if (value?.length > 0) {
                    _flag = [
                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    ].every((pattern) => pattern.test(value));
                  } else {
                    _flag = true;
                  }
                  return _flag;
                },
              }}
            />
          </Grid>

          <Grid item md={12} lg={12} xs={12}>
            <Controller
              name="phone"
              // defaultValue sets the value of child component
              defaultValue={contact?.phone || ""}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  variant="outlined"
                  // handleChange must be on the child component to work as normal
                  onChange={handleChange}
                  fullWidth
                  error={errors.phone ? true : false}
                  helperText={`
                      ${
                        errors.phone?.type === "validate" ? "Invalid phone" : ""
                      }`}
                />
              )}
              rules={{
                required: false,
                validate: (value) => {
                  let _flag;
                  if (value?.length > 0) {
                    _flag = [
                      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                    ].every((pattern) => pattern.test(value));
                  } else {
                    _flag = true;
                  }
                  return _flag;
                },
              }}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Box display="flex" gridColumnGap={10}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleClose}
                type="button"
              >
                Cancel
              </Button>
              <Button
                disabled={submitInProgress}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {buttonText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ContactModal;
