import React, { useState, useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  MenuList,
  CardHeader,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  Divider,
  Card,
  ButtonGroup,
  TableHead,
  TableBody,
  CardContent,
  Paper,
  makeStyles,
  MenuItem,
  Popper,
  Grow,
  Button,
  Box,
  Tooltip,
} from "@material-ui/core";
import { Loader } from "@aws-amplify/ui-react";
import formAPI from "../../../api/forms";
import AccountVisitModal from "./AccountVisitModal";
import ResponseModal from "../../../components/forms/v3/form/ResponseModal";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Pagination } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import AccountOrderModal from "./AccountOrderModal";
import AccountFormModal from "./AccountFormModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  green: {
    color: "#fff",
    backgroundColor: "#5DBB63",
  },
  Table: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  TimelineTableCell: {
    maxWidth: "100px",
    whiteSpace: "nowrap",
    flexShrink: "0",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  TableRow: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
}));

const ListOfEvents = ({
  listOfEvents,
  callBackRefresh,
  userInfo,
  loading,
  error,
}) => {
  const filterTitles = ["All", "CheckIn", "Notes", "Depletion", "Form"];
  const [allForms, setAllForms] = useState([]);
  const [formList, setFormsList] = useState(null);
  const anchorRef = useRef(null);
  const formAnchorRef = useRef(null);
  const { id } = useParams();
  const classes = useStyles();
  const [visitList, setVisitList] = useState([]);
  const [open] = useState(false);
  const [openForm, setOpenForm] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [openVisit, setOpenVisit] = useState(false);
  const [formattedListOfEvents, setFormattedListOfEvents] = useState(
    listOfEvents.slice(0, 6)
  );
  const [formattedListOfEventsNoSlice, setFormattedListOfEventsNoSlice] =
    useState([]);
  const [isFormResponse, setIsFormResponse] = useState(false);
  const [selectedSalesData, setSelectedSalesData] = useState([]);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [dataFilter, setDataFilter] = useState("All");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [popperOpen, setPopperOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(listOfEvents.length / 6)
  );
  const [isFormResponseLoading, setIsFormResponseLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    getForms();
  }, [userInfo]);

  useEffect(() => {
    setFormattedListOfEvents(listOfEvents.slice(0, 6));
    setFormattedListOfEventsNoSlice(listOfEvents);
    setTotalPages(Math.ceil(listOfEvents.length / 6));
  }, [listOfEvents]);

  const handleToggle = () => {
    setOpenFilter((prevOpen) => !prevOpen);
  };
  const handleToggleForm = () => {
    setPopperOpen((popperOpen) => !popperOpen);
  };

  const getForms = async () => {
    setFormsList([]);
    try {
      const data = await formAPI.getFormList(userInfo.mid);

      let _data = data?.results?.map((item) => {
        let _date = new Date(item.created);

        return {
          ...item,
          created: `${_date.getDate()}-${
            _date.getMonth() + 1
          }-${_date.getFullYear()}`,
        };
      });
      let temp = _data.filter((form) => form.on_account || form.is_open);
      if (temp.length > 0) {
        // allforms is for forms matching for old versioned forms
        setAllForms(temp);
        // formList is for the popup menu
        setFormsList(
          _data
            .filter((form) => form.on_account || form.is_open)
            // show only newest iterations by form_id
            .reduce((acc, cur) => {
              const { form_uuid, form_id } = cur;
              const matchInd = acc.findIndex((a) => a.form_uuid === form_uuid);
              if (matchInd > -1) {
                if (form_id > acc[matchInd].form_id) {
                  acc[matchInd] = cur;
                }
              } else {
                acc.push(cur);
              }
              return acc;
            }, [])
        );
      } else {
        setFormsList(null);
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  const handleFormTable = async (form) => {
    console.log(form);
    setIsFormResponseLoading(form.response_id);
    let _form = allForms.find((f) => {
      return f.form_id === form.form_id;
    });
    console.log(allForms);
    if (_form) {
      let _data = {
        form_id: form?.form_id,
        response_id: form?.response_id,
      };
      let _result = await formAPI.getFormResponse(_data);
      let response = {
        form_body: _form?.form_body,
        form_name: _form?.form_name,
        data: _result?.results,
        response_id: form?.response_id,
        form_uuid: form?.form_uuid,
      };
      setOpenForm(response);
    }
    setIsFormResponseLoading(false);
    setIsFormResponse(true);
  };

  const setFilter = (filter) => {
    setCurrentPage(1);
    if (filter === "All") {
      setFormattedListOfEventsNoSlice(listOfEvents);
      setTotalPages(Math.ceil(listOfEvents.length / 6));
      setFormattedListOfEvents(listOfEvents.slice(0, 6));
    } else if (filter === "Notes") {
      setFormattedListOfEventsNoSlice(
        listOfEvents.filter(
          (event) => event.Type === filter || event.Info === "Form Name: Note"
        )
      );
      setTotalPages(
        Math.ceil(
          listOfEvents.filter(
            (event) => event.Type === filter || event.Info === "Form Name: Note"
          ).length / 6
        )
      );
      setFormattedListOfEvents(
        listOfEvents
          .filter(
            (event) => event.Type === filter || event.Info === "Form Name: Note"
          )
          .slice(0, 6)
      );
    } else if (filter === "Form") {
      setFormattedListOfEventsNoSlice(
        listOfEvents.filter(
          (event) => event.Type === filter && event.Info !== "Form Name: Note"
        )
      );
      setTotalPages(
        Math.ceil(
          listOfEvents.filter(
            (event) => event.Type === filter && event.Info !== "Form Name: Note"
          ).length / 6
        )
      );
      setFormattedListOfEvents(
        listOfEvents
          .filter(
            (event) => event.Type === filter && event.Info !== "Form Name: Note"
          )
          .slice(0, 6)
      );
    } else {
      setFormattedListOfEventsNoSlice(
        listOfEvents.filter((event) => event.Type === filter)
      );
      setTotalPages(
        Math.ceil(
          listOfEvents.filter((event) => event.Type === filter).length / 6
        )
      );
      setFormattedListOfEvents(
        listOfEvents.filter((event) => event.Type === filter).slice(0, 6)
      );
    }
    setOpenFilter(false);
    setDataFilter(filter);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenFilter(false);
    }
  }
  const handleClick = () => {
    setSelectedValue(-50);
    setOpenForm(formList[selectedIndex]);
    setIsFormResponse(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setFormattedListOfEvents(
      formattedListOfEventsNoSlice.slice((page - 1) * 6, page * 6)
    );
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setPopperOpen(false);
  };

  const handlePopperClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setPopperOpen(false);
  };
  const handleFormListRefresh = () => {
    setCurrentPage(1);
    callBackRefresh();
    setDataFilter("All");
  };

  return (
    <Card style={{ width: "100%" }} className={classes.fullHeightCard}>
      {openForm !== null && (
        <AccountFormModal
          uuid={selectedValue}
          accountId={id}
          open={true}
          form={openForm}
          onClose={() => {
            handleFormListRefresh();
            setOpenForm(null);
            setAnswers([]);
          }}
          close={() => {
            setOpenForm(null);
            setAnswers([]);
          }}
          answers={answers}
          setAnswers={setAnswers}
          isFormResponse={isFormResponse}
        />
      )}
      {openVisit && (
        <ClickAwayListener onClickAway={() => setOpenVisit(false)}>
          <AccountVisitModal
            open={true}
            selectedValue={selectedValue}
            setVisitList={setVisitList}
            visitList={visitList}
            isFormResponse={isFormResponse}
            close={() => setOpenVisit(false)}
            onClose={() => {
              setOpenVisit(false);
              handleFormListRefresh();
            }}
          />
        </ClickAwayListener>
      )}
      {orderModalOpen && (
        <AccountOrderModal
          open={orderModalOpen}
          onClose={() => setOrderModalOpen(false)}
          selectedSalesData={selectedSalesData}
        />
      )}
      <CardHeader
        title="Account Events"
        action={
          <div style={{ flexDirection: "row", width: "100%" }}>
            <Box display={"inline-block"}>
              <Box>
                {formList?.length && formList?.length > 0 ? (
                  <>
                    Create Form / Note:
                    <ButtonGroup
                      ref={formAnchorRef}
                      style={{ marginLeft: "10px" }}
                    >
                      <Button onClick={handleClick}>
                        {formList[selectedIndex].form_name}
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggleForm}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                    </ButtonGroup>
                    <Popper
                      open={popperOpen}
                      anchorEl={formAnchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{ zIndex: 10 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handlePopperClose}>
                              <MenuList id="split-button-menu">
                                {formList.map((option, index) => (
                                  <MenuItem
                                    key={option.form_name}
                                    selected={index === selectedIndex}
                                    onClick={(event) =>
                                      handleMenuItemClick(event, index)
                                    }
                                  >
                                    {option.form_name}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <>
                    {formList !== null && (
                      <>
                        {" "}
                        Create Form:
                        <Loader
                          style={{ marginLeft: 15, marginRight: 15 }}
                          size={"large"}
                        />
                      </>
                    )}
                  </>
                )}
                {formList === null ? (
                  <Button
                    disabled
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      padding: "8px",
                    }}
                  >
                    No form templates
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <div style={{ display: "inline-block", marginLeft: "15px" }}>
              <ButtonGroup>
                <Button
                  ref={anchorRef}
                  aria-controls={openFilter ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  title={dataFilter}
                  size={"large"}
                >
                  {dataFilter}
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={openFilter}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openFilter}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {filterTitles.map((title) => (
                            <MenuItem
                              key={title}
                              onClick={() => setFilter(title)}
                            >
                              {title}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        }
      />

      <Divider />

      <CardContent>
        {loading && !error ? (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Loader style={{ height: "40px" }} />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.Table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className={classes.TimelineTableCell}>
                    Date
                  </TableCell>
                  <TableCell className={classes.TimelineTableCell} align="left">
                    Type
                  </TableCell>
                  <TableCell className={classes.TimelineTableCell} align="left">
                    Contact
                  </TableCell>
                  <TableCell className={classes.TimelineTableCell} align="left">
                    Info
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {error ? (
                  <div style={{ margin: "10px" }}>
                    Timeline could not be displayed
                  </div>
                ) : null}
                {formattedListOfEvents.map((event, i) => {
                  if (event.Type === "Depletion") {
                    return (
                      <TableRow
                        className={classes.TableRow}
                        key={i}
                        onClick={() => {
                          setSelectedSalesData([event.Orders, event.Date]);
                          setOrderModalOpen(true);
                        }}
                      >
                        <TableCell
                          className={classes.TimelineTableCell}
                          component="th"
                          scope="row"
                        >
                          {
                            <img
                              src={`/static/images/icons/${event.Type}.svg`}
                              width="50px"
                              alt=""
                            />
                          }
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {" "}
                          {new Date(event.Date)
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Type}
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Contact || "Unknown"}
                        </TableCell>
                        <Tooltip
                          title={event.Info}
                          disableFocusListener
                          disableTouchListener
                          placement="top-start"
                        >
                          <TableCell
                            className={classes.TimelineTableCell}
                            align="left"
                          >
                            {event.Info}
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    );
                  } else if (event.Type === "Notes") {
                    return (
                      <TableRow
                        className={classes.TableRow}
                        key={i}
                        onClick={() => {
                          setSelectedValue(event.VisitData);
                          setOpenVisit(true);
                        }}
                      >
                        <TableCell
                          className={classes.TimelineTableCell}
                          component="th"
                          scope="row"
                        >
                          {
                            <img
                              src={`/static/images/icons/${event.Type}.svg`}
                              width="50px"
                              alt=""
                            />
                          }
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {" "}
                          {new Date(event.Date)
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Type}
                        </TableCell>
                        <Tooltip
                          title={event.Contact}
                          disableFocusListener
                          disableTouchListener
                          placement="top-start"
                        >
                          <TableCell
                            className={classes.TimelineTableCell}
                            align="left"
                          >
                            {event.Contact || "Unknown"}
                          </TableCell>
                        </Tooltip>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Info}
                        </TableCell>
                      </TableRow>
                    );
                  } else if (event.Type === "Form") {
                    return (
                      <TableRow
                        className={classes.TableRow}
                        key={i}
                        onClick={() => {
                          handleFormTable(event.Form);
                          setSelectedValue(event.Form.form_id);
                        }}
                      >
                        <TableCell
                          className={classes.TimelineTableCell}
                          component="th"
                          scope="row"
                          alt=""
                        >
                          {isFormResponseLoading === event.Form.response_id ? (
                            <Loader size="large" />
                          ) : (
                            <>
                              {event.Form.form_name === "Note" ? (
                                <img
                                  src={`/static/images/icons/Notes.svg`}
                                  width="50px"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={`/static/images/icons/Form.svg`}
                                  width="50px"
                                  alt=""
                                />
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {" "}
                          {new Date(event.Date)
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Type}
                        </TableCell>
                        <Tooltip
                          title={event.Contact}
                          disableFocusListener
                          disableTouchListener
                          placement="top-start"
                        >
                          <TableCell
                            className={classes.TimelineTableCell}
                            align="left"
                          >
                            {event.Contact || "Unknown"}
                          </TableCell>
                        </Tooltip>
                        <Tooltip
                          title={event.Info}
                          disableFocusListener
                          disableTouchListener
                          placement="top-start"
                        >
                          <TableCell
                            className={classes.TimelineTableCell}
                            align="left"
                          >
                            {event.Info}
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    );
                  } else if (event.Type === "CheckIn") {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          className={classes.TimelineTableCell}
                          component="th"
                          scope="row"
                        >
                          {
                            <img
                              src={`/static/images/icons/${event.Type}.svg`}
                              width="50px"
                              alt=""
                            />
                          }
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {" "}
                          {new Date(event.Date)
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </TableCell>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Type}
                        </TableCell>
                        <Tooltip
                          title={event.Contact}
                          disableFocusListener
                          disableTouchListener
                          placement="top-start"
                        >
                          <TableCell
                            className={classes.TimelineTableCell}
                            align="left"
                          >
                            {event.Contact || "Unknown"}
                          </TableCell>
                        </Tooltip>
                        <TableCell
                          className={classes.TimelineTableCell}
                          align="left"
                        >
                          {event.Info}
                          {""}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Stack alignItems="center" style={{ marginTop: "20px" }}>
          <Pagination
            count={totalPages}
            onChange={(e, page) => handlePageChange(page)}
            page={currentPage}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ListOfEvents;
