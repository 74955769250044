import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import Footer from "./Footer";
import MegaFilter from "../../components/megaFilter";
import useSalesData from "../../components/boot/useSalesDataHook";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "visible",
    width: "100%",
    flexDirection: "column",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "visible",
    paddingTop: 64,
    paddingBottom: 60,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  miniWrapper: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 60,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "visible",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "visible",
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [mini, setMini] = useState(true);
  // eslint-disable-next-line no-unused-vars
  useSalesData();

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => {
          setMobileNavOpen(true);
          setMini(false);
        }}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        mini={mini}
        setMini={setMini}
      />
      <div className={`${classes.wrapper} ${mini ? classes.miniWrapper : ""}`}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <MegaFilter mini={mini} />
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardLayout;
