import React from "react";
import { AwsRum } from "aws-rum-web";
import { Auth } from "aws-amplify";

// Using rum as a js module, defined here: https://github.com/aws-observability/aws-rum-web/blob/main/docs/npm_installation.md
// hackery, to allow RUM to fail on application load without impacting UX
let awsRum = null;

// We have to use a class for error boundaries, hooks don't have an equivalent
export class RumTelemetricWrapper extends React.Component {
  state = {
    awsRum: null,
  };

  componentDidMount() {
    try {
      if (process.env.NODE_ENV !== "production") return

      awsRum = new AwsRum(
        "fdab9de0-1fc6-407a-9b50-3f6d8aca2096",
        "1.0.0",
        "us-east-1",
        {
          sessionSampleRate: 1,
          guestRoleArn:
            "arn:aws:iam::664909534981:role/amplify-amplifyquicksightdas-dev-91529-unauthRole",
          identityPoolId: "us-east-1:c1a4fa6e-7345-4a31-9686-ed2c541b9ab9",
          endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
          telemetries: ["performance", "errors", "http"],
          allowCookies: true,
          enableXRay: false,
        }
      );
      this.setState({ awsRum });
    } catch (err) {
      console.error("problem initialising aws RUM...");
    }
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    const authenticatedChanged =
      this.props.authenticated !== prevProps.authenticated;
    if (this.state.awsRum) {
      if (locationChanged) awsRum.recordPageView(this.props.location.pathname);
      if (authenticatedChanged) {
        Auth.currentCredentials().then((c) =>
          this.state.awsRum.setAwsCredentials(c)
        );
      }
    }
  }

  componentDidCatch(error, info) {
    if (this.state.awsRum) {
      this.state.awsRum.recordError(error);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}
