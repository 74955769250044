import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import formAPI from "../../api/forms";

import Page from "../../components/Page";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Add, Delete, Edit, Assignment } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import { useEffect, useMemo } from "react";
import { useStore } from "../../stores/StoreContext";
import { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react-lite";
import GenericModal from "../../components/modals/GenericModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    margin: "2px",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

const FormsList = observer(() => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo, formList, setFormsList } = useStore();
  const [deleting, setDeleting] = useState(null);
  const [showDrafts, setShowDrafts] = useState(false);
  const loadForms = () => {
    const { manufacturer_id } = userInfo;
    // TODO: make boolean for drafts conditional to manager
    formAPI.getFormList(manufacturer_id).then((res) => {
      setFormsList(res.results);
      console.log(res.results);
    });
  };

  useEffect(() => {
    loadForms();
  }, [userInfo]);

  const deleteForm = (form) => {
    const { form_uuid, form_id } = form;
    console.log("deleting", form);
    if (form.is_open) {
      formAPI.deleteForm(form_uuid).then((res) => {
        setFormsList(formList.filter((f) => f.form_uuid !== form_uuid));
        setDeleting(null);
      });
    } else {
      formAPI.deleteFormVersion(form_uuid, form_id).then((res) => {
        setFormsList(formList.filter((f) => f.form_id !== form_id));
        setDeleting(null);
      });
    }
  };

  const forms = useMemo(() => {
    return Object.values(
      formList
        .filter((f) => f.is_open)
        .reduce((acc, cur) => {
          const { form_id, form_uuid, created, responseCount } = cur;
          if (form_uuid) {
            if (!acc[form_uuid]) {
              acc[form_uuid] = cur;
              return acc;
            }
            const rc = responseCount + acc[form_uuid].responseCount;
            if (created > acc[form_uuid].created) {
              acc[form_uuid] = {
                ...cur,
                responseCount: rc,
                is_open: acc[form_uuid].is_open || cur.is_open,
              };
            } else {
              acc[form_uuid].responseCount = rc;
            }
          } else {
            acc[form_id] = cur;
          }
          return acc;
        }, {})
    );
  }, [formList]);

  const drafts = useMemo(() => {
    return formList.filter((f) => !f.is_open);
  }, [formList]);

  const columns = [
    { field: "form_name", headerName: "Form Name", width: 150 },
    // { field: "form_uuid", headerName: "UUID", width: 350 },
    {
      field: "responseCount",
      headerName: "Responses",
      width: 100,
      renderCell: (params) => {
        return params.row.responseCount > 0 ? (
          <Link to={`/app/forms/${params.row.form_uuid}/responses`}>
            {params.row.responseCount} responses
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      field: "newest",
      headerName: "Latest",
      width: 100,
      renderCell: (params) => {
        return params.row.newest ? (
          <Link
            to={`/app/forms/${params.row.form_uuid}/responses/${params.row.newest.response_id}`}
          >
            {DateTime.fromISO(params.row.newest.created).toRelative()}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      field: "created",
      headerName: "Updated",
      width: 100,
      renderCell: (params) => {
        return DateTime.fromISO(params.row.created).toRelative();
      },
    },
    {
      field: "is_open",
      headerName: "Live",
      width: 50,
    },
    {
      field: "form_actions",
      headerName: "",
      width: 360,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box display="flex">
            <Box px={1}>
              <Button
                onClick={() =>
                  navigate(
                    `/app/forms/builder/${params.row.form_uuid}/${params.row.form_id}`
                  )
                }
                endIcon={<Edit />}
              >
                Edit
              </Button>
            </Box>
            {!showDrafts && (
              <Box px={1} minWidth={"120px"}>
                {params.row.on_account ? (
                  <Typography variant="overline">Account Only</Typography>
                ) : (
                  <Button
                    onClick={() =>
                      navigate(`/app/forms/${params.row.form_uuid}`)
                    }
                    endIcon={<Assignment />}
                    color="primary"
                  >
                    Respond
                  </Button>
                )}
              </Box>
            )}
            <Box px={1}>
              <DeleteButton
                onClick={() => setDeleting(params.row)}
                endIcon={<Delete />}
                color="primary"
                size="small"
              >
                Delete
              </DeleteButton>
            </Box>
          </Box>
        );
      },
    },
  ];
  return (
    <Page title="Forms">
      <Container className={classes.root}>
        <Box>
          <Card>
            <CardContent>
              <Box display="flex">
                <Box mb={2}>
                  <Button
                    onClick={() => navigate("/app/forms/builder")}
                    variant="contained"
                    color="primary"
                    endIcon={<Add />}
                  >
                    New Form
                  </Button>
                </Box>
                <Box display="flex" pl={2}>
                  <Box pr={1}>
                    <Button
                      onClick={() => setShowDrafts(false)}
                      variant="contained"
                      color={showDrafts ? "default" : "secondary"}
                    >
                      {"Live Forms"}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => setShowDrafts(true)}
                      variant="contained"
                      color={!showDrafts ? "default" : "secondary"}
                    >
                      {"Drafts"}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <div>
                <DataGridPro
                  rows={showDrafts ? drafts : forms}
                  autoHeight
                  getRowId={(item) => item.form_id}
                  rowHeight={30}
                  columns={columns}
                />
              </div>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <GenericModal
        open={deleting}
        onClose={() => setDeleting(null)}
        title={`Delete form: ${deleting ? deleting.form_name : ""}?`}
        confirmAction={() => deleteForm(deleting)}
      ></GenericModal>
    </Page>
  );
});

export default FormsList;
