import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { LicenseInfo } from "@mui/x-license-pro";
import { Amplify, Auth, API } from "aws-amplify";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import ReactGA from "react-ga4";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import routes from "./routes";
import awsconfig from "./aws-exports";
import Splash from "./components/Splash";
import DeactivatedUserSplash from "./components/DeactivatedUserSplash";
import { RumTelemetricWrapper } from "./components/aws/rum";
import { useStore } from "./stores/StoreContext";
import { useLocation } from "react-router-dom";
import useApi from "./hooks/useApi";
import usersApi from "./api/users";
import * as Sentry from "@sentry/react";
import "./sass/style.scss";

const useLocalhostBackend = process.env.REACT_APP_TARGET_LOCALHOST_ENV || false;

if (useLocalhostBackend) console.log("React app in localhost dev mode");

Amplify.configure({
  ...awsconfig,
  aws_cloud_logic_custom: null,
  API: {
    endpoints: awsconfig["aws_cloud_logic_custom"].map((value) => {
      return {
        name: value.name,
        endpoint: useLocalhostBackend
          ? "http://localhost:4000"
          : value.endpoint,
        custom_header: async () => {
          const token = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
            "x-st-api-client": "browser",
            "x-st-api-client-version": process.env.REACT_APP_COMMIT_SHA,
          };
        },
      };
    }),
  },
});

const AppAuthentication = () => {
  const routing = useRoutes(routes);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const { authStatus, signOut } = useAuthenticator((context) => [
    context.authStatus,
  ]);
  const putUsersApi = useApi(usersApi.putUsers);

  const store = useStore();
  const { setUserInfo, clearUserInfo, userInfo, setManufacturer } = store;

  const location = useLocation();

  useEffect(() => {
    async function postUser(user) {
      putUsersApi.request(user);
    }
    async function getUserData(userIdentitykey) {
      try {
        const data = await API.get("backendGateway", "/users/me");
        console.log("data from Lambda REST API: ", { data });
        const user = data.results[0];
        if (user.user_identitykey !== userIdentitykey) {
          await postUser({
            ...user,
            user_identitykey: userIdentitykey,
          });
        }
        const userObject = {
          ...user,
          user_identitykey: userIdentitykey,
        };
        setUserInfo(userObject);
        let mid = user.manufacturer_id;
        let manufacturer = {
          manufacturer_id: mid,
        };
        // get manufacturer
        try {
          manufacturer = await API.get("backendGateway", "/manufacturers", {
            queryStringParameters: {
              mid,
            },
          });
          // console.log({ manufacturer });
          const sentObj = {
            email: user.user_email,
            user_id: user.user_id,
            username: user.user_displayname,
            manufacturer: manufacturer.manufacturer_displayname,
            manufacturer_id: manufacturer.manufacturer_id,
          };
          Sentry.setUser(sentObj);
        } catch (error) {}

        ReactGA.initialize("G-R6ZSVRWELE", {
          gaOptions: {
            user_id: user.user_id,
            username: user.user_displayname,
            manufacturer: manufacturer.manufacturer_displayname,
            manufacturer_id: manufacturer.manufacturer_id,
          },
        });
        // ReactGA.set({
        //   manufacturer_id: mid,
        // });

        setManufacturer(manufacturer);
        setUserDataLoaded(true);
      } catch (err) {
        console.log("error fetching data..", err);
      }
    }

    async function load() {
      try {
        if (!userInfo.uid && !userInfo.uname) {
          const userLogin = await Auth.currentUserInfo();
          console.log({ userLogin });
          if (userLogin != null) {
            await getUserData(userLogin["id"]);
          }
        } else {
          setUserDataLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (userDataLoaded && authStatus !== "authenticated") {
      API.configure({ headers: null });
      clearUserInfo();
      setUserDataLoaded(false);
    }
    if (authStatus === "authenticated") load();
  }, [
    authStatus,
    clearUserInfo,
    setManufacturer,
    setUserInfo,
    userDataLoaded,
    userInfo.uid,
    userInfo.uname,
    putUsersApi,
  ]);

  // Keep showing the splash when following a link to a bookmark cus the double splash you get otherwise is annoying
  const isBookMarkPage = /\/bm\/[\d\w-]{36}/.test(location.pathname);

  return (
    <RumTelemetricWrapper authStatus={authStatus}>
      {(!userDataLoaded || isBookMarkPage) && (
        <Splash message={"Getting your account info"} />
      )}
      {authStatus === "authenticated" &&
        userDataLoaded &&
        userInfo.active === 0 && (
          <DeactivatedUserSplash
            signOut={signOut}
            clearUserInfo={clearUserInfo}
            setUserDataLoaded={setUserDataLoaded}
          />
        )}
      {authStatus === "authenticated" &&
        userDataLoaded &&
        userInfo.active === 1 && (
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
          </ThemeProvider>
        )}
    </RumTelemetricWrapper>
  );
};

const App = () => {
  // Data Grid Pro license key
  LicenseInfo.setLicenseKey(
    "7c48ef307c6368b7501c65d35340eee1Tz02ODcyOSxFPTE3MTg0NTk5OTY2NDcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
  );

  return (
    <>
      <Authenticator
        className="bg-blue"
        components={{
          Header() {
            return (
              <View textAlign="center" padding="1rem">
                <img
                  src="/static/images/logos/logo1.png"
                  alt="logo"
                  height={"200px"}
                />
              </View>
            );
          },
        }}
        hideSignUp
      >
        <AppAuthentication />
      </Authenticator>
    </>
  );
};

export default App;
