import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Paper, Card } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import distributorsAPI from "../../api/distributors";

const InventoryView = observer(() => {
  const store = useStore();
  const { selectedDistributors, selectedProducts, userInfo } = store;
  const [data, setData] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });

  // Define the columns for the Data Grid
  const columns = [
    {
      field: "product_displayname",
      headerName: "Product Name",
      width: 450,
    },
    {
      field: "dist_displayname",
      headerName: "Distributor",
      width: 250,
    },
    {
      field: "location_name",
      headerName: "Location Name",
      width: 250,
    },
    {
      field: "onfloor_inventory",
      headerName: "On Floor Inventory",
      width: 180,
      type: "number",
    },
    {
      field: "created",
      headerName: "Last Updated",
      width: 200,
      type: "date",
      valueGetter: (params) => new Date(params.value),
    },
  ];

  const getInventory = () => {
    const { manufacturer_id } = userInfo;
    if (data.length === 0) {
      distributorsAPI
        .getDistributorsInventory(manufacturer_id)
        .then((results) => {
          console.log(results);
          setData(results);
        })
        .catch((error) => {
          console.error("Error fetching inventory data:", error);
        });
    }
  };

  useEffect(() => {
    getInventory();
  }, []);

  return (
    <Paper
      style={{
        display: "flex",
        width: "100%",
        padding: "10px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        marginBottom: "20px",
      }}
    >
      {/* Data Grid with Toolbar */}
      <Card style={{ height: 700, width: "95%", marginTop: 20 }}>
        <DataGrid
          rows={data}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowsPerPageOptions={[50, 100, 200]}
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "onfloor_inventory", sort: "desc" }],
            },
          }}
        />
      </Card>
    </Paper>
  );
});

export default InventoryView;
