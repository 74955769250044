import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import { Card, CardHeader, Box } from "@material-ui/core";
import {
  MapContainer as Map,
  TileLayer,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import { Interval, DateTime } from "luxon";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { observer } from "mobx-react-lite";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import MapToolTip from "./MapToolTip";
import MapAccountCard from "./MapAccountCard";
import { useStore } from "../../../stores/StoreContext";
Storage.configure({ level: "protected" });

const HeatMap = observer(({ className, filterArgs, manufacturer, ...rest }) => {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState([39.5, -78.35]);
  const [dataSets, setDataSets] = useState([]);
  const [targetAccount, setTargetAccount] = useState(null);

  const {
    userInfo,
    allStates,
    selectedQuantity,
    quantities,
    dateRange,
    depletionList,
  } = useStore();

  const rangeInterval = useMemo(() => {
    const lowerDate = DateTime.fromJSDate(dateRange[0]);
    const upperDate = DateTime.fromJSDate(dateRange[1]);
    return Interval.fromDateTimes(lowerDate, upperDate);
  }, [dateRange]);

  const measurementUnit = useMemo(() => {
    const measurementUnit = {
      ...quantities.find((q) => q.value === selectedQuantity),
    };
    return measurementUnit;
  }, [quantities, selectedQuantity]);

  const geoReady = (acc) => {
    const all = acc.position;
    if (all.includes(null)) {
      return false;
    }
    if (all.includes("")) {
      return false;
    }
    if (Math.abs(all[0]) > 90) {
      return false;
    }
    if (Math.abs(all[1]) > 180) {
      return false;
    }
    return true;
  };

  const markers = useMemo(() => {
    const { states, products, brands, distributors, productSold } = filterArgs;
    return dataSets.filter((set) => {
      return (
        (states.size === 0 || states.has(set.state)) &&
        (products.size === 0 ||
          (productSold &&
            set.bottles.some((b) => products.has(b.canonical_product_id))) ||
          (!productSold &&
            !set.bottles.some((b) => products.has(b.canonical_product_id)))) &&
        (brands.size === 0 || set.bottles.some((b) => brands.has(b.brand))) &&
        (distributors.size === 0 ||
          set.bottles.some((b) => distributors.has(b.distributor))) &&
        geoReady(set) &&
        set.bottles.some((b) =>
          rangeInterval.contains(DateTime.fromISO(b.DATE))
        )
        // TODO: add daterange filtering here
      );
    });
  }, [rangeInterval, filterArgs, dataSets]);

  const headerStates =
    filterArgs.states.size >= 6
      ? [`${filterArgs.states.size}/${allStates.length} States`]
      : Array.from(filterArgs.states).toString();

  useEffect(() => {
    // console.log("depletion list now has ", depletionList.length, "items", new Date())
    async function getMarkers() {
      // TODO: get depletionlist, reduce it by account, generate markers
      // console.timeLog("load maps");
      let lines = groupByKey(depletionList, "ACCOUNT_ID");
      let procData = [];
      Object.values(lines).forEach((line) => {
        let m = createMarker(line);
        if (m != null) {
          procData.push(m);
        }
      });
      setDataSets(procData);
    }

    const markerCooldown = setTimeout(getMarkers, 800);
    return () => {
      clearTimeout(markerCooldown);
    };
  }, [userInfo.current_data_hash, depletionList]);

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });

  useEffect(() => {
    if (markers?.length && map) {
      // get array of two coords representing NESW extremes of set
      const markerBox = markers.reduce(
        (acc, cur) => {
          if (cur.position[0] < acc[0][0] || acc[0][0] === null) {
            acc[0][0] = cur.position[0];
          }
          if (cur.position[1] < acc[0][1] || acc[0][1] === null) {
            acc[0][1] = cur.position[1];
          }
          if (cur.position[0] > acc[1][0] || acc[1][0] === null) {
            acc[1][0] = cur.position[0];
          }
          if (cur.position[1] > acc[1][1] || acc[1][1] === null) {
            acc[1][1] = cur.position[1];
          }
          return acc;
        },
        [
          [null, null],
          [null, null],
        ]
      );
      var bounds = L.latLngBounds(markerBox);
      map.fitBounds(bounds); //works!
    }
  }, [markers, map]);

  useEffect(() => {
    return () => setMap(null);
  }, []);

  L.Marker.prototype.options.icon = DefaultIcon;
  useEffect(() => {
    if (!map) return;

    setCenter([39.5, -78.35]);
    setZoom(5);
    map.invalidateSize(true);
  }, [map]);
  const groupByKey = (list, key) =>
    list.reduce((acc, obj) => {
      acc[obj[key]] = (acc[obj[key]] || []).concat(obj);
      return acc;
    }, {});

  function createMarker(item) {
    if (item[0].latlong == null) return null;
    let bottles = [];
    let total = 0;
    // TODO: replace with reducer
    item.forEach((i) => {
      total += i.nine_litre_sold;
      bottles.push({
        product_name: i.PRODUCT_NAME,
        unit_sold: i.PHYSICAL_SOLD,
        brand: i.BRAND,
        nine_sold: i.NINE_SOLD,
        bbl: i.BBL,
        canonical_product_id: i.PRODUCT_ID,
        distributor: i.DISTRIBUTOR_NAME,
        DATE: i.DATE,
      });
    });

    return {
      position: item[0].latlong.split(","),
      bottles: bottles,
      store: item[0].STORENUMBER,
      total: total,
      state: item[0].STATE,
      category: item[0].category,
      brand: item[0].BRAND,
      distributor: item[0].DISTRIBUTOR_NAME,
    };
  }

  function getRetailColor(d) {
    return d > 250
      ? "#460026"
      : d > 150
      ? "#560042"
      : d > 125
      ? "#650065"
      : d > 100
      ? "#5B0074"
      : d > 75
      ? "#4B0082"
      : d > 50
      ? "#4E2295"
      : d > 20
      ? "#5644A7"
      : d > 10
      ? "#6666B8"
      : d > 5
      ? "#8890C9"
      : "#8890C9";
  }
  function isNumeric(num) {
    return !isNaN(num);
  }
  function getOnPremiseColor(d) {
    return d > 250
      ? "#fafa6e"
      : d > 150
      ? "#b5e877"
      : d > 125
      ? "#77d183"
      : d > 100
      ? "#3fb78d"
      : d > 75
      ? "#009c8f"
      : d > 50
      ? "#007f86"
      : d > 20
      ? "#0b717e"
      : d > 10
      ? "#1c6373"
      : d > 5
      ? "#2a4858"
      : "#2a4858";
  }
  function getRadius(d) {
    return d > 150
      ? 30
      : d > 125
      ? 25
      : d > 100
      ? 20
      : d > 75
      ? 15
      : d > 50
      ? 12
      : d > 20
      ? 10
      : d > 10
      ? 8
      : d > 5
      ? 6
      : 6;
  }
  return (
    <Card
      style={{
        position: "relative",
      }}
    >
      <CardHeader
        title={`${headerStates} Heat Map`}
        className="st-card-header"
      />
      {targetAccount !== null && (
        <MapAccountCard
          account={targetAccount}
          close={() => setTargetAccount(null)}
          measurementUnit={measurementUnit}
        />
      )}
      <Box style={{ display: "block" }}>
        <Map
          whenCreated={(mapInstance) => {
            setMap(mapInstance);
          }}
          center={center}
          zoom={zoom}
          style={{
            zIndex: "1",
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MarkerClusterGroup>
            {markers.map((item, idx) => (
              <CircleMarker
                key={idx + item.store}
                color={
                  isNumeric(item.store)
                    ? getRetailColor(item.total)
                    : getOnPremiseColor(item.total)
                }
                center={item.position}
                radius={getRadius(item.total)}
                fillOpacity={0.7}
                stroke={false}
                eventHandlers={{
                  click: () => {
                    setTargetAccount(item);
                  },
                }}
              >
                <Tooltip
                  // direction="right"
                  offset={[-8, -2]}
                  opacity={1}
                  key={idx + item.store}
                  eventHandlers={{ click: () => setTargetAccount(item) }}
                >
                  <MapToolTip
                    account={item}
                    measurementUnit={measurementUnit}
                  />
                </Tooltip>
              </CircleMarker>
            ))}
          </MarkerClusterGroup>
        </Map>
      </Box>
    </Card>
  );
});

HeatMap.propTypes = {
  className: PropTypes.string,
};

export default HeatMap;
