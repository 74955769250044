import {
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
  Table,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { useStore } from "../../../stores/StoreContext";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { autorun } from "mobx";
import { DateTime } from "luxon";
import DistributorDataByMonth from "./distributorDataByMonth";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    button: {
      margin: "1px",
    },
  },
  selectableRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eee",
    },
  },
});
function Row(props) {
  const { row, index } = props;
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={`${classes.root} ${classes.selectableRow}`}>
        {/* <TableCell align="left">{row.id}</TableCell> */}
        <TableCell align="left">
          <RouterLink to={"/app/distributorsDetails/" + row.id}>
            {row.name}
          </RouterLink>
        </TableCell>
        <TableCell>
          {row.dataDate === null
            ? "Never"
            : DateTime.fromISO(row.dataDate).toRelative()}
        </TableCell>

        {/* <TableCell align="left">{row?.description}</TableCell> */}
        {/* <TableCell align="left">{row?.account_region}</TableCell> */}
        {/* <TableCell align="left">{row?.count}</TableCell> */}
      </TableRow>
    </>
  );
}

const DistributorsList = () => {
  const [distributedData, setDistributedData] = useState([]);
  const store = useStore();

  useEffect(() => {
    autorun(() => {
      let _distributedData = [...store.distributorsList];
      _distributedData.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setDistributedData(_distributedData);
    });
  }, [store.distributorsList]);

  return useObserver(() => (
    <>
      <DistributorDataByMonth />
    </>
  ));
};
export default DistributorsList;
