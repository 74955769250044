import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@material-ui/core";
import { Interval, DateTime } from "luxon";
import TopAccounts from "../../views/reports/DataView/TopAccounts";
import TopProducts from "../../views/reports/DataView/TopProducts";
import { useStore } from "../../stores/StoreContext";

const HomeTops = observer(({ filterArgs, userInfo }) => {
  const { dateRange, depletionList } = useStore();

  const lowerDate = DateTime.fromJSDate(dateRange[0]);
  const upperDate = DateTime.fromJSDate(dateRange[1]);
  const rangeInterval = Interval.fromDateTimes(lowerDate, upperDate);
  const { brands, products, states, distributors, productSold, premiseTypes } =
    filterArgs;

  const dailyStats = useMemo(() => {
    const results = depletionList.filter(
      (d) =>
        (brands.size === 0 || brands.has(d.BRAND)) &&
        (products.size === 0 ||
          (productSold && products.has(d.PRODUCT_ID)) ||
          (!productSold && !products.has(d.PRODUCT_ID))) &&
        (states.size === 0 || states.has(d.STATE)) &&
        (distributors.size === 0 || distributors.has(d.DISTRIBUTOR_NAME)) &&
        rangeInterval.contains(DateTime.fromISO(d.DATE)) &&
        premiseTypes.includes(d.premise_type)
    );
    // console.timeEnd("filter tops");

    return results;
  }, [
    depletionList,
    rangeInterval,
    brands,
    productSold,
    distributors,
    states,
    premiseTypes,
    products,
  ]);

  const dailyStatsProductsIntact = useMemo(() => {
    const { brands, states, distributors } = filterArgs;
    const results = depletionList.filter(
      (d) =>
        (brands.size === 0 || brands.has(d.BRAND)) &&
        (states.size === 0 || states.has(d.STATE)) &&
        (distributors.size === 0 || distributors.has(d.DISTRIBUTOR_NAME)) &&
        rangeInterval.contains(DateTime.fromISO(d.DATE))
    );

    return results;
  }, [depletionList, rangeInterval, filterArgs]);

  return (
    <>
      <Grid item lg={12} md={12} xs={12}>
        <TopAccounts
          filterArgs={filterArgs}
          dailyStats={dailyStatsProductsIntact}
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <TopProducts filterArgs={filterArgs} dailyStats={dailyStats} />
      </Grid>
    </>
  );
});

export default HomeTops;
