import React, { useState } from "react";
import {
  Avatar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Chip,
  Link,
  Menu,
  MenuItem,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { parseScheduleData } from "../../helpers/dateUtils";
import { awsS3Image } from "../../helpers/awsS3Image";
import { DateTime } from "luxon";
import { Alert, FormControlLabel, Switch } from "@mui/material";
import SearchBar from "../../components/widgets/SearchBar";
import { API } from "aws-amplify";
import { GridMoreVertIcon } from "@mui/x-data-grid";

const ScheduleList = ({
  scheduleData,
  isMySchedule,
  handleToggle,
  userInfo,
  members,
  refresh,
}) => {
  const { today, tomorrow, thisWeek, future, past } =
    parseScheduleData(scheduleData);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([userInfo]);
  const [dateTime, setDateTime] = useState("");
  const [account, setAccount] = useState({});
  const [purpose, setPurpose] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredAccount, setFilteredAccount] = useState("");
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const itemsPerPage = 8;
  const [defaultAccountId, setDefaultAccountId] = useState(null);
  const [shouldSendEmail, setShouldSendEmail] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToast = (status) => {
    setToastMessage(status);
    setToastOpen(true);
  };

  const handleEdit = async (item) => {
    setTeamMembers(JSON.parse(item.members) || []);
    setSelectedJourney(item);
    setDateTime(
      DateTime.fromISO(item.appointment).toFormat("yyyy-MM-dd'T'HH:mm")
    );
    setPurpose(item.purpose);
    setDefaultAccountId(item.account_id);
    setEditOpen(true);
    handleMenuClose();
    setProcessingSubmit(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setDateTime(DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm"));
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setTeamMembers([userInfo]);
    setDateTime("");
    setAccount({});
    setPurpose("");
  };

  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setDeleteOpen(false);
    setTeamMembers([userInfo]);
    setDateTime("");
    setAccount({});
    setPurpose("");
  };

  const handleEditSave = async () => {
    if (!dateTime) {
      alert("Date and Time cannot be empty");
      setProcessingSubmit(false);
      return;
    }

    const plainTeamMembers = teamMembers.map((member) => ({
      user_id: member.user_id,
      user_displayname: member.user_displayname,
      user_email: member.user_email,
    }));

    setProcessingSubmit(true);
    const appoint = {
      appointment: dateTime,
      account_id: account.id,
      purpose: purpose,
      members: plainTeamMembers,
    };
    console.log("Journey update payload:", appoint);
    try {
      const apiName = "backendGateway";
      const path = `/journeys/${selectedJourney.journey_id}`;
      const myInit = {
        body: appoint,
        headers: {},
      };
      const response = await API.put(apiName, path, myInit);
      console.log("Journey update response:", response);

      if (shouldSendEmail) {
        await sendEditEmail();
      }
      showToast("Event Succesfully Updated");
    } catch (err) {
      console.log("error updating journey:", err);
      showToast("Event Failed to be Updated");
    } finally {
      setProcessingSubmit(false);
    }
    refresh();
    handleClose();
    setProcessingSubmit(false);
  };

  const handleSave = async () => {
    if (!dateTime) {
      alert("Date and Time cannot be empty");
      setProcessingSubmit(false);
      return;
    }

    setProcessingSubmit(true);
    const plainTeamMembers = teamMembers.map((member) => ({
      user_id: member.user_id,
      user_displayname: member.user_displayname,
      user_email: member.user_email,
    }));

    const appoint = {
      user_id: userInfo.user_id,
      type: 0,
      manufacturer_id: userInfo.mid,
      account_id: account.id || null,
      appointment: dateTime,
      is_active: 1,
      members: plainTeamMembers,
      purpose: purpose,
      cw: 99,
    };
    console.log("Journey appointment payload:", appoint);
    try {
      const apiName = "backendGateway";
      const path = "/journeys";
      const myInit = {
        body: appoint,
        headers: {},
      };
      const response = await API.post(apiName, path, myInit);
      console.log("Journey creation response:", response);

      if (shouldSendEmail) {
        await sendCreateEmail();
      }
      showToast("Event Succesfully Created");
    } catch (err) {
      console.error("Error creating journey:", err);
      alert("Error creating journey. Please try again.");
      showToast("Event Failed to be Created");
    }
    refresh();
    handleClose();
    setProcessingSubmit(false);
  };

  const handleDelete = async () => {
    setProcessingSubmit(true);
    try {
      const apiName = "backendGateway";
      const path = `/journeys/${selectedJourney.journey_id}`;
      const myInit = {
        queryStringParameters: {
          journey_id: selectedJourney.journey_id,
        },
        headers: {},
      };
      const response = await API.del(apiName, path, myInit);
      console.log("Journey deletion response:", response);

      if (shouldSendEmail) {
        await sendDeleteEmail();
      }
      showToast("Event Succesfully Deleted");
    } catch (err) {
      console.log("error deleting journey:", err);
      showToast("Event Failed to be Deleted");
    }
    handleClose();
    refresh();
    setAnchorEl(null);
    setProcessingSubmit(false);
  };

  const sendCreateEmail = async () => {
    const appoint = {
      user_emails: teamMembers.map((member) => member.user_email),
      appointment_creator: userInfo.user_email,
      account_name: account ? account.name : null,
      account_location: account ? account.address : null,
      appointment: dateTime || null,
      purpose: purpose,
      account_id: account ? account.id : null,
    };

    console.log("iCal appointment payload:", appoint);

    try {
      const apiName = "backendGateway";
      const path = "/invites/appointment";
      const myInit = {
        body: appoint,
        headers: {},
      };
      const response = await API.post(apiName, path, myInit);
      console.log("iCal creation response:", response);
    } catch (err) {
      console.log("error creating iCal invite:", err);
    }
  };

  const sendEditEmail = async () => {
    const apiName = "backendGateway";
    const emailInit = {
      body: {
        account_name: account.name,
        account_id: account.id,
        account_location: account.address,
        appointment: dateTime,
        appointment_creator: userInfo.user_email,
        user_email: userInfo.user_email,
        user_emails: teamMembers.map((member) => member.user_email),
      },
      headers: {},
    };
    const emailResponse = await API.put(
      apiName,
      "/invites/appointment",
      emailInit
    );
    console.log("Email notification response:", emailResponse);
  };

  const sendDeleteEmail = async () => {
    const apiName = "backendGateway";
    const emailInit = {
      body: {
        account_name: selectedJourney.account_displayname,
        account_id: selectedJourney.account_id,
        account_location: selectedJourney.account_address,
        appointment: selectedJourney.appointment,
        appointment_creator: userInfo.user_email,
        user_email: userInfo.user_email,
        user_emails: [userInfo.user_email],
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const emailResponse = await API.del(
        apiName,
        "/invites/appointment",
        emailInit
      );
      console.log("Email notification response:", emailResponse);
    } catch (error) {
      console.log("Error sending email notification:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getCurrentPageData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const allItems = [
    ...today.map((item) => ({
      ...item,
      section: "Today - ",
      color: "#CEFFBC",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...tomorrow.map((item) => ({
      ...item,
      section: "Tomorrow - ",
      color: "#B0F5FF",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...thisWeek.map((item) => ({
      ...item,
      section: "Rest of this week - ",
      color: "#BDB6FF",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...future.map((item) => ({
      ...item,
      section: "Future - ",
      color: "#FFB3B3",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...past.map((item) => ({ ...item, section: "Past", color: "#DCDCDC" })),
  ];

  const filteredItems = filteredAccount
    ? allItems.filter((item) =>
        item.account_displayname
          ?.toLowerCase()
          .includes(filteredAccount.toLowerCase())
      )
    : allItems;

  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const currentPageData = getCurrentPageData(filteredItems);

  const handleCallback = (selectedAccount) => {
    if (selectedAccount && selectedAccount.name) {
      setFilteredAccount(selectedAccount.name);
    } else {
      setFilteredAccount("");
    }
  };

  const handleCallbackDialog = (account) => {
    if (account === "") {
      setAccount({});
      console.log("Selected account:", account);
    } else {
      setAccount(account);
      console.log("Selected account:", account);
    }
  };

  const handleMenuClick = (event, journey) => {
    setAnchorEl(event.currentTarget);
    setSelectedJourney(journey);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderTableBody = () => {
    let lastSection = "";
    return (
      <TableBody>
        {currentPageData.map((item, index) => {
          const showSectionHeader = item.section !== lastSection;
          lastSection = item.section;
          return (
            <React.Fragment key={index}>
              {showSectionHeader && (
                <TableRow
                  style={{ backgroundColor: item.color, width: "100%" }}
                >
                  <TableCell colSpan={5}>
                    {item.section} {item.dateRange}
                  </TableCell>
                </TableRow>
              )}
              <TableRow key={item.journey_id}>
                <TableCell>
                  <Avatar
                    alt={item.user_displayname}
                    src={awsS3Image(
                      item.user_image,
                      item.user_identitykey,
                      "50x50"
                    )}
                    style={{ background: "#f6f6f6", marginRight: "10px" }}
                  />
                  {item.user_displayname}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(item.appointment).toFormat("dd MMM h:mma")}
                </TableCell>
                <TableCell>
                  <Link
                    href={`/app/accountdetails/${item.account_id}`}
                    target="_blank"
                  >
                    {item.account_displayname}
                  </Link>
                </TableCell>
                <TableCell>
                  {item?.purpose && item?.purpose?.length > 20
                    ? `${item.purpose.substring(0, 20)}...`
                    : item.purpose}
                </TableCell>
                <TableCell>
                  <IconButton
                    edge="end"
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, item)}
                  >
                    <GridMoreVertIcon style={{ transform: "rotate(90deg)" }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleEdit(selectedJourney)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => setDeleteOpen(true)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    );
  };

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          onClose={() => setToastOpen(false)}
          severity={toastMessage.includes("Succesfully") ? "success" : "error"}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ marginRight: 15 }}>
              My Team's Schedule
            </Typography>
            <FormControlLabel
              control={
                <Switch checked={isMySchedule} onChange={handleToggle} />
              }
            />
            <Typography style={{ marginLeft: -10 }}>My Schedule</Typography>
          </div>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Add to Planner
          </Button>
        </div>
        <TableContainer style={{ backgroundColor: "#FFFFFF" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team Member</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>
                  <SearchBar
                    callback={handleCallback}
                    placeholder={"Account (filter)"}
                  />
                </TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </TableContainer>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        />
        <Dialog
          open={open}
          fullWidth
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add to Planner</DialogTitle>
          <DialogContent>
            <Autocomplete
              multiple
              options={members}
              getOptionLabel={(option) => option.user_displayname}
              value={teamMembers}
              onChange={(event, newValue) => {
                if (Array.isArray(newValue)) {
                  const uniqueMembers = newValue.filter(
                    (v, i, a) =>
                      a.findIndex((t) => t.user_id === v.user_id) === i
                  );
                  console.log(uniqueMembers);
                  setTeamMembers(uniqueMembers);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.user_displayname}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Enter Team Member/s"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />

            <TextField
              id="datetime-local"
              label="Select Date & Time"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              style={{ marginTop: "20px", marginBottom: 20 }}
            />
            <SearchBar
              placeholder={"Search for Account (Optional)"}
              callback={handleCallbackDialog}
            />
            <TextField
              id="purpose-event"
              label="Purpose of Event"
              type="text"
              fullWidth
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              style={{ marginTop: "20px" }}
            />
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: 14, paddingLeft: 15 }}>
              Send Email Confirmation
              <Checkbox
                checked={shouldSendEmail}
                onChange={() => setShouldSendEmail(!shouldSendEmail)}
              />
            </div>
            <div>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                color="primary"
                disabled={processingSubmit}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={editOpen}
          fullWidth
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit This Event</DialogTitle>
          <DialogContent>
            <Autocomplete
              multiple
              options={members}
              getOptionLabel={(option) => option.user_displayname}
              value={teamMembers}
              onChange={(event, newValue) => {
                if (Array.isArray(newValue)) {
                  const uniqueMembers = newValue.filter(
                    (v, i, a) =>
                      a.findIndex((t) => t.user_id === v.user_id) === i
                  );
                  console.log(uniqueMembers);
                  setTeamMembers(uniqueMembers);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.user_displayname}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Enter Team Member/s"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
            <TextField
              id="datetime-local"
              label="Select Date & Time"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              style={{ marginTop: "20px", marginBottom: 20 }}
            />
            <SearchBar
              placeholder={"Search for Account (Optional)"}
              callback={handleCallbackDialog}
              defaultAccountId={defaultAccountId}
            />
            <TextField
              id="purpose-event"
              label="Purpose of Event"
              type="text"
              fullWidth
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              style={{ marginTop: "20px" }}
            />
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: 14, paddingLeft: 15 }}>
              Send Email Confirmation
              <Checkbox
                checked={shouldSendEmail}
                onChange={() => setShouldSendEmail(!shouldSendEmail)}
              />
            </div>
            <div>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleEditSave}
                color="primary"
                disabled={processingSubmit}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteOpen}
          fullWidth
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to delete this event?
          </DialogTitle>
          <DialogContent>
            <Typography>Deleting this event cannot be undone.</Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: 14, paddingLeft: 15 }}>
              Send Email Confirmation
              <Checkbox
                checked={shouldSendEmail}
                onChange={() => setShouldSendEmail(!shouldSendEmail)}
              />
            </div>
            <div>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                color="primary"
                disabled={processingSubmit}
              >
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default ScheduleList;
