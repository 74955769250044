import client from "./client";

const getLists = ({ mid, uid, limit }) => {
  const endpoint = "/accounts/lists";
  return client.get(endpoint, { mid: mid, uid: uid, limit: limit });
};
const putLists = (list) => {
  const endpoint = "/accounts/lists";
  return client.put(endpoint, list);
};
const postLists = (list) => {
  const endpoint = "/accounts/lists";
  return client.post(endpoint, list);
};

const getListsLID = ({ lid, limit }) => {
  const endpoint = "/accounts/listdetailsnew/" + lid;
  return client.get(endpoint, { limit: limit });
};

const getListsBottles = ({ mid, limit }) => {
  const endpoint = "/accounts/list/bottles";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const putListsItems = (listItem) => {
  const endpoint = "/accounts/listitems";
  return client.put(endpoint, listItem);
};
const postListsItems = (listItem) => {
  const endpoint = "/accounts/listitems";
  return client.post(endpoint, listItem);
};

export default {
  getLists,
  putLists,
  postLists,
  getListsLID,
  getListsBottles,
  putListsItems,
  postListsItems,
};
