const premise_types = {
  allPremiseTypes: [],
  setAllPremiseTypesCount: 0,
  availablePremiseTypes: [],
  premiseTypes: [],

  setAllPremiseTypes(list) {
    this.allPremiseTypes = list;
    this.setAllPremiseTypesCount += 1;
    this.availablePremiseTypes = list;
    this.premiseTypes = list.filter((premise) =>
      ["ON-PREMISE", "OFF-PREMISE", "UNKNOWN", "DISTRIBUTOR"].includes(premise)
    );
  },
  setPremiseTypes(list) {
    this.premiseTypes = list;
  },
  resetPremiseTypes() {
    this.premiseTypes = this.allPremiseTypes;
  },
};

export default premise_types;
