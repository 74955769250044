import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  makeStyles,
  Popover,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Snackbar,
  Divider,
  Grid,
  Avatar,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { Link } from "react-router-dom";
import { Table } from "@mui/material";
import { Loader } from "@aws-amplify/ui-react";
import { Pagination } from "@material-ui/lab";

const itemsPerPage = 8;

const ProfileActivityTracker = ({ data }) => {
  const typeNames = {
    account_checkin: "CheckIn",
    contact_created: "Contact",
    media_upload: "Image",
    form_response: "Form",
    image_analysis: "Analysis",
  };
  const [formattedDataNoSlice, setformattedDataNoSlice] = useState(data);
  const [formattedData, setFormattedData] = useState(
    data.slice(0, itemsPerPage).sort((a, b) => b.created - a.created)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [direction, setDirection] = useState("desc");
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setFormattedData(
      data.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    );
  };

  useEffect(() => {
    setFormattedData(formattedDataNoSlice.slice(0, itemsPerPage));
    setCurrentPage(1);
  }, [formattedDataNoSlice]);

  useEffect(() => {
    if (direction === "desc") {
      setformattedDataNoSlice(data);
    } else {
      var temp = [];
      temp.push(...data);
      setformattedDataNoSlice(temp.reverse());
    }
  }, [data]);

  return (
    <>
      {data.length && data.length > 0 ? (
        <Paper>
          <Table>
            <TableHead>
              <TableCell style={{ fontSize: "18px" }}></TableCell>
              <TableCell style={{ fontSize: "18px" }}>
                <TableSortLabel
                  active={true}
                  direction={direction}
                  onClick={() => {
                    if (direction === "desc") {
                      setDirection("asc");
                    } else {
                      setDirection("desc");
                    }
                    var tempArray = [];
                    tempArray.push(...formattedDataNoSlice);
                    setformattedDataNoSlice(tempArray.reverse());
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ fontSize: "18px" }}>Type</TableCell>
              <TableCell style={{ fontSize: "18px" }}>Info</TableCell>
            </TableHead>

            {formattedData.map((event, index) => {
              const date = new Date(event.created);

              const day = date.getDate();
              const month = new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(date);
              const hour = date.getHours() % 12 || 12;
              const minute = date.getMinutes();
              const ampm = date.getHours() < 12 ? "am" : "pm";

              const formattedDate = `${day}${
                day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
              } ${month} ${hour}:${minute.toString().padStart(2, "0")} ${ampm}`;

              return (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`/static/images/icons/${typeNames[event.type]}.svg`}
                      alt=""
                      width="50"
                      height="50"
                      style={{ marginRight: "10px" }}
                    />
                  </TableCell>
                  <TableCell>{formattedDate}</TableCell>
                  <TableCell>{typeNames[event.type]}</TableCell>
                  <TableCell style={{ width: "40%" }}>
                    <Link
                      style={{
                        color: "black",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                      to={`/app/accountdetails/${event.account_id}`}
                    >
                      Account: {event.account_displayname}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
          {Math.ceil(data.length / itemsPerPage) > 1 ? (
            <Paper
              style={{
                display: "flex",
                height: "60px",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                onChange={(e, page) => handlePageChange(page)}
                page={currentPage}
              />
            </Paper>
          ) : (
            ""
          )}
        </Paper>
      ) : (
        <Paper style={{ marginLeft: "10px", padding: "20px" }}>
          No data to display, check dates and filters.
        </Paper>
      )}
    </>
  );
};
export default ProfileActivityTracker;
