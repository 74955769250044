import React, { useState, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import Page from "../../../components/Page";
import Results from "./Results";
import { API } from "aws-amplify";
import useApi from "../../../hooks/useApi";
import listsAPI from "../../../api/lists";
import { trackEvent } from "../../../api/analytics";
import { useStore } from "../../../stores/StoreContext";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { autorun } from "mobx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Cancel } from "@material-ui/icons";
import MarketSummaryStats from "../../reports/ChartsView/MarketSummaryStats";
import AccountListAssociator from "../../../components/regions/associator/AccountListAssociator";
import Equalizer from "@material-ui/icons/Equalizer";
import MapIcon from "@material-ui/icons/Map";
import STHeatMap from "../../../components/visualisation/brands/STHeatMap";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  resultWrapper: {
    overflow: "visible",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "600px",
    alignItems: "stretch",
  },
}));

const CustomerListView = observer(() => {
  const location = useLocation();
  const mapHeightParent = useRef();
  const [distributorAccounts, setDistributorAccounts] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [boxFlexValue, setBoxFlexValue] = useState(0);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const bottles = [];
  const [premiseType, setPremiseType] = useState("ALL");
  const [viewStats, setViewStats] = useState(true);
  const [viewStyle, setViewStyle] = useState("stats");
  const [selection, setSelection] = useState([]);

  const {
    setCustomers,
    locations,
    setListId,
    userInfo,
    markets,
    setMarkets,
    manufacturer,
    filteringProducts,
    selectedStates,
    selectedDistributors,
    selectedBrands,
    regions,
    dateRange,
    premiseTypes,
    productSold,
    depletionList,
  } = useStore();

  const longCodeRegionMap = useMemo(() => {
    return Object.fromEntries(regions.map(({ long, code }) => [long, code]));
  }, [regions]);

  const filterArgs = useMemo(
    () => ({
      brands: new Set(selectedBrands),
      states: new Set(selectedStates.map((state) => longCodeRegionMap[state])),
      distributors: new Set(selectedDistributors),
      products: new Set(filteringProducts.map((fp) => fp.product_id)),
      dateRange,
      premiseTypes,
      productSold,
    }),
    [
      selectedBrands,
      selectedStates,
      selectedDistributors,
      filteringProducts,
      longCodeRegionMap,
      dateRange,
      premiseTypes,
      productSold,
    ]
  );

  const { id } = useParams();
  const market = useMemo(() => {
    return markets.find((m) => m.list_id === parseInt(id, 10));
  }, [markets, id]);

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:AccountsList");
  }, []);

  useEffect(() => {
    autorun(() => {
      const val = locations.length || location.state.type === "AUTO" ? 1 : 0;
      setBoxFlexValue(val);
    });
  }, [locations]);

  useEffect(() => {
    setCustomers([]);
    setListId(id);
    setLoading(true);
    getListAccounts();
  }, []);
  const getListsApi = useApi(listsAPI.getLists);
  async function getData() {
    getListsApi
      .request({
        uid: userInfo.uid,
        mid: userInfo.mid,
        limit: 1000,
      })
      .then(({ results }) => setMarkets(results));
  }

  useEffect(() => {
    if (markets.length === 0) {
      getData();
    }
  }, [markets, id]);

  const reportUp = ({ action, data }) => {
    if (action === "customerListUpdate") {
      const regexp = new RegExp(city, "i");
      setDistributorAccounts(
        data.filter(
          (a) =>
            (city.length === 0 || regexp.test(a.account_city)) &&
            (premiseType === "ALL" || premiseType === a.premise_type)
        )
      );
    }
  };

  async function getListAccounts() {
    try {
      const data = await API.get(
        "backendGateway",
        "/accounts/listdetailsnewfixes/" + id,
        {
          queryStringParameters: {
            limit: "10000",
          },
        }
      );

      var sortNames = data.results.sort(
        (a, b) => (a.account_displayname > b.account_displayname && 1) || -1
      );
      setDistributorAccounts(data.results);
      const citySet = data.results.reduce((acc, cur) => {
        let city = cur.account_city;
        if (!city || city.length === 0) {
          return acc;
        }
        const cityUp = city.toUpperCase();
        if (acc[cityUp]) {
          acc[cityUp].count++;
        } else {
          acc[cityUp] = {
            name: cityUp,
            count: 1,
          };
        }
        return acc;
      }, {});
      setCities(Object.values(citySet));
      setCustomers(sortNames);
      setLoading(false);
    } catch (err) {
      console.log("error fetching data..", err);
      setLoading(false);
    }
  }

  const pickCity = (event, object) => {
    setCity(object.props.value);
  };
  function SortArray(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }

  const premiseToggle = (event) => {
    let premise = event.target.value;
    setPremiseType(premise);
  };

  const geoReady = (acc) => {
    const { account_latitude: lat, account_longitude: lon } = acc;
    const all = [lat, lon];
    if (all.includes(null)) {
      return false;
    }
    if (all.includes("")) {
      return false;
    }
    if (Math.abs(lat) > 90) {
      return false;
    }
    if (Math.abs(lon) > 180) {
      return false;
    }
    return true;
  };

  const accountListForSTHeatMap = useMemo(() => {
    return distributorAccounts.filter(
      (a) =>
        (selection.length === 0 || selection.includes(a.account_id)) &&
        (city.length === 0 ||
          a.account_city.toUpperCase() === city.toUpperCase()) &&
        (premiseType === "ALL" || premiseType === a.premise_type) &&
        geoReady(a)
    );
  }, [distributorAccounts, city, premiseType, selection]);

  if (!market) return <></>;
  return (
    <Page
      className={classes.root}
      title="Markets"
      style={{ overflow: "visible", position: "relative", height: "100%" }}
    >
      <div id="m"></div>
      <Container
        maxWidth={false}
        className={clsx(classes.resultWrapper)}
        style={{}}
      >
        {/*
          There's a lot of flex here to support resizing the map to fit the screen
          The flex rule below lets the container shrink if it's a user a list
          and there are currently no locations in search results.
        */}
        <Box
          mt={3}
          style={{
            overflow: "visible",
            flex: boxFlexValue,
            height: "100%",
          }}
        >
          <Card
            style={{
              overflow: "visible",
              width: "100%",
            }}
          >
            <CardHeader
              title={market.list_name}
              style={{
                backgroundColor: "#0C45A6",
                textAlign: "center",
                color: "white",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
              subheader={
                <Box>
                  <Button
                    onClick={() => {
                      setViewStats(false);
                      setViewStyle("map");
                    }}
                    startIcon={<MapIcon />}
                    variant={viewStyle === "map" ? "contained" : "text"}
                  ></Button>
                  <Button
                    onClick={() => {
                      setViewStats(true);
                      setViewStyle("stats");
                    }}
                    startIcon={<Equalizer />}
                    variant={viewStyle === "stats" ? "contained" : "text"}
                  ></Button>
                </Box>
              }
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: viewStats ? "1fr 1.4fr" : "100%",
              }}
            >
              <div>
                {distributorAccounts.length > 0 && (
                  <STHeatMap
                    filterArgs={filterArgs}
                    manufacturer={manufacturer}
                    refresh={viewStats}
                    accounts={accountListForSTHeatMap}
                  />
                )}
              </div>

              {viewStats && (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#2160CC",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderBottomRightRadius: "5px",
                    overflow: "hidden",
                  }}
                >
                  <MarketSummaryStats />
                </div>
              )}
            </div>
          </Card>
        </Box>

        <Box mt={3}>
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <div>
                  <InputLabel id="filterToCity-label">City</InputLabel>
                  <Select
                    labelId="filterToCity-label"
                    label="City"
                    onChange={pickCity}
                    value={city}
                    defaultValue=""
                    renderValue={() => (city ? city : "All Cities")}
                    displayEmpty={true}
                    style={{
                      width: "260px",
                    }}
                  >
                    <MenuItem value="">All Cities</MenuItem>
                    {cities.sort(SortArray).map((c) => (
                      <MenuItem value={c.name.toUpperCase()} key={c.name}>
                        {c.name} ({c.count})
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    paddingLeft: "12px",
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<Cancel />}
                    onClick={() => setCity("")}
                  >
                    All
                  </Button>
                </div>
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  onChange={premiseToggle}
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="ALL"
                >
                  <FormControlLabel
                    value="ON-PREMISE"
                    control={<Radio color="primary" />}
                    label="ON-PREMISE"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="OFF-PREMISE"
                    control={<Radio color="primary" />}
                    label="OFF-PREMISE"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="ALL"
                    control={<Radio color="primary" />}
                    label="ALL"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
            <CardContent>
              <AccountListAssociator accountList={market} />
            </CardContent>
          </Card>
        </Box>

        <Results
          market={market}
          listType={market.type}
          listName={market.list_name}
          lists={markets}
          loading={loading}
          city={city}
          premiseType={premiseType}
          reportUp={reportUp}
          setSelection={setSelection}
          selection={selection}
          style={{
            // position: "absolute",
            // top: 0,
            // left: 0,
            height: "100%",
            width: "100%",
          }}
        />
      </Container>
    </Page>
  );
});

export default CustomerListView;
