import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Button,
  CardContent,
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import AccountListModal from "./AccountListModal";
import Chip from "@material-ui/core/Chip";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { useStore } from "../../../stores/StoreContext";
import { awsS3Image } from "../../../helpers/awsS3Image";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.1),
    },
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ListCard = ({
  className,
  list,
  allLists,
  setData,
  expandState,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const [item, setItem] = useState(list);
  const [bgUrl, setBgUrl] = useState("");
  const [refresh, setRefresh] = useState(true);
  const handleClickOpen = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const s3ImageEl = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  useEffect(() => {
    if (list) {
      let _list = { ...list };
      _list.image_scr = awsS3Image(
        _list.list_image,
        _list.list_imagekey,
        "0x110"
      );
      setItem({ ..._list });
    }
  }, [list]);

  /**
   * after the S3 image is loaded, hook into a ref to find the img url via the DOM
   * and then use it as abackground image on it's previous sibling
   *
   */
  const imageLoaded = (event, item) => {
    const el = s3ImageEl.current;
    if (el && el.nextElementSibling) {
      const next = el.nextElementSibling.shadowRoot;
      const imgUrl = next.querySelector("img").src;
      setBgUrl(imgUrl);
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {item.list_name}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

ListCard.propTypes = {
  className: PropTypes.string,
  list: PropTypes.object.isRequired,
};

export default ListCard;
