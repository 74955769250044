import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  ButtonGroup,
} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { CloudDownload } from "@material-ui/icons";
import { useStore } from "../../stores/StoreContext";
import ProductSelecter from "./ProductSelecter";
import { Loader } from "react-feather";
import PremiseSelecter from "./PremiseSelecter";
import { Divider } from "@mui/material";
import useApi from "../../hooks/useApi";
import productsApi from "../../api/products";
import { DateTime } from "luxon";

class UTCLuxonUtils extends LuxonUtils {
  date = function (value) {
    if (typeof value === "undefined") {
      return DateTime.utc();
    }
    if (value === null) {
      return null;
    }
    if (typeof value === "string") {
      return DateTime.fromJSDate(new Date(value), { zone: "utc" });
    }
    if (value instanceof DateTime) {
      return value;
    }
    return DateTime.fromJSDate(value, { zone: "utc" });
  };
}

const MegaFilterGlobalBar = observer(({ location, globalMinimal }) => {
  const {
    everythingOff,
    everythingOn,
    dateRange,
    dateRangeName,
    latestSale,
    setDateRangeName,
    dateRangeOptions,
    setCustomDateRange,
    changeDateOffset,
    allBrands,
    allDistributors,
    allStates,
    quantities,
    selectedQuantity,
    setSelectedQuantity,
    exportSupportedPaths,
    setAutoCompleteProducts,
    userInfo,
    filteringProducts,
  } = useStore();
  const [editDateRange, setEditDateRange] = useState([...dateRange]);
  const [showSecondaryFilters, setShowSecondaryFilters] = useState({
    products: false,
    premise: false,
  });

  const styles = {
    button: {
      margin: "0 0.6rem",
    },
  };

  useEffect(() => {
    if (filteringProducts.length > 0) {
      setShowSecondaryFilters({
        ...showSecondaryFilters,
        products: true,
      });
    }
  }, [filteringProducts]);

  const rangeToText = (range, dateRangeName) => {
    if (dateRangeName === "All Time") return "All Time";

    const years = range.map((r) => r.getUTCFullYear());
    const months = range.map((r) => r.getUTCMonth());
    // single month
    if (months[0] === months[1] && years[0] === years[1]) {
      return (
        range[0].toLocaleString("default", {
          month: "short",
          timeZone: "UTC",
        }) +
        " " +
        years[0]
      );
    }
    if (years[0] === years[1]) {
      return (
        range[0].toLocaleString("default", {
          month: "short",
          timeZone: "UTC",
        }) +
        " - " +
        range[1].toLocaleString("default", {
          month: "short",
          timeZone: "UTC",
        }) +
        " " +
        years[0]
      );
    }
    return (
      range[0].toLocaleString("default", { month: "short", timeZone: "UTC" }) +
      " " +
      years[0] +
      " - " +
      range[1].toLocaleString("default", { month: "short", timeZone: "UTC" }) +
      " " +
      years[1]
    );
  };

  const getProductsApi = useApi(productsApi.getProducts);
  useEffect(() => {
    getProductsApi
      .request({ mid: userInfo.manufacturer_id, limit: 1000 })
      .then(({ results }) => setAutoCompleteProducts(results))
      .catch((error) => console.log(error));
  }, []);

  const filtersActive =
    [...allStates, ...allDistributors, ...allBrands].length !== 0 &&
    !globalMinimal;
  const exportEnabled =
    exportSupportedPaths.filter((ep) => location.pathname.match(ep)).length > 0;
  const joggable = /(months?|Custom)$/.test(dateRangeName);
  const showDatePickers = dateRangeName === "Custom";
  const inLastMonth =
    dateRange[1] &&
    latestSale &&
    dateRange[1].getMonth() >= latestSale.getMonth() &&
    dateRange[1].getFullYear() >= latestSale.getFullYear();

  const quantityFilter = userInfo.mid === 614 ? "nine_sold" : "bbl";

  const [selectFormatOpen, setSelectFormatOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    setEditDateRange([...dateRange]);
  }, [dateRange]);

  const handleFromDateChange = (date) => {
    setEditDateRange([date.startOf("month").toJSDate(), editDateRange[1]]);
  };

  const handleToDateChange = (date) => {
    setEditDateRange([editDateRange[0], date.endOf("month").toJSDate()]);
  };

  const prepareCustomDateRange = () => {
    setCustomDateRange(editDateRange);
  };

  const emitGlobalExportEvent = (format) => {
    setExportLoading(true);
    if (format === "CSV") {
      const exportEventCSV = new CustomEvent("exportactionCSV", {
        component: "MegaFilterGlobalBar",
      });
      window.dispatchEvent(exportEventCSV);
    } else if (format === "XLSX") {
      const exportEventXLSX = new CustomEvent("exportactionXLSX", {
        component: "MegaFilterGlobalBar",
      });
      window.dispatchEvent(exportEventXLSX);
    } else if (format === "PDF") {
      const exportEventPDF = new CustomEvent("exportactionPDF", {
        component: "MegaFilterGlobalBar",
      });
      window.dispatchEvent(exportEventPDF);
    } else {
      console.log("invalid file format");
      setExportLoading(false);
    }
    window.addEventListener("exportComplete", () => {
      setExportLoading(false);
    });

    setSelectFormatOpen(false);
  };

  const changeQuantity = (event) => {
    setSelectedQuantity(event.target.value);
  };

  return (
    <Grid
      container
      style={{
        background: "#fff",
        boxShadow:
          "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
        paddingTop: "2px",
      }}
    >
      <Grid container>
        {!globalMinimal && (
          <>
            <Grid
              item
              xs={6}
              lg={4}
              style={{
                marginTop: "8px",
              }}
            >
              <Box p={1}>
                <Button
                  onClick={() => changeDateOffset(-1)}
                  startIcon={<ArrowLeftIcon />}
                  disabled={!joggable}
                />
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dateRangeName}
                    onChange={(event) => setDateRangeName(event.target.value)}
                  >
                    {dateRangeOptions.map((opt) => (
                      <MenuItem key={opt.name} value={opt.name}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={() => changeDateOffset(1)}
                  startIcon={<ArrowRightIcon />}
                  disabled={inLastMonth || !joggable}
                />
                <Typography
                  variant="overline"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  &nbsp;{rangeToText(dateRange, dateRangeName)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              lg={2}
              style={{
                marginTop: "8px",
              }}
            >
              {/* <Box p={1}> */}
              <FormControl
                style={{
                  minWidth: "160px",
                }}
              >
                <InputLabel>Quantity Display</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedQuantity}
                  onChange={changeQuantity}
                  label="Quantity Display"
                >
                  {quantities
                    .filter((item) => item.value !== quantityFilter)
                    .map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* </Box> */}
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={6}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0.6rem 1rem",
            }}
            justifyContent={{
              xs: "flex-start",
              lg: "space-around",
            }}
          >
            {filtersActive && (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    setShowSecondaryFilters({
                      ...showSecondaryFilters,
                      products: !showSecondaryFilters.products,
                    });
                  }}
                  variant="contained"
                  style={styles.button}
                  color={showSecondaryFilters.products ? "primary" : "default"}
                >{`Products`}</Button>
                {(location.pathname.includes("depletions") ||
                  location.pathname.includes("home")) && (
                  <Button
                    size="small"
                    onClick={() => {
                      setShowSecondaryFilters({
                        ...showSecondaryFilters,
                        premise: !showSecondaryFilters.premise,
                      });
                    }}
                    variant="contained"
                    style={styles.button}
                    color={showSecondaryFilters.premise ? "primary" : "default"}
                  >{`Premises`}</Button>
                )}
                <Divider orientation="vertical" flexItem />
                <Button
                  title="Select All"
                  onClick={everythingOn}
                  variant="contained"
                  style={styles.button}
                  size="small"
                >
                  Select All
                </Button>
                <Button
                  title="Select None"
                  onClick={everythingOff}
                  variant="contained"
                  style={styles.button}
                  size="small"
                >
                  Select None
                </Button>
                {!selectFormatOpen ? (
                  <Button
                    title="Export"
                    size="small"
                    startIcon={<CloudDownload />}
                    variant="contained"
                    style={styles.button}
                    onClick={() => setSelectFormatOpen(true)}
                    disabled={exportLoading || !exportEnabled}
                  >
                    Export
                    {exportLoading ? <Loader /> : ""}
                  </Button>
                ) : (
                  <ButtonGroup style={{ marginTop: "20px" }}>
                    <Button onClick={() => {}}>File Type:</Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => emitGlobalExportEvent("CSV")}
                      type="button"
                      disabled={!exportEnabled}
                    >
                      CSV
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => emitGlobalExportEvent("XLSX")}
                      type="button"
                      disabled={!exportEnabled}
                    >
                      XLSX
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => emitGlobalExportEvent("PDF")}
                      type="button"
                      disabled={!exportEnabled}
                    >
                      PDF
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ color: "red", borderColor: "red" }}
                      onClick={() => setSelectFormatOpen(!selectFormatOpen)}
                      type="button"
                      disabled={!exportEnabled}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          margin: "1rem",
        }}
      >
        {(showSecondaryFilters.products ||
          location.pathname.includes("lists")) && (
          <Grid item xs={12} md={6}>
            <Box className="product-selecter-wrapper" paddingX={1}>
              <ProductSelecter location={location} />
            </Box>
          </Grid>
        )}
        {showSecondaryFilters.premise && (
          <Grid item xs={6}>
            <Box className="product-selecter-wrapper" paddingX={1}>
              <PremiseSelecter />
            </Box>
          </Grid>
        )}
      </Grid>
      {showDatePickers && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginLeft: 10,
            marginBottom: 10,
          }}
        >
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={UTCLuxonUtils}>
              <DatePicker
                label="START DATE"
                inputVariant="outlined"
                disableFuture="true"
                value={editDateRange[0]}
                onChange={handleFromDateChange}
                views={["month", "year"]}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          -
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={UTCLuxonUtils}>
              <DatePicker
                label="END DATE"
                inputVariant="outlined"
                disableFuture="true"
                value={editDateRange[1]}
                onChange={handleToDateChange}
                views={["month", "year"]}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          |
          <Grid item xs={3}>
            <Button
              color="primary"
              style={{
                border: "1px solid #2458af",
                padding: 5,
                borderRadius: 1000,
                paddingLeft: 15,
                paddingRight: 15,
              }}
              startIcon={<CalendarTodayIcon />}
              onClick={prepareCustomDateRange}
            >
              Set Dates
            </Button>
          </Grid>
        </div>
      )}
    </Grid>
  );
});

export default MegaFilterGlobalBar;
