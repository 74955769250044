import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ProductModal from "./ProductModal";
import { awsS3Image } from "../../../helpers/awsS3Image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  fullHeightCard: {
    height: "100%",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ProductCard = ({ className, product, ...rest }) => {
  const classes = useStyles();
  const handleClose = (value) => {
    setRefresh(false);
    setOpen(false);
    setSelectedValue(value);
    setRefresh(true);
  };
  const [item, setItem] = useState({});
  const [bgUrl, setBgUrl] = useState("");
  const [refresh, setRefresh] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const s3ImageEl = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  useEffect(() => {
    if (product) {
      let _product = { ...product };
      _product.image_scr = awsS3Image(
        _product.product_image,
        _product.product_imagekey,
        "0x190"
      );
      setItem({ ..._product });
    }
  }, [product]);
  /**
   * after the S3 image is loaded, hook into a ref to find the img url via the DOM
   * and then use it as abackground image on it's previous sibling
   *
   */
  const imageLoaded = (event, item) => {
    const el = s3ImageEl.current;
    if (el && el.nextElementSibling) {
      const next = el.nextElementSibling.shadowRoot;
      const imgUrl = next.querySelector("img").src;
      setBgUrl(imgUrl);
    }
  };

  return (
    <div>
      <ProductModal
        selectedValue={selectedValue}
        open={open}
        item={item}
        setItem={setItem}
        onClose={handleClose}
      />
      <Card className={classes.fullHeightCard}>
        <CardActionArea onClick={() => handleClickOpen()}>
          <CardContent>
            <Box display="flex" justifyContent="center" mb={3}>
              {item.tempImage ? (
                <Avatar
                  alt="Product"
                  src={item.tempImage}
                  style={{
                    height: "110px",
                    width: "110px",
                    backgroundColor: "#ffffff",
                    position: "relative",
                  }}
                  variant="circular"
                ></Avatar>
              ) : (
                <Avatar
                  alt="Product"
                  style={{
                    height: "110px",
                    width: "110px",
                    backgroundColor: "#ffffff",
                    position: "relative",
                  }}
                  variant="circular"
                >
                  {refresh && item.product_image !== "none" && (
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="s3ImgToBg"
                        ref={s3ImageEl}
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: `url(${bgUrl})`,
                        }}
                      ></div>

                      <img
                        src={item.image_scr}
                        style={{
                          height: "100%",
                          maxWidth: "100%",
                          position: "absolute",
                          top: "0px",
                          // left: '-50%',
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  )}
                </Avatar>
              )}
            </Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h6"
            >
              {item.product_displayname}
            </Typography>
            <Typography align="center" color="textPrimary" variant="body1">
              Size: {item.product_size}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
        </CardActionArea>
      </Card>
    </div>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
};

export default ProductCard;
