import { API } from "aws-amplify";
import client from "./client";
const apiName = "backendGateway"; //should be env variable

const getDistributors = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors`;
  return API.get(apiName, endpoint, {});
};

const getDistributorsInventory = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/inventory`;
  return API.get(apiName, endpoint, {});
};

const getDistributorsDataFreshness = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/data_freshness/quickfix`;
  return API.get(apiName, endpoint, {});
};

const getDistributorContacts = (manufacturer_id, distributor_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts`;
  return client.get(endpoint, {});
};

const deleteDistributorContact = (
  manufacturer_id,
  distributor_id,
  contact_id
) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts/${contact_id}`;
  return API.del(apiName, endpoint, {});
};

const updateDistributorContact = (
  manufacturer_id,
  distributor_id,
  contact_id,
  data
) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts/${contact_id}`;
  return client.put(endpoint, data);
};
const addDistributorContact = (manufacturer_id, distributor_id, data) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts`;
  return client.post(endpoint, data);
};

const getDistributorDetails = (manufacturer_id, distributor_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/${distributor_id}`;
  return client.get(endpoint, {});
};

const getDistributorComparison = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors_and_brands`;
  return client.get(endpoint, {});
};

const getDistributorFinancialComparison = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/financial_report`;
  return client.get(endpoint, {});
};

const getExternalBrands = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/external_brands/cached`;
  return client.get(endpoint, {});
};

const getTopExternalBrands = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/top-external-brands`;
  return client.get(endpoint, {});
};

export default {
  getDistributors,
  getDistributorsDataFreshness,
  getDistributorContacts,
  getDistributorsInventory,
  deleteDistributorContact,
  updateDistributorContact,
  addDistributorContact,
  getDistributorDetails,
  getDistributorComparison,
  getDistributorFinancialComparison,
  getExternalBrands,
  getTopExternalBrands,
};
