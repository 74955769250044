import { DateTime } from "luxon";

export const PresetOptions = [
  {
    name: "Account > Product",
    associations: ["account", "product"],
    urlString: "account_product",
    sortModel: [
      {
        field: "STORENUMBER",
        sort: "asc",
      },
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Account > Brand",
    associations: ["account", "brand"],
    urlString: "account_brand",
    sortModel: [
      {
        field: "STORENUMBER",
        sort: "asc",
      },
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "State > Product",
    associations: ["region", "product"],
    urlString: "region_product",
    sortModel: [
      {
        field: "STATE",
        sort: "asc",
      },
      // {
      //   field: "CITY",
      //   sort: "asc",
      // },
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "State > Brand",
    associations: ["region", "brand"],
    urlString: "region_brand",
    sortModel: [
      {
        field: "STATE",
        sort: "asc",
      },
      // {
      //   field: "CITY",
      //   sort: "asc",
      // },
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Product Totals",
    associations: ["product"],
    urlString: "product_totals",
    sortModel: [
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Brand Totals",
    associations: ["brand"],
    urlString: "brand_totals",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Brand Accounts Sold",
    associations: ["brand"],
    urlString: "brand_accounts_sold",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Product Accounts Sold",
    associations: ["product"],
    urlString: "product_accounts_sold",
    sortModel: [
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Distributor Comp.",
    associations: ["product"],
    urlString: "distributor_totals",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Distributor Comp. - Brand",
    associations: ["product"],
    urlString: "distributor_brand_totals",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Distributor Comp. - Product",
    associations: ["product"],
    urlString: "distributor_product_totals",
    sortModel: [
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Account > Reorders",
    associations: ["account", "product"],
    urlString: "account_reorders",
    sortModel: [
      {
        field: "orders",
        sort: "desc",
      },
    ],
  },
];

export const columnFiltersPassed = (data, filters) => {};
const tableToQuery = (tableName, settings, id) => {
  const {
    rows,
    cols,
    rendererName = "Table",
    aggregatorName = "Count",
    vals = [],
    customise = false,
  } = settings;
  let args = [`preset=${id}`];
  if (rows.length) {
    args.push(`${tableName}_rows=${rows.join("%2b")}`);
  }
  if (cols.length) {
    args.push(`${tableName}_cols=${cols.join("%2b")}`);
  }
  args.push(`${tableName}_aggregatorName=${aggregatorName}`);
  args.push(`${tableName}_rendererName=${rendererName}`);
  if (vals.length) {
    args.push(`${tableName}_vals=${vals.join("+")}`);
  }
  return args;
};

export const queryBuilder = (preset) => {
  const query = encodeURI(`?` + [`preset=${preset.urlString}`].join("&"));
  return query;
};

export const dataFilter = ({
  results,
  dateRange,
  quantity,
  regions,
  brands,
  distributors,
  products,
  productPositive,
  premiseTypes,
  filterProductStage,
  showComparison,
}) => {
  const currentPeriod = dateRange.map((dateBoundary) => {
    return DateTime.fromJSDate(dateBoundary);
  });

  const previousPeriod = dateRange.map((dateBoundary) => {
    return DateTime.fromJSDate(dateBoundary).minus({ years: 1 });
  });

  const datePass = (item) => {
    const date = DateTime.fromISO(item.DATE);
    return (
      (currentPeriod[0] <= date && date <= currentPeriod[1]) ||
      (showComparison && previousPeriod[0] <= date && date <= previousPeriod[1])
    );
  };

  const regionPass = (item) =>
    regions.includes(item.STATE) || regions.includes(item.REGION);

  const distributorPass = (item) =>
    distributors.includes(item.DISTRIBUTOR_NAME);

  const brandPass = (item) => {
    const reg = new RegExp(`^${item.BRAND}$`, "i");
    return brands.some((b) => {
      return reg.test(b);
    });
  };

  const premisePass = (item) => {
    const reg = new RegExp(`^${item.PREMISETYPE}$`, "i");
    return typeof premiseTypes.find((p) => reg.test(p)) !== "undefined";
  };

  const productBeforePass = (item) => {
    const itemHasProduct = products.find((p) =>
      item.PRODUCTNAME.startsWith(p.product_displayname)
    );
    const found = typeof itemHasProduct === "object";
    return productPositive ? found : !found;
  };
  let productAccounts = new Set();
  let filtered = results
    .filter((item) => {
      if (!datePass(item)) return false;
      if (!regionPass(item)) return false;
      if (!distributorPass(item)) return false;
      if (!brandPass(item)) return false;
      if (!premisePass(item)) return false;
      if (filterProductStage === "before" && products.length) {
        if (!productBeforePass(item)) return false;
      }
      return true;
    })
    .map((f) => {
      if (products.length && filterProductStage === "after") {
        if (
          products.find((p) => f.PRODUCTNAME.startsWith(p.product_displayname))
        ) {
          productAccounts.add(f.STORENUMBER);
        }
      }
      const date = DateTime.fromISO(f.DATE);
      return {
        ...f,
        QTYSOLD: f[quantity],
        COMPARISON_DATA: !(
          currentPeriod[0] <= date && date <= currentPeriod[1]
        ),
        DATE: date.toUTC().toFormat("yyyy/MM/dd"),
      };
    });
  if (products.length && filterProductStage === "after") {
    filtered = filtered.filter((sale) => {
      if (productPositive) {
        return productAccounts.has(sale.STORENUMBER);
      }
      if (!productPositive) {
        return !productAccounts.has(sale.STORENUMBER);
      }
    });
  }

  return filtered;
};

export default {
  PresetOptions,
};
