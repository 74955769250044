import { Box, Button } from "@mui/material";
import Close from "@material-ui/icons/Close";
import AnswersOnScreen from "../answers/AnswerOnScreen";
import { useMemo } from "react";
import { Typography } from "@material-ui/core";

const ResponseModal = ({ form }) => {
  const { form_body, form_name, data } = form;
  const answers = useMemo(() => {
    if (!data) return [];
    const list = JSON.parse(data.response_body).values;
    return list ? list : [];
  }, [data]);

  const questions = useMemo(() => {
    const list = JSON.parse(form_body);
    return list ? list : [];
  }, [form_body]);

  return (
    <Box p={3} position={"relative"}>
      <Box display="flex" justifyContent={"space-between"}>
        <Box>
          <Typography variant="subtitle1">{form_name}</Typography>
        </Box>
      </Box>
      <AnswersOnScreen questions={questions} answers={answers} />
    </Box>
  );
};

export default ResponseModal;
