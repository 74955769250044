import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";
import Account from "./Account";
import Media from "./Media";
import AccountMetrics from "./AccountMetrics";
import { trackEvent } from "../../../api/analytics";
import { API } from "aws-amplify";
import ListOfEvents from "./ListOfEvents";
import { useStore } from "../../../stores/StoreContext";
import moment from "moment";
import formAPI from "../../../api/forms";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
}));

const AccountProfileView = () => {
  const newestFeedItemFirst = (a, b) => {
    return new Date(b.Date) - new Date(a.Date);
  };
  const classes = useStyles();
  const [checkin, setCheckin] = useState("");
  const [loading, setLoading] = useState(true);
  const [listOfEverything, setListOfEverything] = useState([]);
  const { userInfo } = useStore();
  const { id } = useParams();

  const getListOfAllData = useCallback(async () => {
    try {
      // do multiple lookups in parallel
      const [visitData, checkInData, formsData, ordersData] = await Promise.all(
        [
          API.get("backendGateway", "/visits", {
            queryStringParameters: {
              mid: userInfo.mid,
              aid: id,
              limit: 1000,
            },
          }),
          API.get("backendGateway", "/accounts/checkin", {
            queryStringParameters: {
              mid: userInfo.mid,
              id: id,
            },
          }),
          formAPI.getAccountFormResponses(id),
          API.get("backendGateway", "/accounts/sales/timeline", {
            queryStringParameters: {
              mid: userInfo.mid,
              id: id,
              limit: 1000,
            },
          }),
        ]
      );

      let groupByDate = ordersData.results.reduce((group, order) => {
        const { created } = order;
        const dateGroup = moment.utc(new Date(created)).format("MMM YYYY");
        group[dateGroup] = group[dateGroup] || [];
        group[dateGroup].push(order);
        return group;
      }, {});
      groupByDate = Object.entries(groupByDate);

      const tempOrdersList =
        groupByDate?.map((order) => ({
          Date: moment.utc(new Date(order[0])).format("MMM YYYY"),
          Type: "Depletion",
          Contact: " ",
          Info: `${order[1].length} product sales during ${order[0]}`,
          Orders: order[1],
        })) || [];
      const tempFormsList =
        formsData.results?.map((form) => ({
          Date: form.created,
          Type: "Form",
          Contact: form.user_displayname,
          Info: "Form Name: " + form.form_name,
          Form: form,
        })) || [];

      const tempCheckinList =
        checkInData.results?.map((checkIn) => ({
          Date: checkIn.checkin_date,
          Type: "CheckIn",
          Contact: checkIn.name,
          Info: `CheckIn at ${moment
            .utc(new Date(checkIn.checkin_date))
            .format("HH:mm")}`,
        })) || [];

      const tempVisitList =
        visitData.results?.map((visitData) => ({
          Date: visitData.created,
          Type: "Notes",
          Contact: visitData.visit_contact,
          Info: visitData.visit_notes,
          VisitData: visitData,
        })) || [];

      setListOfEverything([
        ...tempVisitList,
        ...tempCheckinList,
        ...tempFormsList,
        ...tempOrdersList,
      ]);
      setLoading(false);
    } catch (err) {
      console.log("error fetching visits data..", err);
    }
  }, [id, userInfo.mid]);

  useEffect(() => {
    getListOfAllData();
    trackEvent(userInfo.uid, "PageView:AccountDetails");
  }, [getListOfAllData, userInfo.uid]);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={12}>
            <Account setCheckin={setCheckin} />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Media />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <AccountMetrics checkin={checkin} setCheckin={setCheckin} />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ marginTop: "20px" }}>
          <ListOfEvents
            listOfEvents={listOfEverything.sort(newestFeedItemFirst)}
            callBackRefresh={() => {
              setLoading(true);
              getListOfAllData();
            }}
            userInfo={userInfo}
            loading={loading}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default AccountProfileView;
