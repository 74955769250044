import React, { useState, useMemo, useRef, Fragment } from "react";
import { observer } from "mobx-react-lite";
import {
  Card,
  Grid,
  CardContent,
  makeStyles,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Button,
} from "@material-ui/core";
import { ChevronDown, ChevronUp } from "react-feather";
import { cloneDeep } from "lodash";
import { useStore } from "../../../stores/StoreContext";
import { Link as RouterLink } from "react-router-dom";
import { ExpandMore, ExpandLess, AccountTree } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  loading: {
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: theme.spacing(4),
  },
  statbox: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
  selectableRow: {
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}));

const TopAccounts = observer(({ filterArgs, dailyStats }) => {
  const { allStates } = useStore();

  const classes = useStyles();

  const [isExpand, setIsExpand] = useState(true);
  const [toggledRow, setToggledRow] = useState(-1);
  const [isTopAccounts, setIsTopAccounts] = useState(true);
  const ccRef = useRef();
  const { productSold, products } = filterArgs;

  const headerData =
    filterArgs.states.size >= 6
      ? [`${filterArgs.states.size}/${allStates.length} States`]
      : Array.from(filterArgs.states).toString();

  const TopAccounsts = useMemo(() => {
    console.log(filterArgs);
    return (
      Object.values(
        dailyStats.reduce((acc, cur, ind) => {
          let account = acc[cur.ACCOUNT_ID];
          let { NINE_SOLD, BBL, PHYSICAL_SOLD, QTYSOLD } = cur;
          if (account) {
            account.NINE_SOLD += cur.NINE_SOLD;
            account.BBL += cur.BBL;
            account.QTYSOLD += cur.QTYSOLD;
            account.PHYSICAL_SOLD += cur.PHYSICAL_SOLD;

            if (account.products[cur.PRODUCTNAME]) {
              account.products[cur.PRODUCTNAME].NINE_SOLD += cur.NINE_SOLD;
              account.products[cur.PRODUCTNAME].BBL += cur.BBL;
              account.products[cur.PRODUCTNAME].QTYSOLD += cur.QTYSOLD;
              account.products[cur.PRODUCTNAME].PHYSICAL_SOLD +=
                cur.PHYSICAL_SOLD;
            } else {
              account.products[cur.PRODUCTNAME] = {
                NINE_SOLD: cur.NINE_SOLD,
                BBL: cur.BBL,
                QTYSOLD: cur.QTYSOLD,
                PHYSICAL_SOLD: cur.PHYSICAL_SOLD,
                SKU: cur.SKU,
                PRODUCTNAME: cur.PRODUCTNAME,
              };
            }
            if (products.size > 0 && products.has(cur.PRODUCT_ID)) {
              account.filteredProductSize.NINE_SOLD += cur.NINE_SOLD;
              account.filteredProductSize.BBL += cur.BBL;
              account.filteredProductSize.PHYSICAL_SOLD += cur.QTYSOLD;
              account.filteredProductIds.add(cur.PRODUCT_ID);
            }
          } else {
            account = cloneDeep(cur);
            account.products = {
              [cur.PRODUCTNAME]: {
                NINE_SOLD: cur.NINE_SOLD,
                BBL: cur.BBL,
                QTYSOLD: cur.QTYSOLD,
                PHYSICAL_SOLD: cur.PHYSICAL_SOLD,
                SKU: cur.SKU,
                PRODUCTNAME: cur.PRODUCTNAME,
              },
            };
            if (products.size > 0 && products.has(cur.PRODUCT_ID)) {
              account.filteredProductIds = new Set([cur.PRODUCT_ID]);
              account.filteredProductSize = {
                NINE_SOLD,
                BBL,
                PHYSICAL_SOLD,
              };
            } else {
              account.filteredProductIds = new Set();
              account.filteredProductSize = {
                NINE_SOLD: 0,
                BBL: 0,
                PHYSICAL_SOLD: 0,
              };
            }
          }
          acc[cur.ACCOUNT_ID] = account;
          return acc;
        }, {})
      )
        // filter products in a Swiss Cheese manner
        .filter((a, ind) => {
          if (products.size === 0) return true;
          if (productSold) {
            return a.filteredProductIds.size === products.size;
          }
          return a.filteredProductIds.size === 0;
        })
        // we only need the top 10 accounts, so pluck them before further manipulations
        .sort((a, b) => {
          if (products.size > 0) {
            return a.filteredProductSize.NINE_SOLD >
              b.filteredProductSize.NINE_SOLD
              ? -1
              : a.filteredProductSize.NINE_SOLD <
                b.filteredProductSize.NINE_SOLD
              ? 1
              : 0;
          }
          return a.NINE_SOLD > b.NINE_SOLD
            ? -1
            : a.NINE_SOLD < b.NINE_SOLD
            ? 1
            : 0;
        })
        .slice(0, 10)
        .map((account) => ({
          ...account,
          products: Object.values(account.products).sort((a, b) =>
            a.NINE_SOLD > b.NINE_SOLD ? -1 : a.NINE_SOLD < b.NINE_SOLD ? 1 : 0
          ),
        }))
    );
  }, [dailyStats]);

  const BottomAccounts = useMemo(() => {
    return (
      Object.values(
        dailyStats.reduce((acc, cur) => {
          let account = acc[cur.ACCOUNT_ID];
          if (account) {
            account.NINE_SOLD += cur.NINE_SOLD;
            account.BBL += cur.BBL;
            account.QTYSOLD += cur.QTYSOLD;
            account.PHYSICAL_SOLD += cur.PHYSICAL_SOLD;

            if (account.products[cur.PRODUCTNAME]) {
              account.products[cur.PRODUCTNAME].NINE_SOLD += cur.NINE_SOLD;
              account.products[cur.PRODUCTNAME].BBL += cur.BBL;
              account.products[cur.PRODUCTNAME].QTYSOLD += cur.QTYSOLD;
              account.products[cur.PRODUCTNAME].PHYSICAL_SOLD +=
                cur.PHYSICAL_SOLD;
            } else {
              account.products[cur.PRODUCTNAME] = {
                NINE_SOLD: cur.NINE_SOLD,
                BBL: cur.BBL,
                QTYSOLD: cur.QTYSOLD,
                PHYSICAL_SOLD: cur.PHYSICAL_SOLD,
                SKU: cur.SKU,
                PRODUCTNAME: cur.PRODUCTNAME,
              };
            }
          } else {
            account = cloneDeep(cur);
            account.products = {
              [cur.PRODUCTNAME]: {
                NINE_SOLD: cur.NINE_SOLD,
                BBL: cur.BBL,
                QTYSOLD: cur.QTYSOLD,
                PHYSICAL_SOLD: cur.PHYSICAL_SOLD,
                SKU: cur.SKU,
                PRODUCTNAME: cur.PRODUCTNAME,
              },
            };
          }
          acc[cur.ACCOUNT_ID] = account;
          return acc;
        }, {})
      )
        // Sort the accounts in ascending order based on NINE_SOLD
        .sort((a, b) =>
          a.NINE_SOLD > b.NINE_SOLD ? 1 : a.NINE_SOLD < b.NINE_SOLD ? -1 : 0
        )
        // Select the bottom 10 accounts
        .slice(0, 10)
        .map((account) => ({
          ...account,
          products: Object.values(account.products).sort((a, b) =>
            a.NINE_SOLD > b.NINE_SOLD ? -1 : a.NINE_SOLD < b.NINE_SOLD ? 1 : 0
          ),
        }))
    );
  }, [dailyStats]);
  const productFilteringString = useMemo(() => {
    let string = "";
    if (products.size === 0) return string;
    if (productSold) {
      string += " which have sold selected Products";
    } else {
      string += " which have not sold selected Products";
    }
    return string;
  }, [products, productSold]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xs={12}>
        <Card>
          <CardHeader
            title={
              isTopAccounts
                ? `${headerData} Top Accounts`
                : `${headerData} Bottom Accounts`
            }
            className="st-card-header"
            action={
              <>
                <Button
                  style={{ color: "white" }}
                  onClick={() => setIsTopAccounts(!isTopAccounts)}
                >
                  {" "}
                  View {!isTopAccounts ? `Top` : `Bottom`} Accounts{" "}
                </Button>
                <IconButton
                  size="medium"
                  onClick={() => setIsExpand((prev) => !prev)}
                >
                  {isExpand ? (
                    <ExpandLess style={{ fill: "white", fontSize: "30px" }} />
                  ) : (
                    <ExpandMore style={{ fill: "white", fontSize: "30px" }} />
                  )}
                </IconButton>
              </>
            }
          />
          <CardContent ref={ccRef}>
            <Box style={{ display: isExpand ? "block" : "none" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      Account Name
                    </TableCell>
                    <TableCell style={{ padding: "8px" }}>Address</TableCell>
                    {/* <TableCell>Phone</TableCell> */}
                    <TableCell style={{ padding: "8px" }}>9Ltr Case</TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      Physical Case
                    </TableCell>
                    <TableCell style={{ padding: "8px" }}>BBL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isTopAccounts ? (
                    <>
                      {TopAccounsts.map((account, index) => {
                        return (
                          <Fragment key={index}>
                            <TableRow
                              key={account.DISTRIBUTOR_ID}
                              className={classes.selectableRow}
                              selected={toggledRow === index}
                              onClick={() => {
                                setToggledRow(
                                  toggledRow === index ? -1 : index
                                );
                              }}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {toggledRow === index ? (
                                  <ChevronUp />
                                ) : (
                                  <ChevronDown />
                                )}
                              </TableCell>
                              <TableCell style={{ padding: "8px" }}>
                                <RouterLink
                                  to={
                                    "/app/accountdetails/" + account.ACCOUNT_ID
                                  }
                                >
                                  {account.STORENUMBER}
                                </RouterLink>
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{account.STOREADDRESS}</span>
                                <span>
                                  {account.CITY} {account.STATE}
                                </span>
                              </TableCell>
                              {/* <TableCell>
                            <a href={`tel:${account.account_phone}`}>
                              {account.account_phone}
                            </a>
                          </TableCell> */}
                              <TableCell style={{ padding: "8px" }}>
                                {account.filteredProductSize.NINE_SOLD > 0
                                  ? account.filteredProductSize.NINE_SOLD.toFixed(
                                      2
                                    )
                                  : account.NINE_SOLD.toFixed(2)}{" "}
                              </TableCell>

                              <TableCell style={{ padding: "8px" }}>
                                {account.filteredProductSize.PHYSICAL_SOLD > 0
                                  ? account.filteredProductSize.PHYSICAL_SOLD.toFixed(
                                      2
                                    )
                                  : account.PHYSICAL_SOLD.toFixed(2)}{" "}
                              </TableCell>
                              <TableCell style={{ padding: "8px" }}>
                                {account.filteredProductSize.BBL > 0
                                  ? account.filteredProductSize.BBL.toFixed(2)
                                  : account.BBL.toFixed(2)}{" "}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {toggledRow === index && (
                                <TableCell
                                  colSpan={5}
                                  style={{
                                    paddingRight: 0,
                                    borderLeft: "2px solid #000",
                                  }}
                                >
                                  <Box>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Top Products</TableCell>
                                          <TableCell>9Ltr Case</TableCell>
                                          <TableCell>Physical Case</TableCell>
                                          <TableCell>BBL</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {account.products.map((product) => {
                                          return (
                                            <TableRow key={product.SKU}>
                                              <TableCell>
                                                {product.PRODUCTNAME}
                                              </TableCell>
                                              <TableCell>
                                                {product.NINE_SOLD.toFixed(2)}
                                              </TableCell>

                                              <TableCell>
                                                {product.PHYSICAL_SOLD.toFixed(
                                                  2
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                {product.BBL.toFixed(2)}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </TableCell>
                              )}
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {BottomAccounts.map((account, index) => {
                        return (
                          <Fragment key={index}>
                            <TableRow
                              key={account.DISTRIBUTOR_ID}
                              className={classes.selectableRow}
                              selected={toggledRow === index}
                              onClick={() => {
                                setToggledRow(
                                  toggledRow === index ? -1 : index
                                );
                              }}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {toggledRow === index ? (
                                  <ChevronUp />
                                ) : (
                                  <ChevronDown />
                                )}
                              </TableCell>
                              <TableCell style={{ padding: "8px" }}>
                                <RouterLink
                                  to={
                                    "/app/accountdetails/" + account.ACCOUNT_ID
                                  }
                                >
                                  {account.STORENUMBER}
                                </RouterLink>
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{account.STOREADDRESS}</span>
                                <span>
                                  {account.CITY} {account.STATE}
                                </span>
                              </TableCell>
                              {/* <TableCell>
                            <a href={`tel:${account.account_phone}`}>
                              {account.account_phone}
                            </a>
                          </TableCell> */}
                              <TableCell style={{ padding: "8px" }}>
                                {" "}
                                {account.NINE_SOLD.toFixed(2)}{" "}
                              </TableCell>

                              <TableCell style={{ padding: "8px" }}>
                                {" "}
                                {account.PHYSICAL_SOLD.toFixed(2)}{" "}
                              </TableCell>
                              <TableCell style={{ padding: "8px" }}>
                                {" "}
                                {account.BBL.toFixed(2)}{" "}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {toggledRow === index && (
                                <TableCell
                                  colSpan={5}
                                  style={{
                                    paddingRight: 0,
                                    borderLeft: "2px solid #000",
                                  }}
                                >
                                  <Box>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Top Products</TableCell>
                                          <TableCell>9Ltr Case</TableCell>
                                          <TableCell>Physical Case</TableCell>
                                          <TableCell>BBL</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {account.products.map((product) => {
                                          return (
                                            <TableRow key={product.SKU}>
                                              <TableCell>
                                                {product.PRODUCTNAME}
                                              </TableCell>
                                              <TableCell>
                                                {product.NINE_SOLD.toFixed(2)}
                                              </TableCell>

                                              <TableCell>
                                                {product.PHYSICAL_SOLD.toFixed(
                                                  2
                                                )}
                                              </TableCell>
                                              <TableCell>
                                                {product.BBL.toFixed(2)}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </TableCell>
                              )}
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default TopAccounts;
