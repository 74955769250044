import { Dialog, DialogTitle } from "@material-ui/core";
import CustomerDetails from "../../../../views/lists/AccountDetailsView/CustomerDetails";
import { useStore } from "../../../../stores/StoreContext";
const SaveLocationDialog = ({ location, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  const store = useStore();
  const { customers, setCustomers } = store;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Save Location</DialogTitle>
      <CustomerDetails
        onClose={handleClose}
        customers={customers}
        setCustomers={setCustomers}
        location={location}
      />
    </Dialog>
  );
};

export default SaveLocationDialog;
