import { useCallback, useMemo, useEffect } from "react";
import { TextField, ButtonGroup, Button, Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Check, Close } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";

const ProductSelecter = observer(({ location }) => {
  const {
    autoCompleteProducts,
    filteringProducts,
    setFilteringProducts,
    selectedBrands,
    productsInData,
    productJoiner,
    setProductJoiner,
    productSold,
    setProductSold,
  } = useStore();
  const adjustedProducts = autoCompleteProducts.filter((p) =>
    selectedBrands.includes(p.product_brand)
  );

  const productChange = (values) => {
    const newFilteringProducts = autoCompleteProducts.filter((acp) =>
      values.find((v) => acp.product_id === v.product_id)
    );
    setFilteringProducts(newFilteringProducts);
  };

  const productJoinerTypeSelectionEnabled = useMemo(() => {
    return location.pathname.match("app/list");
  }, [location]);

  useEffect(() => {
    if (!productSold && productJoiner === "Or") {
      setProductJoiner("And");
    }
  }, [productSold, productJoiner, setProductJoiner]);

  const booleanIsRelevant = useMemo(() => {
    return filteringProducts.length > 1;
  }, [filteringProducts]);

  const optionIsValid = useCallback(
    (option) => {
      // console.log("optionIsValid", { option });
      let filterComparator = "canonical_product_id";
      let dataComparator = "product_id";
      if (productsInData[0]?.hasOwnProperty("PRODUCTNAME")) {
        filterComparator = "PRODUCTNAME";
        dataComparator = "product_displayname";
      }
      if (filterComparator === "PRODUCTNAME") {
        return (
          productsInData.findIndex((pid) => {
            const { product_displayname, product_size } = option;
            return (
              `${product_displayname} - ${product_size}` === pid["PRODUCTNAME"]
            );
          }) > -1
        );
      }
      return (
        productsInData.findIndex(
          (pid) => pid[filterComparator] === option[dataComparator]
        ) > -1
      );
    },
    [productsInData]
  );

  const buttonStyle = {
    width: "30px",
    textAlign: "center",
    marginRight: 0,
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      id="product-selecter"
      className="state-filter home-filter-chunk"
    >
      <Box
        flexDirection={"column"}
        alignItems="flex-end"
        sx={{
          flexGrow: { sx: 0, md: 1 },
        }}
      >
        <Box m={1} textAlign={"right"}>
          <ButtonGroup color="primary" size="small">
            <Button
              variant={productSold === true ? "contained" : ""}
              onClick={() => setProductSold(true)}
              startIcon={<Check />}
              style={buttonStyle}
              title="Include Products"
            ></Button>
            <Button
              variant={productSold === false ? "contained" : ""}
              onClick={() => setProductSold(false)}
              startIcon={<Close />}
              style={buttonStyle}
              title="Exclude Products"
            ></Button>
          </ButtonGroup>
        </Box>
        {productJoinerTypeSelectionEnabled && (
          <Box m={1} textAlign={"right"}>
            <ButtonGroup
              color="primary"
              size="small"
              disabled={!booleanIsRelevant || !productSold}
            >
              <Button
                variant={productJoiner === "And" ? "contained" : ""}
                onClick={() => setProductJoiner("And")}
                style={buttonStyle}
                title="All Chosen Products"
              >
                +
              </Button>
              <Button
                variant={productJoiner === "Or" ? "contained" : ""}
                onClick={() => setProductJoiner("Or")}
                style={buttonStyle}
                title="Any Chosen Product"
              >
                /
              </Button>
            </ButtonGroup>
          </Box>
        )}
      </Box>
      <Box flexGrow={3}>
        <Autocomplete
          disablePortal
          multiple
          options={adjustedProducts}
          getOptionLabel={(option) =>
            `${option.product_displayname} - (${option.product_size})`
          }
          style={{
            marginTop: 0,
          }}
          getOptionDisabled={(option) => !optionIsValid(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Products (${adjustedProducts.length})`}
              style={{
                marginTop: 0,
              }}
            />
          )}
          onChange={(event, value) => productChange(value)}
          value={autoCompleteProducts.filter((product) =>
            filteringProducts.find((fp) => fp.product_id === product.product_id)
          )}
        />
      </Box>
    </Box>
  );
});

export default ProductSelecter;
