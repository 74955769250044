import { API } from "aws-amplify";

export async function trackEvent(user_id, event) {
  try {
    const track = {
      user_id: user_id,
      event: event,
    };
    const apiName = "backendGateway";
    const path = "/analytics";
    const myInit = {
      // OPTIONAL
      body: track,
      headers: {}, // OPTIONAL
    };
    API.post(apiName, path, myInit);
  } catch (err) {
    console.log("error fetching data..", err);
  }
}
