import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { v4 as uuid } from "uuid";
import {
  Button,
  Container,
  makeStyles,
  Box,
  TextField,
  Typography,
  Modal,
} from "@material-ui/core";
import Page from "../../../components/Page";

import { trackEvent } from "../../../api/analytics";
import useStorage from "../../../hooks/useStorage";
import StorageClient from "../../../storage/client";
import Folder from "@material-ui/icons/Folder";
import useApi from "../../../hooks/useApi";
import { useStore } from "../../../stores/StoreContext";
import storageAPI from "../../../api/storage";
import mediaAPI from "../../../api/media";
import { observer } from "mobx-react-lite";
import MediaLibrary from "../../../components/media/MediaLibrary";
import GenericModal from "../../../components/modals/GenericModal";
import { useParams } from "react-router";
import DragAndDropUpload from "./DragAndDropUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MediaView = observer(() => {
  const { setFolders, folders, userInfo, files, setFiles } = useStore();
  const { folder_id, file_id } = useParams();
  const getStorageApi = useApi(storageAPI.getStorage);
  const getStorageApiRequest = getStorageApi.request;
  const postStorageApi = useApi(storageAPI.postStorage);
  const [uploadSnackbarOpen, setUploadSnackbarOpen] = useState(false);
  const classes = useStyles();
  const setS3Items = useStorage(StorageClient.upload);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [newFolder, setNewFolder] = useState({ name: "" });
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const fileRoot = "SalesTier/" + userInfo.mid + "/";
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const mf = folders.map((folder) => {
    return {
      ...folder,
      isFolder: true,
      files: files.filter((file) =>
        folder.files
          .map((ff) => ff.storage_file_id)
          .includes(file.gg_storage_id)
      ),
    };
  });
  const containedFiles = folders
    .map((folder) => folder.files.map((ff) => ff.storage_file_id))
    .flat(1);
  let _looseFiles = files.filter(
    (file) => !containedFiles.includes(file.gg_storage_id)
  );
  const processedFolders = mf;
  const processedFiles = _looseFiles;

  const startUpload = () => {
    setUploadSnackbarOpen(true);

    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

    setTimeout(() => {
      setUploadSnackbarOpen(false);
    }, 4000);
  };

  const handleFilesUpload = async (inputFiles) => {
    setUploadModalOpen(false);
    startUpload();
    const filesArray =
      inputFiles instanceof FileList ? Array.from(inputFiles) : inputFiles;

    try {
      let folderUpdates = {};
      const uploadedFiles = await Promise.all(
        filesArray.map(async (file) => {
          const rootKey = `${fileRoot}${uuid()}_${file.name}`;
          const newFile = {
            identity_id: userInfo.user_identitykey,
            file_key: rootKey,
            file_name: file.name,
            manufacturer_id: userInfo.mid,
            user_id: userInfo.uid,
            is_active: true,
            for_marketing: 1,
            created: new Date().toISOString(),
          };

          try {
            await setS3Items.request(rootKey, file, file.type);
            let result = await postStorageApi.request(newFile);
            newFile.gg_storage_id =
              result?.results?.insertId || result.results[0];

            if (folder_id) {
              await storageAPI.postSaveInFolder(
                folder_id,
                newFile.gg_storage_id
              );
              if (!folderUpdates[folder_id]) {
                folderUpdates[folder_id] = [];
              }
              folderUpdates[folder_id].push({
                ...newFile,
                storage_file_id: newFile.gg_storage_id,
                file_in_folder_id:
                  result?.results?.insertId || result.results[0],
              });
            }

            return newFile;
          } catch (error) {
            console.error("Error uploading file:", error);
            setFileUploadError(true);
            return null;
          }
        })
      );

      if (folder_id && folderUpdates[folder_id]) {
        setFolders(
          folders.map((folder) => {
            if (parseInt(folder_id, 10) === folder.folder_id) {
              return {
                ...folder,
                files: [...folder.files, ...folderUpdates[folder_id]],
              };
            }
            return folder;
          })
        );
      }

      const successfulUploads = uploadedFiles.filter(
        (file) => file !== null && !file.folder_id
      );
      setFiles([...files, ...successfulUploads]);
    } catch (error) {
      console.error("An error occurred during the file upload process:", error);
    }
  };

  useEffect(() => {
    const parseFolders = (list) => {
      return list.map((r) => ({
        ...r,
        files: JSON.parse(r.files),
      }));
    };

    const run = async () => {
      const { manufacturer_id } = userInfo;
      if (manufacturer_id) {
        const { results } = await mediaAPI.getFolders({ manufacturer_id });
        setFolders(parseFolders(results));
        const storedFiles = await getStorageApiRequest({
          mid: manufacturer_id,
          limit: 1000,
          for_marketing: 1,
        });
        if (storedFiles.results) {
          setFiles(storedFiles.results);
        }
        trackEvent(userInfo.uid, "PageView:Media");
      }
    };
    run();
  }, [getStorageApiRequest, setFiles, setFolders, userInfo]);

  const createNewFolder = async () => {
    const { name } = newFolder;
    const { results } = await mediaAPI.createFolder({
      folder_name: name,
      manufacturer_id: userInfo.manufacturer_id,
      user_id: userInfo.uid,
    });
    setNewFolder({ name: "" });
    setShowFolderDialog(false);
    const folder_id = results.insertId || results[0];
    setFolders([
      ...folders,
      {
        name,
        folder_id,
        manufacturer_id: userInfo.manufacturer_id,
        user_id: userInfo.uid,
        files: [],
      },
    ]);
  };

  return (
    <Page className={classes.root} title="Marketing">
      <Snackbar
        open={uploadSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setUploadSnackbarOpen(false)}
      >
        <Alert onClose={() => setUploadSnackbarOpen(false)} severity="info">
          File uploading...
        </Alert>
      </Snackbar>
      {!file_id && (
        <div style={{ marginLeft: 15 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setUploadModalOpen(true)}
          >
            Upload Files
          </Button>

          <Modal
            open={uploadModalOpen}
            onClose={() => setUploadModalOpen(false)}
            aria-labelledby="upload-modal-title"
            aria-describedby="upload-modal-description"
          >
            <Box className={classes.modalStyle}>
              <DragAndDropUpload onUpload={handleFilesUpload} />
            </Box>
          </Modal>
          <Button
            className={classes.button}
            disabled={folder_id}
            onClick={() => setShowFolderDialog(!showFolderDialog)}
            variant="contained"
            color="primary"
            endIcon={<Folder />}
          >
            New Folder
          </Button>
        </div>
      )}
      <Container maxWidth={false}>
        <MediaLibrary
          setFiles={setFiles}
          files={files}
          folder_id={folder_id}
          file_id={file_id}
          processedFiles={processedFiles}
          processedFolders={processedFolders}
          loading={getStorageApi.loading}
        />
        <GenericModal
          title={`New Folder`}
          open={showFolderDialog}
          onClose={() => setShowFolderDialog(false)}
          confirmAction={createNewFolder}
        >
          <TextField
            value={newFolder.name}
            onChange={(event) =>
              setNewFolder({
                ...newFolder,
                name: event.target.value,
              })
            }
          />
        </GenericModal>
        <GenericModal
          title={`Could not store file`}
          open={fileUploadError}
          onClose={() => setFileUploadError(false)}
          confirmAction={() => setFileUploadError(false)}
          doneText="Dismiss"
          showActions={false}
        >
          <Typography variant="body1">
            The file could not be stored at this time.
            <br />
            Please check network connectivity and file format are as expected.
          </Typography>
        </GenericModal>
      </Container>
    </Page>
  );
});

export default MediaView;
