import React, { useEffect, useState } from "react";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Card,
  Typography,
} from "@material-ui/core";
import { Storage } from "aws-amplify";
import { ExpandMore } from "@material-ui/icons";

import statesGeo from "./geoJSON";

const styles = {
  card: {
    padding: "8px",
    marginBottom: "12px",
  },
};

const stateColours = [
  "#2a9d8f",
  "#e9c46a",
  "#f4a261",
  "#e76f51",
  "#61a0af",
  "#96c9dc",
  "#f06c9b",
  "#f9b9b7",
  "#f5d491",
  "#bf4e30",
  "#c6ccb2",
  "#093824",
  "#e5eafa",
  "#78fecf",
  "#fff8f0",
  "#9e2b25",
  "#51355a",
  "#2a0c4e",
  "#f5f8de",
  "#3a3042",
  "#db9d47",
  "#ff784f",
  "#ffe19c",
  "#edffd9",
  "#264653",
];

const buildTip = ({ brands, distributors }) => {
  const brandSample =
    brands.length < 4
      ? brands
      : [...brands.slice(0, 3), `+ ${brands.length - 3} more`];
  return `
	<strong>Brands</strong> - ${brands.length}<br>
	${brandSample.map((b) => `<em>${b}</em><br>`).join("")}
	<strong>Distributors</strong> - ${distributors.length}<br>
	${distributors.map((b) => `<em>${b}</em><br>`).join("")}
 `;
};

const legendStyle = {
  background: `linear-gradient(90deg, hsl(30 50% 60%) 0%, hsl(115 50% 60%) 100%)`,
  display: "block",
  height: "20px",
  marginBottom: "12px",
};

function getColor(d, brands) {
  const h = (d / brands) * 85 + 30;
  const colour = `hsl(${h} 50% 60%)`;
  return colour;
}

const BrandMap = () => {
  const [center, setCenter] = useState([40.832, -100.62]);
  const [data, setData] = useState([]);
  const [map, bindMap] = useState(null);
  const [brands, setBrands] = useState([]);
  const [manuStates, setManuStates] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [statesToRender, setStatesToRender] = useState({ features: [] });
  const [brandStatesToRender, setBrandStatesToRender] = useState({
    features: [],
  });
  const [activeBrands, setActiveBrands] = useState([]);
  const [activeDistributors, setActiveDistributors] = useState([]);
  const [baseGeoLayer, setBaseGeoLayer] = useState(null);
  const [activeStatesForMap, setActiveStatesForMap] = useState(null);
  const [clickedState, setClickedState] = useState({});
  const [brandStates, setBrandStates] = useState([]);
  const [mapGradients, setMapGradients] = useState([]);
  const [zoom, setZoom] = useState(3);
  const [brandMax, setBrandMax] = useState(0);

  const loadS3 = async () => {
    const key = "SalesTier/12/39-brand-map.json";
    const identityId = "us-east-1:ea612a3e-e93d-496b-a11e-f390ca94ed89";
    const file = await Storage.get(key, {
      // level: private | protected | public, // defaults to `public`
      level: "protected",
      identityId, // id of another user, if `level: protected`
      download: true,
      // download?: boolean, // defaults to false
      // expires?: number, // validity of the URL, in seconds. defaults to 900 (15 minutes)
      // contentType?: string // set return content type, eg "text/html"
    });
    file.Body.text().then((string) => {
      // handle the String data return String
      const text = string;
      const s3Data = JSON.parse(text);
      try {
        setData(s3Data);
        console.log("set data from s3");
      } catch (e) {
        console.log("error getting data " + e);
      }
    });
  };

  const brandSelect = (brand) => {
    if (brand === null) {
      return setActiveBrands([]);
    }
    const matchInd = activeBrands.indexOf(brand);
    console.log({ matchInd, brand, activeBrands });
    if (matchInd > -1) {
      let brands = [...activeBrands];
      brands.splice(matchInd, 1);
      setActiveBrands(brands);
    } else {
      setActiveBrands([...activeBrands, brand]);
    }
    // setBrandStates(data.filter(d => d.product_brand === brand))
  };

  useEffect(() => {
    if (activeBrands.length > -1) {
      const brandStates = data
        .filter((d) => activeBrands.includes(d.product_brand))
        .map((b) => b.state);
      setBrandStatesToRender({
        type: "FeatureCollection",
        features: statesGeo.features
          .filter((s) => brandStates.includes(s.properties.name))
          .map((s) => {
            const matches = data.filter((d) => d.state === s.properties.name);
            return {
              ...s,
              properties: {
                ...s.properties,
                brands: [...new Set(matches.map((b) => b.product_brand))],
                // distributors: [
                //   ...new Set(matches.map((b) => b.dist_displayname)),
                // ],
              },
            };
          }),
      });
    }
  }, [activeBrands, mapGradients]);

  const storeMapGradient = (patternName, gradient) => {
    if (!mapGradients.find((p) => p.patternName === patternName)) {
      setMapGradients([
        ...mapGradients,
        {
          patternName,
          gradient,
        },
      ]);
    }
  };

  const stateSpecialColours = (brands) => {
    let gradientColors = brands
      .map((b) => {
        if (activeBrands.includes(b)) {
          return stateColours[activeBrands.indexOf(b)];
        }
        return null;
      })
      .filter((x) => x !== null);
    let patternName = gradientColors.sort().join("-").replace(/#/g, "");
    let gradientString = `<linearGradient id="col-${patternName}" x1="0%" y1="0%" x2="100%" y2="100%">
	${gradientColors
    .map((gc, ind, arr) => {
      return `<stop offset=${Math.round(
        (100 / arr.length) * ind
      )}% stop-color=${gc} />
		<stop offset=${Math.round(
      (100 / arr.length) * (ind + 1)
    )}% stop-color=${gc} />`;
    })
    .join("")}
</linearGradient>`;
    storeMapGradient("col-" + patternName, gradientString);
    let defaultColor = "#fff";
    brands.forEach((brand) => {
      // console.log(brands, activeBrands, stateColours)
      if (activeBrands.includes(brand)) {
        defaultColor = stateColours[activeBrands.indexOf(brand)];
      }
    });
    return `url(#col-${patternName})`;
  };

  useEffect(() => {
    mapGradients.forEach(({ patternName, gradient }) => {
      let mapSvg = document.querySelector("#gm-map svg");
      let inPlace = document.querySelector(`#gm-map svg #${patternName}`);
      if (!inPlace) {
        let svgDefs = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "defs"
        );
        svgDefs.insertAdjacentHTML("afterbegin", gradient);
        mapSvg.appendChild(svgDefs);
      }
    });
  }, [mapGradients]);

  useEffect(() => {
    function style(feature) {
      return {
        fillColor: stateSpecialColours(feature.properties.brands),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7,
        interactive: false,
      };
    }
    console.log({ activeStatesForMap });
    if (activeStatesForMap) {
      activeStatesForMap.clearLayers();
    }
    if (map && brandStatesToRender.features.length > -1) {
      setActiveStatesForMap(
        L.geoJSON(brandStatesToRender, { style })
          // .bindTooltip(
          //   function (layer) {
          //     return `Bradns: <ul>${layer.feature.properties.brands
          //       .map((r) => {
          //         return `<li>${r}</li>`;
          //       })
          //       .join("")}</ul>`; //merely sets the tooltip text
          //   },
          //   { opacity: 1 } //then add your options
          // )
          .on("click", regionClick)
      );
      // setBaseGeoLayer(base)
    }
  }, [brandStatesToRender]);

  useEffect(() => {
    if (map && activeStatesForMap) activeStatesForMap.addTo(map);
  }, [activeStatesForMap]);

  useEffect(() => {
    console.log({ brandStatesToRender });
  }, [brandStatesToRender]);

  const distributorSelect = (brand) => {
    setActiveDistributors(data.filter((d) => d.product_brand === brand));
  };

  useEffect(() => {
    console.log(brandStates);
  }, [brandStates]);

  useEffect(() => {
    if (!data.length) return;
    const brands = new Set();
    const states = new Set();
    const collectDistributors = new Set();
    const bs = {};
    data.forEach((row, ind) => {
      brands.add(row.product_brand);
      states.add(row.state);
      collectDistributors.add(row.dist_displayname);
      if (bs[row.state]) {
        if (bs[row.state][row.brand]) {
          bs[row.state][row.brand] += row.sold;
        } else {
          bs[row.state][row.brand] = row.sold;
        }
      } else {
        bs[row.state] = {
          [row.brand]: row.sold,
        };
      }
    });
    setBrands([...brands].sort());
    setManuStates([...states].sort());
    setDistributors([...collectDistributors].sort());
  }, [data]);

  useEffect(() => {
    setStatesToRender({
      ...statesGeo,
      features: statesGeo.features
        .filter((s) => manuStates.includes(s.properties.name))
        .map((s) => {
          const matches = data.filter((d) => d.state === s.properties.name);
          return {
            ...s,
            properties: {
              ...s.properties,
              brands: [...new Set(matches.map((b) => b.product_brand))],
              distributors: [
                ...new Set(matches.map((b) => b.dist_displayname)),
              ],
            },
          };
        }),
    });
  }, [manuStates]);

  const regionClick = (event) => {
    const { layer } = event;
    const { feature } = layer;
    setClickedState(feature);
    map.fitBounds(layer.getBounds());
  };

  const noState = () => {
    setClickedState({});
    map.fitBounds(baseGeoLayer.getBounds());
  };

  useEffect(() => {
    const mostBrands = statesToRender.features.reduce((acc, cur) => {
      if (cur.properties.brands.length > acc) {
        return cur.properties.brands.length;
      }
      return acc;
    }, 0);
    setBrandMax(mostBrands);
    function style(feature) {
      return {
        fillColor: getColor(feature.properties.brands.length, mostBrands),
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7,
      };
    }

    if (map && statesToRender.features.length > 0 && baseGeoLayer === null) {
      const base = L.geoJSON(statesToRender, { style })
        .bindTooltip(
          function (layer) {
            return buildTip(layer.feature.properties);
          },
          { opacity: 1 } //then add your options
        )
        .on("click", regionClick);
      setBaseGeoLayer(base);
    }
  }, [statesToRender]);

  useEffect(() => {
    if (!map) return;
    if (baseGeoLayer !== null && !map.hasLayer(baseGeoLayer)) {
      console.log({ baseGeoLayer });
      baseGeoLayer.addTo(map);
      map.fitBounds(baseGeoLayer.getBounds());
    } else if (baseGeoLayer && map.hasLayer(baseGeoLayer)) {
      map.fitBounds(baseGeoLayer.getBounds());
    }
  }, [baseGeoLayer]);

  useEffect(async () => {
    if (!data.length) {
      loadS3();
      // getLocalMapData()
      //   .then((results) => setData(results))
      //   .catch((error) => console.error(error));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2} style={{ display: "flex", flexDirection: "column" }}>
        <h4>Brands</h4>
        <Button onClick={() => brandSelect(null)}>All Brands</Button>
        {brands.map((st, ind) => (
          <Button
            key={ind}
            onClick={() => brandSelect(st)}
            variant="contained"
            color={activeBrands.includes(st) ? "secondary" : "default"}
            style={{
              backgroundColor:
                activeBrands.indexOf(st) > -1
                  ? stateColours[activeBrands.indexOf(st)]
                  : "transparent",
            }}
          >
            {st}
          </Button>
        ))}
      </Grid>
      <Grid item xs={8}>
        <Map
          id="gm-map"
          center={center}
          zoom={zoom}
          whenCreated={(mapInstance) => {
            bindMap(mapInstance);
          }}
          style={{ height: "500px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </Map>
        <Grid container style={{ marginTop: "6px" }}>
          <Grid item xs={6}>
            <strong>1 Brand per State</strong>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <strong>{brandMax}</strong>
          </Grid>
        </Grid>
        <div style={legendStyle}></div>
        {activeBrands.length > 0 &&
          activeBrands.map((ab) => (
            <Card style={styles.card}>
              <div>
                <Typography variant="h6">Brand: {ab}</Typography>
                <div style={{ fontSize: "14px" }}>
                  Distributed by:{" "}
                  {data
                    .filter((d) => d.product_brand === ab)
                    .map((d) => d.dist_displayname)
                    .join(", ")}
                </div>
              </div>
            </Card>
          ))}
        {/* { JSON.stringify(clickedState) }<br></br> */}
        {clickedState.properties && (
          <Card style={styles.card}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  State: {clickedState.properties.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{ fontSize: "14px" }}>
                  Brands:{" "}
                  {data
                    .filter((d) => d.state === clickedState.properties.name)
                    .map((d) => d.product_brand)
                    .join(", ")}
                </div>
                <div style={{ fontSize: "14px" }}>
                  Distributors:{" "}
                  {data
                    .filter((d) => d.state === clickedState.properties.name)
                    .map((d) => d.dist_displayname)
                    .join(", ")}
                </div>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
      <Grid item xs={2}>
        <h4>Distributors</h4>
        {distributors.map((st, ind) => (
          <Accordion key={ind}>
            <AccordionSummary
              style={{ fontSize: "14px", padding: "4px" }}
              expandIcon={<ExpandMore />}
            >
              {st}
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {[
                ...new Set(
                  data
                    .filter((d) => d.dist_displayname === st)
                    .map((b) => b.product_brand)
                ),
              ].map((b, i) => (
                <Button
                  key={i}
                  onClick={() => brandSelect(b)}
                  variant="contained"
                  color={activeBrands.includes(b) ? "secondary" : "default"}
                  style={{
                    backgroundColor:
                      activeBrands.indexOf(b) > -1
                        ? stateColours[activeBrands.indexOf(b)]
                        : "transparent",
                  }}
                >
                  {b}
                </Button>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default BrandMap;
