import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";

import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  statbox: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
  },
  fullHeightCard: {},
}));

const TeamProfileStat = ({ className, updatedAccounts, ...rest }) => {
  const { id } = useParams();
  const [accountTotal, setAccountTotal] = useState(0);
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    getAccountData();
  }, [updatedAccounts]);
  const classes = useStyles();
  async function getAccountData() {
    try {
      const data = await API.get("backendGateway", "/accounts/team/profile", {
        queryStringParameters: {
          mid: userInfo.mid,
          uid: id,
          limit: 1000,
        },
      });
      //console.log('data from Lambda REST API: ', data)

      data.results.length
        ? setAccountTotal(data.results[0].account_count)
        : setAccountTotal(0);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card key={accountTotal}>
          <CardContent className={[classes.fullHeightCard, "card-info-row"]}>
            <Typography align="center" color="textPrimary" variant="h6">
              Number of Accounts: {accountTotal}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

TeamProfileStat.propTypes = {
  className: PropTypes.string,
};

export default TeamProfileStat;
