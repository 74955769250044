import React, { useState, useEffect } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import accountsAPI from "../../api/accounts";

const SearchBar = observer(
  ({ placeholder, callback = {}, defaultAccountId = null }) => {
    const { allAccountsObjects } = useStore();
    const [inputValue, setInputValue] = useState("");
    const [selectedAccount, setSelectedAccount] = useState(false);
    const [loadingAccount, setLoadingAccount] = useState(false);
    const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
      if (newInputValue === "") {
        callback("");
      }
    };

    const handleOptionChange = (event, newValue) => {
      setSelectedAccount(newValue);
      if (newValue) {
        callback(newValue);
      }
    };

    const findDefaultAccount = async () => {
      await accountsAPI.getAccountById(defaultAccountId).then((accountData) => {
        setLoadingAccount(false);
        var tempAccount = accountData.results[0];
        console.log(tempAccount);
        setSelectedAccount(tempAccount);
        setInputValue(
          `${tempAccount.account_displayname} - ${tempAccount.account_address}`
        );
        callback({
          address: tempAccount.account_address,
          id: tempAccount.account_id,
          name: tempAccount.account_displayname,
        });
      });
    };

    useEffect(() => {
      if (defaultAccountId) {
        setLoadingAccount(true);
        console.log(defaultAccountId);
        findDefaultAccount();
      }
    }, []);

    const options = allAccountsObjects;

    return (
      <>
        {loadingAccount ? (
          <CircularProgress />
        ) : (
          <Autocomplete
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={options}
            getOptionLabel={(option) =>
              option.name ? `${option.name} - ${option.address}` : ""
            }
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={handleOptionChange}
            value={selectedAccount}
            renderInput={(params) => (
              <TextField
                {...params}
                label={placeholder}
                variant="outlined"
                fullWidth
              />
            )}
            renderOption={(option) => (
              <React.Fragment key={option.id}>
                {option.name} - {option.address}
              </React.Fragment>
            )}
            freeSolo
            style={{ width: "100%" }}
          />
        )}
      </>
    );
  }
);

export default SearchBar;
