export const fieldTypes = [
  {
    value: "text",
    label: "Text - short",
    element: "TextInput",
    defaultLabel: "Text - Long",
    prefix: "text_input_",
    variant: "text",
    variants: ["text", "email", "phone"],
  },
  {
    value: "textarea",
    label: "Text - long",
    element: "TextArea",
    defaultLabel: "Text - Long",
    prefix: "text_area_",
    variants: [],
  },
  {
    value: "number",
    label: "Number",
    element: "NumberInput",
    defaultLabel: "Number",
    prefix: "number_input_",
    variants: [],
  },
  {
    value: "date",
    label: "Date Picker",
    element: "DatePicker",
    defaultLabel: "Date",

    prefix: "date_picker_",
    variants: [],
  },
  {
    value: "dropdown",
    label: "Dropdown",
    element: "Dropdown",
    defaultLabel: "Dropdown",
    prefix: "dropdown_",
    variants: [],
  },
  {
    value: "radio",
    label: "Multiple Choice",
    element: "RadioButtons",
    defaultLabel: "Multiple Choice",
    prefix: "radiobuttons_",
    variants: [],
  },
  {
    value: "checkbox",
    label: "Checkboxes",
    element: "Checkboxes",
    defaultLabel: "Text Label",
    prefix: "checkboxes_",
    variants: [],
  },
  {
    value: "dynamicOptions",
    label: "Database Option",
    element: "DatabaseOption",
    defaultLabel: "Database Options",
    prefix: "dynamic_options_",
    variants: ["distributors", "products", "brands"],
    variant: "distributors",
  },
  {
    value: "contact",
    label: "Contact",
    element: "Contact",
    defaultLabel: "Contact",
    // TODO: fix this being undefined when joinging uip a file name
    prefix: "contact_",
    variants: ["distributor", "account", "team"],
    variant: "distributor",
  },
  {
    value: "account",
    label: "Account",
    element: "Account",
    defaultLabel: "Account",
    prefix: "account_",
    variants: [],
  },
  {
    value: "file",
    label: "File",
    element: "File",
    defaultLabel: "File",
    prefix: "file_",
    variants: ["image", "any"],
    variant: "image",
  },
];
