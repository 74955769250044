import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
const TopExternalBrandsList = ({ brands }) => {
  const columns = [
    {
      field: "brand",
      headerName: "Brand",
      width: 200,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
    },
    {
      field: "num_dist",
      headerName: "#",
    },
  ];
  const rowSelected = (ind) => {
    setSelectedBrand(brands[ind]);
  };
  const [selectedBrand, setSelectedBrand] = useState(null);
  return (
    <Grid container spacing={1} className="teb-list">
      <Grid item xs={6}>
        <DataGrid
          columns={columns}
          rows={brands}
          pagination
          autoHeight
          pageSize={20}
          onSelectionModelChange={rowSelected}
        />
      </Grid>
      <Grid item xs={6}>
        {selectedBrand && (
          <Card>
            <CardHeader title={selectedBrand.brand} />
            <CardContent>
              <Typography variant="h6">
                Supplier: {selectedBrand.supplier}
              </Typography>
              <Typography variant="subtitle1">Distributors</Typography>
              <ul style={{ paddingLeft: "1rem" }}>
                {selectedBrand.distributors.map((d) => (
                  <li>
                    <Link to={`/app/distributorsDetails/${d.distributor_id}`}>
                      {d.distributor_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default TopExternalBrandsList;
