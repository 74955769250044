import React, { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { Observer } from "mobx-react-lite";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import EditIcon from "@material-ui/icons/Edit";
import {
  Box,
  Card,
  Chip,
  Link,
  CardContent,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Button,
  CardHeader,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { toJS } from "mobx";
import { useStore } from "../../../stores/StoreContext";
import ContactListModal from "./ContactListModal";
import ContactModal from "./ContactModal";
import AccountDetailsModal from "./AccountDetailsModal";
import useApi from "../../../hooks/useApi";
import usersApi from "../../../api/users";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
  fullHeightCard: {
    height: "100%",
  },
}));

const Account = ({ className, setCheckin, ...rest }) => {
  const store = useStore();
  const { customers, schedules, userInfo } = store;
  const { id } = useParams();
  const { uid, mid, user_email, roles } = userInfo;
  const [checkingIn, setCheckingIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [openCreateContact, setCreateContact] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [purpose, setPurpose] = useState("");
  const [account, setAccount] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedVisitors, setSelectedVisitors] = useState([user_email]);
  const getUsersApi = useApi(usersApi.getUsers);
  const [processingSubmit, setProcessingSubmit] = useState(false);

  const isAdmin = userInfo.roles
    ? userInfo.roles.includes("ADMIN")
      ? true
      : false
    : false;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreateContact = () => {
    setCreateContact(true);
  };
  const handleCreateContactClose = () => {
    setCreateContact(false);
  };
  const handleClickContact = () => {
    setOpenContact(true);
  };
  const handleClickClose = () => {
    setOpenContact(false);
  };
  const handleEditOpen = () => {
    setOpenEditAccount(true);
  };
  const handleEditClose = () => {
    setOpenEditAccount(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function createJourney() {
    setProcessingSubmit(true);
    const appoint = {
      user_id: uid,
      type: 0,
      manufacturer_id: mid,
      account_id: account.account_id || null,
      appointment: selectedDate || null,
      is_active: 1,
      purpose: purpose,
      cw: 99,
    };

    console.log("Journey appointment payload:", appoint);

    try {
      const apiName = "backendGateway";
      const path = "/journeys";
      const myInit = {
        body: appoint,
        headers: {},
      };
      await API.post(apiName, path, myInit).then((response) =>
        console.log("Journey creation response:", response)
      );
    } catch (err) {
      console.log("error creating journey..", err);
    }
    createJourneyiCal();
    handleClose();
    setProcessingSubmit(false);
  }

  const handleDateChange = (luxonDate) => {
    const dateString = luxonDate;
    setSelectedDate(dateString);
  };
  async function createJourneyiCal() {
    const appoint = {
      user_emails: selectedVisitors,
      appointment_creator: user_email,
      account_name: account.account_displayname || null,
      account_location: `${account.account_address}` || null,
      appointment: selectedDate || null,
      purpose: purpose,
      account_id: account.account_id || null,
    };
    console.log("iCal appointment payload:", appoint);
    try {
      const apiName = "backendGateway";
      const path = "/invites/appointment";
      const myInit = {
        body: appoint,
        headers: {},
      };
      await API.post(apiName, path, myInit).then((response) =>
        console.log("iCal creation response:", response)
      );
    } catch (err) {
      console.log("error creating iCal invite..", err);
    }
  }

  const refreshContacts = useCallback(async () => {
    try {
      const data = await API.get("backendGateway", "/accounts/contact/list", {
        queryStringParameters: {
          aid: id,
          mid: mid,
        },
      });
      //console.log("data from Lambda REST API: ", data);
      setContactList(data.results);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }, [id, mid]);

  useEffect(() => {
    refreshContacts();
  }, [refreshContacts]);

  useEffect(() => {
    async function getAccountID(id) {
      try {
        const data = await API.get("backendGateway", "/accounts", {
          queryStringParameters: {
            account_id: id,
          },
        });
        setAccount(data.results[data.results.length - 1]);
      } catch (err) {
        console.log("error fetching account data..", err);
      }
    }
    getUsersApi.request({ mid: userInfo.mid, limit: 1000 });

    let itemAccount = toJS(
      customers.filter((item) => toJS(item).account_id === id)
    );
    let scheduleAccount = toJS(
      schedules.filter((item) => toJS(item).account_id === id)
    );
    if (itemAccount.length > 0) {
      setAccount(itemAccount);
    } else if (scheduleAccount.length > 0) {
      //incase it's scheduled multiple times
      let acc = scheduleAccount[0];
      setAccount(acc);
    } else {
      getAccountID(id);
    }
  }, [customers, id, schedules]);

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  async function addCheckIn() {
    setCheckingIn(true);
    try {
      const apiName = "backendGateway";
      const path = "/accounts/checkin";
      const myInit = {
        // OPTIONAL
        body: {
          user_id: uid,
          account_id: id,
          manufacturer_id: mid,
        },
        headers: {}, // OPTIONAL
      };
      await API.post(apiName, path, myInit).then((response) =>
        console.log("Check-in response:", response)
      );
      setCheckingIn(false);
      setCheckin("Today");
    } catch (err) {
      console.log("error creating check-in..", err);
      setCheckingIn(false);
    }
  }

  const classes = useStyles();
  return (
    <Observer>
      {() => (
        <Card className={classes.fullHeightCard}>
          <CardHeader
            action={
              <Box>
                <Button
                  onClick={() => {
                    handleClickOpen();
                  }}
                  className="button-two"
                  startIcon={<DateRangeIcon />}
                >
                  Plan Visit
                </Button>
                <Button
                  onClick={() => {
                    addCheckIn();
                  }}
                  className="button-two"
                  startIcon={<PersonPinCircleIcon />}
                  disabled={checkingIn}
                >
                  Checkin
                </Button>
                <Button
                  onClick={() => {
                    handleCreateContact();
                  }}
                  className="button-two"
                  startIcon={<PermContactCalendarIcon />}
                  disabled={checkingIn}
                >
                  Add Contact
                </Button>
                {roles.includes("ADMIN") && (
                  <Button
                    onClick={() => {
                      handleEditOpen();
                    }}
                    className="button-two"
                    startIcon={<EditIcon />}
                    disabled={checkingIn}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            }
          ></CardHeader>
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              key={account.distributor_account_id}
            >
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                {account.account_displayname || ""}
              </Typography>
              <Typography color="textSecondary" variant="body1" align="center">
                {`${[
                  account.account_address,
                  account.account_address_2,
                  account.account_city,
                  account.account_region,
                ]
                  .filter((element) => element)
                  .join(", ")}`}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {`Zip code: ${account.account_regioncode || "N/A"}`}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {`Phone: ${account.account_phone || "N/A"}`}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                <Link href={account.account_website} target="_blank">
                  {truncateString(account.account_website || "", 30)}
                </Link>
              </Typography>
              <Chip
                color="primary"
                label={account.premise_type || "UNKNOWN"}
                variant="outlined"
                style={{ margin: "15px" }}
              />
              <AvatarGroup max={8} onClick={() => handleClickContact()}>
                {contactList.map((contact) => (
                  <Avatar
                    key={contact.contact_id}
                    alt={contact.name}
                    src="/static/images/avatar/1.jpg"
                  />
                ))}
              </AvatarGroup>
            </Box>
            <Dialog
              onClose={handleClickClose}
              aria-labelledby="product-dialog"
              open={openContact}
            >
              <ContactListModal
                onClose={handleClickClose}
                open={openContact}
                setContactList={setContactList}
                contactList={contactList}
              />
            </Dialog>
            <Dialog
              onClose={handleEditClose}
              aria-labelledby="account-dialog"
              open={openEditAccount}
            >
              <AccountDetailsModal
                onClose={handleEditClose}
                open={openEditAccount}
                accountDetails={account}
                saveValues={setAccount}
              />
            </Dialog>
            <ContactModal
              open={openCreateContact}
              contact={null}
              setContactList={setContactList}
              contactList={contactList}
              onClose={handleCreateContactClose}
              refreshContacts={refreshContacts}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              BackdropProps={{
                style: { backgroundColor: "black", opacity: 0.1 },
              }}
            >
              <DialogTitle id="form-dialog-title">Schedule Visit</DialogTitle>
              {getUsersApi?.data?.length > 0 && (
                <DialogContent>
                  <div style={{ flexDirection: "column" }}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl required fullWidth>
                        <Autocomplete
                          onChange={(_, newVal) => {
                            setSelectedVisitors(newVal);
                          }}
                          disableCloseOnSelect
                          multiple
                          options={[
                            {
                              user_id: userInfo.user_id,
                              user_displayname: userInfo.user_displayname,
                              user_email: user_email,
                            },
                            ...getUsersApi?.data,
                          ].map((option) => option.user_email)}
                          value={selectedVisitors}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Visitors" />
                          )}
                        />
                      </FormControl>
                    </Box>

                    <FormControl required fullWidth>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                          label="Date + Time"
                          inputVariant="outlined"
                          disablePast={true}
                          value={selectedDate}
                          closeOnSelect={false}
                          onChange={handleDateChange}
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl required fullWidth>
                      <TextField
                        id="outlined-basic"
                        label="Purpose Of Visit"
                        variant="outlined"
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                        style={{ marginTop: "20px", width: "100%" }}
                      ></TextField>
                    </FormControl>
                  </div>
                </DialogContent>
              )}
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  disabled={
                    processingSubmit ||
                    selectedVisitors.length < 1 ||
                    selectedDate === null ||
                    purpose.trim() === ""
                  }
                  onClick={() => createJourney()}
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
          <div id="m"></div>
        </Card>
      )}
    </Observer>
  );
};

Account.propTypes = {
  className: PropTypes.string,
};

export default Account;
