import React from "react";
import { API } from "aws-amplify";
import PivotTablePlain from "react-pivottable/PivotTable";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { withStyles } from "@material-ui/core/styles";
import TableRenderers from "react-pivottable/TableRenderers";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import GeneratedDropDowns from "./GeneratedDropdowns";
import { dataFilter } from "./PresetOptions";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Parser } from "json2csv";
import { Box, Typography } from "@material-ui/core";

const useStyles = {
  root: {
    overflow: "scroll",
    width: "100%",
  },
  datagrid: {
    backgroundColor: "#ffffff",
  },
  button: {
    margin: "1px",
  },
};

class PivotTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      filtered: [],
      dropDowns: [],
      data: [],
      dataToDownload: "",
      quantity: "AVAILABLESTOCK",
    };
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(oldProps) {
    const { filterCol } = this.props;
    if (
      // TODO: replace with function that returns boolean
      oldProps.dateRange.length !== this.props.dateRange.length ||
      oldProps.dateRange[0] !== this.props.dateRange[0] ||
      oldProps.dateRange[1] !== this.props.dateRange[1] ||
      (oldProps.filterCol.length &&
        (!filterCol.length ||
          filterCol[0].value !== oldProps.filterCol[0].value)) ||
      (!oldProps.filterCol.length && filterCol.length)
    ) {
      if (this.state.data) {
        // console.log('Should set state of filtyered now')
        this.setState({ filtered: dataFilter(this.state.data, [], filterCol) });
      } else {
        // console.log(oldProps.dateRange, this.props.dateRange)
      }
    }
    if (oldProps.usstate !== this.props.usstate) {
      this.getData();
    }
  }
  getData = async () => {
    const { dateRange, filterCol } = this.props;
    try {
      const data = await API.get("backendGateway", "/stats/daily", {
        queryStringParameters: {
          mid: this.props.userInfo?.mid,
          usstate: this.props.usstate,
          offset: 0,
          limit: 5000,
        },
      });
      this.setState({ loader: false });
      this.setState({ data: data.results });
      let filt = dataFilter(data.results, dateRange, filterCol);
      this.setState({ filtered: filt });
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  exportData = async (data) => {
    const parser = new Parser();
    const csv = parser.parse(data);
    //console.log(csv);
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  arrayMinMax = (arr) =>
    arr.reduce(
      ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
      [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
    );
  render() {
    const {
      classes,
      rows,
      cols,
      rendererName,
      aggregatorName,
      vals,
      customise,
    } = this.props;
    return (
      <div className={classes.root} id="salestier-pivot">
        {this.state.loader && (
          <div className={classes.loading}>
            {" "}
            <CircularProgress />{" "}
          </div>
        )}
        <Box display="flex" justifyContent="space-between">
          <GeneratedDropDowns
            dropDowns={this.props.dropDowns}
            data={this.state.data}
            handleFilter={this.props.setFilterCol}
          />
          <Box display="flex" alignItems="center">
            <Typography>Least</Typography>
            <Box
              ml={3}
              mr={3}
              style={{
                height: "40px",
                width: "200px",
                background:
                  "linear-gradient(to right, rgba(42, 222, 152,0.1), rgba(42, 222, 152,1))",
              }}
            ></Box>
            <Typography>Most</Typography>
          </Box>
        </Box>

        <div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<CloudDownloadIcon />}
            onClick={() => this.exportData(this.state.filtered)}
          >
            Export
          </Button>
        </div>
        {customise ? (
          <PivotTableUI
            data={this.state.filtered}
            onChange={(s) => this.setState(s)}
            renderers={Object.assign({}, TableRenderers)}
            tableColorScaleGenerator={(values) => {
              const min = this.arrayMinMax(values)[0];
              const max = this.arrayMinMax(values)[1];
              const range = max - min;
              return (x) => {
                const progress = (x / range) * 100;
                const backgroundColor = `rgba(42, 222, 152, ${progress}%)`;
                return { backgroundColor };
              };
            }}
            {...this.state}
          />
        ) : (
          <PivotTablePlain
            data={this.state.filtered}
            rows={rows}
            cols={cols}
            rendererName={rendererName}
            aggregatorName={aggregatorName}
            vals={vals}
            onChange={(s) => this.setState(s)}
            renderers={Object.assign({}, TableRenderers)}
            tableColorScaleGenerator={(values) => {
              const min = this.arrayMinMax(values)[0];
              const max = this.arrayMinMax(values)[1];
              const range = max - min;
              return (x) => {
                const progress = (x / range) * 100;
                const backgroundColor = `rgba(42, 222, 152, ${progress}%)`;
                return { backgroundColor };
              };
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(useStyles)(PivotTable);
