import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import {
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { ChevronRight } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import Page from "../../../components/Page";
import { Link } from "react-router-dom";
import productsApi from "../../../api/products";
import useApi from "../../../hooks/useApi";
import { useEffect } from "react";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { useForm, Controller } from "react-hook-form";
import { Storage, API } from "aws-amplify";
import Resizer from "react-image-file-resizer";
import Alert from "@material-ui/lab/Alert";
import { useStore } from "../../../stores/StoreContext";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    // padding: 20,
  },
  updateSection: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 50,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "500",
  },
  profileImg: {
    height: 150,
    width: 150,
    borderRadius: "50%",
    border: "2px solid #00000078",
  },
  editIcon: {
    height: 30,
    width: 30,
    border: "2px solid #00000078",
    borderRadius: "50%",
    backgroundColor: "#fffffff0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headingStyle: {
    paddingTop: 10,
    width: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  categoryStyle: { margin: 10, width: "84%" },
  descriptionStyle: {
    fontSize: 14,
    padding: "10px 0px 10px 10px",
    width: "85%",
  },
  tableSection: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
  },
}));

const distributorsDetails = [
  { id: 1, name: "Pafic Edge", state: "CA ZN PAK IND" },
  { id: 1, name: "BC MATH", state: "CA ZN" },
  { id: 1, name: "Rounded Edge", state: "CA ZN PAK" },
  { id: 1, name: "South Edge", state: "CA ZN PAK IND-EN" },
];
const drinkCategories = [
  "AMERICAN WHISKEY",
  "ABSINTHE",
  "AMARO",
  "APERITIF",
  "AQUAVIT",
  "ARMAGNAC",
  "BACANORA",
  "BOURBON",
  "BRANDY",
  "CACHACA",
  "GIN",
  "IRISH WHISKEY",
  "LIQUER",
  "LIQUEUR",
  "MEZCAL",
  "OTHER",
  "RTD",
  "RUM",
  "SOTOL",
  "SPIRIT",
  "TEQUILA",
  "VERMOUTH",
  "VODKA",
  "WHISKEY",
  "WINE",
];

const AddEditView = ({ openEdit, setOpenEdit }) => {
  const classes = useStyles();
  const imageRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const store = useStore();
  const { userInfo } = store;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const getProduct = useApi(productsApi.getProductsPID);
  const [previewUrl, setPreviewUrl] = useState("");
  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "success",
    message: "Successfully Update",
  });
  const [fileName, setFileName] = useState("");

  const [productValues, setProductValues] = useState({
    image_scr: "",
    url: "",
    product_displayname: "",
    product_size: "",
    product_image: "",
    product_imagekey: userInfo.user_identitykey,
    product_id: params.id,
    product_description: "",
    tenant_id: "",
    state_skus: [],
    product_category: "",
    manufacturer_id: userInfo.mid,
    product_proof: "",
    product_retail_unit: "",
    product_physical_case: "",
    product_unit_size: "",
  });

  const crumbs = [
    {
      text: "Products",
      link: "/app/products",
    },
    {
      text: "edit",
      link: `/app/products/edit/${params.id}`,
    },
  ];

  async function getData(id, isEdit) {
    if (!getProduct.currentItems?.length || isEdit) {
      await getProduct.request({
        pid: id,
        mid: userInfo.mid,
        limit: 1000,
      });
    }
    if (
      getProduct?.currentItems &&
      getProduct?.currentItems[0]?.product_id.toString() === id
    ) {
      setProductValues(getProduct?.currentItems[0]);
      reset(getProduct?.currentItems[0]);
    }
  }

  useEffect(() => {
    getData(params.id, false);
  }, [getProduct.currentItems]);

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const changeHandler = (event) => {
    if (event.target.files.length) {
      const filename = uuid();
      setProductValues({
        ...productValues,
        product_image: filename,
        product_imagekey: userInfo.user_identitykey,
        image_scr: awsS3Image(filename, userInfo.user_identitykey, "0x110"),
      });
      setFile(event.target.files[0]);
      setFileName(filename);
      setPreview(true);
      setPreviewUrl(URL.createObjectURL(event.target.files[0]));
    }
  };
  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  async function handleSubmission(file) {
    if (!file) {
      return console.log("No file to handle", { file });
    }
    const image = await resizeFile(file);
    const resizedFile = dataURIToBlob(image);
    try {
      await Storage.put(fileName, resizedFile, {
        contentType: "image/*", // contentType is optional
        level: "protected",
      });
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
  }
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "PNG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const handleupdate = async (payload) => {
    if (preview && file) {
      handleSubmission(file);
      const tempUrl = URL.createObjectURL(file);
    } else if (preview && !file) {
      console.error("Bad file storage attempt ", { file });
    }
    let mergedPayload = {
      ...productValues,
      ...payload,
    };
    // this line allows us to update a product image otherwise it wil use the old uuid and doesn't change the image
    mergedPayload.product_image = productValues.product_image;
    mergedPayload.product_imagekey = productValues.product_imagekey;

    try {
      const apiName = "backendGateway";
      const path = "/products/sku";
      const myInit = {
        body: mergedPayload,
        headers: {},
      };
      if (productValues.product_id === "") {
        await API.post(apiName, path, myInit);
      } else {
        await API.put(apiName, path, myInit);
        setNotification({ type: "success", message: "Successfully Update" });
        setOpenNotification(true);
      }
    } catch (err) {
      console.log("error fetching data..", err);
      setNotification({ type: "error", message: "Something went wrong" });
      setOpenNotification(true);
    }
  };

  return (
    <Page className={classes.root} title="AddEditView">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotification}
        autoHideDuration={5000}
        onClose={() => setOpenNotification(false)}
      >
        <Alert
          onClose={() => setOpenNotification(false)}
          severity={notification.type}
        >
          <Typography component="h2" align="center">
            {notification.message}
          </Typography>
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <Box className={classes.container}>
          <Breadcrumbs separator={<ChevronRight />}>
            {crumbs.map((c, index) => {
              return (
                <Link to={c.link} key={index} className="crumb-link">
                  {c.text}
                </Link>
              );
            })}
          </Breadcrumbs>
          {getProduct.currentItems?.length ? (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit(handleupdate)}
            >
              <Box className={classes.updateSection}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Card className={classes.leftSection}>
                      <CardContent>
                        <Box>
                          <Typography className={classes.heading}>
                            {productValues.product_displayname}
                          </Typography>
                          <Box
                            style={{
                              height: 195,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <Typography
                                  className={classes.editIcon}
                                  onClick={showOpenFileDialog}
                                >
                                  <EditIcon
                                    style={{
                                      fontSize: 20,
                                      color: "#00000078",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Typography>
                              }
                            >
                              {preview ? (
                                <img
                                  src={previewUrl}
                                  className={classes.profileImg}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  alt={"avatar"}
                                  src={awsS3Image(
                                    productValues?.product_image,
                                    productValues?.product_imagekey,
                                    "200x200"
                                  )}
                                  className={classes.profileImg}
                                />
                              )}
                            </Badge>
                            <input
                              ref={imageRef}
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={changeHandler}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Card className={classes.rightSection}>
                      <CardContent>
                        <Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: 50,
                            }}
                          >
                            <Typography
                              className={[
                                classes.heading,
                                classes.headingStyle,
                              ].join(" ")}
                            >
                              Category
                            </Typography>
                            <Box className={classes.categoryStyle}>
                              <Controller
                                control={control}
                                name="product_category"
                                defaultValue={productValues?.product_category}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    fullWidth
                                    labelId="Category"
                                    id="category"
                                    name="product_category"
                                  >
                                    {drinkCategories.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                              />
                            </Box>
                          </Box>
                          <Box style={{ display: "flex", alignItems: "start" }}>
                            <Box>
                              <Typography
                                className={[
                                  classes.heading,
                                  classes.headingStyle,
                                ].join(" ")}
                              >
                                Description
                              </Typography>
                            </Box>
                            <FormControl
                              error={errors.product_description ? true : false}
                              className={classes.descriptionStyle}
                            >
                              <Controller
                                control={control}
                                name="product_description"
                                defaultValue={
                                  productValues?.product_description
                                }
                                rules={{
                                  required: false,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    multiline
                                    minRows={4}
                                  />
                                )}
                              />
                              <FormHelperText></FormHelperText>
                            </FormControl>
                            <Typography
                              className={[
                                classes.heading,
                                classes.headingStyle,
                              ].join(" ")}
                            >
                              Size
                            </Typography>
                            <FormControl
                              error={errors.product_unit_size ? true : false}
                              className={classes.descriptionStyle}
                            >
                              <Controller
                                control={control}
                                name="product_unit_size"
                                defaultValue={productValues?.product_unit_size}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    required
                                    variant="outlined"
                                    multiline
                                    minRows={2}
                                  />
                                )}
                              />
                            </FormControl>
                          </Box>

                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: 50,
                            }}
                          >
                            <Typography
                              className={[
                                classes.heading,
                                classes.headingStyle,
                              ].join(" ")}
                            >
                              Product Internal ID
                            </Typography>
                            <Box className={classes.categoryStyle}>
                              <FormControl
                                error={
                                  errors.product_manufacturer_internal
                                    ? true
                                    : false
                                }
                              >
                                <Controller
                                  control={control}
                                  name="product_manufacturer_internal"
                                  defaultValue={
                                    productValues?.product_manufacturer_internal
                                  }
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      variant="outlined"
                                      minRows={2}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Box>
                          </Box>
                          <Button
                            style={{
                              float: "right",
                              marginRight: 10,
                              marginBottom: 20,
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Update
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </form>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default AddEditView;
