import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ContactModal from "./ContactModal";

import Avatar from "@material-ui/core/Avatar";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    overflow: "auto",
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

const ContactList = ({
  className,
  onClose,
  contactList,
  setContactList,
  ...rest
}) => {
  const classes = useStyles();
  const [contact, setContact] = useState(null);
  const [openContact, setOpenContact] = useState(false);
  const handleChange = (contact) => {
    setOpenContact(true);
    setContact(contact);
  };

  return (
    <>
      <ContactModal
        open={openContact}
        contact={contact}
        contactList={contactList}
        setContactList={setContactList}
        onClose={onClose}
      />
      <List dense className={classes.root}>
        {contactList.map((contact) => {
          return (
            <ListItem
              key={contact.contact_id}
              button
              onClick={() => handleChange(contact)}
            >
              <ListItemAvatar>
                <Avatar
                  alt={contact.name}
                  src={`/static/images/avatar/1.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={contact.name}
                secondary={
                  <div>
                    <div>{"Phone: " + contact.phone}</div>
                    <div>{"Title: " + contact.title}</div>
                    <div>{"Email: " + contact.email}</div>
                  </div>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

ContactList.propTypes = {
  className: PropTypes.string,
};

export default ContactList;
