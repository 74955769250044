import client from "./client";

const getAccounts = ({ aid, limit }) => {
  const endpoint = "/accounts";
  return client.get(endpoint, { account_id: aid, limit: limit });
};
const postAccounts = (account) => {
  const endpoint = "/accounts";
  return client.post(endpoint, account);
};
const putAccounts = (account) => {
  const endpoint = `/accounts/${account.account_id}`;
  return client.put(endpoint, account);
};
const removeAccountsFromList = ({ account_ids, list_id }) => {
  const endpoint = `/accounts/list/${list_id}/items`;
  return client.post(endpoint, { account_ids });
};
// TODO: clean up API
const postAccountsNew = (account) => {
  const endpoint = "/accounts/new";
  return client.post(endpoint, account);
};

const getContacts = (contact) => {
  const endpoint = "/accounts/contact/list";
  return client.get(endpoint, contact);
};

const postContact = (contact) => {
  const endpoint = "/accounts/contact/list";
  return client.post(endpoint, contact);
};

const putContact = (contact) => {
  const endpoint = "/accounts/contact/list";
  return client.put(endpoint, contact);
};
const deleteContact = (contactId) => {
  const endpoint = "/accounts/contact/list";
  return client.post(endpoint, { contact_id: contactId });
};
const getAccountsAID = ({ aid, limit }) => {
  const endpoint = "/accounts/";
  return client.get(endpoint, { account_id: aid, limit: limit });
};

const getAccountsImages = ({ aid, mid, limit }) => {
  const endpoint = "/accounts/images/" + aid;
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getAccountsMap = ({ uid, mid, limit }) => {
  const endpoint = "/accounts/map";
  return client.get(endpoint, { uid: uid, mid: mid, limit: limit });
};

const getAccountsSearch = ({ mid, limit }) => {
  const endpoint = "/accounts/search";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getAccountsStats = ({ mid, aid, limit }) => {
  const endpoint = "/accounts/stats";
  return client.get(endpoint, { mid: mid, id: aid, limit: limit });
};

const saveAccountWithAWS = ({ text, lat, lng, account_id }) => {
  const endpoint = `/locations/${account_id}/place`;
  return client.put(endpoint, { lat, lng, text });
};

const getAccountById = (id) => {
  const endpoint = `/accounts`;
  return client.get(endpoint, { account_id: id });
};
export default {
  getAccounts,
  postAccounts,
  putAccounts,
  removeAccountsFromList,
  postAccountsNew,
  getAccountsAID,
  getAccountsMap,
  getAccountsImages,
  getAccountsSearch,
  getAccountsStats,
  saveAccountWithAWS,
  getContacts,
  postContact,
  putContact,
  deleteContact,
  getAccountById,
};
