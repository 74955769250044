import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  FormControl,
  FormHelperText,
  Box,
  Snackbar,
  Typography,
  Button,
} from "@material-ui/core";
import { Alert } from "@mui/material";
import StorageClient from "../../../../../storage/client";
import useStorage from "../../../../../hooks/useStorage";
import storageAPI from "../../../../../api/storage";
import useApi from "../../../../../hooks/useApi";
import { useStore } from "../../../../../stores/StoreContext";
import AnswerFileDisplay from "../../answers/AnswerFileDisplay";
import PublishIcon from "@material-ui/icons/Publish";
import StLabel from "./StLabel";

const allFiles = [
  // any video
  "video/*",
  // any image
  "image/*",
  // pdf
  "application/pdf",
  "application/vnd.ms-excel",
  // word and text files
  ".doc",
  ".docx",
  ".rtf",
  ".txt",
  // Excel, CSV, Numbers
  ".xlsx",
  ".xls",
  ".csv",
  ".numbers",
  // Powerpoint and Keynote
  ".pptx",
  ".ppt",
  ".key",
  ".xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
].join(",");

const imageFiles = "image/*";

const StFile = ({
  label,
  required,
  error,
  helperText,
  answer,
  variant,
  id,
  changeAnswer = () => {},
}) => {
  const { userInfo } = useStore();
  const [storedFiles, setStoredFiles] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadCount, setUploadCount] = useState(0);

  const changeHandler = async (event) => {
    const files = [...event.target.files];
    Promise.all(files.map((file) => uploadFiles(file)))
      .then((values) => {
        setStoredFiles((prevFiles) => [...prevFiles, ...values]);
        setUploadCount((prevCount) => prevCount + values.length);
        setSuccessMessage(
          `File uploaded successfully! Total uploads: ${
            uploadCount + values.length
          }`
        );
      })
      .finally(() => setProcessing(false));
  };

  const appendNewFile = (newFile) => {
    setStoredFiles([...storedFiles, newFile]);
  };

  const setS3Items = useStorage(StorageClient.upload);
  const postStorageApi = useApi(storageAPI.postStorage);
  const deleteFileApi = useApi(storageAPI.deleteFile);
  const fileRoot = "SalesTier/" + userInfo.mid + "/";

  const uploadFiles = async (file) => {
    const { name, type } = file;
    return new Promise((resolve, reject) => {
      setProcessing(true);
      const rootKey = fileRoot + uuid() + "_" + name;
      const newFile = {
        identity_id: userInfo.user_identitykey,
        file_key: rootKey,
        file_name: name,
        manufacturer_id: userInfo.mid,
        user_id: userInfo.uid,
        is_active: true,
        for_marketing: 0,
        created: new Date().toISOString(),
      };
      setS3Items
        .request(rootKey, file, type)
        .then((foo) => {
          postStorageApi
            .request(newFile)
            .then(({ results }) => {
              if (results && results.insertId) {
                resolve({
                  ...newFile,
                  storage_id: results.insertId,
                });
              } else {
                reject(new Error("Failed to get insertId from results"));
              }
            })
            .catch((error) => {
              console.error("Error in postStorageApi request:", error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error("Error in setS3Items request:", error);
          reject(error);
        });
    });
  };

  const removeFile = (file) => {
    deleteFileApi.request(file.storage_id).then(() => {
      setStoredFiles((prevFiles) =>
        prevFiles.filter((sf) => sf.file_key !== file.file_key)
      );
    });
  };

  useEffect(() => {
    if (
      JSON.stringify(answer.value) !== JSON.stringify(storedFiles) &&
      storedFiles.length
    ) {
      changeAnswer(storedFiles);
    } else if (storedFiles.length === 0 && answer.value.length !== 0) {
      changeAnswer([]);
    }
  }, [storedFiles, answer.value, changeAnswer]);

  useEffect(() => {
    if (answer.value.length && !storedFiles.length) {
      setStoredFiles(answer.value);
    }
  }, [answer.value]);

  const fileAcceptString = variant === "image" ? imageFiles : allFiles;

  return (
    <FormControl fullWidth error={error}>
      <StLabel required={required} text={label} error={error} />
      {!processing && (
        <div style={{ width: "100%" }}>
          <label htmlFor={id} style={{ width: "100%" }}>
            <div
              component="span"
              style={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                flexDirection: "row",
                display: "flex",
                border: "1px solid lightGray",
                padding: 5,
                borderRadius: 4,
                justifyContent: "space-between",
              }}
            >
              <PublishIcon />
              <div>Add File</div>
            </div>
          </label>
          <input
            style={{
              display: "none",
            }}
            accept={fileAcceptString}
            id={id}
            name={id}
            multiple
            type="file"
            onChange={changeHandler}
          />
        </div>
      )}
      {processing && <span>Processing...</span>}
      <Box display="flex" flexWrap={"no-wrap"} width={"100%"}>
        <Box flex="3" flexDirection={"row"} display={"flex"}>
          {storedFiles.map((storedFile, i) => (
            <Box key={i}>
              <AnswerFileDisplay
                file={storedFile}
                editable={true}
                remove={removeFile}
              />
            </Box>
          ))}
        </Box>
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </FormControl>
  );
};

export default StFile;
