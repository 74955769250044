import { DateTime, Interval } from "luxon";

const diffCalc = ({ current, previous }) => {
  const hasCurrent = current !== 0;
  if (previous === 0) {
    return {
      intDiff: 0,
      percentDiff: 0,
      hasPrevious: false,
      hasCurrent,
    };
  }
  const percentDiff = Math.round(((current - previous) / previous) * 100);
  const intDiff = Math.round(current - previous);
  return {
    percentDiff,
    intDiff,
    hasPrevious: true,
    hasCurrent,
  };
};

const makeWindowStrings = (previousRange, currentRange) => {
  const current = currentRange
    .map((time) => [time.monthShort, time.day, time.year].join(" "))
    .join(" - ");
  const previous = previousRange
    .map((time) => [time.monthShort, time.day, time.year].join(" "))
    .join(" - ");
  return {
    current,
    previous,
  };
};

export const generateSummaryTotals = (
  data,
  selectedQuantity,
  filterArgs,
  periods
) => {
  const quantity = selectedQuantity.toUpperCase();
  const filteredData = data.filter((sale) =>
    filterArgs.premiseTypes.includes(sale.PREMISETYPE)
  );
  const reduced = filteredData.reduce((acc, cur, ind) => {
    const saleDate = DateTime.fromISO(cur.DATE);
    for (let p of periods) {
      if (!acc[p.name]) {
        acc[p.name] = {
          previous: 0,
          current: 0,
          ...p,
        };
      }
      if (Interval.fromDateTimes(...p.currentRange).contains(saleDate)) {
        acc[p.name].current += cur[quantity];
      }
      if (Interval.fromDateTimes(...p.previousRange).contains(saleDate)) {
        acc[p.name].previous += cur[quantity];
      }
    }
    return acc;
  }, {});
  const arrayWithDiffs = Object.values(reduced).map((v) => ({
    ...v,
    ...diffCalc({
      previous: v.previous,
      current: v.current,
    }),
    windowStrings: makeWindowStrings(v.previousRange, v.currentRange),
  }));
  return arrayWithDiffs;
};
