import React, { useCallback, useState } from "react";
import { Box, Button, Typography, Modal, Paper } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

function DragAndDropUpload({ onUpload }) {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files;
      if (files.length) {
        onUpload(files);
      }
    },
    [onUpload]
  );

  const handleChange = useCallback(
    (event) => {
      const files = event.target.files;
      if (files.length) {
        onUpload(files);
      }
    },
    [onUpload]
  );

  return (
    <Paper
      elevation={3}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: dragOver ? "2px solid #000" : "2px dashed #aaa",
        backgroundColor: dragOver ? "#e0e0e0" : "#fafafa",
      }}
    >
      <input
        accept="image/*,video/*,application/pdf"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Upload from files
        </Button>
      </label>
      <Typography>or drag and drop files here</Typography>
    </Paper>
  );
}
export default DragAndDropUpload;
