import { useMemo, useState, createContext, useContext } from "react";
import formAPI from "../../../api/forms";
import Builder from "../../../components/forms/v3/builder/Builder";
import { v4 as uuid } from "uuid";

/**
 *
 * @typedef {Object} FormPayload
 * @property {string} form_uuid
 * @property {string} form_name
 * @property {Object} form_body
 * @property {boolean} on_account
 * @property {boolean} on_premise
 * @property {boolean} publish
 * @property {userInfo} Object
 *
 */

const NewForm = () => {
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [form, setForm] = useState({
    form_id: null,
    form_uuid: null,
    form_name: "New Form",
    is_open: false,
    fields: [],
    on_account: false,
    on_premise: false,
    send_emails: "",
  });

  const savedDraft = "Form saved in drafts";
  const savedLive = "Form saved and live";

  /**
   *
   * @param {FormPayload} param0
   */
  const handleFormDetail = ({
    form_uuid,
    form_name,
    form_body,
    on_account,
    on_premise,
    send_emails,
    userInfo,
    is_public,
    is_open,
  }) => {
    let _form = {
      form_uuid,
      form_name,
      form_body,
      on_account,
      on_premise,
      send_emails,
      is_public,
      is_open,
      user_id: userInfo.uid,
      manufacturer_id: userInfo.mid,
    };
    console.log(_form);
    setSaving(true);
    // form will be new
    if (!form.form_id) {
      formAPI
        .addForm(_form)
        .then(({ results }) => {
          setForm({
            ...results,
            fields: JSON.parse(results.form_body),
          });
          setSuccessMessage(
            `${
              _form.is_open ? savedLive : savedDraft
            } at ${new Date().toLocaleTimeString()}`
          );
        })
        .finally(() => setSaving(false));
    }
    // update form
    if (form.form_id) {
      formAPI
        .editForm(_form, form.form_id)
        .then(({ results }) => {
          setForm({
            ...results,
            fields: JSON.parse(results.form_body),
          });
          setSuccessMessage(
            `${
              _form.is_open ? savedLive : savedDraft
            } at ${new Date().toLocaleTimeString()}`
          );
        })
        .finally(() => setSaving(false));
    }
  };

  return (
    <div>
      <Builder
        create={true}
        save={handleFormDetail}
        saving={saving}
        form={form}
        setForm={setForm}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </div>
  );
};

export default NewForm;
