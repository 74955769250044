const forms = {
  formList: [],
  formResponses: [],
  myFormResponses: [],
  setFormsList(formList) {
    this.formList = formList;
  },
  setFormResponses(formResponses) {
    this.formResponses = formResponses;
  },
  setMyFormResponses(myResponses) {
    this.myFormResponses = myResponses;
  },
};

export default forms;
