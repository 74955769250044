import { useEffect, useState, useMemo } from "react";
import { object, string, number, date, InferType } from "yup";
import { useParams } from "react-router";
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { useStore } from "../../../stores/StoreContext";
import formsAPI from "../../../api/forms";
import Page from "../../../components/Page";
import Respond from "../../../components/forms/v3/form/Respond";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const FormRespond = () => {
  const [form, setForm] = useState(null);
  const [fields, setFields] = useState([]);
  const [answers, setAnswers] = useState([]);
  const { uuid, response_id } = useParams();
  const classes = useStyles();
  const getForm = (uuid) => {
    formsAPI.getForm(uuid).then(({ results }) => {
      setFields(JSON.parse(results.form_body));
      setForm(results);
    });
  };

  const loadResponse = ({ form_id, response_id }) => {
    formsAPI
      .getFormResponse({
        form_id,
        response_id,
      })
      .then(({ results }) => {
        const rAnswers = JSON.parse(results.response_body);
        if (rAnswers.values.length > 0) {
          setAnswers(rAnswers.values);
        }
      });
  };

  useEffect(() => {
    if (uuid) {
      getForm(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid && response_id) {
      loadResponse({ form_id: uuid, response_id: response_id });
    }
  }, [uuid, response_id]);
  return (
    <Page
      title={`${form ? form.form_name : ""} Form`}
      className={classes.root}
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <Container maxWidth="xl">
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <div>
              <Respond
                uuid={uuid}
                response_id={response_id}
                form={form}
                answers={answers}
                setAnswers={setAnswers}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default FormRespond;
