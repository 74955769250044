const regions = [
  {
    abbr: "al",
    long: "Alabama",
    code: "AL",
    type: "EXTERNAL",
  },
  {
    abbr: "ak",
    long: "Alaska",
    code: "AK",
    type: "EXTERNAL",
  },
  {
    abbr: "az",
    long: "Arizona",
    code: "AZ",
    type: "EXTERNAL",
  },
  {
    abbr: "ar",
    long: "Arkansas",
    code: "AR",
    type: "EXTERNAL",
  },
  {
    abbr: "ca",
    long: "California",
    code: "CA",
    type: "EXTERNAL",
  },
  {
    abbr: "co",
    long: "Colorado",
    code: "CO",
    type: "EXTERNAL",
  },
  {
    abbr: "ct",
    long: "Connecticut",
    code: "CT",
    type: "EXTERNAL",
  },
  {
    abbr: "de",
    long: "Delaware",
    code: "DE",
    type: "EXTERNAL",
  },
  {
    abbr: "dc",
    long: "District of Columbia",
    code: "DC",
    type: "EXTERNAL",
  },
  {
    abbr: "dub",
    long: "Dublin",
    code: "DUB",
    type: "EXTERNAL",
  },
  {
    abbr: "fl",
    long: "Florida",
    code: "FL",
    type: "EXTERNAL",
  },
  {
    abbr: "ga",
    long: "Georgia",
    code: "GA",
    type: "EXTERNAL",
  },
  {
    abbr: "hi",
    long: "Hawaii",
    code: "HI",
    type: "EXTERNAL",
  },
  {
    abbr: "id",
    long: "Idaho",
    code: "ID",
    type: "EXTERNAL",
  },
  {
    abbr: "il",
    long: "Illinois",
    code: "IL",
    type: "EXTERNAL",
  },
  {
    abbr: "in",
    long: "Indiana",
    code: "IN",
    type: "EXTERNAL",
  },
  {
    abbr: "ia",
    long: "Iowa",
    code: "IA",
    type: "EXTERNAL",
  },

  {
    abbr: "ks",
    long: "Kansas",
    code: "KS",
    type: "EXTERNAL",
  },
  {
    abbr: "ky",
    long: "Kentucky",
    code: "KY",
    type: "EXTERNAL",
  },
  {
    abbr: "la",
    long: "Louisiana",
    code: "LA",
    type: "EXTERNAL",
  },
  {
    abbr: "me",
    long: "Maine",
    code: "ME",
    type: "EXTERNAL",
  },
  {
    abbr: "md",
    long: "Maryland",
    code: "MD",
    type: "EXTERNAL",
  },
  {
    abbr: "ma",
    long: "Massachusetts",
    code: "MA",
    type: "EXTERNAL",
  },
  {
    abbr: "mi",
    long: "Michigan",
    code: "MI",
    type: "EXTERNAL",
  },
  {
    abbr: "mn",
    long: "Minnesota",
    code: "MN",
    type: "EXTERNAL",
  },
  {
    abbr: "ms",
    long: "Mississippi",
    code: "MS",
    type: "EXTERNAL",
  },
  {
    abbr: "mo",
    long: "Missouri",
    code: "MO",
    type: "EXTERNAL",
  },
  {
    abbr: "mt",
    long: "Montana",
    code: "MT",
    type: "EXTERNAL",
  },
  {
    abbr: "ne",
    long: "Nebraska",
    code: "NE",
    type: "EXTERNAL",
  },
  {
    abbr: "nv",
    long: "Nevada",
    code: "NV",
    type: "EXTERNAL",
  },
  {
    abbr: "nh",
    long: "New Hampshire",
    code: "NH",
    type: "EXTERNAL",
  },
  {
    abbr: "nj",
    long: "New Jersey",
    code: "NJ",
    type: "EXTERNAL",
  },
  {
    abbr: "nm",
    long: "New Mexico",
    code: "NM",
    type: "EXTERNAL",
  },
  {
    abbr: "ny",
    long: "New York",
    code: "NY",
    type: "EXTERNAL",
  },
  {
    abbr: "nc",
    long: "North Carolina",
    code: "NC",
    type: "EXTERNAL",
  },
  {
    abbr: "nd",
    long: "North Dakota",
    code: "ND",
    type: "EXTERNAL",
  },
  {
    abbr: "oh",
    long: "Ohio",
    code: "OH",
    type: "EXTERNAL",
  },
  {
    abbr: "ok",
    long: "Oklahoma",
    code: "OK",
    type: "EXTERNAL",
  },
  {
    abbr: "or",
    long: "Oregon",
    code: "OR",
    type: "EXTERNAL",
  },
  {
    abbr: "pa",
    long: "Pennsylvania",
    code: "PA",
    type: "EXTERNAL",
  },
  {
    abbr: "ri",
    long: "Rhode Island",
    code: "RI",
    type: "EXTERNAL",
  },
  {
    abbr: "sc",
    long: "South Carolina",
    code: "SC",
    type: "EXTERNAL",
  },
  {
    abbr: "sd",
    long: "South Dakota",
    code: "SD",
    type: "EXTERNAL",
  },
  {
    abbr: "tn",
    long: "Tennessee",
    code: "TN",
    type: "EXTERNAL",
  },
  {
    abbr: "tx",
    long: "Texas",
    code: "TX",
    type: "EXTERNAL",
  },
  {
    abbr: "ut",
    long: "Utah",
    code: "UT",
    type: "EXTERNAL",
  },
  {
    abbr: "vt",
    long: "Vermont",
    code: "VT",
    type: "EXTERNAL",
  },
  {
    abbr: "va",
    long: "Virginia",
    code: "VA",
    type: "EXTERNAL",
  },
  {
    abbr: "wa",
    long: "Washington",
    code: "WA",
    type: "EXTERNAL",
  },
  {
    abbr: "wv",
    long: "West Virginia",
    code: "WV",
    type: "EXTERNAL",
  },
  {
    abbr: "wi",
    long: "Wisconsin",
    code: "WI",
    type: "EXTERNAL",
  },
  {
    abbr: "wy",
    long: "Wyoming",
    code: "WY",
    type: "EXTERNAL",
  },
];

export default regions;
