import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  CardContent,
  Card,
  TextField,
} from "@material-ui/core";
import { API } from "aws-amplify";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";
import { trackEvent } from "../../../api/analytics";
import JourneyMap from "./JourneyMap";
// import virginia_county from "./mapdata/virginia-cities-min.json";
// import pennsylvania_county from "./mapdata/pennsylvania-counties-min.json";
// import nhampshire_county from "./mapdata/nhampshire-counties-min.json";
import Recommendation from "./Recommedations";
import { useStore } from "../../../stores/StoreContext";

const JourneyView = () => {
  var virginia_county = require("./mapdata/virginia-counties-min.json");
  var pennsylvania_county = require("./mapdata/pennsylvania-counties-min.json");
  var nhampshire_county = require("./mapdata/nhampshire-counties-min.json");

  const [usState, setUSState] = useState("");
  const [usCity, setUSCity] = useState("");
  const [center, setCenter] = useState([39.5, -78.35]);
  const [zoom, setZoom] = useState(5);
  const [selectedState, setSelectedState] = useState([]);
  const [active, setActive] = useState("US");
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Journey");
  }, []);

  const handleChange = (value) => {
    setUSState(value);
    setUSCity("");
    if (value === null) {
      setCenter([39.5, -78.35]);
      setZoom(5);
      setActive("US");
    }
  };

  const getRecommendations = (value) => {
    setUSCity(value);
    getRecommendationsCity(value);
  };
  const [recommendations, setReco] = useState([]);

  async function getRecommendationsCity(city) {
    setSubmit(true);
    try {
      const data = await API.get("backendGateway", "/reco/city", {
        queryStringParameters: {
          city: city,
          limit: "10",
        },
      });
      //console.log("data from Lambda REST API: ", data);
      if (data.results != null) {
        setReco(data.results);
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setSubmit(false);
  }

  const [submit, setSubmit] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <JourneyMap
                  center={center}
                  setCenter={setCenter}
                  zoom={zoom}
                  setZoom={setZoom}
                  active={active}
                  city={usCity}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="combo-box-type"
                  options={["Virginia", "Pennsylvania", "New Hampshire"]}
                  onChange={(event, value) => {
                    handleChange(value);
                  }}
                  value={usState}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State Name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="combo-box-type"
                  options={selectedState}
                  onChange={(event, value) => {
                    getRecommendations(value);
                  }}
                  value={usCity}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City Name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>

          {submit && (
            <div align="center">
              <CircularProgress />
            </div>
          )}
        </Card>
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <Recommendation
          city={usCity}
          recommendations={recommendations}
          setReco={setReco}
        />
      </Grid>
    </Grid>
  );
};

export default JourneyView;
