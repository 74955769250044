import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";

import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { nanoid } from "nanoid";

import { useForm } from "react-hook-form";
import useApi from "../../hooks/useApi";
import accountsApi from "../../api/accounts";
import { useStore } from "../../stores/StoreContext";
import distributorsAPI from "../../api/distributors";
import accountsAPI from "../../api/accounts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    padding: theme.spacing(2),
    overflow: "hidden",
  },
}));

const ContactModal = ({
  onClose,
  contact,
  contactList,
  setContactList,
  refreshContacts,
  open,
  contactContext,
  appendContact,
  ...rest
}) => {
  const classes = useStyles();
  const store = useStore();
  const { userInfo } = store;

  const [deleteInProgress, setDeleteButton] = useState(true);
  const [submitInProgress, setSubmitButton] = useState(false);
  const [buttonText, setButtonText] = useState("CREATE");
  const { handleSubmit } = useForm();

  const handleClose = () => {
    setValues({
      name: "",
      phone: "",
      title: "",
      email: "",
      contact_id: nanoid(5),
    });
    onClose();
  };

  const titleText = (() => {
    if (contactContext.context === "distributor")
      return "Add Contact to " + contactContext.distributor.dist_displayname;
    if (contactContext.context === "account")
      return "Add Contact to " + contactContext.account.account_displayname;
  })();

  async function deleteContact(contactId) {
    try {
      await API.del("backendGateway", "/accounts/contact/list", {
        queryStringParameters: {
          contact_id: contactId,
        },
      });
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setContactList(contactList.filter((x) => x.contact_id !== contactId));
    handleClose();
  }

  const handleChange = (event, value) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    const { manufacturer_id, user_id } = userInfo;
    if (contactContext.context === "distributor") {
      const distributor_id = contactContext.distributor.dist_id;
      distributorsAPI
        .addDistributorContact(manufacturer_id, distributor_id, values)
        .then(({ results }) => {
          appendContact({
            ...values,
            contact_id: results.contact_id,
          });
        })
        .catch((error) => console.error(error));
    }
    if (contactContext.context === "account") {
      const { account_id } = contactContext.account;
      accountsAPI
        .postContact({
          manufacturer_id,
          account_id,
          user_id,
          ...values,
        })
        .then(({ results }) => {
          appendContact({
            ...values,
            contact_id: results[0],
          });
        })
        .catch((error) => console.error(error));
    }

    handleClose();
  };
  const [values, setValues] = useState({
    user_id: userInfo.uid,
    manufacturer_id: userInfo.mid,
    name: contact?.name || "",
    phone: contact?.phone || "",
    title: contact?.title || "",
    email: contact?.email || "",
    contact_id: contact?.contact_id || nanoid(5), //fakeID Key
  });
  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <DialogTitle id="simple-dialog-title">{titleText}</DialogTitle>
      <form className={classes.formControl}>
        <Grid container spacing={5}>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Name"
              required
              variant="outlined"
              name="name"
              fullWidth
              onChange={handleChange}
              value={values.name}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              name="title"
              fullWidth
              onChange={handleChange}
              value={values.title}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              fullWidth
              onChange={handleChange}
              value={values.email}
            />
          </Grid>

          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Phone"
              variant="outlined"
              name="phone"
              fullWidth
              onChange={handleChange}
              value={values.phone}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Button
              disabled={submitInProgress}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              {buttonText}
            </Button>
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Button
              disabled={deleteInProgress}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => deleteContact(values.contact_id)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ContactModal;
