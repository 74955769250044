import { useEffect, useState } from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/StoreContext";
import distributorsApi from "../../../api/distributors";
import { Link } from "react-router-dom";

const CardBody = observer(() => {
  const { allBrands } = useStore();
  return (
    <CardContent>
      {allBrands.length > 0 && (
        <div>
          <Link to={"/app/distributors/regions/us"}>
            Compare Distributors by Brand and Region
          </Link>
        </div>
      )}
    </CardContent>
  );
});

const RegionCompareAvailable = ({ manufacturer }) => {
  return (
    <Card>
      <CardHeader title={"Distributor Data"} />
      <CardBody />
    </Card>
  );
};

export default RegionCompareAvailable;
