import React from "react";

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      className="header-logo"
      src="/static/images/logos/SalesTier_Primary_White.png"
      {...props}
    />
  );
};

export default Logo;
