import _ from "lodash";
import { DateTime } from "luxon";
import { runInAction } from "mobx";

const megaFilter = {
  // allowPaths: ["/app/distributors/regions", "/app/depletions", "/app/home"],
  allowPaths: [
    "/app/distributors/regions",
    "/app/depletions",
    "/app/accountExplorer",
    "/app/home",
    "/app/inventory",
    /\/app\/lists\/\d+/,
  ],
  exportSupportedPaths: ["/app/depletions", "/app/team/manager"],
  denyPaths: [],
  completeDistBrandData: [],
  setCompleteDistBrandData(data) {
    this.completeDistBrandData = data;
  },
  allStates: [],
  setAllStates(data) {
    this.allStates = data.sort();
  },
  allDistributors: [],
  setAllDistributors(data) {
    this.allDistributors = data.sort();
  },
  allBrands: [],
  setAllBrands(data) {
    this.allBrands = data.sort();
  },
  selectedStates: [],
  setSelectedStates(data) {
    this.selectedStates = _.uniq(data).sort();
  },
  selectAllStates() {
    this.selectedStates = [...this.allStates];
  },
  selectNoStates() {
    this.selectedStates = [];
  },
  selectedDistributors: [],
  setSelectedDistributors(data) {
    this.selectedDistributors = _.uniq(data).sort();
  },
  selectAllDistributors() {
    this.selectedDistributors = [...this.allDistributors];
  },
  selectNoDistributors() {
    this.selectedDistributors = [];
  },
  selectedBrands: [],
  setSelectedBrands(data) {
    this.selectedBrands = _.uniq(data).sort();
  },
  selectAllBrands() {
    this.selectedBrands = [...this.allBrands];
  },
  selectNoBrands() {
    this.selectedBrands = [];
  },
  productsInData: [],
  setProductsInData(list) {
    runInAction(() => {
      this.productsInData = list;
    });
  },
  autoCompleteProducts: [],
  setAutoCompleteProducts(list) {
    this.autoCompleteProducts = list;
  },
  filteringProducts: [],
  setFilteringProducts(list) {
    this.filteringProducts = list;
  },
  productJoiner: "And",
  setProductJoiner(join) {
    this.productJoiner = join;
  },
  productSold: true,
  setProductSold(join) {
    this.productSold = join;
  },

  setPremiseTypes(list) {
    this.premiseTypes = list;
  },

  everythingOn() {
    this.selectedStates = [...this.allStates];
    this.selectedDistributors = [...this.allDistributors];
    this.selectedBrands = [...this.allBrands];
  },
  everythingOff() {
    this.selectedStates = [];
    this.selectedDistributors = [];
    this.selectedBrands = [];
  },
  latestSale: null,
  setLatestSale(latestSale) {
    // This should only be called once, on initial load, when we fetch users manufacturer details.
    this.latestSale = latestSale;
    this.dateRange = this._genMonths(12);
  },
  _genQTD() {
    const endDate = this.latestSale || new Date();
    const thisMonth = endDate.getMonth();
    const quarter = thisMonth < 3 ? 0 : Math.floor(thisMonth / 3);
    const startMonth = quarter * 3;
    const start = DateTime.now()
      .setZone("UTC")
      .set({ month: startMonth + 1 })
      .startOf("day")
      .startOf("month")
      .toJSDate();
    const end = DateTime.fromJSDate(endDate, { zone: "UTC" })
      .endOf("day")
      .toJSDate();
    return [start, end];
  },
  _genYTD() {
    const endDate = this.latestSale || new Date();
    const start = DateTime.now().setZone("UTC").startOf("year").toJSDate();
    const end = DateTime.fromJSDate(endDate, { zone: "UTC" })
      .endOf("day")
      .toJSDate();
    return [start, end];
  },
  _genAllTime() {
    const start = new Date(1);
    const end = this.latestSale || new Date();
    return [start, end];
  },
  _genMonths(monthCount, offset = 0) {
    const endDate = this.latestSale || new Date();
    // const previousMonth = DateTime.fromJSDate(endDate).minus({ months: 1 });
    let start = DateTime.fromJSDate(endDate, { zone: "UTC" }).startOf("month");
    let end = DateTime.fromJSDate(endDate, { zone: "UTC" }).endOf("month");
    if (offset !== 0) {
      if (offset < 0) {
        start = start
          .minus({
            months: Math.abs(offset),
          })
          .startOf("month");
        end = end
          .minus({
            months: Math.abs(offset),
          })
          .endOf("month");
      } else {
        start = start
          .plus({
            months: offset,
          })
          .startOf("month");
        end = end
          .plus({
            months: offset,
          })
          .endOf("month");
      }
    }
    if (monthCount > 1) {
      start = start.minus({ months: monthCount - 1 }).startOf("month");
    }
    return [start.toJSDate(), end.toJSDate()];
  },
  // the below allows us to access `this._genMonths` to give them access to `this` after initialisation
  // So we can use the stored latestSale.
  // its an initialisation race condition
  _cachedRange: null,
  get dateRange() {
    if (!this._cachedRange) {
      this.dateRange = this._genMonths(12);
    }
    return this._cachedRange;
  },
  set dateRange(range) {
    this._cachedRange = range;
  },
  setDateRange(range) {
    this.dateRange = range;
  },
  setCustomDateRange(range) {
    this.dateRange = range;
    this.dateRangeName = "Custom";
  },
  setDateRangeName(name) {
    this.dateRangeName = name;
    if (name !== "Custom") {
      const matchingEntry = this.dateRangeOptions.find(
        (dro) => dro.name === name
      );
      // const newRange = this.dateRangeOptions[matchingEntry].action(this);
      this.setDateRange(matchingEntry.action(this));
      // console.log("SDR call ", name, matchingEntry, newRange);
    }
  },
  changeDateOffset(difference) {
    // console.log(new Error("changeDateOffset"), {difference})
    const drn = this.dateRangeName;
    // months and custom can be shifted, unlike QTD and YTD
    if (/(months?|Custom)$/.test(drn)) {
      const [start, end] = this.dateRange;
      const changeFunction = difference < 0 ? "minus" : "plus";
      const changeVal = Math.abs(difference);
      const newStart = DateTime.fromJSDate(start, { zone: "UTC" })
        [changeFunction]({ months: changeVal })
        .startOf("month")
        .toJSDate();
      const newEnd = DateTime.fromJSDate(end, { zone: "UTC" })
        [changeFunction]({ months: changeVal })
        .endOf("month")
        .toJSDate();
      this.dateRange = [newStart, newEnd];
    }
  },
  dateRangeName: "12 months",
  dateRangeOptions: [
    {
      name: "1 month",
      action: (mf) => {
        return mf._genMonths(1);
      },
    },
    {
      name: "3 months",
      action: (mf) => {
        return mf._genMonths(3);
      },
    },
    {
      name: "6 months",
      action: (mf) => {
        return mf._genMonths(6);
      },
    },
    {
      name: "12 months",
      action: (mf) => {
        return mf._genMonths(12);
      },
    },
    {
      name: "Quarter to Date",
      action: (mf) => {
        return mf._genQTD();
      },
    },
    {
      name: "Year to Date",
      action: (mf) => {
        return mf._genYTD();
      },
    },
    {
      name: "All Time",
      action: (mf) => {
        return mf._genAllTime();
      },
    },
    {
      name: "Custom",
      action: () => {},
    },
  ],
  quantities: [
    {
      value: "unit_sold",
      text: "Retail Unit",
    },
    {
      value: "physical_sold",
      text: "Physical Sold",
    },
    {
      value: "nine_sold",
      text: "9L Eqv",
    },
    {
      value: "bbl",
      text: "BBL",
    },
  ],
  selectedQuantity: "physical_sold",
  selectedQuantityLabel: "Physical Sold",
  setSelectedQuantity(q) {
    this.selectedQuantity = q;
    this.selectedQuantityLabel = this.quantities.find(
      (qx) => qx.value === q
    ).text;
  },
  bookmarkUUID: "",
  useBookmark({ qs_state, uuid }) {
    [
      "selectedStates",
      "selectedBrands",
      "selectedDistributors",
      "premiseTypes",
      "filteringProducts",
      "selectedQuantity",
      "productJoiner",
      "productSold",
    ].map((b) => {
      this[b] = qs_state[b];
    });
    if (!Object.hasOwn(qs_state, "dateRange")) {
      this.setDateRangeName(qs_state.dateRangeName);
    } else {
      const prepRange = qs_state.dateRange.map((d) =>
        DateTime.fromISO(d).toJSDate()
      );
      this.setDateRangeName("Custom");
      this.setDateRange(prepRange);
    }
    this.bookmarkUUID = uuid;
  },
};

export default megaFilter;

export const generate = {
  qtd: megaFilter._genQTD,
  ytd: megaFilter._genYTD,
  months: megaFilter._genMonths,
};
