import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ContactList from "./ContactList";

const useStyles = makeStyles({});

const ContactListModal = (props) => {
  const classes = useStyles();
  const { onClose, open, contactList, setContactList } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <ContactList
        onClose={handleClose}
        setContactList={setContactList}
        contactList={contactList}
      />
    </Dialog>
  );
};

ContactListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ContactListModal;
