const Support = () => {
  var urlParams = new URLSearchParams(window.location.search);
  window.hsConversationsSettings = {
    inlineEmbedSelector: "#chat",
  };
  window.hsConversationsOnReady = [
    () => {
      if (urlParams.has("user_email")) {
        const email = urlParams.get("user_email");
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          "identify",
          {
            email,
          },
        ]);
        _hsq.push(["trackPageView"]);
      }
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    },
  ];

  return (
    <>
      <style>
        {`
          #hubspot-conversations-inline-iframe {
            width: 100vw;
            height: 100vh;
            border: none;
          }
        `}
      </style>
      <div id="chat" />
    </>
  );
};
export default Support;
