import client from "./client";

const getBookMarks = ({ mid, uid }) => {
  const endpoint = "/bookmarks";
  return client.get(endpoint, {
    mid,
    uid,
  });
};

const getBookMark = (bid) => {
  const endpoint = `/bookmarks/${bid}`;
  return client.get(endpoint);
};
const deleteBookMark = (bid) => {
  const endpoint = `/bookmarks/${bid}`;
  return client.del(endpoint);
};
const saveBookMark = (payload) => {
  const endpoint = "/bookmarks";
  return client.post(endpoint, payload);
};

export default {
  getBookMarks,
  getBookMark,
  saveBookMark,
  deleteBookMark,
};
