import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Observer } from "mobx-react-lite";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Button,
  ListItem,
} from "@material-ui/core";
import NavItem from "./NavItem";
import clsx from "clsx";
import {
  Home as HomeIcon,
  TableChart,
  AccountBalance,
  DateRange,
  FormatListBulleted,
  PermMedia,
  Face,
  Storage,
  Group,
  Public,
  AccountTree as DistributorsIcon,
  KeyboardArrowLeft,
  PermDeviceInformation as FormBuilder,
  Search,
  Settings,
  Bookmark,
  LocalShipping,
  Business,
} from "@material-ui/icons";
// import MyLocationIcon from "@material-ui/icons/MyLocation";
import { useStore } from "../../../stores/StoreContext";
import { awsS3Image } from "../../../helpers/awsS3Image";

const NavBar = ({ onMobileClose, openMobile, mini, setMini }) => {
  const store = useStore();
  const { manufacturer, userInfo } = store;

  const idn_items = [
    {
      href: "/app/home",
      icon: HomeIcon,
      title: "Home",
    },
    {
      href: "/app/depletions/us",
      icon: TableChart,
      title: "Reports",
      special: "reports",
    },
    {
      href: "/app/lists",
      icon: Public,
      title: "Markets",
    },
    {
      href: "/app/distributors",
      icon: DistributorsIcon,
      title: "Contact & Brand Comparison",
    },
    {
      href: "/app/top-external-brands",
      icon: Search,
      title: "Top Ext. Brands",
    },
    {
      href: "/app/media",
      icon: PermMedia,
      title: "Files",
    },
    {
      href: "/app/forms",
      icon: FormBuilder,
      title: "Forms",
    },
    {
      href: "/app/settings",
      icon: Settings,
      title: "User Settings",
    },
    {
      href: "/app/finance",
      icon: AccountBalance,
      title: "Member Finance",
    },
    {
      href: "/app/profile/" + userInfo.uid,
      icon: Face,
      title: "User Profile",
    },
    {
      href: "/app/bm/",
      icon: Bookmark,
      title: "Bookmarks",
    },
  ];

  const supplier_items = [
    {
      href: "/app/home",
      icon: HomeIcon,
      title: "Home",
    },
    {
      href: "/app/depletions/us",
      icon: TableChart,
      title: "Reports",
      special: "reports",
    },
    {
      href: "/app/lists",
      icon: Public,
      title: "Markets",
    },
    {
      href: "/app/accountExplorer",
      icon: Business,
      title: "Account Explorer",
    },
    {
      href: "/app/team",
      icon: Group,
      title: "Team",
    },
    {
      href: "/app/schedule",
      icon: DateRange,
      title: "Schedule",
    },
    {
      href: "/app/products",
      icon: FormatListBulleted,
      title: "Products",
    },

    {
      href: "/app/distributors",
      icon: DistributorsIcon,
      title: "Distributors",
    },
    {
      href: "/app/media",
      icon: PermMedia,
      title: "Marketing",
    },
    {
      href: "/app/log",
      icon: Storage,
      title: "Depletion Uploads",
    },
    {
      href: "/app/forms",
      icon: FormBuilder,
      title: "Forms",
    },
    {
      href: "/app/settings",
      icon: Settings,
      title: "Settings",
    },
    {
      href: "/app/profile/" + userInfo.uid,
      icon: Face,
      title: "Profile",
    },
    {
      href: "/app/bm/",
      icon: Bookmark,
      title: "Bookmarks",
    },
    // {
    //   href: "#",
    //   special: "toggleMini",
    //   icon: KeyboardArrowLeft,
    //   title: "Thin Menu",
    // },
  ];

  const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
      width: 256,
    },
    mobileDrawerMini: {
      width: 56,
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      height: "calc(100% - 64px)",
      transition: "width 0.3s",
    },
    desktopDrawerMini: {
      extends: "desktopDrawer",
      width: 66,
    },
    avatar: {
      cursor: "pointer",
      width: 64,
      height: 64,
    },
    profWrapper: {
      height: "auto",
      transition: "all 0.3s",
    },
    profWrapperMini: {
      minHeight: 0,
      height: 0,
      overflow: "hidden",
    },
    item: {
      display: "flex",
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0,
      padding: "10px 8px",
      textTransform: "none",
      minWidth: 0,
      width: "100%",
      outline: "0px !important",
      "&.active": {
        color: theme.palette.primary.main,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
        },
        "& $icon": {
          color: theme.palette.primary.main,
          transition: "transform 0.3s",
        },
      },
    },
    title: {
      marginRight: "auto",
      marginLeft: theme.spacing(1),
    },
    miniTitle: {
      marginRight: "auto",
      marginLeft: theme.spacing(1),
      fontSize: "0.6em",
    },
    icon: {},
  }));

  const classes = useStyles();
  const location = useLocation();
  const [userImage, setUserImage] = useState(null);

  const [avatar, setAvatar] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [navItems, setNavItems] = React.useState([]);

  useEffect(() => {
    let allowedScreens = userInfo.access?.screens;

    if (manufacturer.manufacturer_id === 39) {
      setNavItems(idn_items);
      //So we can add depletion reports
      if ([247, 275, 349, 400].includes(userInfo.user_id)) {
        setNavItems([
          ...idn_items,
          {
            href: "/app/log",
            icon: Storage,
            title: "Depletion Uploads",
          },
          {
            href: "/app/team",
            icon: Group,
            title: "Team",
          },
        ]);
      }
    } else {
      setNavItems(
        supplier_items.filter(
          (item) => !allowedScreens || allowedScreens.includes(item.title)
        )
      );
      if (manufacturer.manufacturer_id === 614) {
        supplier_items.splice(5, 0, {
          href: "/app/inventory",
          icon: LocalShipping,
          title: "Inventory",
        });

        setNavItems([...supplier_items]);
      }
    }

    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    setAvatar(userInfo.uimage);
    setLoaded(true);
  }, [location.pathname, userInfo.user_image]);
  useEffect(() => {
    setLoaded(false);
    setTimeout(() => {
      let _image = awsS3Image(
        userInfo.user_image,
        userInfo.user_identitykey,
        "64x64"
      );
      setUserImage(_image);
      setLoaded(true);
    }, 100);
  }, [userInfo.user_image]);

  const content = (
    <Observer>
      {() => (
        <Box height="100%" display="flex" flexDirection="column">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            p={mini ? 0 : 2}
            className={
              mini
                ? `${classes.profWrapper} ${classes.profWrapperMini}`
                : classes.profWrapper
            }
          >
            {avatar === "gg_default_image.png" && (
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                to="/app/settings"
                src="/static/images/avatars/gg_default_image.png"
              />
            )}

            {loaded && avatar !== "gg_default_image.png" && (
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                to="/app/settings"
              >
                <img
                  src={userImage}
                  className={classes.avatar}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  alt=""
                />
              </Avatar>
            )}

            <h3 className="heading-fieldset">{userInfo.uname}</h3>
            <Typography color="textSecondary" variant="body2">
              {userInfo.urole}
            </Typography>
          </Box>
          <Divider />
          <Box p={mini ? 0 : 2}>
            <List data-jank={store?.manufacturer?.state_codes?.join(",") || ""}>
              {navItems.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  special={item.special ? item.special : ""}
                  mini={mini}
                  toggleMini={() => setMini(!mini)}
                />
              ))}
              <ListItem className={clsx(classes.item)} disableGutters>
                <Button
                  className={classes.button}
                  styles={{
                    justifyContent: mini ? "space-around" : "flex-start",
                    outline: "none",
                  }}
                  onClick={() => setMini(!mini)}
                >
                  <KeyboardArrowLeft
                    className={classes.icon}
                    size="20"
                    style={{
                      transform: `rotate(${mini ? 180 : 0}deg)`,
                    }}
                  />
                  {!mini && (
                    <span className={classes.miniTitle}>Thin Menu</span>
                  )}
                </Button>
              </ListItem>
            </List>
          </Box>
          <Box flexGrow={1} />
        </Box>
      )}
    </Observer>
  );

  return (
    <Observer>
      {() => (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              {content}
            </Drawer>
          </Hidden>
          <Hidden mdDown>
            <Drawer
              anchor="left"
              classes={{
                paper: mini
                  ? `${classes.desktopDrawer} ${classes.desktopDrawerMini}`
                  : classes.desktopDrawer,
              }}
              open
              variant="persistent"
            >
              {content}
            </Drawer>
          </Hidden>
        </>
      )}
    </Observer>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
