import { awsS3Image } from "../../../../helpers/awsS3Image";
import { File } from "react-feather";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
const AnswerFileDisplay = ({
  file,
  setChosenFile = () => {},
  editable = false,
  remove,
}) => {
  const isImage =
    Object.hasOwn(file, "image_name") || /\.(jpe?g|png)$/i.test(file.file_name);
  const genUrl = () => {
    const { image_name, identity_key, file_key, identity_id } = file;
    if (file.image_name) {
      // old weird format
      return awsS3Image(image_name, identity_key, "200x0");
    } else {
      // correct format
      return awsS3Image(file_key, identity_id, "200x0");
    }
    return "";
  };
  const url = genUrl();
  return (
    <Box position={"relative"}>
      <Card>
        <CardContent>
          {isImage && (
            <img src={url} alt="" onClick={() => setChosenFile(file)} />
          )}
          {!isImage && (
            <Box textAlign={"center"}>
              <File size={"medium"} />
              <Typography variant="overline">{file.file_name}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      {editable && (
        <div style={{ marginTop: 5 }}>
          <DeleteIcon onClick={() => remove(file)} />
          Remove
        </div>
      )}
    </Box>
  );
};

export default AnswerFileDisplay;
