import { useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { useStore } from "../../stores/StoreContext";
import { stateCodeToState } from "../../helpers/data/usStates";

const useLocalhostDb = process.env.REACT_APP_TARGET_LOCALHOST_DB || false;

const useSalesData = () => {
  const {
    userInfo,
    depletionList,
    setDepletionList,
    setAllMarketsObjects,
    setAllAccountsObjects,
    setAllDistributorsObjects,
    setProductsInData,
    setLatestSale,
    setManufacturerStates,
    setDepletionListMinified,
    setAllMarketsObjectsMinified,
    setAllAccountsObjectsMinified,
    setAllDistributorsObjectsMinified,
    setProductsInDataMinified,
    setAllPremiseTypes,
  } = useStore();

  useEffect(() => {
    const fetchData = async () => {
      let json;
      if (useLocalhostDb) {
        const rawResult = await API.get(
          "backendGateway",
          "/cachefiles/minified"
        );
        json = rawResult;
      } else {
        const file = await Storage.get(
          `cache/${userInfo.mid}/minified.${userInfo.current_data_hash}.json`,
          {
            level: "protected",
            identityId: "system",
            download: true,
          }
        );
        const rawData = await file.Body.text();
        json = JSON.parse(rawData);
      }

      const processedAccounts = Object.keys(json.refactoredFile.accounts).map(
        (key) => ({
          id: key,
          ...json.refactoredFile.accounts[key],
        })
      );

      const processedDistributors = Object.keys(
        json.refactoredFile.distributors
      ).map((key) => {
        const distributor = json.refactoredFile.distributors[key];
        return {
          id: distributor.id,
          displayname: distributor.name,
        };
      });

      const allStates = Array.from(
        new Map(
          Object.keys(json.refactoredFile.accounts).map((key) => [
            json.refactoredFile.accounts[key].state,
            {
              code: json.refactoredFile.accounts[key].state,
              displayname: stateCodeToState(
                json.refactoredFile.accounts[key].state || ""
              ),
            },
          ])
        ).values()
      );

      const allPremiseTypes = Array.from(
        new Set(
          Object.keys(json.refactoredFile.accounts).map(
            (key) => json.refactoredFile.accounts[key].premise_type || "UNKNOWN"
          )
        )
      );

      setAllPremiseTypes(allPremiseTypes);

      const uniqueStates = Array.from(allStates);
      const allowedDistIDs = userInfo.access?.distributors;
      const allowedStates = userInfo.access?.markets;
      let maxDate = null;
      let uniqueStateCodes = new Set();
      const mergedSalesData = json.refactoredFile.depletions
        .filter((sale) => {
          const account = json.refactoredFile.accounts[sale.account];
          const distributor =
            json.refactoredFile.distributors[sale.distributor];
          return (
            (typeof allowedStates === "undefined" ||
              (allowedStates && allowedStates?.includes(account.state))) &&
            (typeof allowedDistIDs === "undefined" ||
              (allowedDistIDs && allowedDistIDs?.includes(distributor.id)))
          );
        })
        .map((sale) => {
          const account = json.refactoredFile.accounts[sale.account];
          const distributor =
            json.refactoredFile.distributors[sale.distributor];
          const product = json.refactoredFile.products[sale.product];
          const saleDate = new Date(sale.date);

          if (maxDate === null) {
            maxDate = saleDate;
          } else if (saleDate > maxDate) {
            maxDate = saleDate;
          }
          uniqueStateCodes.add(account.state);
          return {
            ...sale,
            DATE: sale.date,
            ZIP: account.zip,
            premise_type: account.premise_type
              ? account.premise_type
              : "UNKNOWN",
            DISTRIBUTOR_ID: distributor.id,
            DISTRIBUTOR_NAME: distributor.name,
            ACCOUNT_ID: account.id,
            STORENUMBER: account.name,
            latlong:
              account.geo && account.geo[0] && account.geo[1]
                ? `${account.geo[0]}, ${account.geo[1]}`
                : null,
            STOREADDRESS: account.address,
            REGION: `${account.city} - ${account.state}`,
            STATE: account.state,
            CITY: account.city,
            PRODUCTNAME: product.name,
            PRODUCT_ID: product.id,
            QTYSOLD: sale.qty_unit,
            SKU: product.brand,
            NINELTR: sale.qty_9ltr,
            UNIT_SOLD: sale.qty_unit,
            PHYSICAL_SOLD: sale.qty_case,
            NINE_SOLD: sale.qty_9ltr,
            BBL: sale.qty_bbl,
            PREMISETYPE: account.premise_type
              ? account.premise_type
              : "UNKNOWN",
            BRAND: product.brand,
            MANUFACTURER_ID: json.refactoredFile.manufacturer_id,
            INTERNAL_PRODUCT_ID: product.internal_product_id,
            phone: account.phone,
          };
        });
      let stateCodes = [...uniqueStateCodes];
      setManufacturerStates(stateCodes);
      setLatestSale(maxDate);
      //old
      setAllMarketsObjects(uniqueStates);
      setAllAccountsObjects(processedAccounts);
      setAllDistributorsObjects(processedDistributors);
      setDepletionList(mergedSalesData);
      setProductsInData(mergedSalesData);
      //new
      setDepletionListMinified(json.refactoredFile.depletions);
      // console.log(json.refactoredFile.depletions);
      setAllMarketsObjectsMinified(allStates);
      setAllAccountsObjectsMinified(json.refactoredFile.accounts);
      setAllDistributorsObjectsMinified(json.refactoredFile.distributors);
      setProductsInDataMinified(json.refactoredFile.products);
    };

    fetchData();
  }, [
    setAllAccountsObjects,
    setAllAccountsObjectsMinified,
    setAllDistributorsObjects,
    setAllDistributorsObjectsMinified,
    setAllMarketsObjects,
    setAllMarketsObjectsMinified,
    setAllPremiseTypes,
    setDepletionList,
    setDepletionListMinified,
    setLatestSale,
    setManufacturerStates,
    setProductsInData,
    setProductsInDataMinified,
    userInfo,
  ]);

  useEffect(() => {
    let debounceCooldown;

    const checkManufacturer = async () => {
      const latestUser = await API.get("backendGateway", "/users/me");
      let user = latestUser.results[0];
      return user.current_data_hash !== userInfo.current_data_hash;
    };

    checkManufacturer().then((bool) => {
      if (bool) {
        debounceCooldown = setTimeout({}, 800);
      }
    });

    return () => {
      clearTimeout(debounceCooldown);
    };
  }, [userInfo, depletionList]);

  return {};
};

export default useSalesData;
