import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Storage, API } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  ListItemAvatar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@material-ui/core";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import IconButton from "@material-ui/core/IconButton";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useStore } from "../../../stores/StoreContext";

Storage.configure({ level: "protected" });

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: "350px",
    overflow: "auto",
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Recommendation = ({
  className,
  city,
  recommendations,
  setReco,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const store = useStore();
  const { userInfo } = store;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  async function createJourney() {
    const appoint = {
      user_id: userInfo.uid,
      type: 0,
      manufacturer_id: userInfo.mid,
      account_id: accountSelected.account_id || null,
      appointment: selectedDate || null,
      is_active: 1,
      cw: 99,
    };
    try {
      const apiName = "backendGateway";
      const path = "/journeys";
      const myInit = {
        body: appoint,
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    createJourneyiCal();
    handleClose();
  }
  const [accountSelected, setAccountSelected] = useState({});

  async function createJourneyiCal() {
    const appoint = {
      user_email: userInfo.user_email,
      account_name: accountSelected.account_displayname || null,
      account_location: `${accountSelected.account_address}` || null,
      appointment: selectedDate || null,
      account_id: accountSelected.account_id || null,
    };
    //console.log(appoint);
    try {
      const apiName = "backendGateway";
      const path = "/invites/appointment";
      const myInit = {
        body: appoint,
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (luxonDate) => {
    const dateString = luxonDate;
    setSelectedDate(dateString);
  };

  return (
    <Card className={clsx(className)} {...rest}>
      <CardHeader subheader="On Premise Accounts" title="Account List" />
      <Divider />
      <CardContent>
        <List dense className={classes.root}>
          {recommendations.map((rec) => (
            <RouterLink
              to={"/app/accountdetails/" + rec.account_id}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <ListItem key={rec.account_id} divider>
                <ListItemAvatar>
                  <Avatar>
                    <LocalBarIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={`${rec.account_displayname}`}
                  secondary={`${rec.account_address}`}
                />

                <IconButton
                  aria-label="schedule"
                  className={classes.margin}
                  onClick={(e) => {
                    setAccountSelected(rec);
                    handleClickOpen();
                    e.preventDefault();
                  }}
                >
                  <DateRangeIcon fontSize="large" />
                </IconButton>

                {/* <IconButton aria-label="delete" className={classes.margin}  onClick={() => {
                    handleHide(rec.account_id);
                  }}>
                <DeleteIcon
                  fontSize="large"
                 
                />
              </IconButton> */}
              </ListItem>
            </RouterLink>
          ))}
        </List>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          BackdropProps={{
            style: { backgroundColor: "black", opacity: 0.1 },
          }}
        >
          <DialogTitle id="form-dialog-title">Create Event</DialogTitle>
          <DialogContent>
            {/*LOAD Existing scheduled Items 
                    <DialogContentText>
                      <List>
                        <ListItem>
                          Meeting Bla @ X time
                        </ListItem>
                        <ListItem>
                          Meeting Bla @ X time
                        </ListItem>
                        <ListItem>
                          Meeting Bla @ X time
                        </ListItem>
                      </List>
                    </DialogContentText> */}
            {/* <TextField
                      autoFocus
                      id="date"
                      type="datetime-local"
                      variant="outlined"
                      value={selectedDate}
                      onChange={handleDateChange}
                      fullWidth
                    /> */}
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <DateTimePicker
                label="Date + Time"
                inputVariant="outlined"
                disablePast="true"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => createJourney()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

Recommendation.propTypes = {
  className: PropTypes.string,
};

export default Recommendation;
