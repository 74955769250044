import React, { useState, useRef, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import clsx from "clsx";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { awsS3Image } from "../../../helpers/awsS3Image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TeamCard = ({ className, member, ...rest }) => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(true);
  const [item, setItem] = useState({});
  const [bgUrl, setBgUrl] = useState("");

  useEffect(() => {
    if (member) {
      let _member = { ...member };
      _member.image_src = awsS3Image(
        _member.user_image,
        _member.user_identitykey,
        "0x110"
      );
      setItem({ ..._member });
    }
  }, [member]);

  const imageLoaded = (event, item) => {
    const el = s3ImageEl.current;
    if (el && el.nextElementSibling) {
      const next = el.nextElementSibling.shadowRoot;
      const imgUrl = next.querySelector("img").src;
      setBgUrl(imgUrl);
    }
  };
  const s3ImageEl = useRef(null);

  /**
   * after the S3 image is loaded, hook into a ref to find the img url via the DOM
   * and then use it as abackground image on it's previous sibling
   *
   */

  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <RouterLink to={"/app/profile/" + item.user_id}>
          <CardContent>
            <Box display="flex" justifyContent="center" mb={3}>
              {item.user_image === "gg_default_image.png" && (
                <Avatar
                  className={classes.avatar}
                  style={{
                    height: "110px",
                    width: "110px",
                    backgroundColor: "#ffffff",
                    position: "relative",
                  }}
                  variant="circular"
                  src="/static/images/avatars/gg_default_image.png"
                />
              )}

              {refresh && item.user_image !== "gg_default_image.png" && (
                <Avatar
                  alt="Member"
                  style={{
                    height: "110px",
                    width: "110px",
                    backgroundColor: "#ffffff",
                    position: "relative",
                  }}
                  variant="circular"
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      className="s3ImgToBg"
                      ref={s3ImageEl}
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${bgUrl})`,
                      }}
                    ></div>
                    <img
                      src={item.image_src}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        position: "absolute",
                        top: 0,
                      }}
                      alt=""
                    />
                  </div>
                </Avatar>
              )}
            </Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h6"
            >
              {item.user_displayname}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
        </RouterLink>
      </Card>
    </div>
  );
};

export default TeamCard;
