import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListDetails from "./ListDetails";

const useStyles = makeStyles({});

const AccountListModal = (props) => {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    item,
    setItem,
    setData,
    allLists,
    adjustListsByRegion,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <ListDetails
        item={item}
        setItem={setItem}
        allLists={allLists}
        setData={setData}
        onClose={handleClose}
        {...{
          adjustListsByRegion,
        }}
      />
    </Dialog>
  );
};

AccountListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default AccountListModal;
