import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MegaFilterStates from "./MegaFilterStates";

export const MegaFilterSection = ({
  title,
  data,
  allFields,
  activeFields,
  toggleBox,
  completeFilter,
  filterParent,
  mfType,
  openSection,
  setOpenSection,
  simpleMode,
  setSimpleMode,
  enabledList,
}) => {
  const listString = [...activeFields].sort().join(",");
  const toggleAccordion = () => {
    if (openSection === mfType) {
      setOpenSection(null);
    } else {
      setOpenSection(mfType);
    }
  };
  return allFields.list.length > 0 ? (
    <Accordion
      expanded={openSection === mfType}
      onChange={toggleAccordion}
      classes={{
        root: "mega-filter-section",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          content: "mega-filter-summary",
        }}
      >
        <Grid container spacing={0}>
          <Grid item>
            <Typography
              variant={"subtitle2"}
              style={{
                lineHeight: "2rem",
              }}
            >
              {title} {`${activeFields.length}/${allFields.list.length}`}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Box
              style={{
                maxWidth: "70vw",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="overline"
                style={{
                  lineHeight: "2rem",
                  paddingLeft: "8px",
                }}
              >
                {listString.length
                  ? listString
                  : "Nothing selected. No data will display."}{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: "mega-filter-states",
        }}
      >
        <MegaFilterStates
          allList={data}
          enabledList={enabledList}
          {...{
            allFields,
            activeFields,
            toggleBox,
            completeFilter,
            filterParent,
            mfType,
            simpleMode,
            setSimpleMode,
          }}
        />
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};
