import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Container,
  makeStyles,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import Splash from "../../../components/Splash";
import { useStore } from "../../../stores/StoreContext";
import { useAccountsUnsold, useProducts, useFilteredData } from "./DataHooks";
import AccountsTable from "./AccountsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const FilterSwitch = ({ label, checked, onChange, tooltip }) => (
  <Tooltip title={tooltip}>
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  </Tooltip>
);

const AccountsExplorerView = observer(() => {
  const store = useStore();
  const {
    userInfo,
    setDepletionListCount,
    depletionList,
    selectedQuantityLabel,
  } = store;
  const classes = useStyles();
  const scrollPreserver = useRef(null);
  const navigate = useNavigate();

  const [showProspectiveAccounts, setShowProspectiveAccounts] = useState(true);
  const [showAccountsSold, setShowAccountsSold] = useState(true);
  const [applyDateFilter, setApplyDateFilter] = useState(false);

  const accountsUnsoldState = useAccountsUnsold();
  const productsState = useProducts();
  const { accounts } = useFilteredData({
    accountsUnsold: accountsUnsoldState.accounts,
    accountsSold: depletionList,
    products: productsState.products,
    showProspectiveAccounts,
    showAccountsSold,
    applyDateFilter,
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const showSplash = !(
    accountsUnsoldState.apiCallCount > 0 &&
    productsState.apiCallCount > 0 &&
    setDepletionListCount > 0
  );
  let splashMessage = "Counting Bottles";
  if (
    accountsUnsoldState.apiCallCount > 0 ||
    productsState.apiCallCount > 0 ||
    setDepletionListCount > 0
  )
    splashMessage = "99 Bottles on the Wall..";

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:AccountsExplorer");
  }, [userInfo.uid]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleRowClick = (param) => {
    navigate(`/app/accountdetails/${param.row.id}`);
  };

  return (
    <Page className={classes.root} title="Accounts Explorer">
      <Container maxWidth="xl" className={classes.root}>
        {showSplash && (
          <Grid container spacing={3}>
            <Splash
              message={splashMessage}
              concise={setDepletionListCount !== 0}
            />
          </Grid>
        )}
        <Grid
          container
          spacing={3}
          direction={smallScreen ? "column" : "row"}
          style={{
            minHeight: 0,
          }}
        >
          <Grid item xs={12} ref={scrollPreserver}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <Card>
                  <CardHeader title="Accounts Explorer" />
                  <CardContent>
                    <FilterSwitch
                      label="Show Accounts Without Sales Data"
                      tooltip="If enabled this will show all accounts without any sales attributed to it via the sales depletion data pipeline. These may be manually added or imported accounts. If product or date filters are applied and this option is enabled, these accounts will still show up. These accounts show up with N/A in Total Sold and Last Sale Date Columns."
                      checked={showProspectiveAccounts}
                      onChange={() =>
                        setShowProspectiveAccounts(!showProspectiveAccounts)
                      }
                    />
                    <FilterSwitch
                      label="Show Accounts with Sales Data"
                      tooltip="If enabled this will show all accounts with sales attributed to them via the sales depletion data pipeline."
                      checked={showAccountsSold}
                      onChange={() => setShowAccountsSold(!showAccountsSold)}
                    />
                    <FilterSwitch
                      label="Apply Date Filter"
                      tooltip="If enabled, this option will filter the accounts with sales data to only show accounts with sales during the selected dates within your filters."
                      checked={applyDateFilter}
                      onChange={() => setApplyDateFilter(!applyDateFilter)}
                    />

                    <AccountsTable
                      rows={accounts}
                      selectedRows={selectedRows}
                      onRowClick={handleRowClick}
                      onSelectionModelChange={setSelectedRows}
                      selectedQuantityLabel={selectedQuantityLabel}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
});

export default AccountsExplorerView;
