export const awsS3Image = (image_src, identity_key, size, extras = []) => {
  let url = "";
  if (image_src) {
    const fileArray = image_src.split("/");
    fileArray.splice(-1, 0, size);
    const fileString = fileArray.join("/");
    url = [
      "https://d1u9u2xrw2b06s.cloudfront.net/protected",
      identity_key,
      fileString,
    ].join("/");
  }
  return url;
};
