import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import PasswordForm from "../../../components/forms/PasswordForm";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles(() => ({
  root: {},
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ProfileDetails = ({ className, profile, setUser, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailure, setOpenFailure] = React.useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const store = useStore();
  const { setUserInfo, userInfo } = store;

  // need this so that the Controllers from react-hook-form re-render
  // by default react-hook-form caches values on mount, reset is the correct method for updating
  useEffect(() => {
    reset(profile);
  }, [profile]);

  /*
    Validation: this will only be called if handleSubmit is happy to do so,
    ie: the form is valid
  */
  const onSubmit = (data) => {
    postData();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenFailure(false);
  };
  const handleChange = (event) => {
    let update = event.target.value;
    if (
      event.target.name == "activate_pushnotifications" ||
      event.target.name == "activate_emailnotifications"
    ) {
      update = Number(event.target.checked);
    }
    setUser({
      ...profile,
      [event.target.name]: update,
    });
  };
  const handlePassword = ({ oldPassword, newPassword }) => {
    if (oldPassword != "" && newPassword != "") {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) => {
          setOpenSuccess(true);
        })
        .catch((err) => {
          setOpenFailure(true);
        });
    }
  };
  function localStateChange(identifier, value) {
    const update = {
      ...profile,
    };
    switch (identifier) {
      case "uname":
        setUserInfo({ ...update, uname: value });
        break;
      case "urole":
        setUserInfo({ ...update, urole: value });
        break;
      case "uimage":
        setUserInfo({ ...update, uimage: value });
        break;
      default:
        console.log(identifier);
    }
  }
  async function postData() {
    setLoading(true);
    const update = {
      ...profile,
      user_identitykey: userInfo.user_identitykey,
    };
    setUser(update);
    //console.log(update);
    try {
      const apiName = "backendGateway";
      const path = "/users";
      const myInit = {
        // OPTIONAL
        body: update,
        headers: {}, // OPTIONAL
      };
      await API.put(apiName, path, myInit);
      setUserInfo(update);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    localStateChange("uimage", update.user_image);
    localStateChange("urole", update.user_jobrole);
    localStateChange("uname", update.user_displayname);
    setLoading(false);
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={6} xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader
              subheader="Update Profile Information"
              title="Profile"
            />
            <Divider />

            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  {
                    //controller is a wrapper element used for validation, for react hook form
                  }
                  <Controller
                    name="user_displayname"
                    // defaultValue sets the value of child component
                    defaultValue={profile.user_displayname || ""}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Name"
                        variant="outlined"
                        // handleChange must be on the child component to work as normal
                        onChange={handleChange}
                        fullWidth
                        error={errors.user_displayname ? true : false}
                        helperText={`${
                          errors.user_displayname?.type === "required"
                            ? "Name is required"
                            : ""
                        }
                      ${
                        errors.user_displayname?.type === "minLength"
                          ? "Name must be at least 3 characters"
                          : ""
                      }`}
                      />
                    )}
                    rules={{
                      required: true,
                      minLength: 3,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    name="user_jobrole"
                    control={control}
                    defaultValue={profile.user_jobrole || ""}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Role"
                        onChange={handleChange}
                        fullWidth
                        error={errors.user_jobrole ? true : false}
                        helperText={`${
                          errors.user_jobrole?.type === "required"
                            ? "Role is required"
                            : ""
                        }
                      ${
                        errors.user_jobrole?.type === "minLength"
                          ? "Role must be at least 3 characters"
                          : ""
                      }`}
                      />
                    )}
                    rules={{
                      required: true,
                      minLength: 3,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="user_email"
                    onChange={handleChange}
                    disabled
                    value={profile.user_email || ""}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <Controller
                    name="user_city"
                    defaultValue={profile.user_city || ""}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        label="City"
                        error={errors.user_city ? true : false}
                        helperText={`${
                          errors.user_city?.type === "required"
                            ? "City is required"
                            : ""
                        }
                      ${
                        errors.user_city?.type === "minLength"
                          ? "City must be at least 3 characters"
                          : ""
                      }`}
                      />
                    )}
                    rules={{
                      required: true,
                      minLength: 3,
                    }}
                  />
                </Grid> */}
                {/* <Grid item md={6} xs={12}>
                  <Controller
                    name="user_state"
                    defaultValue={profile.user_state || ""}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        label="State"
                        onChange={handleChange}
                        error={errors.user_state ? true : false}
                        helperText={`${
                          errors.user_state?.type === "required"
                            ? "State is required"
                            : ""
                        }
                      ${
                        errors.user_state?.type === "minLength"
                          ? "State must be at least 2 characters"
                          : ""
                      }`}
                      />
                    )}
                    rules={{
                      required: true,
                      minLength: 2,
                    }}
                  />
                </Grid> */}
                {/* <Grid item md={6} xs={12}>
                  <Controller
                    name="user_zip"
                    defaultValue={profile.user_zip || ""}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        label="Zip Code"
                        error={errors.user_zip ? true : false}
                        helperText={`${
                          errors.user_zip?.type === "required"
                            ? "Zip Code is required"
                            : ""
                        }
                      ${
                        errors.user_zip?.type === "minLength"
                          ? "Zip Code must be at least 4 characters"
                          : ""
                      }`}
                      />
                    )}
                    rules={{
                      required: true,
                      minLength: 4,
                    }}
                  />
                </Grid> */}
              </Grid>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Notifications
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="activate_emailnotifications"
                        checked={
                          Boolean(profile.activate_emailnotifications) ||
                          Boolean(0)
                        }
                        onChange={handleChange}
                      />
                    }
                    label="Email"
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              {loading && (
                <div>
                  {" "}
                  <CircularProgress />{" "}
                </div>
              )}
              <Button
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      </Grid>
      <PasswordForm submit={handlePassword} />
      <Grid item lg={12} md={6} xs={12}>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFailure}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            Incorrect Password Options
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
