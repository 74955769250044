import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  Box,
  FormControlLabel,
  Button,
  IconButton,
  Switch,
  Typography,
  Popover,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { HelpOutline } from "@material-ui/icons";
import { uniq } from "lodash";

const MultipleSimpleExplanation = () => {
  return (
    <Box p={1}>
      <Typography variant="subtitle2">Multiple</Typography>
      <Typography variant="body2">
        Funnel filter by states, then distributors, then brands
      </Typography>
      <Typography variant="subtitle2">Single</Typography>
      <Typography variant="body2">
        Select any item and all matching items will be activated
      </Typography>
    </Box>
  );
};

const MegaFilterStates = ({
  allFields,
  activeFields,
  toggleBox,
  mfType,
  simpleMode,
  setSimpleMode,
  enabledList = [],
}) => {
  const [formattedList, setFormattedList] = useState([]);
  const [value, setValue] = useState("");
  const [unitRange, setUnitRange] = useState([0, 20]);
  const [popEl, setPopEl] = useState(null);

  const handlePageChange = (a) => {
    setUnitRange([(a - 1) * 20, a * 20]);
  };

  const handleSearchChange = (e) => {
    setValue(e.target.value);
    const filteredList = allFields.list
      .filter((word) =>
        word.toLowerCase().includes(e.target.value.toLowerCase())
      )
      .sort();
    setFormattedList([...filteredList]);
  };

  const totalPages = Math.ceil(formattedList.length / 20);

  useEffect(() => {
    setFormattedList([...allFields.list].sort());
  }, [allFields.list]);

  const toggleAll = () => {
    if (mfType === "states") {
      if (activeFields.length === 0) {
        toggleBox(activeFields, formattedList, mfType);
      } else {
        toggleBox(activeFields, [], mfType);
      }
    } else {
      const toToggle = uniq(
        enabledList.filter((ea) => !activeFields.includes(ea))
      );
      toggleBox(activeFields, toToggle, mfType);
    }
  };

  const popoverSummon = (event) => setPopEl(event.currentTarget);
  const popoverDismiss = (event) => setPopEl(null);
  const popoverOpen = Boolean(popEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  return (
    <Grid style={{ width: "100%" }}>
      <Grid
        container
        style={{ paddingBottom: "12px" }}
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        {formattedList.slice(unitRange[0], unitRange[1]).map((o, i) => (
          <Grid item xs={6} sm={4} md={3} key={i} style={{ flexGrow: 0 }}>
            <FormControlLabel
              classes={{
                root: "mega-filter-checkbox",
              }}
              control={
                <Checkbox
                  name={o}
                  checked={activeFields.includes(o)}
                  onChange={() => {
                    toggleBox(activeFields, [o], mfType);
                  }}
                  disabled={
                    !simpleMode &&
                    mfType !== "states" &&
                    !enabledList.includes(o)
                  }
                />
              }
              label={o}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "auto",
        }}
      >
        <div>
          <SearchIcon style={{ marginBottom: "-6px" }} />
          <TextField
            size="small"
            id="outlined-basic"
            label="Search"
            value={value}
            variant="outlined"
            onChange={(text) => handleSearchChange(text)}
            style={{
              width: "200px",
              marginBottom: "10px",
              touchAction: "none",
            }}
          />
        </div>

        <Grid
          component="label"
          alignItems="center"
          container
          style={{
            paddingLeft: "1rem",
            width: "14rem",
          }}
        >
          <Grid item>
            <Typography variant="caption">Multiple</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={simpleMode}
              onChange={(event) => {
                setSimpleMode(event.target.checked);
              }}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="caption">Single</Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="contained"
              aria-describedby={id}
              onClick={popoverSummon}
            >
              <HelpOutline />
            </IconButton>
            <Popover
              id={id}
              open={popoverOpen}
              anchorEl={popEl}
              onClose={popoverDismiss}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MultipleSimpleExplanation />
            </Popover>
          </Grid>
        </Grid>
        <Pagination
          style={{
            visibility: totalPages < 1 ? "hidden" : "visible",
          }}
          count={totalPages}
          onChange={(e, page) => handlePageChange(page)}
        />
        <Grid
          style={{
            display: "inline-block",
            visibility: "visible",
            pointerEvents: "auto",
          }}
          item
        >
          <Button
            onClick={toggleAll}
            style={{
              backgroundColor: "#E0E0E0",
              marginLeft: "5px",
              marginBottom: "5px",
            }}
          >
            Toggle All
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MegaFilterStates;
