import Page from "../../../components/Page";
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@material-ui/core";

import { useParams } from "react-router";
import { useEffect, useState } from "react";
import DistributorContactList from "./distributorContactList";
import { useStore } from "../../../stores/StoreContext";
import distributorsAPI from "../../../api/distributors.js";
import Detail from "./detail";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DistributorsDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    if (id) {
      getContacts();
      getDistributorDetail();
    }
  }, [id]);

  const getContacts = async () => {
    setIsLoading(true);
    const data = await distributorsAPI.getDistributorContacts(userInfo.mid, id);
    setIsLoading(false);
    if (data?.results) {
      let _contacts = data.results.map((contact) => {
        const { contact_id, user_id, email, name, phone, title } = contact;
        return {
          contact_id,
          user_id,
          email,
          name,
          phone,
          title,
        };
      });
      store.setDistributorContact(_contacts);
    }
  };

  const getDistributorDetail = async () => {
    store.setDistributorDetails({});
    const data = await distributorsAPI.getDistributorDetails(userInfo.mid, id);
    if (data?.result) {
      store.setDistributorDetails(data.result);
    }
  };

  return (
    <Page className={classes.root} title="Distributors detail">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={5} md={6} xs={12}>
            <Detail />
          </Grid>
          <Grid item lg={7} md={6} xs={12}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Contacts
                </Typography>
                <Box mt={3}>
                  {isLoading && (
                    <div align="center">
                      <CircularProgress />
                    </div>
                  )}
                  {!isLoading && <DistributorContactList />}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default DistributorsDetails;
