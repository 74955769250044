const distributors = {
  distributorsList: [],
  distributorContactList: [],
  distributorDetails: {},
  distributorComparisonDataFile: {},

  setDistributorContact(contacts) {
    this.distributorContactList = contacts;
  },
  setDistributors(distributors) {
    this.distributorsList = distributors;
  },
  setDistributorDetails(detail) {
    this.distributorDetails = detail;
  },
  setDistributorComparisonDataFile(file) {
    this.distributorComparisonDataFile = file;
  },
};

export default distributors;
