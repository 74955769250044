import { useState } from "react";
import {
  Button,
  Select,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import GenericModal from "../../modals/GenericModal";
import regions from "../../../api/regions";
import Delete from "@material-ui/icons/Delete";
import { useEffect } from "react";

const RegionAssociator = ({
  isOpen,
  handleClose,
  getAssociations,
  makeAssociation,
  removeAssociation,
  modalTitle = "Assigned Regions",
  reportAssociationChange = () => {},
}) => {
  const [availableRegions, setAvailableRegions] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState(0);
  useEffect(() => {
    regions
      .getAvailableRegions({})
      .then(({ results }) => setAvailableRegions(results))
      .catch((error) => console.error(error));

    getAssociations()
      .then(({ results }) => setAssociations(results))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    reportAssociationChange(associations);
  }, [associations]);

  const pickState = (event) => {
    const stateId = event.target.value;
    if (!stateId) {
      return console.error("State id is not valid", stateId);
    }
    setSelectedRegionId(stateId);
    makeAssociation({ region_id: stateId })
      .then(({ results }) => {
        console.log(results);
        const matchingRegion = availableRegions.find(
          (reg) => reg.id === stateId
        );
        const insert = {
          ...matchingRegion,
          assoc_id: results.insertId || results[0],
        };
        console.log({ insert });
        if (matchingRegion) {
          setAssociations([...associations, insert]);
        }
      })
      .catch((error) => console.error(error));
  };
  const remove = (association) => {
    const { assoc_id } = association;
    removeAssociation({ assoc_id })
      .then(() =>
        setAssociations(associations.filter((a) => a.assoc_id !== assoc_id))
      )
      .catch((error) => console.error(error));
  };
  return (
    <GenericModal
      title={modalTitle}
      open={isOpen}
      onClose={handleClose}
      showActions={false}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <FormControl style={{ minWidth: "10em" }}>
            <InputLabel>Select Region to Add</InputLabel>
            <Select onChange={pickState} value={selectedRegionId}>
              {availableRegions
                .sort((a, b) => {
                  return a.region_name.localeCompare(b.region_name);
                })
                .map((ar, ind) => (
                  <MenuItem key={ind} value={ar.id}>
                    {ar.region_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          {associations.map((ass, ind) => (
            <div key={ind}>
              {/* {JSON.stringify(ass)} */}
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                mb={1}
              >
                <Typography variant="subtitle2">{ass.region_name}</Typography>
                <Box pl={2}>
                  <Button
                    onClick={() => remove(ass)}
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<Delete />}
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
            </div>
          ))}
        </Box>
      </Box>
    </GenericModal>
  );
};

export default RegionAssociator;
