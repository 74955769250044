import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import Page from "../../../components/Page";
import ListCard from "./ListCard";
import RegionListCard from "./RegionListCard";

import {
  Box,
  Container,
  Button,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import AccountListModal from "./AccountListModal";
import { trackEvent } from "../../../api/analytics";
import useApi from "../../../hooks/useApi";
import listsAPI from "../../../api/lists";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PeopleIcon from "@material-ui/icons/People";
import { useStore } from "../../../stores/StoreContext";
import regionsAPI from "../../../api/regions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const AccountList = observer(() => {
  const getListsApi = useApi(listsAPI.getLists);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [listType, setListType] = React.useState("AUTO");
  const { userInfo, markets, setMarkets } = useStore();
  const [listsByRegion, setListsByRegion] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  let allowList = userInfo.access?.lists;
  const handleChange = (event, newType) => {
    setListType(newType);
  };

  const lists = useMemo(() => {
    let escaped = searchQuery.replace(/([^a-zA-Z0-9])/g, "\\$1");
    const filterRegEx = new RegExp(escaped, "i");
    let allowedLists = [];
    if (userInfo.access) {
      allowedLists = listsByRegion.map((obj) => ({
        ...obj,
        lists: obj.lists.filter((lst) => allowList.includes(lst.list_id)),
      }));
    } else {
      allowedLists = listsByRegion;
    }

    const filteredList = allowedLists
      .map((list) => ({
        ...list,
        lists: list.lists.filter(
          (childList) =>
            childList.type === listType &&
            (searchQuery.length === 0 || filterRegEx.test(childList.list_name))
        ),
      }))
      .filter((list) => list.lists.length > 0);

    const unassociated = filteredList
      .filter((list) => list.unassociated)
      .map((list) => ({
        ...list,
        title: list.lists[0].list_name,
        lists: list.lists.sort((a, b) =>
          a.list_name > b.list_name ? 1 : a.list_name < b.list_name ? -1 : 0
        ),
      }));
    const groups = filteredList
      .filter((region) => region.lists.length > 0 && !region.unassociated)
      .map((list) => ({
        ...list,
        title: list.region_name,
        lists: list.lists.sort((a, b) =>
          a.list_name > b.list_name ? 1 : a.list_name < b.list_name ? -1 : 0
        ),
      }));
    return [
      ...groups.sort((a, b) =>
        a.title > b.title ? 1 : a.title < b.title ? -1 : 0
      ),
      ...unassociated,
    ];
  }, [listType, listsByRegion, searchQuery]);

  const adjustListsByRegion = (list, action) => {
    const { list_id } = list;
    let newLBR = [];
    if (action === "deleted") {
      newLBR = listsByRegion.map((rList) => ({
        ...rList,
        lists: rList.lists.filter((childList) => childList.list_id !== list_id),
      }));
    }
    if (action === "created") {
      newLBR = listsByRegion.map((lbr) => {
        if (lbr.unassociated) {
          return {
            ...lbr,
            lists: [...lbr.lists, list],
          };
        }
        return lbr;
      });
    }
    if (action === "updated") {
      newLBR = listsByRegion.map((lbr) => {
        if (lbr.unassociated) {
          return {
            ...lbr,
            lists: [
              ...lbr.lists.map((changedList) => {
                if (changedList.list_id === list_id) {
                  return list;
                }
                return changedList;
              }),
            ],
          };
        }
        return lbr;
      });
    }
    setListsByRegion(newLBR);
  };

  const classes = useStyles();

  async function getData() {
    getListsApi
      .request({
        uid: userInfo.uid,
        mid: userInfo.mid,
        limit: 1000,
      })
      .then(({ results }) => {
        setMarkets(results);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
    trackEvent(userInfo.uid, "PageView:Lists");
    regionsAPI
      .getAccountListsByRegion({
        manufacturer_id: userInfo.mid,
      })
      .then(({ results }) => {
        setListsByRegion(results);
      })
      .catch((error) => console.error(error));
  }, [refreshCount]);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState({
    country_code: null,
    region_code: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setLoading(true);
    setOpen(false);
    setSelectedValue(value);
    getData();
  };

  return (
    <Page className={classes.root} title="Markets">
      <Container maxWidth={false}>
        <div>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              Create List
            </Button>
            <AccountListModal
              selectedValue={selectedValue}
              open={open}
              allowList={allowList}
              onClose={handleClose}
              refreshCount={refreshCount}
              setRefreshCount={setRefreshCount}
              {...{
                adjustListsByRegion,
              }}
            />
          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search For List"
                    variant="outlined"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>

        <Box mt={3}>
          <div>
            {getListsApi.loading && (
              <div>
                {" "}
                <CircularProgress />{" "}
              </div>
            )}
          </div>
          {markets.length > 0 && (
            <Grid container spacing={3}>
              <Grid item>
                <ToggleButtonGroup
                  size="large"
                  value={listType}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton value="AUTO">
                    <LocalShippingIcon />
                    <Box ml={1}>Generated Lists</Box>
                  </ToggleButton>
                  <ToggleButton value="USER">
                    <PeopleIcon />
                    <Box ml={1}>User Lists</Box>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {/* {JSON.stringify(lists)} */}
              {lists.map((s) => {
                return (
                  s.lists.length > 0 && (
                    <Grid item key={s.region_code} xs={10} lg={12}>
                      {(s.lists.length === 0 || s.unassociated) && (
                        <>
                          {s.lists.map((list) => {
                            return (
                              <ListCard
                                list={list}
                                key={list.list_id}
                                allLists={getListsApi.data}
                                setData={getListsApi.setData}
                                {...{
                                  adjustListsByRegion,
                                }}
                                style={{
                                  marginBottom: s.unassociated ? "1rem" : "0",
                                }}
                              />
                            );
                          })}
                        </>
                      )}
                      {s.lists.length > 0 && !s.unassociated && (
                        <RegionListCard
                          list={{
                            ...(s.lists.length ? s.lists[0] : {}),
                            list_name: `${s.region_name} (${s.lists.length})`,
                          }}
                          expandState={() =>
                            setRegion({
                              country_code: s.country_code,
                              region_code: s.region_code,
                            })
                          }
                          key={s.lists.length ? s.lists[0].list_id : 0}
                          allLists={getListsApi.data}
                        >
                          {s.lists.map((list) => (
                            <ListCard
                              list={list}
                              key={list.list_id}
                              allLists={getListsApi.data}
                              setData={getListsApi.setData}
                            />
                          ))}
                        </RegionListCard>
                      )}
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}
        </Box>
      </Container>
    </Page>
  );
});

export default AccountList;
