import client from "./client";

const getProducts = ({ mid, limit }) => {
  const endpoint = "/products";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getProductsPID = ({ pid, mid, limit }) => {
  const endpoint = "/products/" + pid;
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getProductsAllStates = ({ mid, limit }) => {
  const endpoint = "/products/allstates";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getProductsSku = ({ pid, mid, limit }) => {
  const endpoint = "/products/sku";
  return client.get(endpoint, { pid: pid, mid: mid, limit: limit });
};
const putProductsSku = (product) => {
  const endpoint = "/products/sku";
  return client.put(endpoint, product);
};
const postProductsSku = (product) => {
  const endpoint = "/products/sku";
  return client.post(endpoint, product);
};

export default {
  getProducts,
  getProductsPID,
  getProductsAllStates,
  getProductsSku,
  putProductsSku,
  postProductsSku,
};
