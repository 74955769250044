import client from "./client";

const getTeamProfile = ({ mid, uid, limit }) => {
  const endpoint = "/accounts/team/profile";
  return client.get(endpoint, { mid: mid, uid: uid, limit: limit });
};
const postTeamList = (profile) => {
  const endpoint = "/accounts/team/list";
  return client.post(endpoint, profile);
};

const getTeamCheckin = ({ mid, uid, limit }) => {
  const endpoint = "/accounts/team/checkin";
  return client.get(endpoint, { mid: mid, uid: uid, limit: limit });
};

const getScorecardData = () => {
  const endpoint = "/teams/scorecard";
  return client.get(endpoint);
};

const getTeamPerformance = ({ mid, limit }) => {
  const endpoint = "/teams/manager";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const postTeamTarget = (target) => {
  const endpoint = "/teams/targets";
  return client.post(endpoint, target);
};
const getTeamTarget = ({ mid, uid, limit }) => {
  const endpoint = "/teams/targets";
  return client.get(endpoint, { mid: mid, uid: uid, Limit: limit });
};

export default {
  getTeamProfile,
  postTeamList,
  getTeamCheckin,
  getTeamPerformance,
  postTeamTarget,
  getTeamTarget,
  getScorecardData,
};
