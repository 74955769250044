import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import {
  MapContainer as Map,
  TileLayer,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import { Interval, DateTime } from "luxon";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { observer } from "mobx-react-lite";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import MapToolTip from "../../../views/reports/ChartsView/MapToolTip";
import MapAccountCard from "../../../views/reports/ChartsView/MapAccountCard";
import { useStore } from "../../../stores/StoreContext";
import { forEach } from "lodash";
import { CircularProgress } from "@material-ui/core";
Storage.configure({ level: "protected" });

const STHeatMap = observer(
  ({
    className,
    filterArgs,
    manufacturer,
    accounts = [],
    refresh,
    ...rest
  }) => {
    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(5);
    const [center, setCenter] = useState([39.5, -78.35]);
    const [dataSets, setDataSets] = useState([]);
    const [targetAccount, setTargetAccount] = useState(null);
    const [finalMapMarkers, setFinalMapMarkers] = useState(false);
    const {
      userInfo,
      allStates,
      selectedQuantity,
      quantities,
      dateRange,
      depletionList,
      allDistributorObjectsMinified,
    } = useStore();

    const rangeInterval = useMemo(() => {
      const lowerDate = DateTime.fromJSDate(dateRange[0]);
      const upperDate = DateTime.fromJSDate(dateRange[1]);
      return Interval.fromDateTimes(lowerDate, upperDate);
    }, [dateRange]);

    const measurementUnit = useMemo(() => {
      const measurementUnit = {
        ...quantities.find((q) => q.value === selectedQuantity),
      };
      return measurementUnit;
    }, [quantities, selectedQuantity]);

    const geoReady = (acc) => {
      const all = acc.position;
      return !(
        all.includes(null) ||
        all.includes("") ||
        Math.abs(all[0]) > 90 ||
        Math.abs(all[1]) > 180
      );
    };

    const markers = useMemo(() => {
      const { states, products, brands, distributors, productSold } =
        filterArgs;
      return dataSets.filter((set) => {
        return (
          (states.size === 0 || states.has(set.state)) &&
          (products.size === 0 ||
            (productSold &&
              set.bottles.some((b) => products.has(b.canonical_product_id))) ||
            (!productSold &&
              !set.bottles.some((b) =>
                products.has(b.canonical_product_id)
              ))) &&
          (brands.size === 0 || set.bottles.some((b) => brands.has(b.brand))) &&
          (distributors.size === 0 ||
            set.bottles.some((b) => distributors.has(b.distributor))) &&
          geoReady(set) &&
          set.bottles.some((b) =>
            rangeInterval.contains(DateTime.fromISO(b.DATE))
          )
        );
      });
    }, [rangeInterval, filterArgs, dataSets]);

    useEffect(() => {
      if (markers) {
        if (accounts && accounts.length > 0) {
          setFinalMapMarkers(createMapMarkersForAllAccounts());
        } else setFinalMapMarkers(markers);
      }
    }, [markers]);

    const headerStates =
      filterArgs.states.size >= 6
        ? [`${filterArgs.states.size}/${allStates.length} States`]
        : Array.from(filterArgs.states).toString();

    useEffect(() => {
      async function fetchMarkers() {
        let lines = groupByKey(depletionList, "ACCOUNT_ID");
        let procData = [];
        Object.values(lines).forEach((line) => {
          let m = createMarker(line);
          if (m != null) {
            if (accounts.length === 0) {
              // console.log(JSON.stringify(line))
            }
            procData.push(m);
          }
        });
        setDataSets(procData);
      }

      const markerCooldown = setTimeout(fetchMarkers, 800);
      return () => {
        clearTimeout(markerCooldown);
      };
    }, [userInfo.current_data_hash, depletionList]);

    function arrayToObject(arr, keyField) {
      return arr.reduce((acc, item) => {
        acc[item[keyField]] = item;
        return acc;
      }, {});
    }

    const createMapMarkersForAllAccounts = () => {
      const lookupTable = arrayToObject(markers, "account");
      const tempMapMarkers = [];

      accounts.forEach((account) => {
        const lookedUpAccount = lookupTable[account.account_id];
        if (Object.keys(lookupTable).includes(account.account_id)) {
          tempMapMarkers.push({
            account: account.account_id,
            bottles: lookedUpAccount.bottles,
            brand: lookedUpAccount.brand,
            category: lookedUpAccount.category,
            distributor: lookedUpAccount.distributor,
            position: lookedUpAccount.position,
            state: lookedUpAccount.state,
            store: lookedUpAccount.store,
            total: lookedUpAccount.total,
          });
        } else {
          let mappedBottles = [];

          if (account.bottles && account.bottles.length > 0) {
            mappedBottles = account?.bottles?.map((bottle) => ({
              DATE: Date.now(),
              brand: bottle.product_brand || "UNKNOWN",
              canonical_product_id: bottle.product_id,
              distributor: allDistributorObjectsMinified
                ? allDistributorObjectsMinified[account.distributor_id]?.name ||
                  "UNKNOWN"
                : "UNKNOWN",
              nine_sold: 0,
              product_name: bottle.product_displayname,
              unit_sold: 0,
              bbl: 0,
            }));
          }
          tempMapMarkers.push({
            account: account.account_id,
            bottles: mappedBottles,
            brand:
              account?.bottles && account?.bottles.length
                ? account.bottles[0].product_brand
                : "UNKNOWN",
            category: undefined,
            distributor: allDistributorObjectsMinified
              ? allDistributorObjectsMinified[account.distributor_id]?.name ||
                "UNKNOWN"
              : "UNKNOWN",
            position: [account.account_latitude, account.account_longitude],
            state: account.account_region,
            store: account.account_displayname,
            total: NaN,
          });
        }
      });

      return tempMapMarkers;
    };

    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
    });

    useEffect(() => {
      if (markers.length && map) {
        const markerBox = markers.reduce(
          (acc, cur) => {
            acc[0][0] = Math.min(cur.position[0], acc[0][0] || Infinity);
            acc[0][1] = Math.min(cur.position[1], acc[0][1] || Infinity);
            acc[1][0] = Math.max(cur.position[0], acc[1][0] || -Infinity);
            acc[1][1] = Math.max(cur.position[1], acc[1][1] || -Infinity);
            return acc;
          },
          [
            [null, null],
            [null, null],
          ]
        );
        const bounds = L.latLngBounds(markerBox);
        map.fitBounds(bounds);
      }
    }, [markers, map]);

    useEffect(() => {
      if (!map) return;
      const c = [map.getCenter().lat, map.getCenter().lng];
      const z = map.getZoom();
      map.invalidateSize();
      map.setView(c, z);
    }, [map, refresh]);

    L.Marker.prototype.options.icon = DefaultIcon;
    useEffect(() => {
      if (!map) return;
      setCenter([39.5, -78.35]);
      setZoom(5);
      map.invalidateSize(true);
    }, [map]);

    const groupByKey = (list, key) =>
      list.reduce((acc, obj) => {
        acc[obj[key]] = (acc[obj[key]] || []).concat(obj);
        return acc;
      }, {});

    function createMarker(item) {
      if (item[0].latlong == null) return null;
      let bottles = [];
      let total = 0;
      item.forEach((i) => {
        total += i.nine_litre_sold;
        bottles.push({
          product_name: i.PRODUCT_NAME,
          unit_sold: i.PHYSICAL_SOLD,
          brand: i.BRAND,
          nine_sold: i.NINE_SOLD,
          bbl: i.BBL,
          canonical_product_id: i.PRODUCT_ID,
          distributor: i.DISTRIBUTOR_NAME,
          DATE: i.DATE,
        });
      });

      return {
        account: item[0].ACCOUNT_ID,
        position: item[0].latlong.split(",").map(Number),
        bottles,
        store: item[0].STORENUMBER,
        total,
        state: item[0].STATE,
        category: item[0].category,
        brand: item[0].BRAND,
        distributor: item[0].DISTRIBUTOR_NAME,
      };
    }

    function getRetailColor(d) {
      return d > 250
        ? "#460026"
        : d > 150
        ? "#560042"
        : d > 125
        ? "#650065"
        : d > 100
        ? "#5B0074"
        : d > 75
        ? "#4B0082"
        : d > 50
        ? "#4E2295"
        : d > 20
        ? "#5644A7"
        : d > 10
        ? "#6666B8"
        : d > 5
        ? "#8890C9"
        : "#8890C9";
    }
    function isNumeric(num) {
      return !isNaN(num);
    }
    function getOnPremiseColor(d) {
      return d > 250
        ? "#fafa6e"
        : d > 150
        ? "#b5e877"
        : d > 125
        ? "#77d183"
        : d > 100
        ? "#3fb78d"
        : d > 75
        ? "#009c8f"
        : d > 50
        ? "#007f86"
        : d > 20
        ? "#0b717e"
        : d > 10
        ? "#1c6373"
        : d > 5
        ? "#2a4858"
        : "#2a4858";
    }
    function getRadius(d) {
      return d > 150
        ? 30
        : d > 125
        ? 25
        : d > 100
        ? 20
        : d > 75
        ? 15
        : d > 50
        ? 12
        : d > 20
        ? 10
        : d > 10
        ? 8
        : d > 5
        ? 6
        : 6;
    }

    return (
      <>
        {targetAccount !== null && (
          <MapAccountCard
            account={targetAccount}
            close={() => setTargetAccount(null)}
            measurementUnit={measurementUnit}
          />
        )}
        {finalMapMarkers ? (
          <Map
            whenCreated={(mapInstance) => {
              setMap(mapInstance);
            }}
            center={center}
            zoom={zoom}
            style={{ zIndex: "1" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup>
              {finalMapMarkers.map((item, idx) => (
                <CircleMarker
                  key={idx + item.store}
                  color={
                    isNumeric(item.store)
                      ? getRetailColor(item.total)
                      : getOnPremiseColor(item.total)
                  }
                  center={item.position}
                  radius={getRadius(item.total)}
                  fillOpacity={0.7}
                  stroke={false}
                  eventHandlers={{
                    click: () => {
                      setTargetAccount(item);
                    },
                  }}
                >
                  <Tooltip
                    offset={[-8, -2]}
                    opacity={1}
                    key={idx + item.store}
                    eventHandlers={{ click: () => setTargetAccount(item) }}
                  >
                    <MapToolTip
                      account={item}
                      measurementUnit={measurementUnit}
                    />
                  </Tooltip>
                </CircleMarker>
              ))}
            </MarkerClusterGroup>
          </Map>
        ) : (
          <CircularProgress />
        )}
      </>
    );
  }
);

STHeatMap.propTypes = {
  className: PropTypes.string,
};

export default STHeatMap;
