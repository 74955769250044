import { useEffect, useMemo, useState } from "react";
import { TextField } from "@material-ui/core";
import { optionMaker } from "./Builder";

const FieldOption = ({ option, updateOptions }) => {
  const [optionText, setOptionText] = useState("");

  useEffect(() => {
    if (!optionText.length && option.text.length) setOptionText(option.text);
  }, [option]);

  const textChange = (text) => {
    setOptionText(text);
    updateOptions({
      option,
      text,
    });
  };

  return (
    <TextField
      value={optionText}
      onChange={(event) => textChange(event.target.value)}
    />
  );
};

const FieldOptions = ({ options = [], updateOptions }) => {
  /**
   * this maintains an array of entered options and one blank option
   * the map below treats them all the same
   * on update, empty values are filtered from the options array
   */
  const displayOptions = useMemo(() => {
    return [...options, new optionMaker("")];
  }, [options]);

  return (
    <div>
      {displayOptions.map((o) => (
        <div style={{ display: "block" }} key={o.key}>
          <FieldOption key={o.key} option={o} updateOptions={updateOptions} />
        </div>
      ))}
    </div>
  );
};

export default FieldOptions;
