import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  AppBar,
} from "@material-ui/core";
import DistributorBrandGrid from "./DistributorBrandGrid";
import DistributorBrandMap from "./DistributorBrandMap";
import BrandDistribution from "./BrandDistribution";
import { Button } from "@material-ui/core";
import ExternalBrands from "../externalBrands";

const RegionCompareUI = ({ data, filteredData, io }) => {
  const [activeBrand, setActiveBrand] = useState([]);
  const [externalBrandList, setExternalBrandList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [brandData, setBrandData] = useState([]);
  useEffect(() => {
    if (activeBrand && data.length > 0) {
      setBrandData(data.filter((d) => d.product_brand === activeBrand));
    }
  }, [activeBrand, data, filteredData]);

  useEffect(() => {
    if (
      activeBrand === "" ||
      !filteredData.map((fd) => fd.product_brand).includes(activeBrand)
    ) {
      setActiveBrand([]);
      setBrandData([]);
    }
  }, [filteredData]);

  return (
    <Grid container data-io={io} spacing={2}>
      <Grid item xs={12}>
        <ExternalBrands
          externalBrandList={externalBrandList}
          setExternalBrandList={setExternalBrandList}
        />
      </Grid>
      <Grid item xs={12}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setActiveTab(v)}
          value={activeTab}
        >
          <Tab selected={activeTab === 0} label="Map" />
          <Tab selected={activeTab === 1} label="Grid" />
        </Tabs>
      </Grid>
      {activeTab === 1 && (
        <div style={{ width: "100%" }}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                style={{ flexDirection: "row" }}
                title={"Brands By Distributor"}
              />

              <CardContent>
                <DistributorBrandGrid
                  data={filteredData}
                  {...{
                    setActiveBrand,
                    activeBrand,
                    externalBrandList,
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          {activeBrand.length > 0 && (
            <Grid item xs={12}>
              <Card
                style={{
                  width: "100%",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <CardHeader
                  title={`States by Distributor for ${activeBrand.join(", ")}`}
                />
                <CardContent>
                  <Grid container>
                    {activeBrand.map((ab, i) => (
                      <Grid item xs={6} md={3} key={i}>
                        <BrandDistribution
                          data={data}
                          brand={ab}
                          externalBrandList={externalBrandList}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </div>
      )}
      <Grid
        item
        xs={12}
        style={{
          visibility: activeTab === 0 ? "visible" : "hidden",
        }}
      >
        <DistributorBrandMap
          externalBrandList={externalBrandList}
          data={data}
        />
      </Grid>
    </Grid>
  );
};

export default RegionCompareUI;
