const user = {
  userInfo: {},
  setUserInfo(info) {
    const infoObj = {
      ...info,
      uid: info.user_id,
      uimage: info.user_image,
      uname: info.user_displayname,
      urole: info.user_jobrole,
      tid: info.team_id,
      mid: info.manufacturer_id,
      access: info?.access ? JSON.parse(info?.access) : null,
      current_data_hash: info.current_data_hash,
    };
    this.userInfo = infoObj;
  },
  clearUserInfo() {
    this.userInfo = {};
  },
};

export default user;
