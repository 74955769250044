import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import ResponseModal from "../../../components/forms/v3/form/ResponseModal";
import Respond from "../../../components/forms/v3/form/Respond";
import { Typography, Button, Box } from "@material-ui/core";
import { useStore } from "../../../stores/StoreContext";

const AccountFormModal = (props) => {
  const { userInfo } = useStore();
  const { roles } = userInfo;
  const isAdmin = () => {
    return roles.includes("ADMIN");
  };
  const admin = isAdmin();
  const {
    onClose,
    selectedValue = 0,
    open,
    form,
    close,
    accountId,
    isFormResponse = false,
    allowEdit = true,
    response,
    answers,
    setAnswers,
  } = props;

  const [editFormActive, setEditFormActive] = useState(false);

  return (
    <Dialog
      onClose={close}
      aria-labelledby="product-dialog"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      {isFormResponse ? (
        <>
          {!editFormActive ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 0,
                  justifyContent: "space-between",
                  padding: 20,
                }}
              >
                <Typography variant="h6">{form.form_name}</Typography>
                {admin || form.data.user_id === userInfo.uid ? (
                  <>
                    {allowEdit ? (
                      <Button
                        style={{ border: "1px solid gray" }}
                        onClick={() => setEditFormActive(true)}
                      >
                        Edit
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <ResponseModal
                uuid={selectedValue ? selectedValue : "XOX"}
                account_id={accountId}
                form={form}
                close={close}
              />
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 20,
                }}
              >
                <Typography variant="h6">{form.form_name}</Typography>
                <Button
                  style={{ border: "1px solid gray" }}
                  onClick={() => setEditFormActive(false)}
                >
                  Cancel Edit
                </Button>
              </div>
              <Respond
                uuid={form.form_uuid}
                close={onClose}
                form={form}
                onClose={() => onClose(selectedValue)}
                account_id={accountId}
                response_id={form.response_id}
                answers={answers}
                setAnswers={setAnswers}
              />
            </>
          )}
        </>
      ) : (
        <Respond
          uuid={form.form_uuid}
          close={onClose}
          onClose={() => onClose(selectedValue)}
          account_id={accountId}
          answers={answers}
          setAnswers={setAnswers}
          response={response}
        />
      )}
    </Dialog>
  );
};

AccountFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default AccountFormModal;
