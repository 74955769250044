import {
  Button,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Select,
  makeStyles,
  MenuItem,
  Card,
  CardContent,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
const useStyles = makeStyles((theme) => ({
  psw: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginBottom: "0.5em",
      "&.hideMobile": {
        display: "none",
      },
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0.5em 0.5em 0",
    },
  },
}));

// default to first

const Presets = ({
  country,
  region,
  prettyRegion,
  presetId,
  preset,
  presetOptions,
  queryBuilder,
}) => {
  const classes = useStyles();
  const store = useStore();
  const navigate = useNavigate();
  const { regions } = store;
  const {
    userInfo: { user_id, manufacturer_id },
  } = store;
  const currentOption = presetOptions.find((p) => p.urlString === preset);

  const dropDownChange = (event) => {
    const us = event.target.value;
    const matchingPreset = presetOptions.find((p) => p.urlString === us);
    if (matchingPreset) {
      navigate({
        pathname: `/app/depletions/`,
        search: queryBuilder(matchingPreset),
      });
    }
  };

  return (
    <Card className="preset-switcher" style={{ margin: "1rem 0" }}>
      <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
              <Select
                value={
                  // presetOptions.find((po) => preset === po.urlString).urlString
                  preset
                    ? presetOptions.find((po) => preset === po.urlString)
                        .urlString
                    : ""
                }
                onChange={dropDownChange}
                label="Report View"
              >
                {presetOptions
                  .sort((a, b) =>
                    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                  )
                  .map((po, i) => (
                    <MenuItem value={po.urlString} key={i}>
                      {po.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <div>
              Showing {currentOption?.name} Report for {prettyRegion()}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Presets;
