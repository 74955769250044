import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Resizer from "react-image-file-resizer";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { Storage, API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { useForm, Controller } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles(() => ({
  root: {},
}));

const drinkCategories = [
  "AMERICAN WHISKEY",
  "ABSINTHE",
  "AMARO",
  "APERITIF",
  "AQUAVIT",
  "ARMAGNAC",
  "BACANORA",
  "BOURBON",
  "BRANDY",
  "CACHACA",
  "GIN",
  "IRISH WHISKEY",
  "LIQUER",
  "LIQUEUR",
  "MEZCAL",
  "OTHER",
  "RTD",
  "RUM",
  "SOTOL",
  "SPIRIT",
  "TEQUILA",
  "VERMOUTH",
  "VODKA",
  "WHISKEY",
  "WINE",
];

const ProductsDetails = ({ className, item, setItem, onClose, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const store = useStore();
  const { userInfo } = store;
  const states = [
    { state: "Alabama", code: "AL" },
    { state: "Alaska", code: "AK" },
    { state: "Arizona", code: "AZ" },
    { state: "Arkansas", code: "AR" },
    { state: "California", code: "CA" },
    { state: "Colorado", code: "CO" },
    { state: "Connecticut", code: "CT" },
    { state: "Washington DC", code: "DC" },
    { state: "Delaware", code: "DE" },
    { state: "Florida", code: "FL" },
    { state: "Georgia", code: "GA" },
    { state: "Hawaii", code: "HI" },
    { state: "Idaho", code: "ID" },
    { state: "Illinois", code: "IL" },
    { state: "Indiana", code: "IN" },
    { state: "Iowa", code: "IA" },
    { state: "Kansas", code: "KS" },
    { state: "Kentucky", code: "KY" },
    { state: "Louisiana", code: "LA" },
    { state: "Maine", code: "ME" },
    { state: "Maryland", code: "MD" },
    { state: "Massachusetts", code: "MA" },
    { state: "Michigan", code: "MI" },
    { state: "Minnesota", code: "MN" },
    { state: "Mississippi", code: "MS" },
    { state: "Missouri", code: "MO" },
    { state: "Montana", code: "MT" },
    { state: "Nebraska", code: "NE" },
    { state: "Nevada", code: "NV" },
    { state: "New Hampshire", code: "NH" },
    { state: "New Jersey", code: "NJ" },
    { state: "New Mexico", code: "NM" },
    { state: "New York", code: "NY" },
    { state: "North Carolina", code: "NC" },
    { state: "North Dakota", code: "ND" },
    { state: "Ohio", code: "OH" },
    { state: "Oklahoma", code: "OK" },
    { state: "Oregon", code: "OR" },
    { state: "Pennsylvania", code: "PA" },
    { state: "Rhode Island", code: "RI" },
    { state: "South Carolina", code: "SC" },
    { state: "South Dakota", code: "SD" },
    { state: "Tennessee", code: "TN" },
    { state: "Texas", code: "TX" },
    { state: "Utah", code: "UT" },
    { state: "Vermont", code: "VT" },
    { state: "Virginia", code: "VA" },
    { state: "Washington", code: "WA" },
    { state: "West Virginia", code: "WV" },
    { state: "Wisconsin", code: "WI" },
    { state: "Wyoming", code: "WY" },
  ];
  const [values, setValues] = useState(
    item || {
      image_scr: "",
      url: "",
      product_displayname: "",
      product_size: "",
      product_image: "",
      product_imagekey: userInfo.user_identitykey,
      product_id: "",
      product_description: "",
      tenant_id: "",
      state_skus: [],
      product_category: "",
      manufacturer_id: userInfo.mid,
      product_manufacturer_internal: "N/A",
      product_proof: "",
      product_retail_unit: "",
      product_physical_case: "",
      product_unit_size: "",
    }
  );

  async function getSKUData() {
    if (values.product_id != "") {
      try {
        const data = await API.get("backendGateway", "/products/sku", {
          queryStringParameters: {
            pid: item.product_id,
            mid: userInfo.mid,
            limit: "20",
          },
        });
        //console.log("data from Lambda REST API: ", data);
        setLineItems(data.results);
        setValues({
          ...values,
          state_skus: data.results,
        });
      } catch (err) {
        console.log("error fetching data..", err);
      }
    }
  }

  const options = states.map((option) => {
    const firstLetter = option.state[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const [items, setLineItems] = useState([]);

  const [skuError, setSkuError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const changeHandler = (event) => {
    const filename = uuid();
    setValues({
      ...values,
      product_image: filename,
      product_imagekey: userInfo.user_identitykey,
      image_scr: awsS3Image(filename, userInfo.user_identitykey, "0x110"),
    });

    setFile(event.target.files[0]);
    setFileName(filename);
    setPreview(true);
    setPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };
  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "PNG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  async function handleSubmission(file) {
    if (!file) {
      return console.log("No file to handle", { file });
    }
    const image = await resizeFile(file);
    const resizedFile = dataURIToBlob(image);
    try {
      await Storage.put(fileName, resizedFile, {
        contentType: "image/*", // contentType is optional
        level: "protected",
      });
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
  }

  async function handleSave(payload) {
    if (items.length === 0) {
      setSkuError(true);
      return;
    } else {
      setSkuError(false);
    }

    const mergedPayload = {
      ...values,
      ...payload,
    };
    //console.log({ mergedPayload });
    setLoading(true);
    if (preview && file) {
      handleSubmission(file);
      const tempUrl = URL.createObjectURL(file);
    } else if (preview && !file) {
      console.error("Bad file storage attempt ", { file });
    }
    if (typeof setItem != "undefined") {
      if (preview && file) {
        const tempUrl = URL.createObjectURL(file);
        const data = {
          tempImage: tempUrl,
          ...mergedPayload,
        };
        setItem(data);
      } else {
        setItem(mergedPayload);
      }
    }
    try {
      const apiName = "backendGateway";
      const path = "/products/sku";
      const myInit = {
        body: mergedPayload,
        headers: {},
      };
      if (values.product_id === "") {
        await API.post(apiName, path, myInit);
      } else {
        await API.put(apiName, path, myInit);
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
    onClose();
  }

  useEffect(() => {
    getSKUData();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit(handleSave)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="Add/Edit Details" title="Enter Details" />
        <CardContent>
          {preview && <img src={previewUrl} width="100px" height="180px" />}
          {!preview && values.product_image !== null && (
            <img
              src={values.image_scr}
              style={{
                width: "140px",
                height: "190px",
                margin: "0px",
              }}
            />
          )}
          <Box display="flex" alignItems="center" p={2}>
            <Button color="primary" variant="contained" component="label">
              Upload picture
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={changeHandler}
              />
            </Button>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Controller
                name="product_displayname"
                defaultValue={values.product_displayname}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Name"
                    required
                    variant="outlined"
                    error={errors.product_displayname ? true : false}
                    helperText={`${
                      errors.product_displayname?.type === "required"
                        ? "Product name is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="product_unit_size"
                defaultValue={values.product_unit_size}
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    return [
                      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                    ].every((pattern) => pattern.test(value));
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    variant="outlined"
                    label="Unit size"
                    InputProps={{
                      // min: 0,
                      endAdornment: (
                        <InputAdornment position="end">ml</InputAdornment>
                      ),
                    }}
                    error={errors.product_unit_size ? true : false}
                    helperText={`${
                      errors.product_unit_size?.type === "required"
                        ? "Unit size is required"
                        : ""
                    }
                      ${
                        errors.product_unit_size?.type === "validate"
                          ? "Invalid unit size"
                          : ""
                      }
                      `}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="product_retail_unit"
                control={control}
                defaultValue={values.product_retail_unit}
                rules={{
                  required: true,
                  validate: (value) => {
                    return [
                      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                    ].every((pattern) => pattern.test(value));
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Retail Unit"
                    required
                    variant="outlined"
                    error={errors.product_retail_unit ? true : false}
                    InputProps={{
                      min: 0,
                      endAdornment: (
                        <InputAdornment position="end">Btl/Pack</InputAdornment>
                      ),
                    }}
                    helperText={`${
                      errors.product_retail_unit?.type === "required"
                        ? "Proof is required"
                        : ""
                    } ${
                      errors.product_retail_unit?.type === "validate"
                        ? "# of units in a purchase"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                name="product_physical_case"
                control={control}
                defaultValue={values.product_physical_case}
                rules={{
                  required: true,
                  validate: (value) => {
                    return [
                      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                    ].every((pattern) => pattern.test(value));
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Physical Case"
                    required
                    variant="outlined"
                    error={errors.product_physical_case ? true : false}
                    InputProps={{
                      min: 0,
                      endAdornment: (
                        <InputAdornment position="end">In Case</InputAdornment>
                      ),
                    }}
                    helperText={`${
                      errors.product_physical_case?.type === "required"
                        ? "Physical is required"
                        : ""
                    }${
                      errors.product_physical_case?.type === "validate"
                        ? "# of units in a case"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl
                fullWidth
                error={errors.product_category ? true : false}
              >
                <InputLabel>Category</InputLabel>
                <Controller
                  control={control}
                  name="product_category"
                  defaultValue={values.product_category}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      labelId="Category"
                      id="category"
                      name="product_category"
                    >
                      {drinkCategories.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}

                      {/* <MenuItem value={"AMERICAN WHISKEY"}>AMERICAN WHISKEYen</MenuItem>
                <MenuItem value={"OTHER WHISKEY"}>OTHER WHISKEY</MenuItem> */}
                    </Select>
                  )}
                />
                <FormHelperText>
                  {errors.product_category?.type === "required"
                    ? "Category is required"
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                fullWidth
                error={errors.product_description ? true : false}
              >
                {/* <InputLabel htmlFor="size">Size2</InputLabel> */}

                <Controller
                  control={control}
                  name="product_description"
                  defaultValue={values.product_description}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="description"
                      label="Description"
                      name="product_description"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* <Grid item md={12} lg={12} xs={12}>
              {skuError && (
                <Typography variant="colorError" component="h6">
                  Please add at least one SKU
                </Typography>
              )}
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">State</TableCell>
                    <TableCell align="left">SKU</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((order, i) => (
                    <TableRow key={order.distributor_sku + i}>
                      <TableCell align="left">{order.state}</TableCell>
                      <TableCell align="left">
                        {order.distributor_sku}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid> */}
            {/* <Grid item md={6} lg={6} xs={6}>
              <Autocomplete
                id="grouped-demo"
                onChange={handleItem}
                name="state_name"
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                getOptionLabel={(option) => option.state}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    name="state_name"
                    label="Add State"
                    variant="outlined"
                  />
                )}
              />
              {isStateNameError && (
                <Typography variant="colorError" component="h6">
                  Invalid state name
                </Typography>
              )}
            </Grid>
            <Grid item md={3} lg={3} xs={3}>
              <TextField
                fullWidth
                label="Add SKU"
                name="sku"
                required
                onChange={handleSKU}
                value={values.quantity}
                variant="outlined"
              />
              {isSkuNameError && (
                <Typography variant="colorError" component="h6">
                  Invalid sku
                </Typography>
              )}
            </Grid>

            <Grid item md={2} lg={2} xs={2}>
              <Button color="primary" variant="contained" onClick={handleOrder}>
                Add
              </Button>
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProductsDetails.propTypes = {
  className: PropTypes.string,
};

export default ProductsDetails;
