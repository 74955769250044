import { useState, useEffect } from "react";
import { FormControl, FormLabel, TextField } from "@material-ui/core";
import StLabel from "./StLabel";
const StTextArea = ({
  required,
  label,
  answer,
  error,
  helperText,
  changeAnswer = () => {},
}) => {
  const [val, setVal] = useState(answer.value);
  const onChange = (e) => {
    setVal(e.target.value);
    changeAnswer(e.target.value);
  };
  useEffect(() => {
    if (answer.value !== val) {
      setVal(answer.value);
    }
  }, [answer, val]);

  const [active, setActive] = useState(false);

  return (
    <FormControl fullWidth>
      <StLabel required={required} text={label} error={error} active={active} />
      <TextField
        variant="outlined"
        multiline
        size="medium"
        value={val}
        minRows={2}
        required={required}
        onChange={onChange}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        {...{
          error,
          helperText,
        }}
      />
    </FormControl>
  );
};

export default StTextArea;
