import React from "react";
import { useState, useMemo } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { Storage } from "aws-amplify";
import "react-pivottable/pivottable.css";
import { cloneDeep } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Parser } from "json2csv";
import { dataFilter } from "./BakedPresetOptions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { PresetOptions } from "./BakedPresetOptions";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {
  regionProductReducer,
  regionBrandReducer,
  accountProductReducer,
  accountBrandReducer,
  brandTotals,
  productTotals,
  brandAccountsSold,
  productAccountsSold,
  distributorBrandTotals,
  distributorProductTotals,
  distributorTotals,
  accountReorders,
} from "../../../helpers/data/reportsReducers";
import { Pagination } from "@material-ui/lab";
import { DateTime } from "luxon";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exportTypesNotRecommendedPDF = [
  "account_product",
  "account_brand",
  "brand_accounts_sold",
  "brand_totals",
  "product_accounts_sold",
  "product_totals",
  "region_brand",
  "region_product",
];
const useStyles = {
  root: {
    width: "100%",
    padding: "20px",
  },
  datagrid: {
    backgroundColor: "#ffffff",
  },
};

const dateRangeChanged = (arr1, arr2) => {
  if (arr1[0] !== arr2[0]) {
    return true;
  }
  if (arr1[1] !== arr2[1]) {
    return true;
  }
  return false;
};

const reducerMatch = {
  region_product: regionProductReducer,
  region_brand: regionBrandReducer,
  account_product: accountProductReducer,
  account_brand: accountBrandReducer,
  product_totals: productTotals,
  brand_totals: brandTotals,
  brand_accounts_sold: brandAccountsSold,
  product_accounts_sold: productAccountsSold,
  distributor_brand_totals: distributorBrandTotals,
  distributor_product_totals: distributorProductTotals,
  distributor_totals: distributorTotals,
  account_reorders: accountReorders,
};

const qsArrayHasChanged = (oldArray, newArray) => {
  if (!oldArray) return false;
  if (oldArray.length !== newArray.length) return true;
  if (JSON.stringify(oldArray) !== JSON.stringify(newArray)) return true;
  return false;
};

const OrderList = ({ orders, quantity }) => {
  const days = useMemo(() => [...new Set(orders.map((o) => o.DATE))], [orders]);
  const groupOrdersByDay = (orderDay) => {
    return orders.filter((o) => o.DATE === orderDay);
  };
  const [order, setOrder] = useState({
    date: days[days.length - 1],
    orders: groupOrdersByDay(days[days.length - 1]),
  });
  const changeOrder = (event, value) => {
    setOrder({
      date: days[value - 1],
      orders: groupOrdersByDay(days[value - 1]),
    });
  };
  const niceDate = (date) =>
    DateTime.fromISO(date.replace(/\//g, "-")).toLocaleString(
      DateTime.DATE_FULL
    );
  return (
    <Card>
      <CardContent>
        <Box p={1}>
          <Typography variant="h5">{niceDate(order.date)}</Typography>
          {order.orders.map((o, i) => (
            <div key={i}>
              <Typography variant="body1">
                {o.PRODUCTNAME} - {o[quantity]}
              </Typography>
            </div>
          ))}
        </Box>
        <Pagination
          count={days.length}
          defaultPage={days.length}
          onChange={changeOrder}
        />
      </CardContent>
    </Card>
  );
};

class SalePivotTable extends React.Component {
  constructor(props) {
    super(props);
    const matchingSortmodel = PresetOptions.find(
      (po) => po.urlString === props.preset
    );
    this.state = {
      defaultDays: 30,
      loader: true,
      filtered: [],
      localPivot: {
        list: [],
        columns: [],
      },
      dropDowns: [],
      data: [],
      selectedFromDate: props.dateRange[0],
      selectedToDate: props.dateRange[1],
      cooldown: null,
      showAlert: false,
      premise: "ALL",
      monthOptions: [1, 3, 6, 12],
      exportFunction: () => {},
      exportData: [],
      productPositive: true,
      sortModel: matchingSortmodel
        ? matchingSortmodel.sortModel
        : [
            {
              field: "REGION",
              sort: "asc",
            },
          ],
      filterModel: {
        items: [],
        andOr: "and",
      },
      modalOpen: false,
      notReccomendedModalOpen: false,
    };
    //this.state = props;
    this.data = [];
    this.apiRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
    window.addEventListener("exportactionCSV", () => {
      this.setState({ exportFunction: this.exportDataCSV }, () => {
        this.state.exportFunction("CSV");
      });
    });
    window.addEventListener("exportactionXLSX", () => {
      this.setState({ exportFunction: this.exportDataXLSX }, () => {
        this.state.exportFunction("XLSX");
      });
    });
    window.addEventListener("exportactionPDF", () => {
      this.setState({ exportFunction: this.exportDataPDF }, () => {
        this.state.exportFunction("PDF");
      });
    });
  }

  componentDidUpdate(oldProps, oldState) {
    if (dateRangeChanged(oldProps.dateRange, this.props.dateRange)) {
      this.setMonthRange(this.props.dateRange);
    }

    if (
      oldProps.preset !== this.props.preset ||
      oldProps.quantity !== this.props.quantity ||
      oldProps.productSold !== this.props.productSold ||
      oldProps.productJoiner !== this.props.productJoiner ||
      oldProps.showMonths !== this.props.showMonths ||
      oldProps.showComparison !== this.props.showComparison
    ) {
      if (
        !this.props.preset.startsWith("account") &&
        !this.state.productPositive
      ) {
        this.setState({
          productPositive: true,
        });
      }
      const matchingSortmodel = PresetOptions.find(
        (po) => po.urlString === this.props.preset
      );
      if (this.props.preset) {
        this.setState({
          sortModel: matchingSortmodel.sortModel,
        });
        this.getData();
      }
    }
    if (
      // TODO: replace with function that returns boolean
      (oldProps.dateRange[0] &&
        oldProps.dateRange[1] &&
        (oldProps.dateRange[0] !== this.props.dateRange[0] ||
          oldProps.dateRange[1] !== this.props.dateRange[1])) ||
      oldProps.quantity !== this.props.quantity ||
      oldProps.showMonths !== this.props.showMonths ||
      oldProps.showComparison !== this.props.showComparison ||
      oldState.premise !== this.state.premise ||
      qsArrayHasChanged(oldProps.selectedBrands, this.props.selectedBrands) ||
      qsArrayHasChanged(
        oldProps.selectedDistributors,
        this.props.selectedDistributors
      ) ||
      qsArrayHasChanged(
        oldProps.filteringProducts,
        this.props.filteringProducts
      ) ||
      qsArrayHasChanged(oldProps.premiseTypes, this.props.premiseTypes)
    ) {
      this.getData();
    }
  }

  getFilterArgs(results) {
    const filterProductStage = this.props.preset.startsWith("account")
      ? "after"
      : "before";
    const {
      regions,
      selectedBrands: brands,
      selectedDistributors: distributors,
      filteringProducts: products,
      premiseTypes,
      quantity,
      dateRange,
      productSold: productPositive,
      showMonths,
      showComparison,
    } = this.props;
    return {
      results,
      dateRange,
      regions,
      brands,
      distributors,
      products,
      productPositive,
      premiseTypes,
      quantity,
      filterProductStage,
      showMonths,
      showComparison,
    };
  }

  handleCallbackFilter = (filter) => {
    if (filter[0].value === "SHOWALL") {
      if (filter[0].col === "PRODUCTNAME") {
        this.setState({
          filterColProduct: [],
        });
      } else {
        this.setState({
          filterColBrand: [],
        });
      }
    } else {
      if (filter[0].col === "PRODUCTNAME") {
        this.setState({
          filterColProduct: [...filter],
        });
      } else {
        this.setState({
          filterColBrand: [...filter],
        });
      }
    }
  };
  changeDisplay = (event) => {
    let quantity = event.target.value;
    this.setState({
      quantity,
    });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  getData = async () => {
    this.setState({ loader: true });
    try {
      let results = this.props.depletionList;
      let filtered = dataFilter(this.getFilterArgs(results));
      this.setState({
        data: filtered,
        localPivot: reducerMatch[this.props.preset](
          filtered,
          this.props.quantity,
          {
            productJoiner: this.props.productJoiner,
            productSold: this.props.productSold,
            products: this.props.filteringProducts,
            showMonths: this.props.showMonths,
            showComparison: this.props.showComparison,
          }
        ),
      });
      const productsToReport = {};
      results.forEach((r) => (productsToReport[r.PRODUCTNAME] = r));
      const productsArray = Object.keys(productsToReport).map(
        (pk) => productsToReport[pk]
      );
      this.props.reportProducts(productsArray);
      this.setState({ loader: false });
    } catch (err) {
      console.log("error fetching data..", err);
      this.setState({ loader: false });
    }
  };

  setMonthRange = (range) => {
    this.setState({
      selectedFromDate: range[0],
      selectedToDate: range[1],
    });
  };

  getMonths = () => {
    this.setMonthRange(this.props.dateRange);
  };

  dgFilterFuncs = {
    contains(string, comp) {
      return string.toLowerCase().includes(comp.toLowerCase());
    },
    startsWith(string, comp) {
      return string.toLowerCase().startsWith(comp.toLowerCase());
    },
    endsWith(string, comp) {
      return string.toLowerCase().endsWith(comp.toLowerCase());
    },
    equals(string, comp) {
      return string.toLowerCase() === comp.toLowerCase();
    },
    isEmpty(string) {
      return typeof string === "undefined" || string.toString() === "";
    },
    isNotEmpty(string) {
      return string.toString().length > 0;
    },
    isAnyOf(string, comp) {
      return comp.map((c) => c.toLowerCase()).includes(string.toLowerCase());
    },
  };

  formatForExport = (data, pre, boolean = true) => {
    const filteredRowsMap = this.apiRef.current.getVisibleRowModels();
    const filteredRowsArray = Array.from(
      filteredRowsMap,
      ([name, value]) => value
    );

    let d = cloneDeep(filteredRowsArray);

    const amendOrdersRow = (row) => {
      const orderDays = [...new Set(row.orders.map((o) => o.DATE))].sort();
      const orderCount = orderDays.length;
      const last_order = orderDays[0];
      const last_order_age = Math.abs(
        Math.round(
          DateTime.fromISO(last_order.replace(/\//g, "-"))
            .diffNow("days")
            .as("days")
        )
      );
      return {
        ...row,
        orders: orderCount,
        last_order,
        last_order_age,
      };
    };
    const colNames = this.state.localPivot.columns.map((c) => c.field);
    if (colNames.includes("STORENUMBER") && boolean) {
      colNames.push("STOREADDRESS", "CITY", "REGION", "ZIP");
    }
    //only 27
    const internalIdSet = filteredRowsArray.find((x) => x.INTERNAL_PRODUCT_ID);
    if (
      internalIdSet &&
      colNames.includes("PRODUCTNAME") &&
      this.props.userInfo?.mid === 27
    )
      colNames.push("INTERNAL_PRODUCT_ID");

    if (colNames.includes("total") && boolean) {
      if (this.props.showComparison) {
        this.props.userInfo?.mid === 614
          ? colNames.push("UNIT_SOLD_PREV", "PHYSICAL_SOLD_PREV", "BBL_PREV")
          : colNames.push(
              "UNIT_SOLD_PREV",
              "PHYSICAL_SOLD_PREV",
              "NINE_SOLD_PREV"
            );
      }
      this.props.userInfo?.mid === 614
        ? colNames.push("UNIT_SOLD", "PHYSICAL_SOLD", "BBL")
        : colNames.push("UNIT_SOLD", "PHYSICAL_SOLD", "NINE_SOLD");
    }

    const cleanNumber = (val) => {
      if (typeof val === "string" || !val) {
        return val;
      }
      // return an integer unchanged
      if (Number.isInteger(val)) {
        return val;
      }
      // return a float capped to 2 decimal points
      if (typeof val === "number") {
        return parseFloat(val.toFixed(2));
      }
      // if it's a Set, return size of Set
      if (val && val.size) {
        return val.size;
      }
      // return non-numeric unchanged
      return val;
    };

    d = d.map((dr) => {
      let row = {};
      colNames.forEach((col) => {
        row[col] = cleanNumber(dr[col]);
      });
      if (colNames.includes("orders")) {
        row = amendOrdersRow(row);
      }
      return row;
    });
    return d;
  };

  reportNotReady = () => {
    alert("Can not export until data has finished loading");
    window.dispatchEvent(new Event("exportComplete"));
  };

  exportDataXLSX = async () => {
    if (!this.apiRef.current) {
      return this.reportNotReady();
    }
    const data = this.formatForExport(
      this.state.localPivot.list,
      this.props.preset
    );
    if (data.length === 0) {
      this.setState({
        modalOpen: true,
      });
      return;
    }

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileContent = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileExtension = "xlsx";

    const downloadLink = document.createElement("a");
    const url = URL.createObjectURL(fileContent);
    downloadLink.href = url;
    downloadLink.download = `report_${this.props.preset}.${fileExtension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Dispatch the "exportComplete" event
    window.dispatchEvent(new Event("exportComplete"));
  };

  exportDataCSV = async () => {
    if (!this.apiRef.current) {
      return this.reportNotReady();
    }
    const data = this.formatForExport(
      this.state.localPivot.list,
      this.props.preset
    );
    if (data.length === 0) {
      this.setState({
        modalOpen: true,
      });
      return;
    }
    const parser = new Parser();
    const csv = parser.parse(data);
    const blob = new Blob(["\ufeff", csv]);
    const url = URL.createObjectURL(blob);

    fetch(url)
      .then((response) => {
        // Trigger the download
        response.blob().then((blob) => {
          const downloadUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = downloadUrl;
          downloadLink.download = `report_${this.props.preset}.csv`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      })
      .finally(() => {
        // Dispatch the "exportComplete" event after the download is finished
        setTimeout(() => {
          window.dispatchEvent(new Event("exportComplete"));
        }, 0);
      });
  };

  exportDataPDF = async () => {
    if (!this.apiRef.current) {
      return this.reportNotReady();
    }
    if (exportTypesNotRecommendedPDF.includes(this.props.preset)) {
      this.setState({
        notReccomendedModalOpen: true,
      });
    } else {
      this.accepted();
    }
  };

  accepted = async () => {
    if (!this.apiRef.current) {
      return this.reportNotReady();
    }
    const data = this.formatForExport(
      this.state.localPivot.list,
      this.props.preset,
      false
    );
    if (data.length === 0) {
      this.setState({
        modalOpen: true,
      });
      return;
    }

    const pageSize = {
      width: 842,
      height: 595,
    };

    const maxColumnsPerPage = 6; // Maximum number of columns per page
    const maxRowsPerPage = 8; // Maximum number of rows per page
    const imageUrl = "/static/images/logos/logo.png";

    const imageDataUrl = await this.getImageDataUrl(imageUrl);

    const documentDefinition = {
      pageSize: pageSize,
      content: [{ text: this.props.preset, style: "header" }, { text: "\n" }],
      footer: {
        columns: [
          { image: imageDataUrl, width: 80, margin: [20, 0, 0, 0] },
          {
            text: window.location.href,
            alignment: "center",
            fontSize: 8,
            color: "#0C45A6",
            margin: [0, 15, 0, 0],
          },
        ],
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          color: "#0C45A6",
        },
      },
    };

    const headers = Object.keys(data[0]);

    if (headers.length <= maxColumnsPerPage) {
      // Case: Less than or equal to maxColumnsPerPage columns
      const content = [];
      const headerRow = headers.map((header) => ({
        text: header,
        bold: true,
      }));
      headerRow.unshift({ text: "Row #" }); // Added index column at the beginning
      content.push(headerRow);

      data.forEach((row, index) => {
        const rowData = headers.map((header) => {
          const cellValue = row[header] || ""; // Handle empty cells
          return { text: cellValue };
        });

        rowData.unshift({ text: (index + 1).toString() }); // Added index column at the beginning
        content.push(rowData);
      });

      const table = {
        table: {
          headerRows: 1,
          widths: Array(headers.length + 1).fill("*"), // Update the widths to include the index column
          body: content,
        },
      };

      documentDefinition.content.push(table);
    } else {
      // Case: More than maxColumnsPerPage columns
      let remainingData = data;
      let rowIndex = 1; // Initialize rowIndex

      while (remainingData.length > 0) {
        const currentPageData = remainingData.slice(0, maxRowsPerPage);
        remainingData = remainingData.slice(maxRowsPerPage);

        const headerGroups = [];
        let currentGroup = [];

        headers.forEach((header) => {
          if (currentGroup.length === maxColumnsPerPage) {
            headerGroups.push(currentGroup);
            currentGroup = [];
          }
          currentGroup.push(header);
        });

        headerGroups.push(currentGroup);

        const tableContent = []; // Accumulate table content

        headerGroups.forEach((group, groupIndex) => {
          const content = [];
          const groupHeaderRow = group.map((header) => ({
            text: header,
            bold: true,
          }));
          groupHeaderRow.unshift({ text: "Row #" }); // Added index column at the beginning
          content.push(groupHeaderRow);

          currentPageData.forEach((row, dataIndex) => {
            const rowData = group.map((header) => {
              const cellValue = row[header] || ""; // Handle empty cells
              return { text: cellValue };
            });

            // Calculate the index for the row
            const rowPageIndex = rowIndex + dataIndex;

            rowData.unshift({ text: rowPageIndex.toString() }); // Added index column at the beginning
            content.push(rowData);
          });

          const table = {
            table: {
              headerRows: 1,
              widths: Array(group.length + 1).fill("*"), // Update the widths to include the index column
              body: content,
            },
          };

          tableContent.push(table);
        });

        documentDefinition.content.push(...tableContent); // Add the accumulated table content to the document definition

        // Insert the separator after the remaining data and before the next group of original data rows
        if (remainingData.length > 0) {
          documentDefinition.content.push({ text: "\f" });
          documentDefinition.content.push({ text: "\f" });
        }
        rowIndex += maxRowsPerPage; // Increment the rowIndex by the number of rows per page
      }
    }

    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

    // Download the PDF using pdfmake's download method
    pdfDocGenerator.download(`report_${this.props.preset}.pdf`, () => {
      // Dispatch the "exportComplete" event after the download is finished
      window.dispatchEvent(new Event("exportComplete"));
    });
  };

  notAccepted = () => {
    window.dispatchEvent(new Event("exportComplete"));
  };

  getImageDataUrl = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  searchData = async () => {
    this.setState({ showAlert: false });
    const date1 = this.state.selectedFromDate;
    const date2 = this.state.selectedToDate;
    const diff = date2.diff(date1, ["days"]);
    if (diff.days < 0 || diff.days > 185) {
      this.setState({ showAlert: true });
    } else {
      this.getData();
    }
  };

  handleToDateChange = (luxonDate) => {
    const dateString = luxonDate;
    this.setState({ selectedToDate: dateString });
  };
  handleFromDateChange = (luxonDate) => {
    const dateString = luxonDate;
    this.setState({ selectedFromDate: dateString });
  };

  premiseToggle = (event) => {
    let premise = event.target.value;
    this.setState({
      premise,
    });
  };

  dgFilterChange = ({ items, linkOperator }) => {
    const newModel = {
      andOr: linkOperator,
      items,
    };
    this.setState({
      filterModel: newModel,
    });
    console.log(newModel);
  };

  onClickHandler = (option) => {
    if (option === "PDF") {
      this.accepted();
    } else if (option === "XLSX") {
      this.exportDataXLSX();
    } else if (option === "CSV") {
      this.exportDataCSV();
    } else if (option === "CLOSE") {
      this.setState({ notReccomendedModalOpen: false });
      window.dispatchEvent(new Event("exportComplete"));
    }
    this.setState({ notReccomendedModalOpen: false });
  };

  arrayMinMax = (arr) =>
    arr.reduce(
      ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
      [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
    );

  expandRow = (row) => {
    if (Object.hasOwn(row, "orders")) {
      return <OrderList orders={row.orders} quantity={this.props.quantity} />;
    }
    return null;
  };
  render() {
    const { classes } = this.props;
    /**
     * panels may be an empty object, or pass props to DataGrid
     * the expanding panel props must only be passed when two things are valid
     * 1. an appropriate preset is set
     * 2. the first row to render has appropriate props
     */
    // TODO: find way to keep preset and rows in sync, probable performance benefit
    const panels =
      ["account_reorders"].includes(this.props.preset) &&
      this.state.localPivot.list.length > 0 &&
      Object.hasOwn(this.state.localPivot.list[0], "orders")
        ? {
            getDetailPanelContent: ({ row }) => this.expandRow(row),
            getDetailPanelHeight: ({ row }) => "auto", // Height based on the content.
          }
        : {};
    return (
      <div className={[classes.root, "spinner-holder-parent"]}>
        <Grid>
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              {this.state.showAlert && (
                <Alert
                  severity="info"
                  onClose={() => {
                    this.setState({ showAlert: false });
                  }}
                >
                  Start And End Dates Must be Valid, and less than 6 months
                </Alert>
              )}
            </Grid>
            <Grid item md={4} xs={4}>
              <div>
                <Typography variant="caption">
                  Click &#x22EE; in column header to filter data
                </Typography>
              </div>
              <FormControlLabel
                style={{ margin: 0 }}
                label="Show Months"
                control={
                  <Checkbox
                    onChange={() =>
                      this.props.setShowMonths(!this.props.showMonths)
                    }
                    checked={this.props.showMonths}
                  />
                }
              />
              <FormControlLabel
                style={{ margin: 0 }}
                label="Show Comparison"
                control={
                  <Checkbox
                    onChange={() =>
                      this.props.setShowComparison(!this.props.showComparison)
                    }
                    checked={this.props.showComparison}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          style={{
            margin: 0,
            overflow: "auto",
            display: "flex",
          }}
          id="salestier-pivot"
          className="spinner-holder-parent"
        >
          {this.state.loader && (
            <div className="spinner-holder">
              <CircularProgress />
            </div>
          )}
          <div style={{ flexGrow: "1", minHeight: "600px" }}>
            {this.state.data.length === 0 && (
              <Box p={4}>
                <Typography variant="subtitle1">
                  No data for current selection. Use the Quick Select feature to
                  change states, distributors or brands.
                </Typography>
              </Box>
            )}
            {this.state.data.length > 0 && (
              <DataGrid
                id="reports-data-grid"
                // autoHeight={true}
                pagination={true}
                rows={this.state.localPivot.list}
                apiRef={this.apiRef}
                columns={this.state.localPivot.columns}
                pinnedRows={this.state.localPivot.pinnedRows}
                sortModel={this.state.sortModel}
                onSortModelChange={(model) =>
                  this.setState({ sortModel: model })
                }
                onFilterModelChange={this.dgFilterChange}
                classes={{
                  columnHeader: "reports-grid-header",
                  ["pinnedRows--bottom"]: "column-totals",
                }}
                experimentalFeatures={{
                  rowPinning: true,
                }}
                initialState={{
                  pinnedColumns: {
                    right: [
                      "total",
                      "previousTotal",
                      "fullList",
                      "prevFullList",
                      "diff",
                      "percent",
                    ],
                  },
                }}
                {...panels}
              />
            )}
          </div>
        </Grid>
        <Dialog
          open={this.state.modalOpen}
          onClose={this.closeModal}
          PaperProps={{
            style: {
              width: "540px",
              height: 250,
              padding: 10,
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={"100%"}
          >
            <DialogTitle id="simple-dialog-title">
              No Data To Export
            </DialogTitle>
            <Typography variant="body2" align="center">
              Your current filter selections would create a blank file.
            </Typography>
            <Box display="flex" gridColumnGap={10}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={this.closeModal}
                type="button"
              >
                OK
              </Button>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          open={this.state.notReccomendedModalOpen}
          onClose={this.closeModal}
          PaperProps={{
            style: {
              width: "540px",
              height: 250,
              padding: 10,
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={"100%"}
          >
            <DialogTitle id="simple-dialog-title">
              Uh oh, this table has lots of data and a PDF isn't reccomended
            </DialogTitle>
            <Typography variant="body2" align="center">
              We would reccomend you export with CSV or XLSX instead
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              {/* <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => this.onClickHandler("PDF")}
                type="button"
              >
                Export as PDF anyway
              </Button> */}
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => this.onClickHandler("CSV")}
                type="button"
                style={{ fontSize: "14px" }}
              >
                Export as CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => this.onClickHandler("XLSX")}
                type="button"
                style={{ fontSize: "12px" }}
              >
                Export as XLSX
              </Button>
              <Button
                variant="outlined"
                color="warning"
                fullWidth
                onClick={() => this.onClickHandler("CLOSE")}
                type="button"
                style={{
                  border: "1px solid red",
                  color: "red",
                  fontSize: "12px",
                }}
              >
                Close
              </Button>
            </div>
          </Box>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(SalePivotTable);
