const validStateCodes = new Set([
  "AA",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]);
const mappedStates = [
  { state: "Alabama", abbr: "Ala.", code: "AL" },
  { state: "Alaska", abbr: "", code: "AK" },
  { state: "Arizona", abbr: "Ariz.", code: "AZ" },
  { state: "Arkansas", abbr: "Ark.", code: "AR" },
  { state: "California", abbr: "Calif.", code: "CA" },
  { state: "Colorado", abbr: "Colo.", code: "CO" },
  { state: "Connecticut", abbr: "Conn.", code: "CT" },
  { state: "Delaware", abbr: "Del.", code: "DE" },
  { state: "District of Columbia", abbr: "D.C.", code: "DC" },
  { state: "Florida", abbr: "Fla.", code: "FL" },
  { state: "Georgia", abbr: "Ga.", code: "GA" },
  { state: "Hawaii", abbr: "", code: "HI" },
  { state: "Idaho", abbr: "", code: "ID" },
  { state: "Illinois", abbr: "Ill.", code: "IL" },
  { state: "Indiana", abbr: "Ind.", code: "IN" },
  { state: "Iowa", abbr: "", code: "IA" },
  { state: "Kansas", abbr: "Kans.", code: "KS" },
  { state: "Kentucky", abbr: "Ky.", code: "KY" },
  { state: "Louisiana", abbr: "", code: "LA" },
  { state: "Maine", abbr: "Me.", code: "ME" },
  { state: "Maryland", abbr: "Md.", code: "MD" },
  { state: "Massachusetts", abbr: "Mass.", code: "MA" },
  { state: "Michigan", abbr: "Mich.", code: "MI" },
  { state: "Military", abbr: "Mil.", code: "AA" },
  { state: "Minnesota", abbr: "Minn.", code: "MN" },
  { state: "Mississippi", abbr: "Miss.", code: "MS" },
  { state: "Missouri", abbr: "Mo.", code: "MO" },
  { state: "Montana", abbr: "Mont.", code: "MT" },
  { state: "Nebraska", abbr: "Nebr.", code: "NE" },
  { state: "Nevada", abbr: "Nev.", code: "NV" },
  { state: "New Hampshire", abbr: "N.H.", code: "NH" },
  { state: "New Jersey", abbr: "N.J.", code: "NJ" },
  { state: "New Mexico", abbr: "N.Mex.", code: "NM" },
  { state: "New York", abbr: "N.Y.", code: "NY" },
  { state: "North Carolina", abbr: "N.C.", code: "NC" },
  { state: "North Dakota", abbr: "", code: "ND" },
  { state: "Ohio", abbr: "", code: "OH" },
  { state: "Oklahoma", abbr: "Okla.", code: "OK" },
  { state: "Oregon", abbr: "Ore.", code: "OR" },
  { state: "Pennsylvania", abbr: "Penn.", code: "PA" },
  { state: "Rhode Island", abbr: "R.I.", code: "RI" },
  { state: "South Carolina", abbr: "S.C.", code: "SC" },
  { state: "South Dakota", abbr: "", code: "SD" },
  { state: "Tennessee", abbr: "Tenn.", code: "TN" },
  { state: "Texas", abbr: "Tex.", code: "TX" },
  { state: "Utah", abbr: "", code: "UT" },
  { state: "Vermont", abbr: "Vt.", code: "VT" },
  { state: "Virginia", abbr: "Va.", code: "VA" },
  { state: "Washington", abbr: "Wash.", code: "WA" },
  { state: "West Virginia", abbr: "W.Va.", code: "WV" },
  { state: "Wisconsin", abbr: "Wis.", code: "WI" },
  { state: "Wyoming", abbr: "Wyo.", code: "WY" },
];
const byCode = Object.assign(
  {},
  ...mappedStates.map((stateObj) => ({ [stateObj.code]: stateObj }))
);

export function isValidStateCode(stateCode) {
  return validStateCodes.has(stateCode);
}

export function tryMapStateCode(stateString) {
  if (!stateString || stateString === "") return;
  const lowerState = stateString.toLowerCase();

  if (isValidStateCode(stateString)) return stateString;

  return mappedStates.find((stateObj) => {
    return (
      [
        stateObj.state.toLowerCase(),
        stateObj.abbr.toLowerCase(),
        stateObj.code.toLowerCase(),
      ].includes(lowerState) || stateObj.abbr.toLowerCase() === `${lowerState}.`
    );
  })?.code;
}

export function stateToStateCode(stateString) {
  return tryMapStateCode(stateString) || "";
}

export function stateCodeToState(stateCodeString) {
  return byCode[stateCodeString]?.state || stateCodeString;
}
