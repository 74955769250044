import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useStore } from "../../stores/StoreContext";
import { useObserver } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  Breadcrumbs,
  Grid,
  Card,
  CircularProgress,
  Link,
} from "@material-ui/core";
import MediaCard from "./MediaCard";
import MediaFolder from "./MediaFolder";
import MediaFile from "./MediaFile.js";
import usersApi from "../../api/users";

const MediaLibrary = ({
  files,
  processedFolders,
  processedFiles,
  loading,
  file_id,
  folder_id,
  setFiles,
}) => {
  const { userInfo, setUserList, manufacturer } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const getTeam = async () => {
      let _result = await usersApi.getUsers({ mid: userInfo.mid });
      if (_result.status < 400) {
        setUserList(_result.results);
      }
    };

    getTeam();
  }, [setUserList, userInfo.mid]);

  const handleCard = (item) => {
    if (Object.hasOwn(item, "folder_id")) {
      navigate(`/app/media/folder/${item.folder_id}`);
    }
    if (Object.hasOwn(item, "gg_storage_id")) {
      if (folder_id) {
        navigate(`/app/media/folder/${folder_id}/${item.gg_storage_id}`);
      } else {
        navigate(`/app/media/root/${item.gg_storage_id}`);
      }
    }
  };

  const crumbs = useMemo(() => {
    return manufacturer
      ? [
          {
            text: `${manufacturer.manufacturer_displayname} files`,
            link: "marketingFiles",
          },
        ]
      : [];
  }, [manufacturer]);

  return useObserver(() => (
    <div>
      {!folder_id && !file_id && (
        <>
          <Box mb={3}>
            <Breadcrumbs separator={<ChevronRightIcon />}>
              {crumbs.map((c, index) => {
                return <Link key={index}>{c.text}</Link>;
              })}
            </Breadcrumbs>
          </Box>
          <Grid container spacing={3}>
            {processedFolders.map((folder, index) => (
              <Grid item key={folder.gg_storage_id} lg={2} md={4} xs={12}>
                <MediaCard
                  handleCard={handleCard}
                  item={folder}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            ))}
            {processedFiles.map((file, index) => (
              <Grid item key={file.gg_storage_id} lg={2} md={4} xs={12}>
                <MediaCard
                  handleCard={handleCard}
                  item={file}
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            ))}
            {loading && (
              <Card
                style={{
                  height: "150px",
                  width: "150px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <CircularProgress />
                </Box>
              </Card>
            )}
          </Grid>
        </>
      )}
      {folder_id && !file_id && (
        <MediaFolder
          processedFolders={processedFolders}
          folder_id={folder_id}
          files={files}
          setFiles={setFiles}
        />
      )}
      {file_id && (
        <MediaFile
          processedFolders={processedFolders}
          folder_id={folder_id}
          files={files}
          setFiles={setFiles}
          file_id={file_id}
        />
      )}
    </div>
  ));
};

export default MediaLibrary;
