import { useMemo } from "react";
import { useStore } from "../../../stores/StoreContext";
import { Typography } from "@material-ui/core";
import { DateTime, Interval } from "luxon";

const MapToolTip = ({ account, measurementUnit }) => {
  const { depletionList, selectedQuantity, quantities, dateRange } = useStore();
  const accountSales = useMemo(() => {
    const accname = account.store;
    const interval = Interval.fromDateTimes(
      ...dateRange.map((d) => DateTime.fromJSDate(d))
    );
    return depletionList.filter((dl) => {
      const goodDate = interval.contains(DateTime.fromISO(dl.DATE));
      return dl.STORENUMBER === accname && goodDate;
    });
  }, [account, depletionList]);
  const combinedSales = accountSales
    .reduce((acc, curr) => {
      const matchIndex = acc.findIndex(
        (bottle) => bottle.PRODUCT_ID === curr.canonical_product_id
      );
      if (matchIndex >= 0) {
        acc[matchIndex].physical_sold += curr.PHYSICAL_SOLD;
        acc[matchIndex].unit_sold += curr.UNIT_SOLD;
        acc[matchIndex].nine_sold += curr.NINE_SOLD;
        acc[matchIndex].bbl += curr.BBL;
        return acc;
      } else {
        return [
          ...acc,
          {
            ...curr,
            physical_sold: curr.PHYSICAL_SOLD,
            unit_sold: curr.UNIT_SOLD,
            nine_sold: curr.NINE_SOLD,
            bbl: curr.BBL,
          },
        ];
      }
    }, [])
    .filter((bottle) => bottle.unit_sold !== 0);

  const tot = combinedSales?.reduce((total, bottle) => {
    return total + bottle[measurementUnit.value];
  }, 0);
  const quantityName = quantities.find(
    (q) => q.value === selectedQuantity
  ).text;
  let topThree = combinedSales
    .sort((a, b) =>
      a[selectedQuantity] < b[selectedQuantity]
        ? 1
        : a[selectedQuantity] > b[selectedQuantity]
        ? -1
        : 0
    )
    .slice(0, 3);
  return (
    <div>
      <Typography variant="h5">{account.store}</Typography>
      {topThree.length > 0 && (
        <div>
          <Typography variant="h6">Top Sellers</Typography>
          {topThree.map((t) => {
            return (
              <div key={t.PRODUCT_ID}>
                {t.PRODUCTNAME} /{" "}
                <strong>
                  {selectedQuantity === "unit_sold"
                    ? t[selectedQuantity]
                    : t[selectedQuantity].toFixed(2)}
                </strong>{" "}
                {quantityName}
              </div>
            );
          })}
          <Typography variant="h6">Totals</Typography>
          <div>
            <strong>{combinedSales.length}</strong> products
          </div>
          <div>
            <strong>
              {selectedQuantity === "unit_sold" ? tot : tot.toFixed(2)}
            </strong>{" "}
            {quantityName}.
          </div>
        </div>
      )}
      {topThree.length === 0 && (
        <Typography variant="h6">No sales in selected date range</Typography>
      )}
      <Typography variant="overline">Click for full info</Typography>
    </div>
  );
};

export default MapToolTip;
