import React from "react";

import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const InfoCard = ({ item, handleCard }) => {
  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Card className={clsx(classes.root)} style={{ height: "100%" }}>
        <Box p={1}>
          <Box onClick={() => handleCard(item)} style={{ cursor: "pointer" }}>
            <CardContent>
              <Box display="flex" justifyContent="center">
                {item.icon}
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="body1"
                style={{ marginTop: "5px", overflowWrap: "break-word" }}
              >
                {item.name}
              </Typography>
            </CardContent>
            <Box flexGrow={1} />
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default InfoCard;
