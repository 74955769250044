import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import ProductsDetails from "./ProductsDetails";

const ProductModal = (props) => {
  const { onClose, selectedValue, open, item, setItem } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <ProductsDetails item={item} setItem={setItem} onClose={handleClose} />
    </Dialog>
  );
};

ProductModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default ProductModal;
