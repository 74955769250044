import _ from "lodash";
import { Grid, Box, TextField, Button, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import distributorsAPI from "../../../api/distributors";
import { Storage } from "aws-amplify";
import { useStore } from "../../../stores/StoreContext";

const auto = [
  {
    product_brand: "Alpha",
    id: 1,
  },
  {
    product_brand: "Bravo",
    id: 2,
  },
  {
    product_brand: "Gamma",
    id: 3,
  },
  {
    product_brand: "Delta",
    id: 4,
  },
];
const ExternalBrands = ({ externalBrandList, setExternalBrandList }) => {
  const [acData, setAcData] = useState([]);
  const [acJSON, setAcJSON] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acDirty, setAcDirty] = useState(false);
  const [acChecked, setAcChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [buttonBrands, setButtonBrands] = useState([]);

  const autoChange = (event, value) => {
    if (value !== null) {
      addBrand(value);
    }
  };
  const loadS3 = async (fileRef) => {
    const { file_key, identity_id } = fileRef;
    const file = await Storage.get(file_key, {
      // level: private | protected | public, // defaults to `public`
      level: "protected",
      identityId: identity_id, // id of another user, if `level: protected`
      download: true,
      // download?: boolean, // defaults to false
      // expires?: number, // validity of the URL, in seconds. defaults to 900 (15 minutes)
      // contentType?: string // set return content type, eg "text/html"
    });
    file.Body.text().then((string) => {
      // handle the String data return String
      const text = string;
      const s3Data = JSON.parse(text);
      try {
        setAcJSON(s3Data);
        setLoading(false);
      } catch (e) {
        console.log("error getting data " + e);
      }
    });
  };

  const { userInfo } = useStore();

  const getSuggestions = () => {
    setLoading(true);
    distributorsAPI
      .getExternalBrands(userInfo.manufacturer_id)
      .then((response) => {
        if (response.results.length > 0) {
          loadS3(response.results[0]);
        } else {
          setLoading(false);
        }
        setAcChecked(true);
      })
      .catch(() => {
        // do nothing
        setLoading(false);
      });
  };

  useEffect(() => {
    setButtonBrands(_.uniqBy(externalBrandList, "brand"));
  }, [externalBrandList]);

  useEffect(() => {
    if (inputValue.length && acJSON.length && !acData.length) {
      setAcData(
        acJSON.filter((a) =>
          a.brand.toLowerCase().startsWith(inputValue.toLowerCase())
        )
      );
    }
  }, [acJSON]);

  const addBrand = (brand) => {
    const matchingEntries = acData.filter((ac) => ac.brand === brand.brand);
    setExternalBrandList([...externalBrandList, ...matchingEntries]);
  };

  const deleteBrand = (brand) => {
    setExternalBrandList(
      externalBrandList.filter((cb) => cb.brand !== brand.brand)
    );
  };

  const acInputChange = (event, value) => {
    setInputValue(value);
    if (value.length > 0 && acChecked) {
      setAcData(
        acJSON.filter((a) =>
          a.brand.toLowerCase().startsWith(value.toLowerCase())
        )
      );
    } else {
      if (acJSON.length === 0 && !acChecked) {
        getSuggestions();
      }
    }
  };

  return (
    <Grid container>
      <Grid xs={4}>
        <Autocomplete
          options={acData}
          getOptionLabel={(option) => option.brand}
          onChange={autoChange}
          inputValue={inputValue}
          onInputChange={acInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search External Brands"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid xs={4}>
        {loading && <Button disabled={true}>Loading suggestions...</Button>}
        <div>
          {buttonBrands.map((cb) => (
            <Button
              key={cb.dist_brand_id}
              variant="outlined"
              style={{ margin: "3px" }}
              onClick={() => deleteBrand(cb)}
              startIcon={<DeleteIcon />}
            >
              {cb.brand}
            </Button>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default ExternalBrands;
