import { Box, Button, Card, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import distributorsAPI from "../../../../api/distributors";
import productsAPI from "../../../../api/products";
import { useStore } from "../../../../stores/StoreContext";
import { useEffect, useMemo, useState } from "react";
import FieldWrapper from "./FieldWrapper";
import { prebakeRepeatingGroups } from "../helpers";

const Form = observer(
  ({
    form,
    fields,
    validateForm,
    getAnswer,
    answers = [],
    setAnswers,
    errors = [],
  }) => {
    const {
      userInfo,
      distributorsList,
      productsList,
      setProducts,
      setDistributors,
    } = useStore();
    const [checked, setChecked] = useState({
      products: false,
      distributors: false,
    });

    const amendedFields = useMemo(() => {
      return fields.map((f) => {
        if (!f.conditionalGroups || f.conditionalGroups.length === 0) return f;
        const prebakedGroups = prebakeRepeatingGroups(f);
        return {
          ...f,
          conditionalGroups: prebakedGroups,
        };
      });
    }, [fields]);
    const errorById = (field) =>
      errors.find((e) => e.path === field.field_name);
    const formBlur = () => {
      if (errors.length) {
        validateForm();
      }
    };
    const updateAnswer = ({
      field,
      value,
      textValue = "",
      objectValue = {},
      parentLabel = "",
    }) => {
      const answer = answers.findIndex((a) => a.name === field.field_name);
      if (answer === -1) {
        setAnswers([
          ...answers,
          {
            name: field.field_name,
            value,
            textValue,
            custom_name: field.field_name,
            variant: field.variant ? field.variant : null,
            parentLabel,
          },
        ]);
      } else {
        const changedAnswers = answers.map((a) => {
          if (a.name === field.field_name) {
            return {
              ...a,
              value,
              textValue,
              objectValue,
              parentLabel,
            };
          }
          return a;
        });
        setAnswers(changedAnswers);
      }
    };
    const trackDataRequirements = () => {
      const { manufacturer_id } = userInfo;
      const variants = fields
        .filter((f) => f.value === "dynamicOptions" || f.element === "Contact")
        .map((f) => f.variant);

      if (
        variants.includes("distributors") &&
        !distributorsList.length &&
        !checked.distributors
      ) {
        distributorsAPI
          .getDistributors(manufacturer_id)
          .then((results) => {
            setDistributors(results);
          })
          .finally(() =>
            setChecked({
              ...checked,
              distributors: true,
            })
          );
      }
      if (
        (variants.includes("products") || variants.includes("brands")) &&
        !productsList.length
      ) {
        productsAPI
          .getProducts({ mid: manufacturer_id })
          .then(({ results }) => {
            setProducts(results);
          })
          .finally(() =>
            setChecked({
              ...checked,
              products: true,
            })
          );
      }
    };

    useEffect(() => {
      if (fields.length) {
        trackDataRequirements();
      }
    }, [fields]);

    return (
      <Box display="flex" width={"100%"}>
        <Box width={"100%"}>
          <Card style={{ width: "100%", padding: 20, boxShadow: "none" }}>
            <Typography style={{ fontWeight: "500" }} variant="h5">
              {form.form_name}
            </Typography>
          </Card>
          <fieldset>
            {amendedFields.map((field, index) => (
              <Box width={"100%"} py={1} key={index}>
                <FieldWrapper
                  field={field}
                  groups={field.conditionalGroups || []}
                  updateAnswer={updateAnswer}
                  answers={answers}
                  answer={getAnswer(field)}
                  error={errorById(field) ? true : false}
                  helperText={
                    errorById(field) ? errorById(field).specialMessage : ""
                  }
                />
              </Box>
            ))}
          </fieldset>{" "}
        </Box>
      </Box>
    );
  }
);

export default Form;
