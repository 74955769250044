import React, { useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";

function DataTable({ data }) {
  const [rowTitles, setRowTitles] = useState([...Object.keys(data)]); // on-premise + off-premise
  const [columnTitles, setColumnTitles] = useState(
    Object.keys(data[Object.keys(data)[0]])
  ); // Accounts YTD + Accounts QTD + 9Ltr Cases YTD etc...
  const [formatted_rowTitles, setFormatted_rowTitles] = useState([]);
  const [formattedData, setFormattedData] = useState({});
  useEffect(() => {
    formatTotal();
  }, [data]);

  const formatTotal = () => {
    // I was having trouble with rendering the table towards the end and figured it was because it was re-formatting the already
    // formatted data. To stop this I reset all the values. (Not even sure if useState is sync or async but it appears to be working now.)
    setFormattedData({});
    setRowTitles([...Object.keys(data)]);
    setColumnTitles(Object.keys(data[Object.keys(data)[0]]));
    setFormatted_rowTitles([]);

    var total = {};

    rowTitles.forEach((row) => {
      columnTitles.forEach((col) => {
        if (!Object.keys(total).includes(col)) {
          total[col] = data[row][col] || 0;
        } else {
          total[col] += data[row][col] || 0;
        }
      });
    });
    setFormattedData({ ...data, total });
    setFormatted_rowTitles(Object.keys({ ...data, total }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper style={{ maxWidth: "50vw" }}>
        {formattedData && formatted_rowTitles && (
          <TableContainer sx={{ width: "45vw" }} style={{ maxHeight: "300px" }}>
            <Table stickyHeader sx={{ width: "max-content" }} size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {columnTitles.map((title, index) => (
                    <TableCell key={index} align="right">
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {formatted_rowTitles.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row}</TableCell>
                    {columnTitles.map((col, index2) => (
                      <TableCell key={index2} align="right">
                        {formattedData[row][col]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
}

DataTable.defaultProps = {
  title: "No Title",
};

export default DataTable;
