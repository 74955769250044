const media = {
  folders: [],
  setFolders(folders) {
    this.folders = folders;
  },
  files: [],
  setFiles(files) {
    this.files = files;
  },
};

export default media;
