import React from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { Tooltip, Avatar } from "@material-ui/core";
import { DateTime } from "luxon";
import { AvatarGroup } from "@material-ui/lab";

const AccountsTable = ({
  rows,
  selectedRows,
  onRowClick,
  onSelectionModelChange,
  selectedQuantityLabel,
}) => {
  const nullValueConverter =
    (wrapper = (x) => x) =>
    (params) =>
      params.value === null || params.value === undefined
        ? null
        : wrapper(params.value);
  const nullValueSorter = (v1, v2, param1, param2) => {
    // Treat nulls as negative infinity
    if (v1 === null && v2 === null) {
      return 0;
    }
    if (v1 === null) {
      return -1; // v1 (null) comes before v2 (non-null) in ascending order
    }
    if (v2 === null) {
      return 1; // v2 (null) comes before v1 (non-null) in ascending order
    }
    // Default to numeric comparison
    return v1 - v2;
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 240,
      filterable: true,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 240,
      filterable: true,
      sortable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "region",
      headerName: "Region",
      width: 140,
      filterable: true,
      sortable: true,
    },
    {
      field: "zip",
      headerName: "Zip Code",
      width: 140,
      filterable: true,
      sortable: true,
    },
    {
      field: "premiseType",
      headerName: "Premise Type",
      width: 140,
      filterable: true,
      sortable: true,
    },
    {
      field: "lastSoldDate",
      headerName: "Last Sale Date",
      width: 140,
      filterable: true,
      sortable: true,
      valueGetter: nullValueConverter(),
      renderCell: (params) =>
        params.value === null
          ? "N/A"
          : DateTime.fromJSDate(params.value).toLocaleString(DateTime.DATE_MED),
      sortComparator: nullValueSorter,
    },
    {
      field: "totalSold",
      headerName: `Total Sold (${selectedQuantityLabel})`,
      width: 140,
      filterable: true,
      sortable: true,
      valueGetter: nullValueConverter(Number),
      renderCell: (params) =>
        params.value === null ? "N/A" : Number(params.value).toFixed(2),
      sortComparator: nullValueSorter,
    },
    {
      field: "productsSold",
      headerName: "Products Sold",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <AvatarGroup max={5}>
          {params.value.map((product, index) => (
            <Tooltip
              key={index}
              title={`${
                product.productName
              }, Total Sold (${selectedQuantityLabel}): ${product.totalSold.toFixed(
                2
              )}, Last Sale Date: ${DateTime.fromJSDate(
                product.lastSoldDate
              ).toLocaleString(DateTime.DATE_MED)}`}
            >
              <Avatar
                alt={product.productName}
                src={product.productUrl}
                style={{ background: "white" }}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      ),
    },
  ];

  return (
    <div style={{ height: 800, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        getRowHeight={() => "auto"}
        onRowClick={onRowClick}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectedRows}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }], // Initial sort model
          },
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </div>
  );
};

export default AccountsTable;
