import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import VisitDetails from "./VisitDetails";

const useStyles = makeStyles({});

const AccountVisitModal = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open, visitList, setVisitList, close } =
    props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={close} aria-labelledby="product-dialog" open={open}>
      <VisitDetails
        onClose={handleClose}
        setVisitList={setVisitList}
        visitList={visitList}
        selectedValue={selectedValue}
      />
    </Dialog>
  );
};

AccountVisitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default AccountVisitModal;
