import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
const PremiseSelecter = observer(() => {
  const { premiseTypes, setPremiseTypes, allPremiseTypes } = useStore();

  const handleChange = (event) => {
    console.log(event.target.value);
    setPremiseTypes(event.target.value);
  };

  return (
    <FormControl
      style={
        {
          // maxWidth: 300,
        }
      }
    >
      <InputLabel>Premise Type</InputLabel>
      <Select
        multiple
        value={premiseTypes}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        {allPremiseTypes.map((p, i) => (
          <MenuItem key={i} value={p}>
            {p}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default PremiseSelecter;
