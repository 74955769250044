import React, { useEffect, useState } from "react";

import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  Snackbar,
  Typography,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import {
  Folder,
  Image,
  PictureAsPdf,
  InsertDriveFile,
  CloudDownload,
  Visibility,
  Delete,
  Link,
  DeleteOutline,
} from "@material-ui/icons";
import { awsS3Image } from "../../helpers/awsS3Image";
import { Alert } from "@material-ui/lab";
import StorageClient from "../../storage/client";
import useStorage from "../../hooks/useStorage";
import storageAPI from "../../api/storage";
import DeleteModal from "../deleteModal/DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  deletedCard: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.text.secondary,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  iconSize: {
    fontSize: theme.spacing(12),
  },
  progressBar: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const MediaCard = ({ item, handleCard, files, setFiles }) => {
  const [isImage, setIsImage] = useState(false);
  const classes = useStyles();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const s3Storage = useStorage(StorageClient.download);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileDeleted, setFileDeleted] = useState(false);
  const [deleteTimeoutId, setDeleteTimeoutId] = useState(null);
  const [progress, setProgress] = useState(0);

  const copyImageUrlToClipboard = async (url) => {
    console.log(url);
    try {
      await navigator.clipboard.writeText(url);
      setToastMessage("Image URL copied to clipboard");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setToastMessage("Failed to copy image URL");
      setToastOpen(true);
    }
  };

  useEffect(() => {
    if (item && !item.isFolder) {
      const ext = item?.file_key?.split(".").reverse()[0].toLowerCase();
      switch (ext) {
        case "jpeg":
        case "png":
        case "jpg":
          setIsImage(true);
          break;
        default:
          setIsImage(false);
          break;
      }
    }
  }, [item]);

  const iconSize = 80;

  const fileIcon = (fileName) => {
    const ext = fileName.split(".").reverse()[0].toLowerCase();
    switch (ext) {
      case "jpeg":
      case "png":
      case "jpg":
        return <Image style={{ fontSize: iconSize, color: "black" }} />;
      case "pdf":
        return <PictureAsPdf style={{ fontSize: iconSize, color: "black" }} />;
      default:
        return (
          <InsertDriveFile style={{ fontSize: iconSize, color: "black" }} />
        );
    }
  };

  const handleDownload = async () => {
    try {
      await s3Storage.download(item.file_key, item.identity_id);
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to Download: ", err);
      setToastMessage("Failed to Download");
      setToastOpen(true);
    }
  };

  const handleViewOriginal = () => {
    console.log("View original");
    const url = awsS3Image(item.file_key, item.identity_id, "0x0");
    window.open(url, "_blank");
  };

  const handleDeleteConfirm = async () => {
    setIsLoading(true);
    try {
      await storageAPI.deleteFile(item.gg_storage_id);
      setIsLoading(false);
      setDeleteMode(false);
      setFileDeleted(true);

      let progressValue = 0;
      const intervalId = setInterval(() => {
        progressValue += 1.1;
        setProgress(progressValue);
      }, 50);

      const timeoutId = setTimeout(() => {
        setFiles(files.filter((f) => f.gg_storage_id !== item.gg_storage_id));
        clearInterval(intervalId);
      }, 5000);

      setDeleteTimeoutId(timeoutId);
    } catch (error) {
      console.error("Error deleting file: ", error);
      setIsLoading(false);
    }
  };

  const handleCancelDelete = () => {
    if (deleteTimeoutId) {
      clearTimeout(deleteTimeoutId);
      setDeleteTimeoutId(null);
      setFileDeleted(false);
      setProgress(0);
    }
  };

  if (fileDeleted) {
    return (
      <Card className={clsx(classes.root, classes.deletedCard)}>
        <Box
          pt={3}
          pb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <DeleteOutline className={classes.iconSize} />
          <Typography variant="subtitle1">File Deleting</Typography>
          <LinearProgress
            style={{ color: "white", backgroundColor: "white" }}
            variant="determinate"
            value={progress}
            className={classes.progressBar}
          />
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            style={{
              marginTop: "16px",
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: "white",
            }}
          >
            Cancel Delete
          </Button>
          <Button
            onClick={() => {
              handleCancelDelete();
              setFiles(
                files.filter((f) => f.gg_storage_id !== item.gg_storage_id)
              );
            }}
            variant="contained"
            style={{
              marginTop: "16px",
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: "white",
            }}
          >
            Delete Now
          </Button>
        </Box>
      </Card>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert onClose={() => setToastOpen(false)} severity="success">
          {toastMessage}
        </Alert>
      </Snackbar>
      {deleteMode && (
        <DeleteModal
          title={`Delete ${item?.file_name}`}
          description={"Are you sure you want to delete this file?"}
          deleteButtonLoading={isLoading}
          handleConfirmDelete={handleDeleteConfirm}
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
        />
      )}
      <Card className={clsx(classes.root)} style={{ height: "100%" }}>
        <Box pt={3} pb={3}>
          <Box>
            <CardContent>
              {isImage ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    onClick={() => handleCard(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={awsS3Image(
                        item.file_key,
                        item.identity_id,
                        "0x100",
                        ["fit-in", "-100-100"]
                      )}
                      alt=""
                    />
                  </Box>
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="body2"
                    style={{ marginTop: "5px", overflowWrap: "break-word" }}
                  >
                    {item.file_name}
                  </Typography>
                  {!item.isFolder ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        size="small"
                        className={classes.button}
                        onClick={handleViewOriginal}
                        style={{ marginRight: "auto" }}
                      >
                        <Visibility style={{ marginRight: 10 }} /> View Original
                      </Button>
                      <Button
                        size="small"
                        className={classes.button}
                        onClick={() =>
                          copyImageUrlToClipboard(
                            awsS3Image(item.file_key, item.identity_id, "0x0")
                          )
                        }
                        style={{ marginRight: "auto" }}
                      >
                        <Link style={{ marginRight: 10 }} />
                        Copy Image Link
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    onClick={() => handleCard(item)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.isFolder ? (
                      <Folder style={{ fontSize: iconSize }} />
                    ) : (
                      fileIcon(item.file_key)
                    )}
                  </Box>
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="body1"
                    style={{ marginTop: "5px", overflowWrap: "break-word" }}
                  >
                    {item.isFolder ? item.name : item.file_name}
                  </Typography>
                </>
              )}

              {item.isFolder ? (
                <></>
              ) : (
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      size="small"
                      className={classes.button}
                      style={{ marginRight: "auto" }}
                      onClick={() => {
                        handleDownload();
                        setToastMessage("Download Started");
                      }}
                    >
                      <CloudDownload style={{ marginRight: 10 }} /> Download
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => setDeleteMode(true)}
                      style={{ marginRight: "auto" }}
                    >
                      <Delete style={{ marginRight: 10 }} />
                      Delete
                    </Button>
                  </div>
                </>
              )}
            </CardContent>
            <Box flexGrow={1} />
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default MediaCard;
