import React, { useState, useEffect } from "react";
import {
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Dialog,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { Link } from "react-router-dom";
import { Table } from "@mui/material";
import { Loader } from "@aws-amplify/ui-react";
import { Pagination } from "@material-ui/lab";
import { API } from "aws-amplify";
import AccountFormModal from "../../customer/AccountProfileView/AccountFormModal";
import formAPI from "../../../api/forms";
import { useStore } from "../../../stores/StoreContext";

const typeNames = {
  account_checkin: "CheckIn",
  contact_created: "Contact",
  media_upload: "Image",
  form_response: "Form",
};

const itemsPerPage = 8;

function displayFormName(formData) {
  const name = JSON.parse(formData);
  return name.form_name ? name.form_name : "Form";
}

const TeamActivityTracker = ({ members, data }) => {
  const store = useStore();
  const { userInfo } = store;
  const [formattedDataNoSlice, setformattedDataNoSlice] = useState(data);
  const [formattedData, setFormattedData] = useState(
    data.slice(0, itemsPerPage).sort((a, b) => b.created - a.created)
  );
  const [imageOpen, setImageOpen] = useState(false);
  const [formList, setFormsList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [direction, setDirection] = useState("desc");
  const [contactOpen, setContactOpen] = useState(false);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setFormattedData(
      data.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    );
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormattedData(formattedDataNoSlice.slice(0, itemsPerPage));
    setCurrentPage(1);
  }, [formattedDataNoSlice]);

  useEffect(() => {
    if (direction === "desc") {
      setformattedDataNoSlice(data);
    } else {
      var temp = [];
      temp.push(...data);
      setformattedDataNoSlice(temp.reverse());
    }
  }, [data]);

  const getContact = async (event) => {
    // Parse media_data to extract contact_id
    let contactId;
    try {
      const mediaData = JSON.parse(event.media_data);
      contactId = mediaData.contact_id;
    } catch (error) {
      console.error("Failed to parse media_data:", error);
      return;
    }

    const contactResponse = await API.get(
      "backendGateway",
      "/accounts/contact/individual",
      {
        queryStringParameters: {
          aid: event.account_id,
          contact_id: contactId,
        },
      }
    );
    setContactOpen(contactResponse.results[0]);
  };

  useEffect(() => {
    getForms();
  }, []);

  const getForms = async () => {
    setFormsList([]);
    try {
      const data = await formAPI.getFormList(userInfo.mid);

      let _data = data?.results?.map((item) => {
        let _date = new Date(item.created);

        return {
          ...item,
          created: `${_date.getDate()}-${
            _date.getMonth() + 1
          }-${_date.getFullYear()}`,
        };
      });
      let temp = _data.filter((form) => form.on_account || form.is_open);
      if (temp.length > 0) {
        setFormsList(_data.filter((form) => form.on_account || form.is_open));
      } else {
        setFormsList(null);
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  const handleFormTable = async (form) => {
    setSelectedValue(form.form_id);
    let _form = formList.find((f) => {
      return f.form_id === form.form_id;
    });
    if (_form) {
      let _data = {
        form_id: form?.form_id,
        response_id: form?.response_id,
      };
      let _result = await formAPI.getFormResponse(_data);
      let response = {
        form_body: _form?.form_body,
        form_name: _form?.form_name,
        data: _result?.results,
      };
      setOpenForm(response);
      setLoading(false);
    }
    setIsFormResponse(true);
  };

  const [openForm, setOpenForm] = useState(null);
  const [isFormResponse, setIsFormResponse] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  // const formRespone = async (event) => {
  //   const contactResponse = await API.get(
  //     "quicksight",
  //     "/accounts/contact/individual",
  //     {
  //       queryStringParameters: {
  //         aid: event.account_id, //27
  //         created: event.created, // 59
  //       },
  //     }
  //   );
  //   console.log(contactResponse);
  // };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => {
          setImageOpen(false);
          setLoading(false);
        }}
        aria-labelledby="product-dialog"
        open={imageOpen}
        onClick={() => setImageOpen(false)}
      >
        {imageOpen ? (
          <img
            src={imageOpen}
            style={{
              height: "100%",
              width: "100%",
            }}
            alt=""
          />
        ) : (
          ""
        )}
      </Dialog>
      {openForm !== null && (
        <AccountFormModal
          allowEdit={false}
          isFormResponse={true}
          open={true}
          selectedValue={selectedValue}
          form={openForm}
          accountId={isFormResponse}
          onClose={() => {
            setOpenForm(null);
          }}
          close={() => {
            setOpenForm(null);
          }}
        />
      )}
      <Dialog
        fullWidth
        onClose={() => setContactOpen(false)}
        aria-labelledby="product-dialog"
        open={contactOpen}
        onClick={() => setContactOpen(false)}
      >
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
          }}
        >
          <div
            style={{
              paddingBottom: 10,
              fontSize: 24,
              fontWeight: "600",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Contact Details
          </div>
          <div>Name: {contactOpen.name || ""}</div>
          <div>Title: {contactOpen.title || ""}</div>
          <div>Email: {contactOpen.email || ""}</div>
          <div>Phone: {contactOpen.phone || ""}</div>
        </div>
      </Dialog>

      {members ? (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: "18px" }}>Team Member</TableCell>
                <TableCell style={{ fontSize: "18px" }}>
                  <TableSortLabel
                    active={true}
                    direction={direction}
                    onClick={() => {
                      if (direction === "desc") {
                        setDirection("asc");
                      } else {
                        setDirection("desc");
                      }
                      var tempArray = [];
                      tempArray.push(...formattedDataNoSlice);
                      setformattedDataNoSlice(tempArray.reverse());
                    }}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontSize: "18px" }}>Type</TableCell>
                <TableCell style={{ fontSize: "18px" }}>Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formattedData.map((event) => {
                const date = new Date(event.created);

                // Get local time components
                const localDate = new Date(date.getTime());

                const day = localDate.getDate();
                const month = new Intl.DateTimeFormat(undefined, {
                  month: "short",
                }).format(localDate);
                const hour = localDate.getHours() % 12 || 12;
                const minute = localDate.getMinutes();
                const ampm = localDate.getHours() < 12 ? "am" : "pm";

                // Format the date string
                const formattedDate = `${day}${
                  day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
                } ${month} ${hour}:${minute
                  .toString()
                  .padStart(2, "0")} ${ampm}`;

                const member = members.find((m) => m.user_id === event.user_id);
                const pfpsrc = awsS3Image(
                  member?.user_image,
                  member?.user_identitykey,
                  "0x110"
                );

                return (
                  <TableRow
                    key={event.type + event.user_displayname + date.getTime()}
                  >
                    <TableCell
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {member &&
                      member?.user_image !== "gg_default_image.png" ? (
                        <img
                          src={pfpsrc}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "1000px",
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src="/static/images/avatars/gg_default_image.png"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                          alt=""
                        />
                      )}
                      <Link
                        style={{
                          color: "black",
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                        to={`/app/profile/${event.user_id}`}
                      >
                        {event.user_displayname}
                      </Link>
                    </TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`/static/images/icons/${
                          typeNames[event.type]
                        }.svg`}
                        alt=""
                        width="40"
                        height="40"
                        style={{ marginRight: "10px" }}
                      />
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => {
                          if (event.type === "contact_created") {
                            getContact(event);
                          } else if (event.type === "form_response") {
                            handleFormTable(JSON.parse(event?.form_data));
                            setLoading(event?.form_data);
                          } else if (event.type === "media_upload") {
                            setImageOpen(
                              awsS3Image(
                                JSON.parse(event?.media_data).path,
                                JSON.parse(event?.media_data).identity_key,
                                "0x960"
                              )
                            );
                            setLoading(event?.media_data);
                          } else {
                            console.log("");
                          }
                        }}
                      >
                        <div
                          style={{
                            textDecoration:
                              event.type !== "account_checkin"
                                ? "underline"
                                : "none",
                            cursor:
                              event.type !== "account_checkin"
                                ? "pointer"
                                : "default",
                          }}
                        >
                          {event?.form_data
                            ? displayFormName(event?.form_data)
                            : typeNames[event.type]}
                          {loading === event?.form_data ||
                          loading === event?.media_data ? (
                            <CircularProgress size={"1rem"} />
                          ) : null}
                        </div>
                      </button>
                    </TableCell>
                    <TableCell style={{ width: "40%" }}>
                      <Link
                        style={{
                          color: "black",
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                        to={`/app/accountdetails/${event.account_id}`}
                      >
                        Account: {event.account_displayname}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {Math.ceil(data.length / itemsPerPage) > 1 ? (
            <Paper
              style={{
                display: "flex",
                height: "60px",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                onChange={(e, page) => handlePageChange(page)}
                page={currentPage}
              />
            </Paper>
          ) : (
            ""
          )}
        </Paper>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default TeamActivityTracker;
