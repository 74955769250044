import { Button, Card } from "@material-ui/core";
import { PlusOne } from "@material-ui/icons";
import { useState } from "react";
const FieldAdder = ({ addField }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      onClick={addField}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        paddingLeft: 10,
        boxShadow: "none",
        height: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        background: "linear-gradient(to bottom, white 70%, #F4F6F8)",
        cursor: "pointer",
        borderTop: isHovered ? "3px solid #1F449F" : "3px solid #FFFFFF",
      }}
    >
      <h4
        style={{
          marginTop: 10,
          color: isHovered ? "#1F449F" : "inherit",
        }}
      >
        + Add Field
      </h4>
    </Card>
  );
};

export default FieldAdder;
