import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import { DateTime } from "luxon";
import { generateSummaryTotals } from "../../helpers/data/statTables";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { TrendingDown, TrendingUp, TrendingFlat } from "@material-ui/icons";
const SummaryTotalComparison = observer(({ filterArgs }) => {
  const {
    depletionList,
    selectedQuantity,
    quantities,
    latestSale,
    _genMonths,
    _genQTD,
    _genYTD,
  } = useStore();
  const textStyle = {
    textAlign: "center",
  };
  const iconStyle = {
    fontSize: 40,
  };
  const periods = useMemo(() => {
    const qtd = _genQTD();
    const ytd = _genYTD();
    return [
      {
        name: "threeMonths",
        label: "3 Months",
        currentRange: _genMonths(3).map((q) =>
          DateTime.fromJSDate(q, { zone: "utc" })
        ),
        previousRange: _genMonths(3, -12).map((q) =>
          DateTime.fromJSDate(q, { zone: "utc" })
        ),
      },
      {
        name: "year",
        label: "12 Months",
        currentRange: _genMonths(12).map((q) =>
          DateTime.fromJSDate(q, { zone: "utc" })
        ),
        previousRange: _genMonths(12, -12).map((q) =>
          DateTime.fromJSDate(q, { zone: "utc" })
        ),
      },
      {
        name: "qtd",
        label: "QTD",
        currentRange: qtd.map((q) => DateTime.fromJSDate(q, { zone: "utc" })),
        previousRange: qtd.map((q) =>
          DateTime.fromJSDate(q, { zone: "utc" }).minus({ months: 12 })
        ),
      },
      {
        name: "ytd",
        label: "YTD",
        currentRange: ytd.map((q) => DateTime.fromJSDate(q, { zone: "utc" })),
        previousRange: ytd.map((q) =>
          DateTime.fromJSDate(q, { zone: "utc" }).minus({ months: 12 })
        ),
      },
    ];
  }, [filterArgs]);
  const boxes = useMemo(() => {
    return generateSummaryTotals(
      depletionList,
      selectedQuantity,
      filterArgs,
      periods
    );
  }, [depletionList, selectedQuantity, filterArgs, periods]);

  const quantityText = quantities.find(
    (q) => q.value === selectedQuantity
  ).text;
  return (
    <div>
      <Box display="flex" justifyContent={"space-around"}>
        {boxes.map((p, ind) => (
          <Card key={ind}>
            <CardContent>
              {!p.hasPrevious && !p.hasCurrent && (
                <Box display="flex" flexDirection="column" style={textStyle}>
                  <Typography variant="subtitle1">{p.label}</Typography>
                  <Typography variant="subtitle1">No Data</Typography>
                </Box>
              )}
              {(p.hasPrevious || p.hasCurrent) && (
                <Box display="flex" flexDirection="column" style={textStyle}>
                  <Typography variant="subtitle1">{p.label}</Typography>
                  <Typography variant="h4">
                    {p.current.toLocaleString(undefined, { timeZone: "UTC" })}
                  </Typography>
                  <Typography variant="body2">
                    {p.windowStrings.current}
                  </Typography>
                  <Typography variant="h5">
                    vs.{" "}
                    {p.previous.toLocaleString(undefined, { timeZone: "UTC" })}
                  </Typography>
                  <Typography variant="body2">
                    {p.windowStrings.previous}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    {p.percentDiff > 0 && (
                      <>
                        <TrendingUp
                          style={{
                            ...iconStyle,
                            color: "#0c0",
                          }}
                        />
                        <Typography variant="h4">+{p.percentDiff}% </Typography>
                      </>
                    )}
                    {p.percentDiff < 0 && (
                      <>
                        <TrendingDown
                          style={{
                            ...iconStyle,
                            color: "#c00",
                          }}
                        />
                        <Typography variant="h4">{p.percentDiff}% </Typography>
                      </>
                    )}
                    {p.percentDiff === 0 && (
                      <>
                        <TrendingFlat
                          style={{
                            ...iconStyle,
                          }}
                        />
                        <Typography variant="h4">{p.percentDiff}% </Typography>
                      </>
                    )}
                  </Box>
                  <Typography variant="h5">
                    {" "}
                    {p.intDiff > 0 && "+"}
                    {p.intDiff.toLocaleString(undefined, { timeZone: "UTC" })}
                  </Typography>
                  <Typography variant="subtitle2">{quantityText}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
      {/* <p>Summary total comparison {depletionList.length}</p>
      <p>{JSON.stringify(boxes)}</p>
      <p>{JSON.stringify(depletionList[0])}</p> */}
    </div>
  );
});

export default SummaryTotalComparison;
