import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";

import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { nanoid } from "nanoid";

import { useForm } from "react-hook-form";
import useApi from "../../../hooks/useApi";
import accountsApi from "../../../api/accounts";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    padding: theme.spacing(2),
    overflow: "hidden",
  },
}));

const ContactModal = ({
  onClose,
  contact,
  contactList,
  setContactList,
  refreshContacts,
  open,
  ...rest
}) => {
  const classes = useStyles();
  const postContactApi = useApi(accountsApi.postContact);
  const putContactApi = useApi(accountsApi.putContact);
  const deleteContactApi = useApi(accountsApi.deleteContact);
  const store = useStore();
  const { userInfo } = store;

  const { id } = useParams();
  const [deleteInProgress, setDeleteButton] = useState(true);
  const [submitInProgress, setSubmitButton] = useState(false);
  const [buttonText, setButtonText] = useState("CREATE");
  const [titleText, setTitleText] = useState("Add");
  const { handleSubmit } = useForm();

  const handleClose = () => {
    setValues({
      user_id: userInfo.uid,
      manufacturer_id: userInfo.mid,
      name: "",
      phone: "",
      title: "",
      email: "",
      account_id: id,
      contact_id: nanoid(5),
    });
    onClose();
  };

  async function deleteContact(contactId) {
    try {
      await API.del("backendGateway", "/accounts/contact/list", {
        queryStringParameters: {
          contact_id: contactId,
        },
      });
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setContactList(contactList.filter((x) => x.contact_id !== contactId));
    handleClose();
  }
  useEffect(() => {
    if (contact != null) {
      setButtonText("UPDATE");
      setTitleText("Edit");
      setValues({
        user_id: userInfo.uid,
        manufacturer_id: userInfo.mid,
        name: contact?.name || "",
        phone: contact?.phone || "",
        title: contact?.title || "",
        email: contact?.email || "",
        contact_id: contact?.contact_id || nanoid(5), //fakeID Key
        account_id: id,
      });
      setDeleteButton(false);
    }
  }, [contact]);

  const handleChange = (event, value) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    let index = contactList.findIndex(
      (obj) => obj.contact_id === values.contact_id
    );
    if (index === -1) {
      setSubmitButton(postContactApi.loading);
      postContactApi.request(values).then(() => {
        refreshContacts();
      });
      setSubmitButton(postContactApi.loading);
    } else {
      setSubmitButton(putContactApi.loading);
      putContactApi.request(values);
      setSubmitButton(putContactApi.loading);
      contactList[index] = values;
      setContactList(contactList);
    }

    handleClose();
  };
  const [values, setValues] = useState({
    user_id: userInfo.uid,
    manufacturer_id: userInfo.mid,
    name: contact?.name || "",
    phone: contact?.phone || "",
    title: contact?.title || "",
    email: contact?.email || "",
    contact_id: contact?.contact_id || nanoid(5), //fakeID Key
    account_id: id,
  });
  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <DialogTitle id="simple-dialog-title">{titleText} Contact</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formControl}>
        <Grid container spacing={5}>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Name"
              required
              variant="outlined"
              name="name"
              fullWidth
              onChange={handleChange}
              value={values.name}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              name="title"
              fullWidth
              onChange={handleChange}
              value={values.title}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              fullWidth
              onChange={handleChange}
              value={values.email}
            />
          </Grid>

          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Phone"
              variant="outlined"
              name="phone"
              fullWidth
              onChange={handleChange}
              value={values.phone}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Button
              disabled={submitInProgress}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              {buttonText}
            </Button>
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Button
              disabled={deleteInProgress}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => deleteContact(values.contact_id)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ContactModal;
