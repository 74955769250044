import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Grid,
  Box,
  IconButton,
  CardContent,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { useStore } from "../../../stores/StoreContext";
import SalesOverview from "../../../components/dataGrids/SalesOverview";

const AllTimeBar = observer(({ filterArgs }) => {
  const [isExpand, setIsExpand] = useState(true);

  const {
    userInfo,
    depletionListMinified,
    productsInDataMinified,
    allDistributorsObjectsMinified,
    allAccountsObjectsMinified,
    allMarketsObjectsMinified,
  } = useStore();

  const manufacturer_id = userInfo.manufacturer_id;

  return (
    <>
      {manufacturer_id ? (
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader
                title={`Sales Overview`}
                className="st-card-header"
                action={
                  <IconButton
                    size="medium"
                    onClick={() => setIsExpand((prev) => !prev)}
                  >
                    {isExpand ? (
                      <ExpandLess style={{ fill: "white", fontSize: "30px" }} />
                    ) : (
                      <ExpandMore style={{ fill: "white", fontSize: "30px" }} />
                    )}
                  </IconButton>
                }
              />
              {isExpand && (
                <CardContent>
                  <Box p={1}>
                    <SalesOverview
                      manufacturer_id={manufacturer_id}
                      depletions={depletionListMinified}
                      filterArgs={filterArgs}
                      allDistributorsObjects={allDistributorsObjectsMinified}
                      allAccountsObjects={allAccountsObjectsMinified}
                      allMarketsObjects={allMarketsObjectsMinified}
                      productsInData={productsInDataMinified}
                    />
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
});

AllTimeBar.propTypes = {
  className: PropTypes.string,
};

export default AllTimeBar;
