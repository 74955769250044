import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { API } from "aws-amplify";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  Avatar,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  DialogActions,
  Button,
  Select,
  TextField,
  MenuItem,
  Typography,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import {
  Cancel,
  CheckBox,
  Search,
  CloudDownload,
  CheckBoxOutlineBlank,
  CheckBoxTwoTone,
  RemoveCircle,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { FixedSizeList } from "react-window";
import { Parser } from "json2csv";
import { utils as XLSXUtils, write as writeXLSX } from "xlsx";

import AutoSizer from "./AutoSizer-react-virtualised";
import useApi from "../../../hooks/useApi";
import listsAPI from "../../../api/lists";
import accountsAPI from "../../../api/accounts";
import { useStore } from "../../../stores/StoreContext";
import { awsS3Image } from "../../../helpers/awsS3Image";
import AccountSearch from "./AccountSearch";

import DeleteModal from "../../../components/deleteModal/DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "clip",
    textDecoration: "none",
  },
  avatar: {
    // marginRight: theme.spacing(2),
  },
  black: {
    color: theme.palette.getContrastText("#E6E6FA"),
    backgroundColor: "#ffffff",
  },
  dialog: {
    width: "600px",
    maxWidth: "90%",
    minHeight: "200px",
  },
  checker: {
    marginRight: "12px",
  },
  toolbarWrapper: {
    flexShrink: 0,
    flexGrow: 1,
    // flexOrder: highEnough ? 2 : 0,
    position: "relative",
  },
}));

const setEqual = (xs, ys) =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x));

const isSubset = (xs, ys) =>
  xs.size < ys.size && [...xs].every((x) => ys.has(x));

const exportData = async (data, format) => {
  var downloadLink = document.createElement("a");
  const filteredData = data.map((x) => ({
    name: x.account_displayname,
    address: x.account_address,
    city: x.account_city,
    state: x.account_region,
    zip: x.account_regioncode,
    phone: x.account_phone || "",
  }));
  const parser = new Parser();
  if (format === "csv") {
    const csv = parser.parse(filteredData);
    //console.log(csv);
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `accounts_${new Date().toJSON().slice(0, 10)}.csv`;
  }
  if (format === "xlsx") {
    const workbook = XLSXUtils.book_new();
    const worksheet = XLSXUtils.json_to_sheet(filteredData);
    XLSXUtils.book_append_sheet(workbook, worksheet, "Responses");
    const xlsxBuffer = writeXLSX(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    blob = new Blob([xlsxBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `accounts_${new Date().toJSON().slice(0, 10)}.xlsx`;
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const RemoveModalDescription = (selectedAccounts, displayAccounts) => {
  return (
    <>
      <Box fontSize={"1rem"}>
        {"Are you sure you want to remove the following " +
          selectedAccounts.size +
          " account" +
          (selectedAccounts.size > 1 ? "s" : "") +
          " from the list:"}
      </Box>
      <ul
        style={{
          display: "inline-block",
          margin: "auto",
          textAlign: "left",
          listStyle: "circle inside",
          fontSize: "1rem",
          marginTop: "0.5rem",
          height: "4rem",
          overflowY: "scroll",
          padding: "0 5px",
        }}
      >
        {displayAccounts.map((account) =>
          selectedAccounts.has(account.account_id) ? (
            <li>{account.account_displayname}</li>
          ) : null
        )}
      </ul>
    </>
  );
};

const Results = observer(
  ({
    className,
    listType,
    lists,
    loading,
    city,
    premiseType,
    reportUp,
    selection,
    setSelection,
    listName,
    market,
    ...rest
  }) => {
    const classes = useStyles();
    const postListsApi = useApi(listsAPI.postListsItems);
    const removeFromListAPI = useApi(accountsAPI.removeAccountsFromList);
    const highEnough = useMediaQuery("(min-height:700px)");
    const {
      customers,
      autoCompleteProducts,
      filteringProducts,
      setProductsInData,
      productSold,
      productJoiner,
      userInfo,
    } = useStore();
    const [customersWithBottles, setCustomersWithBottles] = useState([]);

    const [openSearch, setOpenSearch] = React.useState(false);
    const [openLists, setOpenLists] = useState(false);
    const [accountProducts, setAccountProducts] = useState({});
    const [openExports, setOpenExports] = useState(false);
    const [exportList, setExportList] = useState(null);
    const [exportState, setExportState] = useState("inactive");
    const [selectedList, setSelectedList] = useState("");
    const [accountFilterString, setAccountFilterString] = useState("");
    const [productImgUrls, setProductImgUrls] = useState({});
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const allowedStates = userInfo.access?.markets;

    const removeAccountsFromList = (selectedAccounts) => {
      const payload = {
        list_id: market.list_id,
        account_ids: Array.from(selectedAccounts),
      };
      removeFromListAPI.request(payload).then((res) => {
        setCustomersWithBottles(
          customersWithBottles.filter(
            (acc) => !selectedAccounts.has(acc.account_id)
          )
        );
        setSelection([]);
        setShowRemoveModal(false);
      });
    };

    useEffect(() => {
      if (!customers.length) return;
      const allPrems = premiseType === "ALL";
      setCustomersWithBottles(
        customers
          .filter(
            (x) => !allowedStates || allowedStates.includes(x.account_region)
          )
          .filter(
            (c) =>
              (city === "" || c.account_city.toUpperCase() === city) &&
              (accountFilterString === "" ||
                c.account_displayname
                  .toLowerCase()
                  .includes(accountFilterString.toLowerCase())) &&
              (allPrems || c.premise_type === premiseType)
          )
          .map((c) => {
            return {
              ...c,
              bottles: accountProducts[c.account_id]
                ? accountProducts[c.account_id]
                : [],
            };
          })
      );
    }, [customers, city, premiseType, accountFilterString, accountProducts]);

    useEffect(() => {
      if (customers.length && autoCompleteProducts.length) {
        const prods = new Set(
          customers.reduce((acc, cur) => {
            return [
              ...acc,
              ...(cur.product_ids
                ? JSON.parse(cur.product_ids).map((p) => parseInt(p, 10))
                : []),
            ];
          }, [])
        );
        const prodsToSet = autoCompleteProducts
          .filter((p) => prods.has(p.product_id))
          .map((p) => ({ ...p, canonical_product_id: p.product_id }));
        setProductsInData(prodsToSet);
        setProductImgUrls(
          prodsToSet.reduce((acc, p) => {
            if (!acc[p.product_id]) {
              acc[p.product_id] = awsS3Image(
                p.product_image,
                p.product_imagekey,
                "0x30"
              );
            }
            return acc;
          }, {})
        );

        const accPro = customers.reduce((acc, cur) => {
          const product_ids = cur.product_ids
            ? JSON.parse(cur.product_ids).map((i) => parseInt(i, 10))
            : null;
          return {
            ...acc,
            [cur.account_id]: autoCompleteProducts.filter((acp) =>
              product_ids !== null ? product_ids.includes(acp.product_id) : true
            ),
          };
        }, {});
        setAccountProducts(accPro);
      }
    }, [customers, autoCompleteProducts, setProductsInData]);

    useEffect(() => {
      setExportState("inactive");
    }, [openExports]);

    const displayAccounts = useMemo(() => {
      if (!filteringProducts.length) {
        return customersWithBottles;
      }
      const prodIds = filteringProducts.map((fp) => fp.product_id);
      const soldCompareValue = productJoiner === "And" ? prodIds.length : 1;
      const notSoldCompareValue = productJoiner === "And" ? 0 : prodIds.length;
      return customersWithBottles.filter((cwb) =>
        productSold
          ? // account has sold all in filter
            cwb.bottles.filter((b) =>
              prodIds.includes(parseInt(b.product_id, 10))
            ).length >= soldCompareValue
          : // account has sold 0 in filter
            cwb.bottles.filter((b) =>
              prodIds.includes(parseInt(b.product_id, 10))
            ).length <= notSoldCompareValue
      );
    }, [customersWithBottles, filteringProducts, productSold, productJoiner]);

    useEffect(() => {
      reportUp({
        action: "customerListUpdate",
        data: customers,
      });
    }, [customers]);

    useEffect(() => {
      reportUp({
        action: "customerListUpdate",
        data: displayAccounts,
      });
    }, [displayAccounts]);

    const selectionSet = new Set(selection);
    const visibleCustomers = customersWithBottles.map((c) => c.account_id);
    const visibleCustomersSet = new Set(visibleCustomers);

    const handleSearchClose = () => {
      setOpenSearch(false);
    };
    const handleCloseList = () => {
      setOpenLists(false);
    };
    async function postToList() {
      const postBody = {
        list_id: selectedList,
        account_id: undefined,
        is_active: 1,
      };
      postListsApi.request(postBody);
      setOpenLists(false);
    }

    const exportToList = (list) => {
      const payload = {
        body: {
          list_id: exportList,
          account_id: selection,
          is_active: 1,
        },
        headers: {},
      };

      setExportState("posting");

      API.post("backendGateway", "/accounts/listitems", payload)
        .then(() => {
          setExportState("success");
        })
        .catch(() => setExportState("error"));
    };

    const checkBoxAction = (event, customer_id) => {
      event.stopPropagation();
      event.preventDefault();
      if (!selection.includes(customer_id)) {
        setSelection([...selection, customer_id]);
      } else {
        setSelection(selection.filter((id) => id !== customer_id));
      }
    };

    const toggleSelectAll = () => {
      if (!setEqual(selectionSet, visibleCustomersSet)) {
        setSelection(visibleCustomers);
      } else {
        setSelection([]);
      }
    };

    const accountFilterStringUpdate = (event) => {
      setAccountFilterString(event.target.value);
    };

    const resultRow = ({ index, style, imgUrls }) => {
      const customer = displayAccounts[index];

      if (!customer) {
        // return <></>;
      }
      return (
        <ListItem
          key={customer.account_id}
          component={RouterLink}
          to={"/app/accountdetails/" + customer.account_id}
          style={style}
        >
          <div
            onClick={(event) => checkBoxAction(event, customer.account_id)}
            className={clsx(classes.checker, className)}
          >
            {selection.includes(customer.account_id) ? (
              <CheckBox />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </div>
          <ListItemText
            primary={
              <Typography variant="h6" style={{ color: "#263238" }}>
                {customer.account_displayname}
              </Typography>
            }
            secondary={`${
              (customer.account_address || "") +
              " " +
              (customer.account_city || "") +
              ", " +
              (customer.account_region || "")
            }`}
          />

          <AvatarGroup max={3}>
            {customer.bottles.map((bottle) => (
              <Avatar
                key={bottle.distributor_account_id + "-" + bottle.product_id}
                style={{ backgroundColor: "#ffffff" }}
              >
                {/** TODO: only one this once per product  */}
                <img
                  src={productImgUrls[bottle.product_id.toString()]}
                  alt={bottle.product_displayname}
                  style={{
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    objectFit: "contain",
                  }}
                />
              </Avatar>
            ))}
          </AvatarGroup>
        </ListItem>
      );
    };

    let CheckBoxToRender = CheckBoxOutlineBlank;
    if (isSubset(visibleCustomersSet, selectionSet))
      CheckBoxToRender = CheckBoxTwoTone;
    if (setEqual(selectionSet, visibleCustomersSet))
      CheckBoxToRender = CheckBox;

    return (
      <>
        <Box
          mt={3}
          className={clsx(classes.toolbarWrapper)}
          style={{
            order: highEnough ? 1 : 2,
            flexBasis: "400px",
          }}
        >
          <div>
            {loading && (
              <div>
                {" "}
                <CircularProgress />{" "}
              </div>
            )}
          </div>
          <Card className={clsx(classes.root, className)} {...rest}>
            <Grid container>
              <Grid item xs={5}>
                {customersWithBottles.length > 0 && (
                  <List>
                    <ListItem onClick={toggleSelectAll}>
                      <div className={clsx(classes.checker, className)}>
                        <CheckBoxToRender />
                      </div>
                      <ListItemText
                        primary={
                          <Typography variant="h6" style={{ color: "#263238" }}>
                            Select All {displayAccounts.length}{" "}
                            {city && `in ${city}`} ({selectionSet.size}{" "}
                            Selected)
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                )}
              </Grid>
              <Grid item xs={7}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ marginTop: 5 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      alignItems: "center",
                      paddingRight: "8px",
                    }}
                  >
                    <TextField
                      label={"Filter by Name"}
                      value={accountFilterString}
                      onChange={accountFilterStringUpdate}
                    />
                    <Button
                      endIcon={<Cancel />}
                      variant="contained"
                      onClick={() => setAccountFilterString("")}
                      style={{ maxHeight: 40 }}
                    >
                      Clear
                    </Button>

                    <Button
                      endIcon={<CloudDownload />}
                      variant="contained"
                      onClick={() => exportData(customersWithBottles, "csv")}
                      style={{ maxHeight: 40 }}
                    >
                      CSV
                    </Button>
                    <Button
                      endIcon={<CloudDownload />}
                      variant="contained"
                      onClick={() => exportData(customersWithBottles, "xlsx")}
                      style={{ maxHeight: 40 }}
                    >
                      XLSX
                    </Button>
                    <>
                      <Button
                        endIcon={<RemoveCircle />}
                        variant="contained"
                        disabled={selectionSet.size < 1}
                        onClick={() => setShowRemoveModal(true)}
                        style={{ marginRight: 10, maxHeight: 40 }}
                      >
                        Remove
                      </Button>
                      {showRemoveModal && (
                        <DeleteModal
                          title={`Remove ${
                            selectionSet.size > 1 ? "Accounts" : "Account"
                          } from ${listName} List`}
                          description={RemoveModalDescription(
                            selectionSet,
                            displayAccounts
                          )}
                          deleteButtonLoading={false}
                          handleConfirmDelete={() =>
                            removeAccountsFromList(selectionSet)
                          }
                          open={showRemoveModal}
                          onClose={() => setShowRemoveModal(false)}
                          altDeleteText="Remove"
                        />
                      )}
                    </>
                  </div>
                  <Grid item xs={3}>
                    <Button
                      endIcon={<Search />}
                      variant="contained"
                      onClick={() => setOpenSearch(true)}
                    >
                      Search Account
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                height: "calc(100% - 80px)",
              }}
            >
              {!loading && (
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemSize={60}
                      itemCount={displayAccounts.length}
                      dense
                      className={
                        classes.root +
                        " pig-" +
                        Object.keys(productImgUrls).length
                      }
                    >
                      {resultRow}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              )}
            </div>

            <Dialog
              open={openSearch}
              onClose={(event, reason) => {
                handleSearchClose();
              }}
              aria-labelledby="form-dialog-title"
              BackdropProps={{
                style: { backgroundColor: "black", opacity: 0.1 },
              }}
              fullWidth
            >
              <AccountSearch
                className={className}
                handleClose={handleSearchClose}
              />
            </Dialog>
            <Dialog
              open={openLists}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  handleCloseList();
                }
              }}
              aria-labelledby="form-dialog-title"
              BackdropProps={{
                style: { backgroundColor: "black", opacity: 0.1 },
              }}
            >
              <DialogTitle id="form-dialog-title">Select List</DialogTitle>
              <DialogContent className={clsx(classes.dialog, className)}>
                <Select
                  fullWidth
                  id="demo-simple-select-outlined"
                  name="visit_purpose"
                  onChange={(event, value) =>
                    setSelectedList(event.target.value)
                  }
                  value={selectedList}
                >
                  {lists
                    .filter((i) => i.type === "USER")
                    .map((item) => (
                      <MenuItem key={item.list_id} value={item.list_id}>
                        {item.list_name}
                      </MenuItem>
                    ))}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseList} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => postToList()} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openExports}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  setOpenExports(false);
                }
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Export To</DialogTitle>
              <DialogContent className={clsx(classes.dialog, className)}>
                <Select
                  fullWidth
                  id="demo-simple-select-outlined"
                  name="visit_purpose"
                  onChange={(event, value) => setExportList(event.target.value)}
                  value={exportList}
                >
                  {lists
                    .filter((i) => i.type === "USER")
                    .map((item) => (
                      <MenuItem key={item.list_id} value={item.list_id}>
                        {item.list_name}
                      </MenuItem>
                    ))}
                </Select>
                {exportState === "success" && (
                  <Alert
                    severity="success"
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    {selection.length > 1
                      ? `${selection.length} accounts`
                      : `Account`}{" "}
                    saved to{" "}
                    {lists.find((c) => c.list_id === exportList).list_name}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenExports(false)} color="primary">
                  Close
                </Button>
                <Button
                  onClick={() => exportToList()}
                  color="primary"
                  disabled={
                    exportState === "posting" || exportState === "success"
                  }
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Box>
        <Box
          mt={3}
          style={{
            flexShrink: 1,
            flexGrow: 0,
            position: "relative",
            order: highEnough ? 2 : 1,
          }}
        >
          <Card>
            <Box
              m={1}
              style={{
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Box mr={2}>
                <Button
                  title="Copy All To List"
                  onClick={() => {
                    setOpenExports(true);
                    setSelection(customersWithBottles.map((c) => c.account_id));
                  }}
                  color="primary"
                  variant="contained"
                >
                  Copy All to List
                </Button>
              </Box>

              <Button
                title="Copy Selection to List"
                onClick={() => {
                  setOpenExports(true);
                }}
                color="primary"
                variant="contained"
                disabled={selection.length === 0}
              >
                Copy {selection.length} selected to List
              </Button>
            </Box>
          </Card>
        </Box>
      </>
    );
  }
);

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
