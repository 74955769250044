import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import { observer } from "mobx-react-lite";
import {
  Card,
  Grid,
  CardContent,
  makeStyles,
  CardHeader,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  IconButton,
} from "@material-ui/core";
import { ChevronDown, ChevronUp } from "react-feather";
import { cloneDeep } from "lodash";
import { useStore } from "../../../stores/StoreContext";
import { Link as RouterLink } from "react-router-dom";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  loading: {
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: theme.spacing(4),
  },
  statbox: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
  selectableRow: {
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}));

const TopAccounts = observer(({ filterArgs, dailyStats }) => {
  const { allStates } = useStore();

  const classes = useStyles();

  const ccRef = useRef();
  const [isExpand, setIsExpand] = useState(true);
  const [reducedStats, setReducedStats] = useState([]);
  const [toggledRow, setToggledRow] = useState(-1);

  const headerData =
    filterArgs.states.size >= 6
      ? [`${filterArgs.states.size}/${allStates.length} States`]
      : Array.from(filterArgs.states).toString();

  useEffect(() => {
    const rsObj = cloneDeep(dailyStats).reduce((acc, cur) => {
      if (acc[cur.PRODUCTNAME]) {
        acc[cur.PRODUCTNAME].NINE_SOLD += cur.NINE_SOLD;
        acc[cur.PRODUCTNAME].BBL += cur.BBL;
        acc[cur.PRODUCTNAME].QTYSOLD += cur.QTYSOLD;
        acc[cur.PRODUCTNAME].PHYSICAL_SOLD += cur.PHYSICAL_SOLD;
        if (acc[cur.PRODUCTNAME].accounts["_" + cur.ACCOUNT_ID]) {
          acc[cur.PRODUCTNAME].accounts["_" + cur.ACCOUNT_ID].NINE_SOLD +=
            cur.NINE_SOLD;
          acc[cur.PRODUCTNAME].accounts["_" + cur.ACCOUNT_ID].BBL += cur.BBL;
          acc[cur.PRODUCTNAME].accounts["_" + cur.ACCOUNT_ID].QTYSOLD +=
            cur.QTYSOLD;
          acc[cur.PRODUCTNAME].accounts["_" + cur.ACCOUNT_ID].PHYSICAL_SOLD +=
            cur.PHYSICAL_SOLD;
        } else {
          acc[cur.PRODUCTNAME].accounts["_" + cur.ACCOUNT_ID] = cur;
        }
      } else {
        acc[cur.PRODUCTNAME] = {
          ...cur,
          accounts: {
            ["_" + cur.ACCOUNT_ID]: cur,
          },
        };
      }
      return acc;
    }, {});
    const rsArr = Object.values(rsObj)
      .map((product) => ({
        ...product,
        accounts: Object.values(product.accounts)
          .sort((a, b) =>
            a.NINE_SOLD > b.NINE_SOLD ? -1 : a.NINE_SOLD < b.NINE_SOLD ? 1 : 0
          )
          .slice(0, 10),
      }))
      .sort((a, b) =>
        a.NINE_SOLD > b.NINE_SOLD ? -1 : a.NINE_SOLD < b.NINE_SOLD ? 1 : 0
      )
      .slice(0, 10);
    setReducedStats(rsArr);
  }, [dailyStats]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xs={12}>
        <Card>
          <CardHeader
            title={`${headerData} Top Products`}
            className="st-card-header"
            action={
              <IconButton
                size="medium"
                onClick={() => setIsExpand((prev) => !prev)}
              >
                {isExpand ? (
                  <ExpandLess style={{ fill: "white", fontSize: "30px" }} />
                ) : (
                  <ExpandMore style={{ fill: "white", fontSize: "30px" }} />
                )}
              </IconButton>
            }
          />
          <CardContent ref={ccRef}>
            <Box style={{ display: isExpand ? "block" : "none" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      Product Name
                    </TableCell>
                    {/* <TableCell>Phone</TableCell> */}
                    <TableCell style={{ padding: "8px" }}>9Ltr Case</TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      Physical Case
                    </TableCell>
                    <TableCell style={{ padding: "8px" }}>BBL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reducedStats.map((product, index) => {
                    return (
                      <Fragment key={index}>
                        <TableRow
                          key={index}
                          className={classes.selectableRow}
                          onClick={() => {
                            setToggledRow(toggledRow === index ? -1 : index);
                          }}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {toggledRow === index ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )}
                          </TableCell>
                          <TableCell style={{ padding: "8px" }}>
                            {product.PRODUCTNAME}
                          </TableCell>
                          {/* <TableCell>
                            <a href={`tel:${account.account_phone}`}>
                              {account.account_phone}
                            </a>
                          </TableCell> */}
                          <TableCell style={{ padding: "8px" }}>
                            {" "}
                            {product.NINE_SOLD.toFixed(2)}{" "}
                          </TableCell>

                          <TableCell style={{ padding: "8px" }}>
                            {" "}
                            {product.PHYSICAL_SOLD.toFixed(2)}{" "}
                          </TableCell>
                          <TableCell style={{ padding: "8px" }}>
                            {" "}
                            {product.BBL.toFixed(2)}{" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {toggledRow === index && (
                            <TableCell
                              colSpan={5}
                              style={{
                                paddingRight: 0,
                                borderLeft: "2px solid #000",
                              }}
                            >
                              <Box>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Top Accounts</TableCell>
                                      <TableCell>Address</TableCell>
                                      <TableCell>9Ltr Case</TableCell>
                                      <TableCell>Physical Case</TableCell>
                                      <TableCell>BBL</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {product.accounts.map((account) => {
                                      return (
                                        <TableRow key={account.SKU}>
                                          <TableCell>
                                            <RouterLink
                                              to={
                                                "/app/accountdetails/" +
                                                account.SKU
                                              }
                                            >
                                              {account.STORENUMBER}
                                            </RouterLink>
                                          </TableCell>
                                          <TableCell>
                                            {account.STOREADDRESS}
                                            <br />
                                            {account.CITY} {account.STATE}
                                          </TableCell>
                                          <TableCell>
                                            {account.NINE_SOLD.toFixed(2)}
                                          </TableCell>

                                          <TableCell>
                                            {account.PHYSICAL_SOLD.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {account.BBL.toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default TopAccounts;
