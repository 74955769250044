import React, { useState, useEffect, useRef } from "react";
import { API, JS } from "aws-amplify";
import LuxonUtils from "@date-io/luxon";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Box,
  Container,
  makeStyles,
  Popover,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  ButtonGroup,
  InputAdornment,
  SvgIcon,
  Snackbar,
  Divider,
  Grid,
  MenuItem,
  Popper,
  Grow,
  MenuList,
  Paper,
} from "@material-ui/core";
import Page from "../../../components/Page";
import TeamProfileStat from "./TeamProfileStat";
import Profile from "./Profile";
import Targets from "../../targets";
import { useParams } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { trackEvent } from "../../../api/analytics";
import TeamTargetModal from "./TeamTargetModal";
import useApi from "../../../hooks/useApi";
import teamsApi from "../../../api/team";
import listsApi from "../../../api/lists";
import { useStore } from "../../../stores/StoreContext";
import ProfileActivityTracker from "./ProfileActivityTracker";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Loader } from "@aws-amplify/ui-react";

const filterTitles = [
  "All",
  "account_checkin",
  "contact_created",
  "media_upload",
  "form_response",
  "image_analysis",
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TeamProfileView = () => {
  const { id } = useParams();
  const getTeamsApi = useApi(teamsApi.getTeamTarget);
  const getListsApi = useApi(listsApi.getLists);
  const [accountSelected, setAccountSelected] = useState();
  const [updatedAccounts, setUpdatedAccounts] = useState(false);
  const store = useStore();
  const { userInfo } = store;
  const [dataFilter, setDataFilter] = useState("All");
  const anchorRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterDate, setOpenFilterDate] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toDate, setToDate] = useState(new Date());
  const dateFilterButtons = ["All", "This Month", "This Week", "Today"];
  const [activeDateAction, setActiveDateAction] = useState("This Month");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRefDate = useRef(null);
  const [activitiesData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateChanged, setDateChanged] = useState(false);
  const { roles } = userInfo;
  const isAdmin = () => {
    return roles.includes("ADMIN");
  };
  const admin = isAdmin();
  const resetDateRangesToDefault = () => {
    setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    setToDate(new Date());
    setActiveDateAction("This Month");
    setDateChanged(false);
  };

  function updateAccounts() {
    if (accountSelected !== undefined && accountSelected != null) {
      postListAccountData(accountSelected.list_id);
    }
  }
  const filterData = (res) => {
    if (dataFilter === "All") {
      setFormattedData(
        res.filter(
          (item) =>
            new Date(item.created) >= new Date(fromDate) &&
            new Date(item.created) <= new Date(toDate) &&
            item.user_id === parseInt(id, 10)
        )
      );
    } else {
      setFormattedData(
        res.filter(
          (item) =>
            new Date(item.created) >= new Date(fromDate) &&
            new Date(item.created) <= new Date(toDate) &&
            item.user_id === parseInt(id, 10) &&
            item.type === dataFilter
        )
      );
    }

    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getListOfAllData().then((res) => {
      filterData(res);
    });
  }, [fromDate, toDate, id]);

  async function postListAccountData(list_id) {
    try {
      const apiName = "backendGateway";
      const path = "/accounts/team/list";
      const jsonBody = {
        list_id: list_id,
        manufacturer_id: userInfo.mid,
        user_id: id,
      };
      const myInit = {
        // OPTIONAL
        body: jsonBody,
        headers: {}, // OPTIONAL
      };
      await API.post(apiName, path, myInit);
      setUpdatedAccounts(!updatedAccounts);
      getTargets();
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenFilter(false);
    }
  }
  const handleDateChange = (type, date) => {
    setDateChanged(true);
    setActiveDateAction("Custom");
    if (type === "to") {
      setToDate(date);
    } else if (type === "from") {
      setFromDate(date);
    }
  };
  const setFilter = (filter) => {
    if (filter === "All") {
      setFormattedData(
        activitiesData.filter(
          (event) =>
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate) &&
            event.user_id === parseInt(id, 10)
        )
      );
    } else {
      setFormattedData(
        activitiesData.filter(
          (event) =>
            event.type === filter &&
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate) &&
            event.user_id === parseInt(id, 10)
        )
      );
    }
    setOpenFilter(false);
    setDataFilter(filter);
  };

  const handleToggle = (param) => {
    if (param === "filter") {
      setOpenFilter((prevOpen) => !prevOpen);
    } else {
      setOpenFilterDate((prevOpen) => !prevOpen);
    }
  };

  const getListOfAllData = async () => {
    try {
      const data = await API.get("backendGateway", "/teams/activities", {
        queryStringParameters: {
          mid: userInfo.mid,
        },
      });
      setActivityData(data.results);
      return data.results;
    } catch (err) {
      console.log("error fetching visits data..", err);
    }
  };

  const quickDateActions = (action) => {
    setDateChanged(true);
    setDataFilter("All");
    setActiveDateAction(action);
    const now = new Date();
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    );

    if (action === "This Month") {
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      setFromDate(startOfMonth);
      setToDate(endOfDay);
    } else if (action === "This Week") {
      setFromDate(startOfWeek);
      setToDate(endOfDay);
    } else if (action === "Today") {
      const startOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      setFromDate(startOfDay);
      setToDate(endOfDay);
    } else if (action === "All") {
      setFromDate(new Date(0));
      setToDate(endOfDay);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenFilterDate(false);
    setOpenFilter(false);
  };
  async function getTargets() {
    getTeamsApi.request({
      mid: userInfo.mid,
      uid: id,
      limit: 1000,
    });
  }
  const options = getListsApi.data.map((option) => {
    const firstLetter = option.list_name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      type: "Lists",
      ...option,
    };
  });
  async function getLists() {
    getListsApi.request({
      mid: userInfo.mid,
      uid: id,
      limit: 1000,
    });
  }
  const classes = useStyles();
  const [openTarget, setTargetOpen] = useState(false);
  const handleTargetClose = (value) => {
    setTargetOpen(false);
    getTargets();
  };
  const handleTargetOpen = (value) => {
    setTargetOpen(true);
  };
  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:TeamMember");
    getLists();
    getTargets();
  }, []);

  return (
    <Page className={classes.root} title="Account">
      <TeamTargetModal open={openTarget} onClose={handleTargetClose} />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile />
            {/*
            <Box my={1}>
              <Autocomplete
                id="combo-box-demo"
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.type}
                onChange={(event, value) => setAccountSelected(value)}
                getOptionLabel={(option) => option.list_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Lists"
                    variant="outlined"
                  />
                )}
              />{" "}
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!accountSelected}
              onClick={() => {
                updateAccounts();
              }}
            >
              Add to My Accounts
            </Button>
            {userInfo.uid == id && (
              <Box my={1}>
                <div style={{ height: "50px" }} />
              </Box>
            )}
           */}
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            {/* <TeamProfileStat updatedAccounts={updatedAccounts} /> */}
            {(getTeamsApi.loading ||
              (userInfo.uid == id && getTeamsApi.data?.length > 0) ||
              admin) && (
              <div>
                <div>
                  {getTeamsApi.loading && (
                    <div>
                      {" "}
                      <CircularProgress />{" "}
                    </div>
                  )}
                </div>
                {((userInfo.uid == id && getTeamsApi.data?.length > 0) ||
                  admin) && (
                  <Targets
                    targets={getTeamsApi.data}
                    setTargets={getTeamsApi.setData}
                  />
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <Paper
          style={{
            display: "flex",
            width: "100%",
            padding: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              fontSize: "26px",
              fontWeight: "500",
              marginLeft: "10px",
            }}
          >
            Activity
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <ButtonGroup>
              <Button
                disabled
                style={{
                  cursor: "unset",
                  color: "black",
                  borderColor: "1px solid rgba(0, 0, 0, 0.23)",
                }}
                onClick={() => handleToggle("filter")}
              >
                Filter Type:
              </Button>
              <Button
                ref={anchorRef}
                aria-controls={openFilter ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={() => handleToggle("filter")}
                title={dataFilter}
                size={"large"}
              >
                {dataFilter}
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <div
              style={{
                borderLeft: "1px solid lightGrey",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
            <Popper
              open={openFilter}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={openFilter}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {filterTitles.map((title) => (
                          <MenuItem
                            key={title}
                            onClick={() => setFilter(title)}
                          >
                            {title.replace("_", " ").toUpperCase()}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <DatePicker
                label="START DATE"
                inputVariant="outlined"
                style={{ width: "150px" }}
                size="small"
                disableFuture="true"
                value={fromDate}
                onChange={(e) => handleDateChange("from", e)}
              />
              <div style={{ marginTop: "10px" }}>-</div>

              <DatePicker
                label="END DATE"
                inputVariant="outlined"
                style={{ width: "150px" }}
                fullWidth={false}
                size="small"
                disableFuture="true"
                value={toDate}
                onChange={(e) => handleDateChange("to", e)}
              />
            </MuiPickersUtilsProvider>
            <div
              style={{
                borderLeft: "1px solid lightGrey",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
            <Button
              style={{
                border: "1px solid lightGrey",
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
              ref={anchorRefDate}
              aria-controls={openFilterDate ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => handleToggle("date")}
              title={dataFilter}
              size={"large"}
            >
              {activeDateAction}
              <ArrowDropDownIcon />
            </Button>
            <Popper
              open={openFilterDate}
              anchorEl={anchorRefDate.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={openFilterDate}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {dateFilterButtons.map((title) => (
                          <MenuItem
                            key={title}
                            onClick={() => quickDateActions(title)}
                          >
                            {title}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {dateChanged ? (
              <Button
                style={{
                  border: "1px solid lightGrey",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  color: "#1A4399",
                }}
                onClick={() => {
                  resetDateRangesToDefault();
                }}
              >
                Reset Date
              </Button>
            ) : (
              ""
            )}
          </div>
        </Paper>
        {!loading ? (
          <ProfileActivityTracker
            data={formattedData.sort(
              (a, b) => new Date(b.created) - new Date(a.created)
            )}
          />
        ) : (
          <Loader />
        )}
      </Container>
    </Page>
  );
};

export default TeamProfileView;
