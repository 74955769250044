const brands = {
  internalBrands: [],
  setInternalBrands(arr) {
    this.internal = arr;
  },
  externalBrands: [],
  setExternalBrands(arr) {
    this.external = arr;
  },
};

export default brands;
