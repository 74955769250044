import {
  Paper,
  Table,
  TableContainer,
  Typography,
  TablePagination,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
// import data from "./sampleData.json";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { API } from "aws-amplify";
import { useStore } from "../../../stores/StoreContext";

const DistributorDataByMonth = () => {
  const [data, setData] = useState(false);
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    getData().then(setData);
  }, []);

  const getData = async () => {
    const response = await API.get(
      "backendGateway",
      "/manufacturers/" + userInfo.mid + "/distributors_data"
    ).catch((err) => console.log(err));
    return response;
  };

  const navigate = useNavigate();
  const BlueBrick = () => {
    return (
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: "#3C7DF6",
          borderRadius: 2,
        }}
      />
    );
  };
  const RedBrick = ({ month = false, dist_id, legend = false }) => {
    const linkToUploads = () => {
      navigate("/app/log/" + month + "/" + dist_id);
    };
    return (
      <>
        {month && !legend ? (
          <div
            onClick={() => linkToUploads()}
            style={{
              width: 20,
              height: 20,
              backgroundColor: "#FF0000",
              borderRadius: 2,
              cursor: "pointer",
            }}
          >
            <PriorityHighIcon
              style={{
                color: "white",
                margin: 0,
                padding: 0,
                fontSize: "1.3rem",
                marginTop: -8,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: 20,
              height: 20,
              backgroundColor: "#FF0000",
              borderRadius: 2,
            }}
          >
            <PriorityHighIcon
              style={{
                color: "white",
                margin: 0,
                padding: 0,
                fontSize: "1.3rem",
                marginTop: -8,
              }}
            />
          </div>
        )}
      </>
    );
  };
  const GreyBrick = () => {
    return (
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: "#EDEDED",
          borderRadius: 2,
        }}
      />
    );
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const currentMonth = new Date().getMonth();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const thisYear = new Date().getFullYear();
  const [sortAsc, setSortAsc] = useState(true); // State for sorting order

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const sortDistributors = (ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const nameA = a.dist_displayname.toUpperCase();
      const nameB = b.dist_displayname.toUpperCase();
      if (nameA < nameB) return ascending ? -1 : 1;
      if (nameA > nameB) return ascending ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const handleSortClick = () => {
    setSortAsc(!sortAsc);
  };

  const countBlueAndRedBricks = () => {
    let blueBricks = 0;
    let redBricks = 0;

    data.forEach((dist) => {
      monthNames.forEach((_, index) => {
        if (dist[currentYear]?.some((item) => item.month === index + 1)) {
          blueBricks++;
        } else if (index + 1 <= currentMonth || !(currentYear === thisYear)) {
          redBricks++;
        }
      });
    });

    return { blueBricks, redBricks };
  };

  const calculatePercentage = () => {
    const { blueBricks, redBricks } = countBlueAndRedBricks();
    const total = blueBricks + redBricks;
    return ((blueBricks / total) * 100).toFixed(2);
  };

  return (
    <>
      {data ? (
        <TableContainer
          component={Paper}
          style={{ marginBottom: 20, padding: 10 }}
        >
          <Button onClick={(e) => setCurrentYear(currentYear - 1)}>
            {" "}
            {"<"}{" "}
          </Button>
          {currentYear}
          <Button
            onClick={(e) => {
              if (currentYear + 1 <= new Date().getFullYear()) {
                setCurrentYear(currentYear + 1);
              }
            }}
          >
            {">"}
          </Button>
          {calculatePercentage()}% of Data Complete
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ flex: "1 0 25%" }}>
                <Button onClick={handleSortClick}>
                  Dist Names{" "}
                  {sortAsc ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Button>
              </div>
              <div
                style={{
                  flex: "1 0 75%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {monthNames.map((monthName, index) => (
                  <div key={index}>{monthName}</div>
                ))}
              </div>
            </div>

            {/* Business Data Rows */}
            {sortDistributors(sortAsc)
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((dist, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      flex: "1 0 25%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    className="business-name"
                  >
                    <RouterLink to={"/app/distributorsDetails/" + dist.dist_id}>
                      {dist.dist_displayname}
                    </RouterLink>
                  </div>
                  <div
                    style={{
                      flex: "1 0 75%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {monthNames.map((_, index) => (
                      <div key={index}>
                        {dist[currentYear]?.some(
                          (item) => item.month === index + 1
                        ) ? (
                          <BlueBrick />
                        ) : index + 1 <= currentMonth ||
                          !(currentYear === thisYear) ? (
                          <RedBrick month={index + 1} dist_id={dist.dist_id} />
                        ) : (
                          <GreyBrick />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {"Data Uploaded/Depletions: "} <BlueBrick />{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {"Data Missing/No Depletions: "} <RedBrick legend={true} />{" "}
              </div>
            </div>
            <TablePagination
              component="div"
              count={data.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            />
          </div>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default DistributorDataByMonth;
