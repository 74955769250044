import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { Send, ArrowBack } from "@material-ui/icons";
import {
  Button,
  Box,
  TextField,
  Card,
  Chip,
  Container,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Alert, Typography } from "@mui/material";
import FieldAdder from "./FieldAdder";
import BuilderCard from "./BuilderCard";
import BuilderHistory from "./BuilderHistory";
import FormOptions from "./FormOptions";
import { Autocomplete, FormControl } from "@mui/material";
import { fieldTypes } from "../fieldTypes";
import { useNavigate } from "react-router";
import { useStore } from "../../../../stores/StoreContext";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import distributorsAPI from "../../../../api/distributors";
import productsAPI from "../../../../api/products";
import { normaliseFields } from "../helpers";
import usersApi from "../../../../api/users";
export class optionMaker {
  constructor(string) {
    this.text = string;
    this.value = string;
    this.key = uuid();
    this.children = [];
  }
}
class rowMaker {
  constructor(string) {
    this.text = string;
    this.value = string;
    this.key = uuid();
  }
}

const dateTimeProps = {
  dateFormat: "MM/dd/yyyy",
  timeFormat: "hh:mm aa",
};
class v3Field {
  constructor(value, label, element, prefix) {
    this.field_type = value;
    this.value = value;
    this.label = label;
    this.decimal = true;
    this.options = [new optionMaker("option a")];
    this.rows = [new rowMaker("row a")];
    this.required = false;
    this.id = uuid();
    // FB2 compatibility
    this.element = element;
    this.canHaveAnswer = true;
    this.canHaveOptionValue = true;
    this.field_name = prefix + uuid();
    if (this.value === "datetime") {
      this.dateFormat = "MM/dd/yyyy";
      this.timeFormat = "hh:mm aa";
    }
    this.conditionalGroups = [];
  }
}
const { value, label, element, prefix } = fieldTypes[0];
const defaultField = () => new v3Field(value, label, element, prefix);

const Builder = observer(
  ({ save, saving, form, successMessage, setSuccessMessage }) => {
    const [formStates, setFormStates] = useState([
      {
        form_name: form.form_name,
        fields: form.fields,
        on_premise: form.on_premise,
        on_account: form.on_account,
        send_emails: form.send_emails?.split(" ") || [],
      },
    ]);
    const [stateStep, setStateStep] = useState(0);
    const [editableFormName, setEditableFormName] = useState(form.form_name);
    const [activeFieldUuid, setActiveFieldUuid] = useState("");
    const fieldRef = useRef(null);

    const navigate = useNavigate();

    const nameChangeTimer = useRef(null);
    const {
      userInfo,
      distributorsList,
      setDistributors,
      productsList,
      setProducts,
    } = useStore();
    const { mid } = userInfo;
    const [allUsers, setAllUsers] = useState([]);

    const setSelectedEmails = (changeObject) => {
      const newState = {
        ...currentState,
        send_emails: changeObject.join(" "),
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };

    const currentState = useMemo(() => {
      return formStates[stateStep];
    }, [formStates, stateStep]);

    const [checked, setChecked] = useState({
      products: false,
      distributors: false,
    });

    useEffect(() => {
      usersApi
        .getUsers({
          mid: mid,
          limit: 1000,
        })
        .then((res) => {
          setAllUsers(res?.results || []);
        });
    }, []);

    useEffect(() => {
      const variants = currentState.fields
        .filter((f) => f.value === "dynamicOptions")
        .map((f) => f.variant);
      if (variants.length) {
        const { manufacturer_id } = userInfo;

        if (
          variants.includes("distributors") &&
          !distributorsList.length &&
          !checked.distributors
        ) {
          distributorsAPI
            .getDistributors(manufacturer_id)
            .then((results) => {
              setDistributors(results);
            })
            .finally(() =>
              setChecked({
                ...checked,
                distributors: true,
              })
            );
        }
        if (
          (variants.includes("products") || variants.includes("brands")) &&
          !productsList.length
        ) {
          productsAPI
            .getProducts({ mid: manufacturer_id })
            .then(({ results }) => {
              setProducts(results);
            })
            .finally(() =>
              setChecked({
                ...checked,
                products: true,
              })
            );
        }
      }
    }, [currentState.fields]);

    useEffect(() => {
      const fields = normaliseFields(form.fields, fieldTypes);
      const { form_name, on_account, on_premise, send_emails } = form;
      setFormStates([
        {
          form_name,
          on_account,
          on_premise,
          send_emails,
          fields,
        },
      ]);
      setStateStep(0);
    }, [form]);

    const addField = () => {
      const { form_name, fields, on_account, on_premise, send_emails } =
        currentState;
      const newState = {
        form_name,
        on_account,
        on_premise,
        send_emails,
        fields: [...fields, defaultField()],
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };

    const duplicateField = (field) => {
      const newFieldName =
        field.field_name.substring(0, field.field_name.length - 36) + uuid();
      const newState = {
        ...currentState,
        fields: [
          ...currentState.fields,
          {
            ...field,
            id: uuid(),
            field_name: newFieldName,
          },
        ],
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };

    const deleteField = (field_uuid) => {
      const newState = {
        ...currentState,
        fields: currentState.fields.filter((f) => f.id !== field_uuid),
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };

    const changeField = (field_uuid, changeObject) => {
      console.log({
        field_uuid,
        changeObject,
      });
      const newState = {
        ...currentState,
        fields: currentState.fields.map((f) => {
          if (f.id === field_uuid) {
            if (
              changeObject[element] &&
              changeObject[element] === "DatePicker"
            ) {
              Object.entries(dateTimeProps).forEach(([key, value]) => {
                changeObject[key] = value;
              });
            }
            const field_name = changeObject.prefix
              ? changeObject.prefix + field_uuid
              : f.field_name;
            return {
              ...f,
              ...changeObject,
              field_name,
            };
          }
          return f;
        }),
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };

    const changeFormProp = (changeObject) => {
      const newState = {
        ...currentState,
        ...changeObject,
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };

    const onDragEnd = ({ destination, source }) => {
      if (destination === null) return;
      const moved = currentState.fields[source.index];
      let tempArr = [...currentState.fields];
      tempArr.splice(source.index, 1);
      tempArr.splice(destination.index, 0, moved);
      const newState = {
        ...currentState,
        fields: tempArr,
      };
      setFormStates([...formStates.slice(0, stateStep + 1), newState]);
      setStateStep(stateStep + 1);
    };
    const grid = 2;
    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: "0",
      margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "transparent",

      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
      background: isDraggingOver ? "#dfd" : "transparent",
      width: "100%",
    });

    const saveForm = (is_open = false) => {
      const { form_id, form_uuid } = form;
      const payload = {
        form_id,
        form_uuid,
        form_name: currentState.form_name,
        form_body: currentState.fields,
        on_account: currentState.on_account,
        on_premise: currentState.on_premise,
        send_emails: currentState.send_emails,
        userInfo,
        is_public: true,
        is_open,
        is_active: true,
      };
      save(payload);
    };

    useEffect(() => {
      if (editableFormName !== currentState.form_name) {
        nameChangeTimer.current && clearTimeout(nameChangeTimer.current);
        nameChangeTimer.current = setTimeout(() => {
          changeFormProp({
            form_name: editableFormName,
          });
        }, 800);
      }
    }, [editableFormName]);

    const saveDisabled = useMemo(() => {
      return saving || currentState.fields.length === 0;
    }, [form, currentState, saving]);

    const close = () => {
      navigate("/app/forms");
    };

    const [isFocused, setIsFocused] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };
    return (
      <Container maxWidth="xl">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <Button startIcon={<ArrowBack fontSize={"large"} />} onClick={close}>
            Back to Forms
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Questions" />
              <Tab label="Settings" />
            </Tabs>
          </div>

          {/* This is just an invisible element with the same width */}
          <Box display={"flex"} justifyContent={"space-between"}>
            <BuilderHistory
              stepCount={formStates.length}
              {...{
                stateStep,
                setStateStep,
              }}
            />
          </Box>
          {/* This is just an invisible element with the same width */}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginLeft: "auto" }}></div>
        </div>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            {tabValue === 0 && (
              <>
                <Card
                  style={{
                    width: "100%",
                    padding: 20,
                    boxShadow: "none",
                    borderLeft:
                      isFocused === "formName" ? "4px solid blue" : "none",
                  }}
                >
                  <TextField
                    value={editableFormName}
                    onChange={(event) =>
                      setEditableFormName(event.target.value)
                    }
                    InputProps={{
                      style: {
                        fontSize: "30px",
                      },
                    }}
                    onFocus={() => setIsFocused("formName")}
                    onBlur={() => setIsFocused(false)}
                    InputLabelProps={{
                      style: {
                        fontSize: "30px",
                      },
                    }}
                    variant="standard"
                    fullWidth
                    autoFocus
                  />
                </Card>
                <Box display={"flex"}>
                  <Box flex={6} position={"relative"}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="cardDroppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {currentState.fields.map((field, ind) => (
                              <Draggable
                                key={field.id}
                                draggableId={field.id}
                                index={ind}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div style={{ marginBottom: 10 }}>
                                      <BuilderCard
                                        field={field}
                                        addField={addField}
                                        duplicateField={duplicateField}
                                        deleteField={deleteField}
                                        fieldTypes={fieldTypes}
                                        changeField={changeField}
                                        activeFieldUuid={activeFieldUuid}
                                        setActiveFieldUuid={setActiveFieldUuid}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        fieldRef={fieldRef}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
                <Box py={1}>
                  <FieldAdder addField={addField} disabled={saveDisabled} />
                </Box>
              </>
            )}
            {tabValue === 1 && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Card>
                  <FormOptions
                    value={currentState.on_account}
                    label="Complete on Account pages only"
                    propName={"on_account"}
                    changeFormProp={changeFormProp}
                    tip="Form is for use on accounts and can be found on account pages."
                  />
                  <FormOptions
                    value={currentState.on_premise}
                    propName={"on_premise"}
                    label="On-Premise Accounts only"
                    changeFormProp={changeFormProp}
                    tip="Form is limited to on-premise accounts."
                  />

                  <FormControl required fullWidth>
                    <Autocomplete
                      onChange={(_, newVal) => {
                        setSelectedEmails(newVal);
                      }}
                      disableCloseOnSelect
                      multiple
                      options={[...allUsers].map((option) => option.user_email)}
                      value={
                        currentState.send_emails &&
                        currentState.send_emails.length > 0
                          ? currentState.send_emails.split(" ")
                          : []
                      }
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Send Form Responses To:"
                        />
                      )}
                    />
                  </FormControl>
                </Card>
              </div>
            )}
            <Box py={1}>
              {successMessage && (
                <Alert
                  severity="success"
                  closeText="Close"
                  onClose={() => setSuccessMessage("")}
                >
                  {successMessage}{" "}
                  {form.is_open && (
                    <Link to="/app/forms">Go back to Forms</Link>
                  )}
                </Alert>
              )}
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box p={1}>
                <Typography variant="overline">
                  This version of the form is{" "}
                  {form.is_open ? "live" : "a draft"}
                </Typography>
              </Box>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => saveForm()}
                disabled={saveDisabled}
              >
                Save Draft
              </Button>
              <Button
                color="primary"
                endIcon={<Send />}
                variant="contained"
                onClick={() => saveForm(true)}
                disabled={saveDisabled}
              >
                Save & Publish
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
);

export default Builder;
