import { API } from "aws-amplify";

const apiName = "backendGateway"; //should be env variable
const get = async (endpoint, params) => {
  const response = await API.get(apiName, endpoint, {
    queryStringParameters: params,
  });
  if (response.status === 200) {
    return response;
  }
  return null;
};

const post = async (endpoint, params) => {
  const response = await API.post(apiName, endpoint, {
    body: params,
    headers: {},
  });
  if ([200, 201].includes(response.status)) {
    return response;
  }
  return { status: response.status, results: [] };
};

//201 Check?
const put = async (endpoint, params) => {
  const response = await API.put(apiName, endpoint, {
    body: params,
    headers: {},
  });
  if (response.status === 204) {
    return response;
  }
  return response;
};

const del = async (endpoint) => {
  const response = await API.del(apiName, endpoint, {
    headers: {},
  });
  if (response.status === 200) {
    return response;
  }
  return { status: response.status, results: [] };
};

export default {
  get,
  post,
  put,
  del,
};
