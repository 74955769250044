import { Box, Card, Typography } from "@material-ui/core";
import ConditionalGroupRead from "./ConditionalGroupRead";
const AnswerWrapper = ({
  field,
  answer = { value: "", textValue: "", objectValue: {} },
  answers,
  groups = [],
  parentLabel = "",
  answerFormat,
  noAnswerString,
  nested = false,
}) => {
  return (
    <Box width={"100%"} id={field.field_name} display="flex">
      <Card
        style={{
          width: "100%",
          padding: nested ? 5 : 20,
          marginBottom: nested ? 0 : -5,
          boxShadow: "none",
        }}
      >
        <Typography variant="body2">{field.label}</Typography>
        <Typography variant="subtitle2">
          {answerFormat(answer, field) || noAnswerString(field)}
        </Typography>
        <ConditionalGroupRead
          parentFieldId={field.id}
          parent={field}
          groups={groups}
          answer={answer}
          answers={answers}
          options={field.options || []}
          parentLabel={parentLabel}
          answerFormat={answerFormat}
          noAnswerString={noAnswerString}
          nested={true}
        />
      </Card>
    </Box>
  );
};

export default AnswerWrapper;
