import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  Dialog,
  makeStyles,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TablePagination,
  TableSortLabel,
  Grid,
  useMediaQuery,
  TableFooter,
} from "@material-ui/core";
import { Pagination, Stack } from "@mui/material";
import { Download, Edit } from "react-feather";
import { useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/StoreContext";
import accountsAPI from "../../../api/accounts";
import usersAPI from "../../../api/users";
import AnswersOnScreen from "../../../components/forms/v3/answers/AnswerOnScreen";
import AnswerFileDisplay from "../../../components/forms/v3/answers/AnswerFileDisplay";
import { exportResponses } from "../../../components/forms/v3/helpers";
import formAPI from "../../../api/forms";
import { awsS3Image } from "../../../helpers/awsS3Image";

import Page from "../../../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    button: {
      margin: theme.spacing(1),
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy] || !a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy] || !b[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ResponseRow({ row, form_id }) {
  const classes = useRowStyles();
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      className={classes.root}
      onClick={(e) => {
        navigate(`/app/forms/${form_id}/responses/${row.response_id}`);
      }}
    >
      <TableCell>
        <Link to={`/app/forms/${form_id}/responses/${row.response_id}`}>
          {row.response_id}
        </Link>
      </TableCell>
      <TableCell>
        {DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED)}
      </TableCell>
      <TableCell>
        <Link
          onClick={(e) => {
            e.stopPropagation();
          }}
          to={`/app/profile/${row.user_id}`}
        >
          {row.user_displayname}
        </Link>
      </TableCell>
      <TableCell>
        {row.account_displayname ? (
          <>
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={`/app/accountdetails/${row.account_id}`}
            >
              {row.account_displayname}
            </Link>
            <br />
            <>
              {row.account_address}, {row.account_city},{" "}
              {row.account_regioncode}, {row.account_region}
            </>
          </>
        ) : (
          "-"
        )}
      </TableCell>
    </TableRow>
  );
}

const headCells = [
  { id: "response_id", label: "Response ID" },
  { id: "created", label: "Created date" },
  { id: "user_displayname", label: "Created By" },
  { id: "account_displayname", label: "Associated Account" },
];

function ResponseTable({ responses, form_id }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - responses.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Table aria-label="responses table">
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span
                    style={{
                      border: 0,
                      clip: "rect(0 0 0 0)",
                      height: 1,
                      margin: -1,
                      overflow: "hidden",
                      padding: 0,
                      position: "absolute",
                      top: 20,
                      width: 1,
                    }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? stableSort(responses, getComparator(order, orderBy)).slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : stableSort(responses, getComparator(order, orderBy))
        ).map((item) => (
          <ResponseRow
            key={item.form_id + "-" + item.response_id}
            row={item}
            form_id={form_id}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 250, { label: "All", value: -1 }]}
            colSpan={4}
            count={responses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            // slotProps={{
            //   select: {
            //     inputProps: {
            //       "aria-label": "rows per page",
            //     },
            //     native: true,
            //   },
            // }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export const ResponseHeader = ({ account, response, user, editLink }) => {
  return (
    <Box my={2} minWidth={"50vw"}>
      <Box display={"flex"}>
        <Box flex={1} pr={2} textAlign={"right"}>
          <Typography variant="subtitle1">Respondent</Typography>
        </Box>
        <Box flex={2}>
          <Typography variant="subtitle1">
            <strong>{user ? user.user_displayname : "Removed user"}</strong>
          </Typography>
        </Box>
      </Box>
      {account && (
        <Box display={"flex"}>
          <Box flex={1} pr={2} textAlign={"right"}>
            <Typography variant="subtitle1">Account</Typography>
          </Box>
          <Box flex={2}>
            <Typography variant="subtitle1">
              <span>
                <Link to={`/app/accountdetails/${account.gg_dist_acc_id}/`}>
                  <strong>{account.account_displayname}</strong>
                </Link>
                <br />
                {account.account_address}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
      <Box display={"flex"}>
        <Box flex={1} pr={2} textAlign={"right"}>
          <Typography variant="subtitle1">Date</Typography>
        </Box>
        <Box flex={2}>
          <Typography variant="subtitle1">
            <strong>{new Date(response.created).toLocaleDateString()}</strong>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box textAlign={"center"} py={2}>
          <Button
            variant="contained"
            component={Link}
            to={editLink}
            color="primary"
            startIcon={<Edit />}
          >
            Edit response
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const FormResponses = observer(() => {
  const [responses, setResponses] = useState([]);
  const [chosenFile, setChosenFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState({});
  const [accounts, setAccounts] = useState({});
  const { uuid } = useParams();
  const { userInfo, userList, setUserList } = useStore();

  const [openForm, setOpenForm] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    formAPI
      .getResponses(uuid)
      .then(({ results }) => {
        setResponses(
          results.sort((a, b) => new Date(b.created) - new Date(a.created))
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const loadMatchingForm = (form_id) => {
    formAPI.getFormVersions(uuid).then(({ results }) => {
      setForms(
        results.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.form_id]: cur,
          };
        }, {})
      );
    });
  };

  useEffect(() => {
    if (userList.length === 0) {
      usersAPI
        .getUsers({
          mid: userInfo.manufacturer_id,
        })
        .then(({ results }) => {
          setUserList(results);
        });
    }
  }, [userList]);

  const response = useMemo(() => {
    return responses[openForm] ? responses[openForm] : null;
  }, [responses, openForm]);

  const user = useMemo(() => {
    return response && userList
      ? userList.find((u) => u.user_id === response.user_id)
      : null;
  }, [response, userList]);

  const account = useMemo(() => {
    if (response && response.account_id) {
      return accounts["_" + response.account_id.toString()]
        ? accounts["_" + response.account_id.toString()]
        : null;
    }
    return null;
  }, [response, accounts]);

  useEffect(() => {
    if (responses.length === 0) return;
    const { form_id } = responses[openForm];
    if (!forms[form_id]) {
      loadMatchingForm(form_id);
    }
  }, [openForm, responses, forms, loadMatchingForm]);

  useEffect(() => {
    if (
      response &&
      response.account_id &&
      !accounts[response.account_id.toString()]
    ) {
      accountsAPI
        .getAccountsAID({
          aid: response.account_id,
        })
        .then(({ results }) => {
          setAccounts({
            ...accounts,
            ["_" + response.account_id.toString()]: results[0],
          });
        });
    }
  }, [response, accounts]);

  const questions = useMemo(() => {
    if (!forms[responses[openForm]?.form_id]) return [];
    return JSON.parse(forms[responses[openForm].form_id].form_body);
  }, [forms, openForm, responses]);

  const { values: answers, attachedImages } = useMemo(() => {
    if (responses.length === 0)
      return {
        values: [],
        attachedImages: [],
      };
    return JSON.parse(responses[openForm].response_body);
  }, [responses, openForm]);

  const form_name = useMemo(() => {
    if (!forms[responses[openForm]?.form_id]) return "";
    return forms[responses[openForm].form_id].form_name;
  }, [forms]);

  const beginExport = (format) => {
    exportResponses({
      responses,
      forms,
      form: {
        form_uuid: uuid,
      },
      format,
      userList,
    });
  };
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Page title="Form Responses">
      <Container className={classes.root}>
        <Grid
          container
          spacing={3}
          direction={smallScreen ? "column" : "row"}
          style={{
            minHeight: 0,
          }}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  mb={2}
                >
                  <Box>
                    <Typography variant="h4">
                      Responses for {form_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Download />}
                      onClick={() => beginExport("CSV")}
                      disabled={loading || forms.length === 0}
                    >
                      CSV
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Download />}
                      onClick={() => beginExport("XLSX")}
                      disabled={loading || forms.length === 0}
                    >
                      XLSX
                    </Button>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box>
                    {loading && <p>Loading</p>}
                    {response && (
                      <ResponseTable
                        responses={responses}
                        form_id={responses[openForm].form_id}
                      />
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Stack alignItems={"center"}>
                    <Pagination
                      count={responses.length}
                      onChange={(e, page) => setOpenForm(page - 1)}
                    />
                  </Stack>
                  {loading && <p>Loading</p>}
                  {response && (
                    <ResponseHeader
                      user={user}
                      account={account}
                      response={response}
                      editLink={`${response.response_id}/edit`}
                    />
                  )}
                  <Box minWidth={"50%"}>
                    <AnswersOnScreen answers={answers} questions={questions} />
                  </Box>
                  {attachedImages.length > 0 && (
                    <Box display={"flex"}>
                      {attachedImages.map((a, i) => (
                        <AnswerFileDisplay
                          file={a}
                          key={i}
                          setChosenFile={setChosenFile}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        onClose={() => setChosenFile(null)}
        aria-labelledby="product-dialog"
        open={chosenFile !== null}
        maxWidth="lg"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
          maxWidth={"90%"}
          maxHeight={"80%"}
          alignContent={"center"}
          p={1}
          mx={"auto"}
        >
          <Box>
            {chosenFile && (
              <img
                src={awsS3Image(
                  chosenFile.image_name,
                  chosenFile.identity_key,
                  "1000x0"
                )}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </Page>
  );
});

export default FormResponses;
