import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const LineChart = ({ data, type, goals }) => {
  const typeColors = {
    Contacts: "#75B96D",
    CheckIns: "#49D1DD",
    Media: "#8676FF",
    Forms: "#FF708B",
  };
  const chartRef = useRef(null);

  const getMonthData = () => {
    const labels = [];
    const dataValues = [];

    const startDate = new Date(data[0]);
    const endDate = new Date(data[data.length - 1]);

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1

      const monthLabel = `${year}-${month < 10 ? "0" : ""}${month}`;

      const count = data.filter(
        (date) =>
          new Date(date).getFullYear() === year &&
          new Date(date).getMonth() === month - 1 // Months are 0-indexed
      ).length;

      labels.push(monthLabel);
      dataValues.push(count);

      currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
    }

    return { labels, dataValues };
  };

  useEffect(() => {
    const ctx =
      data.length && data.length > 0 ? chartRef.current.getContext("2d") : 0;
    const { labels, dataValues } = getMonthData();

    const chartInstance = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels || [],
        datasets: [
          {
            label: type || "",
            data: dataValues || [],
            borderColor: typeColors[type],
            fill: false,
            tension: 0.4, // Adjust the tension to make the line slightly smooth
            borderWidth: 2, // Increase the width of the line
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: true,
              color: "#F8F8F8",
            },
          },
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 70,
              font: {
                size: 10,
              },
            },
            grid: {
              display: true,
              color: "#F8F8F8",
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        },
        elements: {
          point: {
            radius: 0, // Remove the points on the line
          },
        },
        interaction: {
          mode: "index", // Display the tooltip for the nearest data point
          intersect: false,
        },
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data, type]);

  return (
    <div
      style={{
        maxHeight: "400px",
        minWidth: "800px",
        padding: "10px",
        marginBottom: "40px", // Corrected the typo here
      }}
    >
      <div style={{ fontSize: "20px", fontWeight: "500", margin: "10px" }}>
        {type} - Goal: {goals[type]}
      </div>
      {data.length && data.length > 0 ? (
        <canvas ref={chartRef} />
      ) : (
        "No data to display"
      )}
    </div>
  );
};

export default LineChart;
