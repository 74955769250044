import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Alert,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";

import { useForm } from "react-hook-form";
import useApi from "../../../hooks/useApi";
import accountsApi from "../../../api/accounts";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    padding: theme.spacing(2),
    overflow: "hidden",
  },
}));

const AccountDetailsModal = ({
  onClose,
  accountDetails,
  open,
  saveValues,
  ...rest
}) => {
  const classes = useStyles();
  const putAccountsApi = useApi(accountsApi.putAccounts);
  const store = useStore();
  const { availablePremiseTypes } = store;
  const [submitInProgress, setSubmitButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [requestSuccess, setRequestSuccess] = useState(false);
  const { handleSubmit } = useForm();

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event, _) => {
    setValues((currentValues) => ({
      ...currentValues,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = () => {
    setSubmitButton(putAccountsApi.loading);
    putAccountsApi
      .request(values)
      .then((res) => {
        if (res.status === 200) {
          setErrorMessage("");
          saveValues(values);
          setRequestSuccess(true);
        }
        setTimeout(() => {
          handleClose();
        }, 1500);
        setSubmitButton(putAccountsApi.loading);
      })
      .catch((res) => {
        setErrorMessage(res.message);
        setSubmitButton(false);
      });
  };
  const [values, setValues] = useState({
    ...accountDetails,
  });
  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <DialogTitle>Edit Account Details</DialogTitle>
      {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
      {requestSuccess && (
        <Alert severity="success">Successfully Updated!</Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formControl}>
        <Grid container spacing={5}>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Name"
              variant="outlined"
              name="account_displayname"
              fullWidth
              onChange={handleChange}
              value={values.account_displayname}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Address"
              variant="outlined"
              name="account_address"
              fullWidth
              onChange={handleChange}
              value={values.account_address}
            />
          </Grid>
          <Grid item md={4} lg={4} xs={6}>
            <TextField
              label="City"
              variant="outlined"
              name="account_city"
              fullWidth
              onChange={handleChange}
              value={values.account_city}
            />
          </Grid>
          <Grid item md={4} lg={4} xs={6}>
            <TextField
              label="Region"
              variant="outlined"
              name="account_region"
              fullWidth
              onChange={handleChange}
              value={values.account_region}
            />
          </Grid>
          <Grid item md={4} lg={4} xs={12}>
            <TextField
              label="ZIP Code"
              variant="outlined"
              name="account_regioncode"
              fullWidth
              onChange={handleChange}
              value={values.account_regioncode}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <TextField
              label="Phone"
              variant="outlined"
              name="account_phone"
              fullWidth
              onChange={handleChange}
              value={values.account_phone}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="premise-type-label">Premise Type</InputLabel>
              <Select
                labelId="premise-type-label"
                value={values.premise_type}
                label="Premise Type"
                onChange={handleChange}
                name="premise_type"
                MenuProps={{ disablePortal: true }}
              >
                {availablePremiseTypes.map((premiseType) => (
                  <MenuItem key={premiseType} value={premiseType}>
                    {premiseType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12} lg={12} xs={12}>
            <Button
              disabled={submitInProgress}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

AccountDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default AccountDetailsModal;
