import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Checkbox, // Add Checkbox import
  FormControlLabel, // Add FormControlLabel import
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles(() => ({
  root: {},
}));

const CustomerDetails = ({
  className,
  onClose,
  closeSearchOverlay = () => {},
  setCustomers,
  location = null,
  ...rest
}) => {
  const classes = useStyles();
  const store = useStore();
  const { customers, userInfo, regions } = store;
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm();
  const { id } = useParams();
  const [checkboxError, setCheckboxError] = useState(false);

  function getStateCode(longName) {
    const matchingState = regions.find((r) => r.long === longName);
    if (longName === "District of Columbia") {
      return "DC";
    } else if (matchingState) {
      return matchingState.code;
    } else {
      return longName;
    }
  }

  const [values, setValues] = useState({
    account_displayname: location ? location.Label.split(",")[0] : "",
    account_address: location
      ? (location.AddressNumber || "") + " " + location.Street
      : "",
    account_city: location ? location.Municipality : "",
    account_region: location ? getStateCode(location.Region) : "",
    account_regioncode: location ? location.PostalCode : "",
    distributor_id: 1, //gp
    distributor_account_id: location
      ? userInfo.mid + "_" + location.Label.replace(/\W/g, "")
      : "",
    latitude: location ? parseFloat(location.Geometry.Point[1]) : "",
    longitude: location ? parseFloat(location.Geometry.Point[0]) : "",
    list_id: id,
    uid: userInfo.uid,
    mid: userInfo.mid,
    premise: null,
  });

  async function handleSave(payload) {
    if (values.premise !== null) {
      setSaving(true);
      const mergedPayload = {
        ...values,
        ...payload,
      };
      try {
        const apiName = "backendGateway";
        const path = "/accounts/new";
        const myInit = {
          body: mergedPayload,
          headers: {},
        };
        const data = await API.post(apiName, path, myInit);
        const newaccount = {
          ...mergedPayload,
          account_id: data.results,
        };
        setCustomers([
          ...customers,
          {
            ...newaccount,
            fromGoogle: true,
            account_latitude: newaccount.latitude,
            account_longitude: newaccount.longitude,
          },
        ]);
        setSaved(true);
        setSaving(false);
      } catch (err) {
        console.log("error fetching data..", err);
      } finally {
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } else {
      setCheckboxError(true);
    }
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit(handleSave)}
      {...rest}
    >
      <Card>
        <CardHeader title="Enter Details" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Controller
                name="account_displayname"
                defaultValue={values.account_displayname}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Premise Name"
                    name="account_displayname"
                    required
                    variant="outlined"
                    error={errors.account_displayname ? true : false}
                    helperText={`${
                      errors.account_displayname?.type === "required"
                        ? "Premise name is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Controller
                name="account_address"
                defaultValue={values.account_address}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Address"
                    name="account_address"
                    required
                    variant="outlined"
                    error={errors.account_address ? true : false}
                    helperText={`${
                      errors.account_address?.type === "required"
                        ? "Address is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Controller
                name="account_city"
                defaultValue={values.account_city}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="City"
                    name="account_city"
                    required
                    variant="outlined"
                    error={errors.account_city ? true : false}
                    helperText={`${
                      errors.account_city?.type === "required"
                        ? "City is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Controller
                name="account_region"
                defaultValue={values.account_region}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Region"
                    name="account_region"
                    required
                    variant="outlined"
                    error={errors.account_region ? true : false}
                    helperText={`${
                      errors.account_region?.type === "required"
                        ? "Region is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <div>
                <p>Premise Type:</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.premise === "ON-PREMISE"}
                      onChange={() => {
                        setValues({ ...values, premise: "ON-PREMISE" });
                      }}
                      name="premise-on"
                    />
                  }
                  label="ON-PREMISE"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.premise === "OFF-PREMISE"}
                      onChange={() => {
                        setValues({ ...values, premise: "OFF-PREMISE" });
                      }}
                      name="premise-off"
                    />
                  }
                  label="OFF-PREMISE"
                />
              </div>
              {checkboxError ? (
                <div style={{ color: "red" }}>
                  You must select a premise type.
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {!saved && !saving && (
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          )}
          {saving && <CircularProgress />}
          {saved && (
            <Box display="flex" alignItems="center">
              <CheckCircleOutlineIcon style={{ color: "green" }} />
              <Box ml={1}>Saved</Box>
            </Box>
          )}
        </Box>
      </Card>
    </form>
  );
};

CustomerDetails.propTypes = {
  className: PropTypes.string,
};

export default CustomerDetails;
