import { useEffect, useState } from "react";
import formAPI from "../../../api/forms";
import Builder from "../../../components/forms/v3/builder/Builder";
import { useParams } from "react-router";

/**
 *
 * @typedef {Object} FormPayload
 * @property {string} form_id
 * @property {string} form_uuid
 * @property {string} form_name
 * @property {Object} form_body
 * @property {boolean} on_account
 * @property {boolean} on_premise
 * @property {boolean} publish
 * @property {userInfo} Object
 *
 */

const NewForm = () => {
  const { uuid, form_id } = useParams();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [form, setForm] = useState(null);
  const savedDraft = "Form saved in drafts";
  const savedLive = "Form saved and live";

  const getLatestVersion = () => {
    if (!form_id) {
      formAPI
        .getForm(uuid)
        .then(({ results }) => {
          setForm({
            ...results,
            fields: JSON.parse(results.form_body),
            form_id: null,
          });
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      formAPI
        .getFormVersionById({
          form_uuid: uuid,
          form_id,
        })
        .then(({ result }) => {
          setForm({
            ...result,
            fields: JSON.parse(result.form_body),
          });
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  /**
   *
   * @param {FormPayload} param0
   */
  const handleFormDetail = ({
    form_name,
    form_body,
    on_account,
    on_premise,
    send_emails,
    userInfo,
    is_public,
    is_open,
  }) => {
    let _form = {
      form_uuid: uuid,
      form_name,
      form_body,
      on_account,
      on_premise,
      send_emails,
      is_public,
      is_open,
      user_id: userInfo.uid,
      manufacturer_id: userInfo.mid,
    };
    setSaving(true);
    // form will be new
    if (form.is_open) {
      formAPI
        .addForm(_form)
        .then(({ results }) => {
          setForm({
            ...results,
            fields: JSON.parse(results.form_body),
          });
          setSuccessMessage(
            `${
              _form.is_open ? savedLive : savedDraft
            } at ${new Date().toLocaleTimeString()}`
          );
          setSuccessMessage(_form.is_open ? savedLive : savedDraft);
        })
        .finally(() => setSaving(false));
    } else {
      // update form
      formAPI
        .editForm(_form, form.form_id)
        .then(({ results }) => {
          setForm({
            ...results,
            fields: JSON.parse(results.form_body),
          });
          setSuccessMessage(
            `${
              _form.is_open ? savedLive : savedDraft
            } at ${new Date().toLocaleTimeString()}`
          );
        })
        .finally(() => setSaving(false));
    }
  };

  useEffect(() => {
    getLatestVersion(uuid);
  }, [uuid]);

  return (
    <div>
      {form && (
        <Builder
          create={true}
          save={handleFormDetail}
          saving={saving}
          form={form}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
};

export default NewForm;
