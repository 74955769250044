import {
  makeStyles,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { observer } from "mobx-react-lite";
import DistributorsList from "./distributorsList";
import { useStore } from "../../../stores/StoreContext";
import distributorsAPI from "../../../api/distributors.js";
import RegionCompareAvailable from "../../../components/distributor/regions/RegionCompareAvailable";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minWidth: "100%",
    padding: theme.spacing(3),
  },
  heading: {
    fontWeight: 600,
  },
  button: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const DistributorView = observer(() => {
  const classes = useStyles();
  const { userInfo, distributorsList, setDistributors } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [showDataComponent, setShowDataComponent] = useState(false);
  const [dataFreshness, setDataFreshness] = useState([]);

  useEffect(() => {
    getDistributorsList();
    if (userInfo && userInfo.manufacturer_id === 39) {
      setShowDataComponent(true);
    }
  }, [userInfo]);

  useEffect(() => {
    setDistributors(
      distributorsList.map((d) => {
        let matchingDataStorage = dataFreshness.find(
          (df) => d.id === df.distributor_id
        );
        return {
          ...d,
          dataDate: matchingDataStorage
            ? matchingDataStorage.last_upload
            : null,
        };
      })
    );
  }, [dataFreshness]);

  async function getDistributorsList() {
    try {
      setIsLoading(true);
      const data = await distributorsAPI.getDistributors(userInfo.mid);
      setIsLoading(false);
      let _distributors = data.map((distributor) => {
        const { dist_id, dist_displayname, account_region } = distributor;
        return {
          id: dist_id,
          name: dist_displayname,
          account_region: account_region,
        };
      });
      setDistributors(_distributors);
      getDataFreshness();
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }
  const getDataFreshness = () => {
    distributorsAPI
      .getDistributorsDataFreshness(userInfo.mid)
      .then((result) => setDataFreshness(result))
      .catch((error) => console.log(error));
  };
  return (
    <Page className={classes.root} title="Distributors">
      <Grid container spacing={3} className={classes.root}>
        {showDataComponent && (
          <Grid item md={6} xs={12}>
            <RegionCompareAvailable
              manufacturer={{ id: userInfo ? userInfo.mid : 0 }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h2">
            Distributors
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          {isLoading && (
            <div align="center">
              <CircularProgress />
            </div>
          )}
          {!isLoading && <DistributorsList />}
        </Grid>
      </Grid>
    </Page>
  );
});
export default DistributorView;
