import client from "./client";
import { API } from "aws-amplify";
const apiName = "backendGateway";

const getStorage = ({ mid, limit, for_marketing }) => {
  const endpoint = "/storage";
  return client.get(endpoint, {
    mid: mid,
    limit: limit,
    for_marketing: for_marketing,
  });
};
const getDistStorage = ({ mid, limit }) => {
  const endpoint = "/storage/distributor";
  return client.get(endpoint, { mid: mid, limit: limit });
};
const getDistIds = ({ mid, limit }) => {
  const endpoint = "/storage/distributor/ids";
  return client.get(endpoint, { mid: mid, limit: limit });
};
const putStorage = (file) => {
  const endpoint = "/storage";
  return client.put(endpoint, file);
};
const postStorage = (file) => {
  const endpoint = "/storage";
  return client.post(endpoint, file);
};
const postDistStorage = (file) => {
  const endpoint = "/storage/distributor";
  return client.post(endpoint, file);
};
const postSaveInFolder = (folder_id, storage_id) => {
  const endpoint = `/storage/folders/${folder_id}?storage_id=${storage_id}`;
  return client.post(endpoint, {});
};

const removeFileFromFolder = (file_in_folder_id) => {
  const endpoint = `/storage/removefile/${file_in_folder_id}`;
  return API.del(apiName, endpoint, {});
};

const deleteFile = (storage_id) => {
  const endpoint = `/storage?storage_id=${storage_id}`;
  return API.del(apiName, endpoint, {});
};

const deleteStorage = (storageId) => {
  const endpoint = `/storage/distributor?storage_id=${storageId}`;
  return API.del(apiName, endpoint, {});
};

export default {
  getStorage,
  getDistStorage,
  getDistIds,
  putStorage,
  postStorage,
  postSaveInFolder,
  postDistStorage,
  removeFileFromFolder,
  deleteFile,
  deleteStorage,
};
