import {
  Button,
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import Backspace from "@material-ui/icons/Backspace";
import { useStore } from "../../stores/StoreContext";
import { DateTime, Interval } from "luxon";

const dateInRange = (item, dateInterval) => {
  const thisDate = DateTime.fromISO(item.DATE);
  return dateInterval.contains(thisDate);
};

const refactorDepletionsData = (
  depletionList,
  accounts,
  products,
  distributors,
  manufacturer_id
) => {
  if (!depletionList.length) {
    return [];
  }
  return depletionList.map((depletion) => {
    const account = accounts[depletion.account] || {};
    const product = products[depletion.product] || {};
    const distributor = distributors[depletion.distributor] || {};

    return {
      ACCOUNT_ID: depletion.account,
      BRAND: product.brand,
      CITY: account.city,
      DATE: depletion.date,
      DISTRIBUTOR_ID: depletion.distributor,
      DISTRIBUTOR_NAME: distributor.name,
      INTERNAL_PRODUCT_ID: product.internal_product_id,
      MANUFACTURER_ID: manufacturer_id,
      NINELTR: depletion.qty_9ltr,
      BBL: depletion.qty_bbl,
      NINE_SOLD: depletion.qty_9ltr,
      PHYSICAL_SOLD: depletion.qty_case,
      PREMISETYPE: account.premise_type,
      PRODUCTNAME: product.name,
      PRODUCT_ID: depletion.product,
      QTYSOLD: depletion.qty_unit,
      REGION: `${account.city} - ${account.state}`,
      SKU: product.brand,
      STATE: account.state,
      STOREADDRESS: account.address,
      STORENUMBER: account.name,
      UNIT_SOLD: depletion.qty_unit,
      ZIP: account.zip,
      latlong:
        account.geo && account.geo.length
          ? `${account.geo[0]} , ${account.geo[1]}`
          : null,
      phone: account.phone,
      premise_type: account.premise_type,
    };
  });
};

const dataToRows = (
  data,
  dataParams,
  filterArgs,
  dateWindows,
  { selectedQuantity, premiseTypes }
) => {
  const { dateRange, previousPeriod } = dateWindows;
  const { productSold } = filterArgs;
  const filterFields = dataParams.filter((dp) => dp.value.length > 0);
  const dateRangeInterval = Interval.fromDateTimes(...dateRange);
  const previousPeriodInterval = Interval.fromDateTimes(...previousPeriod);

  const combineField = dataParams.find((dp) => dp.value === "").property;
  const q = selectedQuantity.toUpperCase();
  return Object.values(
    data.reduce((acc, cur, ind, original) => {
      const filter =
        // brands
        (filterArgs.brands.size === 0 || filterArgs.brands.has(cur.BRAND)) &&
        // products step
        (filterArgs.products.size === 0 ||
          (productSold && filterArgs.products.has(cur.PRODUCT_ID)) ||
          (!productSold && !filterArgs.products.has(cur.PRODUCT_ID))) &&
        // states
        (filterArgs.states.size === 0 || filterArgs.states.has(cur.STATE)) &&
        // distributors
        (filterArgs.distributors.size === 0 ||
          filterArgs.distributors.has(cur.DISTRIBUTOR_NAME)) &&
        // premise
        premiseTypes.includes(cur.PREMISETYPE) &&
        (filterFields.length === 0 ||
          filterFields.filter((ff) => ff.value === cur[ff.property]).length ===
            filterFields.length);

      if (!filter) return acc;

      if (acc[cur[combineField]]) {
        if (dateInRange(cur, dateRangeInterval)) {
          acc[cur[combineField]].count += cur[q];
        } else if (dateInRange(cur, previousPeriodInterval)) {
          acc[cur[combineField]].previousCount += cur[q];
        } else {
        }
      } else {
        let previousCount = 0;
        let count = 0;
        if (dateInRange(cur, dateRangeInterval)) {
          count = cur[q];
        } else if (dateInRange(cur, previousPeriodInterval)) {
          previousCount = cur[q];
        }
        acc[cur[combineField]] = {
          ...cur,
          id: ind,
          count,
          previousCount,
        };
      }

      return acc;
    }, {})
  ).map((entry) => {
    const diff = entry.count - entry.previousCount;
    const percent =
      entry.previousCount === 0 ? 0 : (diff / entry.previousCount) * 100;
    return {
      ...entry,
      diff,
      percent,
    };
  });
};

const SalesOverview = observer(
  ({
    manufacturer_id,
    depletions,
    filterArgs,
    allDistributorsObjects,
    allAccountsObjects,
    productsInData,
  }) => {
    const { dateRange, dateRangeName, selectedQuantity, premiseTypes } =
      useStore();
    const [primary, setPrimary] = useState("STATE");
    const [dataParams, setDataParams] = useState([
      {
        property: "STATE",
        value: "",
      },
    ]);
    // const [rows, setRows] = useState([]);
    const [sortModel, setSortModel] = useState([
      { field: "count", sort: "desc" },
    ]);

    const resetPrimary = (string) => {
      setPrimary(string);
      setDataParams([
        {
          property: string,
          value: "",
        },
      ]);
    };

    const previousPeriod = useMemo(() => {
      return dateRange.map((dateBoundary) => {
        return DateTime.fromJSDate(dateBoundary).minus({ years: 1 }).toJSDate();
      });
    }, [dateRange]);

    const cols = useMemo(() => {
      if (dataParams.length === 0) return [];

      const posOrNeg = (params) => {
        return params.value > 0
          ? "positive"
          : params.value < 0
          ? "negative"
          : 0;
      };

      const colNames = {
        STATE: "State",
        BRAND: "Brand",
        PRODUCTNAME: "Product",
      };

      const firstCol = {
        field: dataParams[dataParams.length - 1].property,
        headerName: colNames[dataParams[dataParams.length - 1].property],
      };

      return [
        {
          field: firstCol.field,
          headerName: firstCol.headerName,
          width: firstCol.field === "STATE" ? 50 : 300,
          flex: 1,
          valueFormatter: (params) => (params.value ? params.value : " "),
        },
        {
          headerName: `${dateRangeName}`,
          field: "count",
          type: "number",
          flex: 1,
          valueFormatter: (params) =>
            params.value ? params.value.toFixed(2) : 0,
        },
        {
          headerName: `Previous Year`,
          field: "previousCount",
          type: "number",
          flex: 1,
          valueFormatter: (params) =>
            params.value ? params.value.toFixed(2) : "N/A",
        },
        {
          headerName: "Change",
          field: "diff",
          type: "number",
          flex: 1,
          valueFormatter: (params) =>
            params.value
              ? (params.value > 0 ? "+" : "") + params.value.toFixed(2)
              : 0,
          cellClassName: (params) => posOrNeg(params),
        },
        {
          headerName: "% Change",
          field: "percent",
          type: "number",
          flex: 1,
          valueFormatter: (params) =>
            params.value
              ? (params.value > 0 ? "+" : "") + Math.round(params.value) + "%"
              : "N/A",
          cellClassName: (params) => posOrNeg(params),
        },
      ];
    }, [dataParams, dateRangeName]);

    const rows = useMemo(() => {
      if (dataParams.length === 0 || depletions.length === 0) {
        return [];
      }
      // Generate the data array with the new format
      const data = refactorDepletionsData(
        depletions,
        allAccountsObjects,
        productsInData,
        allDistributorsObjects,
        manufacturer_id
      );
      if (data && data.length) {
        return dataToRows(
          data,
          dataParams,
          filterArgs,
          {
            dateRange,
            previousPeriod,
          },
          {
            selectedQuantity,
            premiseTypes,
          }
        );
      }
    }, [
      depletions,
      dataParams,
      dateRange,
      filterArgs,
      premiseTypes,
      previousPeriod,
      selectedQuantity,
      allAccountsObjects,
      productsInData,
      allDistributorsObjects,
      manufacturer_id,
    ]);

    const primSecs = {
      STATE: {
        STATE: "BRAND",
        BRAND: "PRODUCTNAME",
      },
      BRAND: {
        BRAND: "STATE",
        STATE: "PRODUCTNAME",
      },
      PRODUCTNAME: {
        PRODUCTNAME: "STATE",
        STATE: "BRAND",
      },
    };

    const cellClick = (params) => {
      const allowList = ["STATE", "BRAND", "PRODUCTNAME"];
      const value = params.row[params.columns[0].field];
      const field = params.columns[0].field;
      if (!allowList.includes(field)) {
        // NOOP cell
        return;
      }
      const amendCurrentParams = [...dataParams];
      // get the property order by the first property in the array
      const first = dataParams[0].property;
      // update first property in array to have a value, and so be used to filter
      amendCurrentParams[amendCurrentParams.length - 1].value = value;
      if (amendCurrentParams.length < 3) {
        amendCurrentParams.push({
          property: primSecs[first][field],
          value: "",
        });
        setDataParams(amendCurrentParams);
      }
    };

    const reduceDataParams = () => {
      const shorterDataParams = [...dataParams].slice(0, -1);
      shorterDataParams[shorterDataParams.length - 1].value = "";
      setDataParams(shorterDataParams);
    };

    const updateSortCol = (model) => {
      setSortModel(model);
    };

    const columnTotals = cols.map((col) => {
      if (col.field === dataParams[dataParams.length - 1].property) {
        return "Totals:";
      } else if (col.field !== "percent") {
        const total = rows.reduce((acc, row) => {
          return acc + row[col.field];
        }, 0);
        return col.headerName + ": " + total.toFixed(2);
      } else {
        const totalDiff = rows.reduce((acc, row) => {
          return acc + (row.diff || 0);
        }, 0);
        const totalPrevCount = rows.reduce((acc, row) => {
          return acc + (row.previousCount || 0);
        }, 0);
        const totalPercent =
          totalPrevCount !== 0 ? (totalDiff / totalPrevCount) * 100 : 0;
        return (
          col.headerName +
          ": " +
          (totalPercent > 0 ? "+" : "") +
          Math.round(totalPercent) +
          "%"
        );
      }
    });

    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Box p={1} py={1} pt={0}>
              <Button
                onClick={() => resetPrimary("STATE")}
                variant={primary === "STATE" ? "contained" : "outlined"}
              >
                By State
              </Button>
              <Button
                onClick={() => resetPrimary("BRAND")}
                variant={primary === "BRAND" ? "contained" : "outlined"}
              >
                By Brand
              </Button>
              <Button
                onClick={() => resetPrimary("PRODUCTNAME")}
                variant={primary === "PRODUCTNAME" ? "contained" : "outlined"}
              >
                By Product
              </Button>
              <RouterLink to="/app/depletions/us" className="floatingLink">
                <Button color="primary">See Reports for more Detail&gt;</Button>
              </RouterLink>
            </Box>
            <div style={{ fontSize: 14, marginLeft: 5, fontWeight: "300" }}>
              Click a row to drill down
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: 5,
            marginBottom: 5,
            border: "1px solid lightGray",
          }}
        >
          <div style={{ display: "flex", color: "white", padding: "10px" }}>
            {columnTotals.map((total, index) => (
              <div key={index} style={{ marginRight: 20, color: "black" }}>
                {total}
              </div>
            ))}
          </div>
        </div>
        {dataParams.length > 1 && (
          <Box p={1} px={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="textPrimary"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {dataParams[0].property}
              </Typography>
              {dataParams.slice(0, -1).map((dp, i) => (
                <Link key={i}>{dp.value}</Link>
              ))}
              <Button
                startIcon={<Backspace />}
                onClick={reduceDataParams}
              ></Button>
            </Breadcrumbs>
          </Box>
        )}
        <DataGrid
          columns={cols}
          rows={rows}
          onRowClick={cellClick}
          sortModel={sortModel}
          onSortModelChange={(model) => updateSortCol(model)}
          autoHeight
        />
      </div>
    );
  }
);

export default SalesOverview;
