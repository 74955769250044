import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

import {
  Button,
  CardHeader,
  Card,
  CardContent,
  MenuItem,
  Input,
  InputLabel,
  Select,
  Grid,
  makeStyles,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { DateTime } from "luxon";
import LuxonUtils from "@date-io/luxon";
import useStorage from "../../../hooks/useStorage";
import StorageClient from "../../../storage/client";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import useApi from "../../../hooks/useApi";
import storageAPI from "../../../api/storage";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

const FileUploadDetails = ({
  className,
  open,
  setOpen,
  refreshData,
  distributors,
  distributorFiles,
  month,
  dist_id,
  ...rest
}) => {
  const classes = useStyles();
  const store = useStore();
  const { userInfo } = store;
  const [loading, setLoading] = useState(false);
  const setS3Items = useStorage(StorageClient.upload);
  const postDistStorageApi = useApi(storageAPI.postDistStorage);
  const [distributorId, setDistributorId] = useState("");
  const fileRoot = "SalesTier/" + userInfo.mid + "/distributorFiles/";
  const currentDate = new Date();
  const previousMonthEnd = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );

  const [salesDate, setSalesDate] = useState(
    DateTime.fromJSDate(previousMonthEnd, { zone: "UTC" })
  );
  const [file, setFile] = useState();

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
  };

  function uploadFile() {
    setLoading(true);
    const uuid = nanoid(5);
    if (distributorId !== "" && file !== "") {
      let key = uuid + "_" + file.name;
      let type = file.type;
      const rootKey = fileRoot + key;
      setS3Items
        .request(rootKey, file, type)
        .then(() => {
          return postDistStorageApi.request({
            identity_id: userInfo.user_identitykey,
            file_key: rootKey,
            file_name: key,
            manufacturer_id: userInfo.mid,
            user_id: userInfo.uid,
            distributor_id: distributorId,
            sales_date: salesDate.toISO(),
          });
        })
        .then(() => {
          refreshData();
          setOpen(false);
          setLoading(false);
        });
    }
  }
  function getLastDayOfMonth(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber);
    date.setDate(0);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}T00:00:00.000Z`;

    return formattedDate;
  }
  const lastDayOfJanuary = getLastDayOfMonth(1);

  useEffect(() => {
    if (month && dist_id) {
      setSalesDate(getLastDayOfMonth(month));
      setDistributorId(dist_id);
    }
  }, []);

  const handleChange = (event) => {
    setDistributorId(event.target.value);
  };

  const distributorList = [
    { distributor_id: 24, distributor_name: "OTHER" },
    ...distributors,
  ];
  const selectedDistributor = distributorList.find(
    (dist) => dist.distributor_id === distributorId
  );
  const selectedDistributorFiles = distributorFiles
    .filter((file) => file.distributor_id === distributorId)
    .sort((a, b) => new Date(b.created) - new Date(a.created));

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="File Upload" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} lg={12} xs={12}>
              <InputLabel id="contained-button-file">File</InputLabel>
              <Input
                accept="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,image/*"
                id="contained-button-file"
                fullWidth
                multiple={false}
                type="file"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              <InputLabel id="demo-simple-select-outlined-label">
                Distributor / State
              </InputLabel>
              <Select
                fullWidth
                id="demo-simple-select-outlined"
                name="visit_purpose"
                value={distributorId}
                onChange={handleChange}
              >
                {distributorList.map((row) => (
                  <MenuItem key={row.distributor_id} value={row.distributor_id}>
                    {row.distributor_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              <InputLabel id="demo-simple-select-outlined-label">
                Default Sales Date of Report Data
              </InputLabel>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <DatePicker
                    label="DATE"
                    inputVariant="outlined"
                    style={{ width: "150px" }}
                    fullWidth={false}
                    size="small"
                    disableFuture={true}
                    value={salesDate}
                    onChange={(e) => {
                      setSalesDate(
                        e.setZone("UTC", { keepLocalTime: false }).toUTC()
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              <Button
                color={"primary"}
                variant="contained"
                onClick={() => uploadFile()}
                disabled={distributorId === "" || file === "" || loading}
              >
                {loading ? <CircularProgress color="black" /> : "Save"}
              </Button>
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
              {selectedDistributor && selectedDistributorFiles.length ? (
                <>
                  <p>
                    <b>{selectedDistributor.distributor_name}</b> Previously
                    Uploaded Files:
                  </p>
                  <TableContainer style={{ maxHeight: 300 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>File Name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Created</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedDistributorFiles.map((file) => {
                          return (
                            <TableRow>
                              <TableCell>{file.file_name}</TableCell>
                              <TableCell>{file.status}</TableCell>
                              <TableCell>
                                {DateTime.fromISO(file.created).toFormat(
                                  "MM/dd/yyyy h:mma"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : selectedDistributor ? (
                <p>
                  No Previously Uploaded files for{" "}
                  <b>{selectedDistributor.distributor_name}</b>
                </p>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

FileUploadDetails.propTypes = {
  className: PropTypes.string,
};

export default FileUploadDetails;
