import React, { useEffect } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const store = useStore();
  const { userInfo } = store;
  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Dashboard-OnPremise");
  }, []);
  return (
    <Page className={classes.root} title="Accounts Dashboard">
      <Container maxWidth={true}></Container>
    </Page>
  );
};

export default Dashboard;
