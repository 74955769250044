import React from "react";

const StLabel = ({ text, required, error, children, active = false }) => {
  const labelStyle = {
    flex: 1,
    height: "auto",
    fontSize: 14,
    fontWeight: "600",
    color: error ? "red" : active ? "#0c45a6" : "inherit",
  };

  const requiredStyle = {
    marginLeft: 10,
    fontSize: 10,
    fontWeight: "600",
    color: error ? "red" : "inherit",
  };

  return (
    <div
      style={{
        flexDirection: "row",
        marginBottom: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div style={labelStyle}>{text}</div>
      {required && <div style={requiredStyle}>*Required</div>}
      {children}
    </div>
  );
};

export default StLabel;
