import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Parser } from "json2csv";
import { makeStyles, Paper } from "@material-ui/core";
import * as XLSX from "xlsx";
import teamAPI from "../../../api/team";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "500px",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TeamScorecardView = ({ users }) => {
  const getCurrentYear = () => new Date().getFullYear();

  const generateMonths = () => {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  };

  const getCurrentMonthString = () => {
    const currentDate = new Date();
    return currentDate.toLocaleString("default", { month: "long" });
  };

  const weekToMonth = (week) => {
    const monthMap = {
      1: "January",
      2: "January",
      3: "January",
      4: "January",
      5: "February",
      6: "February",
      7: "February",
      8: "February",
      9: "March",
      10: "March",
      11: "March",
      12: "March",
      13: "April",
      14: "April",
      15: "April",
      16: "April",
      17: "May",
      18: "May",
      19: "May",
      20: "May",
      21: "May",
      22: "June",
      23: "June",
      24: "June",
      25: "June",
      26: "June",
      27: "July",
      28: "July",
      29: "July",
      30: "July",
      31: "July",
      32: "August",
      33: "August",
      34: "August",
      35: "August",
      36: "August",
      37: "September",
      38: "September",
      39: "September",
      40: "September",
      41: "September",
      42: "October",
      43: "October",
      44: "October",
      45: "October",
      46: "October",
      47: "November",
      48: "November",
      49: "November",
      50: "November",
      51: "December",
      52: "December",
    };
    return monthMap[week];
  };

  const years = [2022, 2023, 2024];
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthString());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const months = generateMonths();

  const mergeObjects = (array) => {
    const merged = {};

    array.forEach((obj) => {
      const key = `${obj.userId}-${obj.month}`;
      if (!merged[key]) {
        merged[key] = { ...obj };
      } else {
        Object.keys(obj).forEach((field) => {
          if (typeof obj[field] === "number") {
            if (merged[key][field] === 0) {
              merged[key][field] = obj[field];
            }
          } else {
            merged[key][field] = obj[field];
          }
        });
      }
    });

    return Object.values(merged);
  };

  const handleExportCSV = () => {
    const exportData = (
      selectedRows.length
        ? filteredData.filter((row) => selectedRows.includes(row.id))
        : filteredData
    ).map((row) => {
      const { avatar, ...rest } = row;
      return rest;
    });
    const parser = new Parser();
    const csv = parser.parse(exportData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "scorecard.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  function reorderObjectFields(obj, order) {
    const orderedObj = {};
    for (const key of order) {
      if (obj.hasOwnProperty(key)) {
        orderedObj[key] = obj[key];
      }
    }
    for (const key in obj) {
      if (!orderedObj.hasOwnProperty(key)) {
        orderedObj[key] = obj[key];
      }
    }
    return orderedObj;
  }

  function reorderFieldsInArray(objectsArray, order) {
    return objectsArray.map((obj) => reorderObjectFields(obj, order));
  }

  const handleExportExcel = () => {
    const exportData = (
      selectedRows.length
        ? filteredData.filter((row) => selectedRows.includes(row.id))
        : filteredData
    ).map((row) => {
      const { avatar, ...rest } = row;
      return rest;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "scorecard.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await teamAPI.getScorecardData();
        console.log("Lambda REST API: ", data);
        if (data.results.length) {
          const reshapedData = data.results
            .map((entry) => {
              const parsedData = JSON.parse(entry.data);
              const base = {
                id: entry.id,
                userId: entry.user_id,
                name: entry.user_displayname,
                year: 2024,
              };

              const monthlyData = {};

              Object.keys(parsedData).forEach((week) => {
                const month = weekToMonth(parseInt(week));
                parsedData[week].forEach((visit) => {
                  const key = `${entry.user_id}-${month}-${visit.visit_type}`;
                  if (!monthlyData[key]) {
                    monthlyData[key] = {
                      ...base,
                      id: key,
                      month,
                      [visit.visit_type]: visit.visit_count,
                    };
                  } else {
                    if (monthlyData[key][visit.visit_type]) {
                      monthlyData[key][visit.visit_type] += Number(
                        visit.visit_count
                      );
                    } else {
                      monthlyData[key][visit.visit_type] = Number(
                        visit.visit_count
                      );
                    }
                  }
                });
              });

              return Object.values(monthlyData);
            })
            .flat();
          const visitTypes = [
            ...new Set(
              reshapedData.flatMap((row) =>
                Object.keys(row).filter(
                  (key) =>
                    key !== "id" &&
                    key !== "userId" &&
                    key !== "name" &&
                    key !== "avatar" &&
                    key !== "month" &&
                    key !== "year"
                )
              )
            ),
          ];

          const dynamicColumns = [
            { field: "name", headerName: "Name", width: 150 },
            { field: "month", headerName: "Month", width: 150 },
            { field: "year", headerName: "Year", width: 100 },
            ...visitTypes.map((visitType) => ({
              field: visitType,
              headerName: visitType.replace("form_", "(Form) "),
              width: 150,
            })),
          ];
          const completeData = reshapedData.map((row) => {
            visitTypes.forEach((visitType) => {
              if (!(visitType in row)) {
                row[visitType] = 0;
              }
            });
            return row;
          });
          const order = ["id", "userId", "name", "month", ...visitTypes];
          const orderedObjects = reorderFieldsInArray(completeData, order);
          const mergedObjects = mergeObjects(orderedObjects);
          setColumns(dynamicColumns);
          setData(mergedObjects);
        }
      } catch (err) {
        console.log("error fetching data..", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = data.filter(
      (item) =>
        (!selectedUser || item.userId === selectedUser) &&
        (!selectedMonth || item.month === selectedMonth) &&
        (!selectedYear || item.year === selectedYear)
    );
    setFilteredData(filtered);
  }, [selectedUser, selectedMonth, selectedYear, data]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper className={classes.root} title="Scorecard">
      <Box sx={{ height: 600, width: "100%" }}>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>User</InputLabel>
          <Select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="User"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.user_displayname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            label="Year"
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Month</InputLabel>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            label="Month"
          >
            {months.map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportCSV}
          sx={{ m: 1 }}
        >
          Export CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportExcel}
          sx={{ m: 1 }}
        >
          Export Excel
        </Button>

        <DataGrid
          rows={filteredData}
          columns={columns}
          pageSize={10}
          checkboxSelection
          onSelectionModelChange={(ids) => setSelectedRows(ids)}
        />
      </Box>
    </Paper>
  );
};

export default TeamScorecardView;
