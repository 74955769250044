const LogoWhite = () => (
  <div>
    <img
      className="splash-logo-white rotating"
      src="/static/images/logos/SalestierIcon_White_200x200.png"
      alt=""
    />
  </div>
);

export default LogoWhite;
