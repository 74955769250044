const accounts = {
  markets: [],
  setMarkets(list) {
    this.markets = list;
  },
  accountsList: [],
  setAccounts(accounts) {
    this.accountsList = accounts;
  },
};

export default accounts;
