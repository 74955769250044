import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const MarketSummaryChart = ({ data }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const backGroundColors = [
    "rgb(87, 235, 224, 1)",
    "rgb(12, 69, 166, 1)",
    "rgba(255, 0, 245, 1)",
    "rgba(250, 255, 0, 1)",
    "rgb(0, 255, 133, 1)",
    "rgb(255, 32, 32, 1)",
    "rgb(0, 0, 0, 1)",
  ];

  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const [RowTitles, setRowTitles] = useState([...Object.keys(data)]); // on-premise + off-premise + total
  const [ColumnTitles, setColumnTitles] = useState(
    Object.keys(data[Object.keys(data)[0]]).filter(
      (name) => !name.includes("Accounts")
    )
  ); // Accounts YTD + Accounts QTD + 9Ltr Cases YTD etc...
  var dataObject = {};
  const [datasets, setDataSets] = useState([]);

  useEffect(() => {
    dataObject = {};
    setDataSets([]);
    setRowTitles([...Object.keys(data)]);
    setColumnTitles(
      Object.keys(data[Object.keys(data)[0]]).filter(
        (name) => !name.includes("Accounts")
      )
    );
    formatData();
  }, [data]);

  const formatData = () => {
    var list = [];
    var tempDataSet = [];
    RowTitles.map((row) => {
      var tempArray = [];
      ColumnTitles.map((col) => {
        tempArray.push(data[row][col] || 0);
      });
      list.push(tempArray);
      dataObject[row] = tempArray;
    });

    RowTitles.map((row) => {
      tempDataSet.push({
        label: row,
        data: dataObject[row],
        backgroundColor: backGroundColors[RowTitles.indexOf(row) % 7],
      });
    });
    setDataSets(tempDataSet);
  };

  return (
    <Bar
      options={options}
      data={{
        labels: ColumnTitles,
        datasets,
      }}
    />
  );
};
export default MarketSummaryChart;
