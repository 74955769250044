import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { Delete as DeleteIcon } from "@material-ui/icons";

import { trackEvent } from "../../../api/analytics";
import useStorage from "../../../hooks/useStorage";
import StorageClient from "../../../storage/client";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CachedIcon from "@material-ui/icons/Cached";
import useApi from "../../../hooks/useApi";
import storageAPI from "../../../api/storage";
import FileUploadDetails from "./FileUploadDetails";
import { DateTime } from "luxon";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../../../components/deleteModal/DeleteModal";
import { useStore } from "../../../stores/StoreContext";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const LogView = () => {
  const { month = false, dist_id = false } = useParams();
  const getDistStorageApi = useApi(storageAPI.getDistStorage);
  const getDistIdsApi = useApi(storageAPI.getDistIds);
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const classes = useStyles();
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const store = useStore();
  const { userInfo } = store;
  const downloadS3Items = useStorage(StorageClient.download);

  const handleClose = () => {
    setOpen(false);
  };

  async function listFiles() {
    getDistStorageApi.request({
      mid: userInfo.mid,
      limit: 1000,
    });
  }

  function downloadFiles(key, identityId) {
    downloadS3Items.download(key, identityId);
  }
  useEffect(() => {
    listFiles();
    trackEvent(userInfo.uid, "PageView:Media");
    getDistributors();
  }, []);

  useState(() => {
    if (month && dist_id) {
      setOpen(true);
    }
  }, []);

  const getDistributors = () => {
    getDistIdsApi.request({ mid: userInfo.mid, limit: 1000 });
  };
  const dateFormat = (string) => {
    let formatedDate = DateTime.fromISO(string).toFormat("MM/dd/yyyy h:mma");
    return formatedDate;
  };
  const columns = [
    {
      name: "dist_displayname",
      label: "Distributor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "file_name",
      label: "File Name",
      options: {
        filter: false,
        sort: true,
        //
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created",
      label: "Last Updated",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (val) => dateFormat(val),
      },
    },
    {
      name: "gg_storage_file_id",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (val) => (
          <IconButton
            aria-label="Delete"
            color="primary"
            onClick={(event) => handleDelete(event, val)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    },
    {
      name: "file_key",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "identity_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const handleDelete = (event, val) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDeleteModal(true);
    setSelectedFileId(val);
  };
  const handleConfirmDelete = async () => {
    setIsLoading(true);
    let _response = await storageAPI.deleteStorage(selectedFileId);
    setIsLoading(false);
    if (_response.status < 400) {
      listFiles();
      closeModal();
    }
  };
  const closeModal = () => {
    setShowDeleteModal(false);
    setIsLoading(false);
  };
  const handleRowClick = (rowData, rowMeta) => {
    try {
      downloadFiles(rowData[5], rowData[6]);
    } catch (error) {}
  };
  const options = {
    filterType: "checkbox",
    print: false,
    onRowClick: handleRowClick,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150],
    selectableRows: "none",
    download: false,
  };
  return (
    <Page className={classes.root} title="Depletions">
      <Container maxWidth={false}>
        <Button
          component="span"
          onClick={() => setOpen(true)}
          className={classes.button}
          variant="contained"
          color="primary"
          endIcon={<CloudUploadIcon />}
        >
          Upload
        </Button>
        <Button
          className={classes.button}
          onClick={() => listFiles()}
          variant="contained"
          color="primary"
          endIcon={<CachedIcon />}
        >
          Refresh
        </Button>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <div>
                {getDistStorageApi.loading && (
                  <div>
                    <CircularProgress />
                  </div>
                )}
              </div>
              {getDistStorageApi.data.length > 0 && (
                <MUIDataTable
                  className={classes.root}
                  data={getDistStorageApi.data}
                  columns={columns}
                  options={options}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <FileUploadDetails
          open={open}
          setOpen={setOpen}
          refreshData={listFiles}
          distributors={getDistIdsApi.data}
          distributorFiles={getDistStorageApi.data}
          month={month}
          dist_id={dist_id}
        />
      </Dialog>
      <DeleteModal
        title={`Delete`}
        open={showDeleteModal}
        description={"Are you sure you want to Delete this file?"}
        deleteButtonLoading={isLoading}
        handleConfirmDelete={handleConfirmDelete}
        onClose={closeModal}
      />
    </Page>
  );
};

export default LogView;
