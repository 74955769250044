import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  Grid,
  Dialog,
  Input,
  Button,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { trackEvent } from "../../api/analytics";
import { useStore } from "../../stores/StoreContext";
import RadialBarChart from "./radialBarChart";
import { useParams } from "react-router";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Loader } from "@aws-amplify/ui-react";
import LineChart from "./lineChart";

const tableTitles = ["Contacts", "CheckIns", "Media", "Forms"];

const useStyles = makeStyles((theme) => ({
  root: {},
  gridList: {
    flexWrap: "nowrap",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  green: {
    color: "#fff",
    backgroundColor: "#5DBB63",
  },
  Table: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  TimelineTableCell: {
    textOverflow: "visible",
    overflow: "visible",
  },
  TableRow: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
}));

const iconToLabel = {
  Contacts: "Contact",
  CheckIns: "CheckIn",
  Media: "Image",
  Forms: "Form",
};

const Targets = ({ targets, setTargets }) => {
  const { id } = useParams();
  const store = useStore();
  const { userInfo } = store;
  const classes = useStyles();
  const [contactObject, setContactObject] = useState(false);
  const [checkInObject, setCheckInObject] = useState(false);
  const [imageObject, setImageObject] = useState(false);
  const [formResponseObject, setFormResponseObject] = useState(false);
  const [goals, setGoals] = useState(false);
  const [lineChartOpen, setLineChartOpen] = useState(false);

  const fillTypeObjects = (profileResults) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 to get the month in the range 1-12

    let tempContactCount = 0;
    let tempCheckInCount = 0;
    let tempImageCount = 0;
    let formResponseCount = 0;
    const tempContactArray = [];
    const tempCheckInArray = [];
    const tempImageArray = [];
    const formResponseArray = [];

    profileResults.forEach((item) => {
      const itemDate = new Date(item.created);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth() + 1; // Adding 1 to get the month in the range 1-12

      if (item.type === "account_checkin") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          tempCheckInCount += 1;
        }
        tempCheckInArray.push(item.created);
      } else if (item.type === "media_upload") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          tempImageCount += 1;
        }
        tempImageArray.push(item.created);
      } else if (item.type === "form_response") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          formResponseCount += 1;
        }
        formResponseArray.push(item.created);
      } else if (item.type === "contact_created") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          tempContactCount += 1;
        }
        tempContactArray.push(item.created);
      } else {
        console.log("invalid type");
      }
    });

    setContactObject({
      count: tempContactCount,
      data: tempContactArray.sort((a, b) => b.created - a.created),
    });
    setCheckInObject({
      count: tempCheckInCount,
      data: tempCheckInArray.sort((a, b) => b.created - a.created),
    });
    setImageObject({
      count: tempImageCount,
      data: tempImageArray.sort((a, b) => b.created - a.created),
    });
    setFormResponseObject({
      count: formResponseCount,
      data: formResponseArray.sort((a, b) => b.created - a.created),
    });
  };

  useEffect(() => {
    getGoals();
    getCurrentProgress().then((res) => {
      fillTypeObjects(res);
    });
    trackEvent(userInfo.uid, "PageView:TeamMember");
  }, []);

  const getGoals = async () => {
    var tempGoals = {
      Contacts: 15,
      Forms: 15,
      CheckIns: 15,
      Media: 15,
    };
    try {
      const goals = await API.get("backendGateway", "/teams/targets", {
        queryStringParameters: {
          uid: id,
          limit: 1000,
        },
      });
      const filteredGoals = goals.results.filter((item) =>
        ["Forms", "Contacts", "CheckIns", "Media"].includes(item?.target_type)
      );

      const mostRecentGoals = filteredGoals.reduce((acc, goal) => {
        if (
          !acc[goal.target_type] ||
          goal.created > acc[goal.target_type].created
        ) {
          acc[goal.target_type] = goal;
        }
        return acc;
      }, {});

      Object.values(mostRecentGoals).map((item) => {
        tempGoals[item.target_type] = item.target || 0;
      });
      setGoals(tempGoals);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  const getCurrentProgress = async () => {
    try {
      const data = await API.get("backendGateway", "/teams/activities", {
        queryStringParameters: {
          mid: userInfo.mid,
        },
      });
      return data.results.filter((item) => item.user_id === parseInt(id));
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  const [accountTotal, setAccountTotal] = useState(0);
  const [changeGoalsOpen, setChangeGoalsOpen] = useState(false);
  const [contactsGoal, setContactsGoal] = useState(false);
  const [visitsGoal, setVisitsGoal] = useState(false);
  const [mediaGoal, setMediaGoal] = useState(false);
  const [formsGoal, setFormsGoal] = useState(false);
  const typesToGoal = {
    Contacts: contactsGoal,
    CheckIns: visitsGoal,
    Media: mediaGoal,
    Forms: formsGoal,
  };

  useEffect(() => {
    typesToGoal.Contacts = contactsGoal;
  }, [contactsGoal]);
  useEffect(() => {
    typesToGoal.CheckIns = visitsGoal;
  }, [visitsGoal]);
  useEffect(() => {
    typesToGoal.Media = mediaGoal;
  }, [mediaGoal]);
  useEffect(() => {
    typesToGoal.Forms = formsGoal;
  }, [formsGoal]);

  const postSearchQuery = async (target, type) => {
    console.log(type, target);
    try {
      const apiName = "backendGateway";
      const path = "/teams/goals";
      const jsonBody = {
        user_id: id,
        manufacturer_id: userInfo.mid,
        target: target || 0,
        target_type: type,
        cadence: "month",
      };
      const myInit = {
        body: jsonBody,
        headers: {}, // OPTIONAL
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  const handleClose = () => {
    setChangeGoalsOpen(false);
  };

  const setGoal = (type, goal) => {
    if (type === "Contacts") {
      setContactsGoal(goal);
    } else if (type === "CheckIns") {
      setVisitsGoal(goal);
    } else if (type === "Media") {
      setMediaGoal(goal);
    } else if (type === "Forms") {
      setFormsGoal(goal);
    } else {
      console.log("invalid type");
    }
  };

  const percentageComplete = () => {
    var tempPercentageArray = [];
    var percentageComplete = 0;
    Object.keys(goals).map((goal) => {
      if (goal === "Contacts") {
        tempPercentageArray.push(
          Math.min((contactObject.count / goals[goal]) * 100, 100)
        );
      } else if (goal === "CheckIns") {
        tempPercentageArray.push(
          Math.min((checkInObject.count / goals[goal]) * 100, 100)
        );
      } else if (goal === "Forms") {
        tempPercentageArray.push(
          Math.min((formResponseObject.count / goals[goal]) * 100, 100)
        );
      } else if (goal === "Media") {
        tempPercentageArray.push(
          Math.min((imageObject.count / goals[goal]) * 100, 100)
        );
      }
    });
    percentageComplete =
      ((tempPercentageArray[0] +
        tempPercentageArray[1] +
        tempPercentageArray[2] +
        tempPercentageArray[3]) /
        400) *
      100;

    return percentageComplete.toPrecision(2);
  };

  const handleGoalSave = async () => {
    setChangeGoalsOpen(false);
    setCurrentlyEditingArray([]);
    console.log(currentlyEditingArray);
    const promises = currentlyEditingArray.map(async (type) => {
      if (typesToGoal[type] !== false) {
        await postSearchQuery(typesToGoal[type], type);
      } else {
        console.log("invalid type");
      }
    });

    try {
      await Promise.all(promises);
      refreshGoals();
    } catch (err) {
      console.log("error occurred during API calls", err);
    }
  };

  const refreshGoals = () => {
    setGoals(false);
    getGoals();
  };

  const getDaysLeftInMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get the last day of the current month
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    // Calculate the number of days left in the month
    const daysLeft = lastDayOfMonth - currentDate.getDate();

    return daysLeft;
  };

  const [currentlyEditingArray, setCurrentlyEditingArray] = useState([]);

  const addToCurrentlyEditing = (type) => {
    console.log(type);

    setCurrentlyEditingArray([...currentlyEditingArray, type]);
    console.log(currentlyEditingArray);
  };

  useEffect(() => {
    console.log(currentlyEditingArray);
  }, [currentlyEditingArray]);
  const removeFromCurrentlyEditing = (type) => {
    const newArray = currentlyEditingArray.filter((x) => x !== type);

    setCurrentlyEditingArray(newArray);

    if (type === "Contacts") {
      setContactsGoal(false);
    } else if (type === "Visits") {
      setVisitsGoal(false);
    } else if (type === "Media") {
      setMediaGoal(false);
    } else if (type === "Forms") {
      setFormsGoal(false);
    } else {
      console.log(type);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Dialog
          open={lineChartOpen?.data}
          onClose={() => setLineChartOpen(false)}
          maxWidth="lg"
        >
          <DialogContent dividers style={{ minHeight: "400px" }}>
            {lineChartOpen.data?.length && lineChartOpen.data?.length > 0 ? (
              <LineChart
                goals={goals}
                data={lineChartOpen.data}
                type={lineChartOpen.type}
              />
            ) : (
              ""
            )}
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={handleClose}
          aria-labelledby="product-dialog"
          open={changeGoalsOpen}
          PaperProps={{
            style: {
              width: "300px",
              padding: 10,
            },
          }}
        >
          <DialogTitle>Edit Goals</DialogTitle>
          <DialogContent>
            {Object.keys(goals).map((type) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <img
                      src={`/static/images/icons/${iconToLabel[type]}.svg`}
                      alt=""
                      height={30}
                      width={30}
                      style={{ marginRight: "20px" }}
                    />
                    {type}
                  </div>
                  {!currentlyEditingArray.includes(type) ? (
                    <Button onClick={() => addToCurrentlyEditing(type)}>
                      Edit
                    </Button>
                  ) : (
                    <Button onClick={() => removeFromCurrentlyEditing(type)}>
                      Close
                    </Button>
                  )}
                </div>

                {currentlyEditingArray.includes(type) ? (
                  <Input
                    type="number"
                    onChange={(e) => setGoal(type, parseInt(e.target.value))}
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
          </DialogContent>

          <Button
            onClick={handleGoalSave}
            style={{ backgroundColor: "#0C45A5", color: "white" }}
          >
            Save
          </Button>
        </Dialog>
        <Grid item xs={12}>
          <Card key={accountTotal}>
            <CardContent className={[classes.fullHeightCard, "card-info-row"]}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography align="left" color="textPrimary" variant="h6">
                  Monthly Goals
                </Typography>
                <Button onClick={() => setChangeGoalsOpen(true)}>
                  <EditOutlinedIcon fontSize="small" />
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {(formResponseObject || formResponseObject === 0) && goals ? (
        <Paper
          style={{
            display: "flex",
            marginTop: "10px",
            flexDirection: "row",
            height: "350px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{ width: "60%", height: "100%", flexDirection: "column" }}
          >
            <Table>
              {tableTitles.map((title) => (
                <TableRow
                  onClick={() => {
                    setLineChartOpen({
                      type: title,
                      data:
                        title === "Contacts"
                          ? contactObject.data
                          : title === "CheckIns"
                          ? checkInObject.data
                          : title === "Media"
                          ? imageObject.data
                          : title === "Forms"
                          ? formResponseObject.data
                          : [],
                    });
                  }}
                  className={classes.TableRow}
                  style={{ height: "87.5px", cursor: "pointer" }}
                >
                  <TableCell className={classes.TimelineTableCell}>
                    <img
                      src={`/static/images/icons/${iconToLabel[title]}.svg`}
                      alt=""
                      height={50}
                      width={50}
                      style={{ marginRight: "20px" }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.TimelineTableCell}
                    style={{ fontSize: "18px", fontWeight: 400 }}
                  >
                    {title}
                  </TableCell>
                  <TableCell
                    className={classes.TimelineTableCell}
                    style={{ fontSize: "18px", fontWeight: 400 }}
                  >
                    {title === "Contacts" ? contactObject.count : ""}
                    {title === "CheckIns" ? checkInObject.count : ""}
                    {title === "Media" ? imageObject.count : ""}
                    {title === "Forms" ? formResponseObject.count : ""}/
                    {goals[title]}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </div>
          <div
            style={{
              width: "40%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <RadialBarChart
              counts={[
                [
                  contactObject.count || 0,
                  Math.max(goals["Contacts"] - (contactObject.count || 0), 0),
                ],
                [
                  checkInObject.count || 0,
                  Math.max(goals["CheckIns"] - (checkInObject.count || 0), 0),
                ],
                [
                  imageObject.count || 0,
                  Math.max(goals["Media"] - (imageObject.count || 0), 0),
                ],
                [
                  formResponseObject.count || 0,
                  Math.max(goals["Forms"] - (formResponseObject.count || 0), 0),
                ],
              ]}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "5px",
                textAlign: "right",
                marginRight: "5px",
              }}
            >
              <span
                style={{ color: getDaysLeftInMonth() < 5 ? "red" : "#0C45A5" }}
              >
                {getDaysLeftInMonth().toString()} day(s) left
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "auto",
                textAlign: "right",
                marginRight: "5px",
                bottom: 0,
              }}
            >
              {percentageComplete()}% completed
            </div>
          </div>
        </Paper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Targets;
