import { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  Checkbox,
  FormLabel,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import StLabel from "./StLabel";
const StCheckbox = ({
  required,
  label,
  error,
  helperText,
  name,
  custom_name,
  options,
  answer,
  changeAnswer = () => {},
}) => {
  const [values, setValues] = useState(answer.value);
  const textValues = useMemo(() => {
    return options
      .filter((option) => values.includes(option.key))
      .map((option) => option.text);
  }, [options, values]);

  const handleChange = (event) => {
    const { value } = event.target;
    if (event.target.checked) {
      setValues([...values, value]);
    } else {
      setValues(values.filter((v) => v !== value));
    }
  };

  useEffect(() => {
    if (!answer || JSON.stringify(values) !== JSON.stringify(answer.value)) {
      changeAnswer(values, textValues);
    }
  }, [textValues]);

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(answer.value)) {
      if (answer.value.length === 0) {
        setValues([]);
      } else {
        setValues(answer.value);
      }
    }
  }, [answer]);

  const [active, setActive] = useState(false);

  return (
    <FormControl fullWidth required={required} error={error}>
      <StLabel required={required} text={label} error={error} active={active} />{" "}
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                name={option.value}
                checked={values.includes(option.key)}
                onChange={(event) => handleChange(event, index)}
                value={option.key}
              />
            }
            label={option.text}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default StCheckbox;
