import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  CloudDownload,
  Delete,
  CheckCircle,
  AddCircle,
} from "@material-ui/icons";
import { useNavigate } from "react-router";
import { PictureAsPdf } from "@material-ui/icons";
import PanoramaIcon from "@material-ui/icons/Panorama";
import { FileCopyOutlined } from "@material-ui/icons";
import { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { File } from "react-feather";
import { awsS3Image } from "../../helpers/awsS3Image";
import { DateTime } from "luxon";
import { useStore } from "../../stores/StoreContext";
import StorageClient from "../../storage/client";
import useStorage from "../../hooks/useStorage";
import storageAPI from "../../api/storage";
import DeleteModal from "../deleteModal/DeleteModal";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const FileDetailView = observer(({ file }) => {
  const store = useStore();
  const { userList, setFiles, files, folders, setFolders } = store;
  const [fileType, setFileType] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [userName, setUserName] = useState("");
  const s3Storage = useStorage(StorageClient.download);
  const removeS3Trash = useStorage(StorageClient.remove);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selected, setSelected] = useState("");

  const navigate = useNavigate();

  const parentFolderIDs = useMemo(() => {
    return folders
      .filter((item) =>
        item.files.some(
          (fo_file) => fo_file.storage_file_id === file.gg_storage_id
        )
      )
      .map((fold) => fold.folder_id);
  }, [folders, file]);

  useEffect(() => {
    if (userList && file) {
      let _name = userList?.find(
        (user) => user.user_id === file.user_id
      )?.user_displayname;
      setUserName(_name);
    }
  }, [userList, file]);
  const fileToMediaType = (file) => {
    const fileEnd = file.file_name.split(".").reverse()[0];
    const images = ["jpeg", "jpg", "png"];
    const videos = ["mp4", "m4v"];
    const pdfs = ["pdf"];
    let _mediaType = "generic";
    if (videos.includes(fileEnd)) _mediaType = "video";
    if (images.includes(fileEnd)) _mediaType = "image";
    if (pdfs.includes(fileEnd)) _mediaType = "pdf";
    setMediaType(_mediaType);
  };

  const copyImageUrlToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setToastMessage("Image URL copied to clipboard");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setToastMessage("Failed to copy image URL");
      setToastOpen(true);
    }
  };

  useEffect(() => {
    if (file) {
      let _ext = file.file_key.split(".").reverse()[0].toLowerCase();
      setFileType(_ext);

      fileToMediaType(file);
    }
  }, [file]);

  const newSelected = useMemo(() => {
    return folders.find((f) => f.folder_id === selected.folder_id);
  }, [selected, folders]);

  const fileIcon = (fileName) => {
    const ext = fileName.split(".").reverse()[0].toLowerCase();
    // setFileType(ext)
    switch (ext) {
      case "png":
      case "jpeg":
      case "jpg":
        // return <Image style={{ fontSize: "40", color: "black" }} />;
        return (
          <img
            src={awsS3Image(file.file_key, file.identity_id, "80x80")}
            alt="fileimage"
          />
        );
      case "pdf":
        return <PictureAsPdf style={{ fontSize: "40", color: "black" }} />;
      default:
        return <File style={{ fontSize: "40", color: "black" }} />;
    }
  };

  const handleFullView = async () => {
    if (mediaType === "pdf") {
      let windowReference = window.open();

      s3Storage
        .getSignedUrl(file.file_key, file.identity_id, false)
        .then(function (url) {
          windowReference.location = url;
        });
    } else if (mediaType === "image" || mediaType === "video") {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    await s3Storage.download(file.file_key, file.identity_id);
    setDownloadLoading(false);
  };
  const handleConfirmDelete = async () => {
    setIsLoading(true);
    removeS3Trash.remove(file.file_key);
    let _result = await storageAPI.deleteFile(file.gg_storage_id);
    setIsLoading(false);
    if (_result.status < 400) {
      navigate(-1);
      setDeleteMode(false);
      setFiles(files.filter((f) => f.gg_storage_id !== file.gg_storage_id));
    }
  };
  const storeInFolder = async (folder, storage_id) => {
    const { folder_id } = folder;
    let _response = await storageAPI.postSaveInFolder(folder_id, storage_id);
    const file_in_folder_id =
      _response.results.insertId || _response.results[0];
    const adjustedFolders = folders.map((fold) => {
      if (fold.folder_id !== folder_id) {
        return fold;
      }
      return {
        ...fold,
        files: [
          ...fold.files,
          {
            file_in_folder_id,
            storage_file_id: file.gg_storage_id,
          },
        ],
      };
    });
    setFolders(adjustedFolders);
  };

  const removeFromFolder = async (folder) => {
    const matchingFile = folder.files.find(
      (item) => item.gg_storage_id === file.storage_file_id
    );
    if (matchingFile) {
      const { file_in_folder_id } = matchingFile;
      await storageAPI.removeFileFromFolder(file_in_folder_id);
      // TODO: react to show change
      const adjustedFolders = folders.map((fol) => {
        if (folder.folder_id !== fol.folder_id) {
          return fol;
        }
        return {
          ...fol,
          files: files.filter(
            (f) => f.gg_storage_id !== matchingFile.gg_storage_id
          ),
        };
      });
      setFolders(adjustedFolders);
    }
  };

  const pickFolder = (event) => {
    setSelected(event.target.value);
  };

  const folderAction = useMemo(() => {
    if (parentFolderIDs.includes(selected.folder_id)) {
      return "remove";
    }
    return "add";
  }, [parentFolderIDs, selected.folder_id]);

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert onClose={() => setToastOpen(false)} severity="success">
          {toastMessage}
        </Alert>
      </Snackbar>

      <Card style={{ marginBottom: "1rem" }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mr={3}>{fileIcon(file.file_key)}</Box>
            <Button
              color="primary"
              size="small"
              disabled={downloadLoading}
              variant="contained"
              onClick={() => handleDownload()}
              startIcon={downloadLoading ? <></> : <CloudDownload />}
            >
              {downloadLoading ? <CircularProgress /> : "Download"}
            </Button>

            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={mediaType === "generic"}
              onClick={handleFullView}
              startIcon={downloadLoading ? <></> : <PanoramaIcon />}
            >
              View Original
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => setDeleteMode(true)}
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </Box>
          <Box display="flex" alignItems="center" mt={3}>
            <Typography>File Name :</Typography>
            <Typography
              variant="body2"
              style={{ marginLeft: "0.3rem", fontWeight: "600" }}
            >
              {file.file_name}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" style={{ gap: "3px" }}>
            <Typography>Uploaded by </Typography>
            <Typography variant="body1" style={{ fontWeight: "600" }}>
              {" "}
              {userName}{" "}
            </Typography>
            <Typography> on </Typography>
            <Typography variant="body1" style={{ fontWeight: "600" }}>
              {DateTime.fromISO(file.created).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>File Type :</Typography>
            <Typography
              variant="body2"
              style={{ marginLeft: "0.3rem", fontWeight: "600" }}
            >
              {fileType.toUpperCase()}
            </Typography>
          </Box>
        </CardContent>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() =>
            copyImageUrlToClipboard(
              awsS3Image(file.file_key, file.identity_id, "0x0")
            )
          }
          startIcon={<FileCopyOutlined />}
          style={{ margin: 10, marginTop: 0 }}
        >
          Copy Image Address
        </Button>
      </Card>

      {/* Folders */}
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Folders
          </Typography>
          <Box
            my={3}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"end"}
          >
            <Box flexGrow={1}>
              <FormControl style={{ minWidth: "90%" }}>
                <InputLabel>Folder</InputLabel>
                <Select
                  value={newSelected}
                  onChange={(event) => pickFolder(event)}
                  style={
                    {
                      // minWidth: "300px",
                    }
                  }
                >
                  {folders.map((f) => (
                    <MenuItem key={f.folder_id} value={f}>
                      {f.name}{" "}
                      {parentFolderIDs.includes(f.folder_id) ? (
                        <CheckCircle />
                      ) : (
                        <AddCircle />
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              {folderAction === "add" && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    storeInFolder(selected, file.gg_storage_id);
                  }}
                  size="small"
                >
                  Add
                </Button>
              )}
              {folderAction === "remove" && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    removeFromFolder(selected);
                  }}
                  size="small"
                >
                  Remove
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Dialog
        onClose={handleClose}
        aria-labelledby="product-dialog"
        open={open}
        PaperProps={{
          style: {
            width: "90%",
            padding: 10,
          },
        }}
      >
        <img
          alt="noimage"
          src={awsS3Image(file.file_key, file.identity_id, "0x0")}
          style={{ objectFit: "cover" }}
        />
      </Dialog>

      {deleteMode && (
        <DeleteModal
          title={`Delete ${file?.file_name}`}
          description={"Are you sure you want to Delete this file?"}
          deleteButtonLoading={isLoading}
          handleConfirmDelete={handleConfirmDelete}
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
        />
      )}
    </>
  );
});
export default FileDetailView;
