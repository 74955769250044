const minified = {
  depletionListMinified: [],
  allMarketsObjectsMinified: {},
  allAccountsObjectsMinified: {},
  allDistributorsObjectsMinified: {},
  productsInDataMinified: {},

  setdepletionListMinifiedCount: 0,
  allMarketsObjectsMinifiedCount: 0,
  allAccountsObjectsMinifiedCount: 0,
  allDistributorsObjectsMinifiedCount: 0,
  productsInDataMinifiedCount: 0,

  setDepletionListMinified(list) {
    this.depletionListMinified = list;
    this.setdepletionListMinifiedCount = this.setdepletionListMinifiedCount + 1;
  },
  setAllMarketsObjectsMinified(list) {
    this.allMarketsObjectsMinified = list;
    this.allMarketsObjectsMinifiedCount =
      this.allMarketsObjectsMinifiedCount + 1;
  },
  setAllAccountsObjectsMinified(list) {
    this.allAccountsObjectsMinified = list;
    this.allAccountsObjectsMinifiedCount =
      this.allAccountsObjectsMinifiedCount + 1;
  },
  setAllDistributorsObjectsMinified(list) {
    this.allDistributorsObjectsMinified = list;
    this.allDistributorsObjectsMinifiedCount =
      this.allDistributorsObjectsMinifiedCount + 1;
  },
  setProductsInDataMinified(list) {
    this.productsInDataMinified = list;
    this.productsInDataMinifiedCount = this.productsInDataMinifiedCount + 1;
  },
};

export default minified;
