import React, { useState, useEffect } from "react";
import { Pagination } from "@material-ui/lab";
import Page from "../../../components/Page";
import {
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import ProductModal from "./ProductModal";
import ProductCard from "./ProductCard";
import { trackEvent } from "../../../api/analytics";
import useApi from "../../../hooks/useApi";
import productsApi from "../../../api/products";
import ProductTable from "./ProductTable";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const ProductList = () => {
  const store = useStore();
  const getProductsApi = useApi(productsApi.getProducts);
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(4);
  const [page, setPage] = useState(1);
  const { userInfo } = store;

  async function getData() {
    getProductsApi.request({ mid: userInfo.mid, limit: 1000 });
  }
  useEffect(() => {
    if (getProductsApi.data?.length) {
      let _getProductsApi = getProductsApi.data.reduce((group, product) => {
        const { product_brand } = product;
        group[product_brand] = group[product_brand] || [];
        group[product_brand].push(product);
        return group;
      }, {});
      setProducts(Object.entries(_getProductsApi));
    }
  }, [getProductsApi.data]);
  useEffect(() => {
    setPageCount(Math.ceil(products.length / itemsPerPage));
    paginationWeb(1);
  }, [products]);
  useEffect(() => {
    getData();
    trackEvent(userInfo.uid, "PageView:Products");
  }, []);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const searchFilter = (value) => {
    if (value !== "") {
      let filtered = products.filter((x) =>
        x[0].toLowerCase().includes(value.toLowerCase())
      );

      let productSearch = products.filter((x) => {
        let isSearchItemInProducts = false;
        x[1].forEach((y) => {
          if (
            y.product_displayname.toLowerCase().includes(value.toLowerCase())
          ) {
            isSearchItemInProducts = true;
          }
        });
        return isSearchItemInProducts;
      });

      let productAndBrandSearch = [...filtered, ...productSearch];

      let productAndBrandSearchRemovedDuplicates = productAndBrandSearch.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t[0] === thing[0])
      );

      store.setProducts([...productAndBrandSearchRemovedDuplicates]);
    } else {
      //Goes Back to Previous
      const offset = (page - 1) * itemsPerPage;
      store.setProducts(products.slice(offset, offset + itemsPerPage));
    }
  };
  const paginationWeb = (currentPg) => {
    setPage(currentPg);
    const offset = (currentPg - 1) * itemsPerPage;
    store.setProducts(products.slice(offset, offset + itemsPerPage));
  };

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <div>
          <Box display="flex" justifyContent="flex-end">
            {/* <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpen}
            >
              Add product
            </Button> */}
            <ProductModal
              selectedValue={selectedValue}
              open={open}
              onClose={handleClose}
            />
          </Box>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search product"
                    variant="outlined"
                    onChange={(e) => searchFilter(e.target.value)}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={3}>
          <div>
            {getProductsApi.loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <CircularProgress />{" "}
              </div>
            )}
          </div>
          {getProductsApi.data?.length > 0 && <ProductTable />}
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination
            color="primary"
            count={pageCount}
            page={page}
            onChange={(event, value) => paginationWeb(value)}
            size="small"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
