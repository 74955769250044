import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import { ScrollView } from "@aws-amplify/ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  green: {
    color: "#fff",
    backgroundColor: "#5DBB63",
  },
  fullHeightCard: {
    marginBottom: 10,
  },
  table: {
    maxHeight: "100vh",
  },
  borderBottom: {
    borderBottom: "2px solid #c2c2c2",
  },
}));

const AccountOrderModal = ({
  open,
  onClose,
  selectedSalesData = [{ sales: [] }, { date: "" }],
}) => {
  const classes = useStyles();
  const sales = selectedSalesData[0];
  const date = selectedSalesData[1];

  const getTotal = () => {
    var total = 0;

    sales.map((sale) => {
      total += sale.cases_sold; // cases_sold is actually physical in the backend
    });

    return total;
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <ScrollView className={classes.fullHeightCard}>
        <CardHeader title="Sales" />
        <CardContent>
          <TableContainer className={classes.table} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Created</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Size</TableCell>
                  <TableCell align="left">Physical Sold</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ backgroundColor: "#ececec" }}>
                  <TableCell style={{ fontWeight: "500" }} align="left">
                    {new Date(date).toDateString()}
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
                {sales.map((s, i) => {
                  return (
                    <>
                      <TableRow key={i}>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {s.product_displayname}
                        </TableCell>
                        <TableCell align="left">{s.product_size}</TableCell>
                        <TableCell align="left">{s.cases_sold}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.borderBottom}
                  ></TableCell>
                  <TableCell
                    align="left"
                    className={classes.borderBottom}
                  ></TableCell>
                  <TableCell
                    className={classes.borderBottom}
                    style={{
                      fontWeight: "600",
                      backgroundColor: "#c2c2c2",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className={classes.borderBottom}
                    style={{
                      fontWeight: "500",
                      backgroundColor: "#c2c2c2",
                    }}
                    align="left"
                  >
                    {parseFloat(getTotal()).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </ScrollView>
    </Dialog>
  );
};
export default AccountOrderModal;
