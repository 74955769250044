import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StoreProvider } from "./stores/StoreContext.js";
import App from "./App";
import Support from "./views/support/support";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://85af913dcde6482cb1e87444c5115516@o1249413.ingest.sentry.io/6410049",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `saas-app@${process.env.REACT_APP_COMMIT_SHA}`,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <Routes>
        <Route exact strict path="support" element={<Support />} />
        <Route path="*" element={<App />} />
      </Routes>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
