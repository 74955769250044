import { DateTime } from "luxon";

export const parseScheduleData = (data) => {
  const today = [];
  const tomorrow = [];
  const thisWeek = [];
  const future = [];
  const past = [];

  const now = DateTime.now();
  const endOfToday = now.endOf("day");
  const endOfTomorrow = now.plus({ days: 1 }).endOf("day");
  const endOfWeek = now.endOf("week");

  data.forEach((item) => {
    const appointmentDate = DateTime.fromISO(item.appointment);
    if (appointmentDate < now) {
      past.push(item);
    } else if (appointmentDate <= endOfToday) {
      today.push(item);
    } else if (appointmentDate <= endOfTomorrow) {
      tomorrow.push(item);
    } else if (appointmentDate <= endOfWeek) {
      thisWeek.push(item);
    } else {
      future.push(item);
    }
  });
  past.sort(
    (a, b) => DateTime.fromISO(b.appointment) - DateTime.fromISO(a.appointment)
  );

  return { today, tomorrow, thisWeek, future, past };
};
