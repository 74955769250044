import { Typography, Box } from "@material-ui/core";
import { autorun } from "mobx";
import { useObserver } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../stores/StoreContext";

const ViewDistributors = ({ distributorDetails }) => {
  return (
    <>
      <Box mt={3}>
        <Typography variant="h6" component="h6">
          Name
        </Typography>
        <Typography variant="body1">
          {distributorDetails.dist_displayname}
        </Typography>
      </Box>
      {/* <Box mt={3}>
        <Typography variant="h6" component="h6">
          Description
        </Typography>
        <Typography gutterBottom variant="body1">
          {distributorDetails?.description}
        </Typography>
      </Box> */}
    </>
  );
};
export default ViewDistributors;
