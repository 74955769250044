import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
//Supplier
import AccountsView from "./views/reports/AccountsView";
import NotFoundView from "./views/errors/NotFoundView";
import ProductListView from "./views/product/ProductListView";
import SettingsView from "./views/settings/SettingsView";
import HomeView from "./views/home/HomeView";
import InventoryView from "./views/inventory";
import TeamListView from "./views/team/TeamListView";
import TeamProfileView from "./views/team/TeamProfileView";
import PivotView from "./views/reports/PivotView";
import AccountProfileView from "./views/customer/AccountProfileView";
import MapView from "./views/journey/MapView";
import CommitmentView from "./views/commitment/CommitmentView";
import AccountListView from "./views/lists/AccountListView";
import AccountDetailsView from "./views/lists/AccountDetailsView";
import TeamManagerView from "./views/team/TeamManagerView";
import TeamScoredView from "./views/team/TeamScorecardView";
import FinanceView from "./views/finance/FinanceView";
import MediaView from "./views/media/MediaView";
import LogView from "./views/log/LogView";
import SpikeView from "./views/spike/spike";
import BrandMap from "./views/spike/BrandMap";
import DistributorView from "./views/distributor/DistributorView";
import DistributorsDetails from "./views/distributor/DistributorView/distributorsDetails";
import TopExternalBrands from "./views/distributor/ExternalBrands/TopExternalBrands";
import DistributorRegionsView from "./views/distributor/regions";
import AddEditView from "./views/product/ProductListView/AddEditView";
import Stock from "./views/stock";
import ScheduleView from "./views/schedule";
import BookMarks from "./views/bookmarks";
import AccountsExplorerView from "./views/accounts/AccountsExplorerView";

import FormList from "./views/forms";
import FormNew from "./views/forms/builder/new";
import FormEdit from "./views/forms/builder/edit";
import FullOverlay from "./layouts/FullOverlay";
import Form from "./views/forms/form";
import OldForm from "./views/forms/form/OldForm";
import FormResponses from "./views/forms/responses";
import FormResponseEntry from "./views/forms/responses/Response";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "map", element: <MapView /> },
      { path: "home", element: <HomeView /> },
      { path: "accounts", element: <AccountsView /> },
      { path: "accountExplorer", element: <AccountsExplorerView /> },
      { path: "stock", element: <Stock /> },
      { path: "accountdetails/:id", element: <AccountProfileView /> },
      { path: "depletions", element: <PivotView /> },
      { path: "depletions/:country/", element: <PivotView /> },
      { path: "depletions/:country/:region", element: <PivotView /> },
      { path: "products", element: <ProductListView /> },
      { path: "products/edit/:id", element: <AddEditView /> },
      { path: "schedule", element: <ScheduleView /> },
      { path: "lists", element: <AccountListView /> },
      { path: "lists/:id", element: <AccountDetailsView /> },
      { path: "settings", element: <SettingsView /> },
      { path: "commitments", element: <CommitmentView /> },
      { path: "inventory", element: <InventoryView /> },
      { path: "distributors", element: <DistributorView /> },
      { path: "distributors/regions/us", element: <DistributorRegionsView /> },
      {
        path: "distributors/regions/us/:regionList",
        element: <DistributorRegionsView />,
      },
      { path: "distributorsDetails/:id", element: <DistributorsDetails /> },
      { path: "top-external-brands", element: <TopExternalBrands /> },
      { path: "team", element: <TeamListView /> },
      { path: "team/manager", element: <TeamManagerView /> },
      { path: "team/scorecard", element: <TeamScoredView /> },
      { path: "media", element: <MediaView /> },
      { path: "media/root/:file_id", element: <MediaView /> },
      { path: "media/folder/:folder_id", element: <MediaView /> },
      { path: "media/folder/:folder_id/:file_id", element: <MediaView /> },
      { path: "log", element: <LogView /> },
      { path: "log/:month/:dist_id", element: <LogView /> },
      { path: "pivot", element: <PivotView /> },
      { path: "profile/:id", element: <TeamProfileView /> },
      { path: "spike", element: <SpikeView /> },
      { path: "spike/brand-map", element: <BrandMap /> },
      { path: "finance", element: <FinanceView /> },

      {
        path: "bm",
        element: <BookMarks />,
        children: [{ path: ":uuid", element: <BookMarks /> }],
      },

      {
        path: "forms",
        children: [
          {
            index: true,
            element: <FormList />,
          },
          {
            path: ":uuid",
            element: <Form />,
            children: [
              { path: "old/:form_id", element: <OldForm /> },
              { path: "edit/:response_id", element: <Form /> },
            ],
          },
          { path: ":uuid/responses", element: <FormResponses /> },
          {
            path: ":uuid/responses/:response_id",
            element: <FormResponseEntry />,
          },
          { path: ":uuid/responses/:response_id/edit", element: <Form /> },
        ],
      },
      {
        path: "forms/builder",
        element: <FullOverlay />,
        children: [
          {
            index: true,
            element: <FormNew />,
          },
          {
            path: ":uuid",
            element: <FormEdit />,
          },
          {
            path: ":uuid/:form_id",
            element: <FormEdit />,
          },
        ],
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/home" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
