import * as React from "react";

import { createStyles, makeStyles, Avatar } from "@material-ui/core";
import { AmplifyS3Image } from "@aws-amplify/ui-react/legacy";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {},
  })
);

const AvatarS3 = ({ linkTo, imageKey, imageIdentityID }) => {
  const classes = useStyles();
  //TODO Cache images instead of calling S3
  if (imageKey === "gg_default_image.png") {
    return (
      <Avatar
        component={RouterLink}
        to={linkTo}
        src={"/static/images/avatars/gg_default_image.png"}
      />
    );
  } else {
    return (
      <Avatar className={classes.avatar} component={RouterLink} to={linkTo}>
        <AmplifyS3Image
          className={classes.avatar}
          level="protected"
          identityId={imageIdentityID}
          imgKey={imageKey}
          style={{
            "--width": "100%",
            "--height": "100%",
            borderRadius: "50%",
          }}
        />
      </Avatar>
    );
  }
};

export default {
  AvatarS3,
};
