import client from "./client";

const getFolders = ({ manufacturer_id }) => {
  const endpoint = "/storage/folders";
  return client.get(endpoint, { mid: manufacturer_id });
};

const createFolder = ({ folder_name, manufacturer_id, user_id }) => {
  return client.post("/storage/folders", {
    name: folder_name,
    manufacturer_id,
    user_id,
  });
};

const updateFolder = ({ folder_name, folder_id, manufacturer_id, user_id }) => {
  return client.put(`/storage/folders/${folder_id}`, {
    name: folder_name,
    manufacturer_id,
    user_id,
  });
};
const deleteFolder = (folder_id) => {
  return client.del(`/storage/folders/${folder_id}`);
};

export default {
  getFolders,
  createFolder,
  updateFolder,
  deleteFolder,
};
