import { API } from "aws-amplify";
import client from "./client";
const apiName = "backendGateway"; //should be env variable

const getFormList = (manufacturer_id) => {
  const endpoint = `/forms`;
  return client.get(endpoint, {
    mid: manufacturer_id,
  });
};
const getForm = (form_uuid) => {
  const endpoint = `/forms/${form_uuid}`;
  return client.get(endpoint, {});
};

const getFormVersions = (form_uuid) => {
  const endpoint = `/forms/${form_uuid}/versions`;
  return client.get(endpoint, {});
};

const getFormVersionById = ({ form_uuid, form_id }) => {
  const endpoint = `/forms/${form_uuid}/versions/${form_id}`;
  return client.get(endpoint, {});
};

const deleteForm = (formId) => {
  const endpoint = `/forms/${formId}`;
  return API.del(apiName, endpoint, {});
};
const deleteFormVersion = (form_uuid, form_id) => {
  const endpoint = `/forms/${form_uuid}/${form_id}`;
  return API.del(apiName, endpoint, {});
};
const addForm = (data) => {
  const endpoint = `/forms`;
  return client.post(endpoint, data);
};

const editForm = (data, formId) => {
  const endpoint = `/forms/${formId}`;
  return client.put(endpoint, data);
};

const saveFormResponse = (data) => {
  const endpoint = `/forms/${data.form_id}/responses`;
  return client.post(endpoint, data);
};
const updateFormResponse = (data) => {
  const endpoint = `/forms/${data.form_id}/responses/${data.response_id}`;
  return client.put(endpoint, data);
};
const editFormResponse = (data) => {
  const endpoint = `/forms/responses/${data.response_id}/editResponse`;
  return client.put(endpoint, { response_body: data.response_body });
};

const getFormResponse = (data) => {
  const endpoint = `/forms/${data.form_id}/responses/${data.response_id}`;
  return client.get(endpoint, data);
};

const getResponses = (formId) => {
  const endpoint = `/forms/${formId}/responses`;
  return client.get(endpoint, {});
};

const getResponsesExport = (formId) => {
  const endpoint = `/forms/${formId}/export`;
  return client.get(endpoint, {});
};

const getResponsesExport2 = (formId) => {
  // temp new endpoint until approved
  const endpoint = `/forms/${formId}/exportTestEthan`;
  return client.get(endpoint, {});
};

const getMyResponsesExport = (formId, user_email) => {
  // exports an individual users forms with filled and null account ids
  const endpoint = `/forms/${formId}/export/user/${user_email}`;
  return client.get(endpoint, {});
};

const getMyFormResponses = (userId) => {
  const endpoint = `/forms/user_responses/${userId}`;
  return client.get(endpoint, {});
};
const getAccountFormResponses = (accountId) => {
  const endpoint = `/forms/account_responses/${accountId}`;
  return client.get(endpoint, {});
};

const deletFormResponse = (formId, responseId) => {
  const endpoint = `/forms/${formId}/responses/${responseId}`;
  return API.del(apiName, endpoint, {});
};
export default {
  getFormList,
  deleteForm,
  deleteFormVersion,
  addForm,
  getForm,
  getFormVersions,
  getFormVersionById,
  editForm,
  saveFormResponse,
  updateFormResponse,
  editFormResponse,
  getFormResponse,
  getResponses,
  deletFormResponse,
  getMyFormResponses,
  getAccountFormResponses,
  getResponsesExport,
  getResponsesExport2,
  getMyResponsesExport,
};
