const depletions = {
  depletionList: [],
  setDepletionListCount: 0,
  setDepletionList(list) {
    this.depletionList = list;
    this.setDepletionListCount = this.setDepletionListCount + 1;
  },
};

export default depletions;
