import { useMemo } from "react";
import { useStore } from "../../../stores/StoreContext";

const {
  Card,
  CardHeader,
  CardContent,
  Button,
  CardActions,
  makeStyles,
  Divider,
} = require("@material-ui/core");
const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
}));
const MapAccountCard = ({ account, close, measurementUnit }) => {
  const { depletionList, selectedQuantity, quantities } = useStore();
  const accountSales = useMemo(() => {
    const accname = account.store;
    return depletionList.filter((dl) => dl.STORENUMBER === accname);
  }, [account, depletionList]);
  const combinedSales = accountSales
    .reduce((acc, curr) => {
      const matchIndex = acc.findIndex(
        (bottle) => bottle.PRODUCT_ID === curr.canonical_product_id
      );
      if (matchIndex >= 0) {
        acc[matchIndex].physical_sold += curr.PHYSICAL_SOLD;
        acc[matchIndex].unit_sold += curr.UNIT_SOLD;
        acc[matchIndex].nine_sold += curr.NINE_SOLD;
        acc[matchIndex].bbl += curr.BBL;
        return acc;
      } else {
        return [
          ...acc,
          {
            ...curr,
            physical_sold: curr.PHYSICAL_SOLD,
            unit_sold: curr.UNIT_SOLD,
            nine_sold: curr.NINE_SOLD,
            bbl: curr.BBL,
          },
        ];
      }
    }, [])
    .filter((bottle) => bottle.unit_sold !== 0);

  const tot = combinedSales?.reduce((total, bottle) => {
    return total + bottle[measurementUnit.value];
  }, 0);
  const quantityName = quantities.find(
    (q) => q.value === selectedQuantity
  ).text;
  const classes = useStyles();
  return (
    <>
      {account && (
        <Card
          style={{
            position: "absolute",
            top: "80px",
            left: "calc(50% - 200px)",
            zIndex: "3",
            width: "480px",
          }}
        >
          <CardHeader title={account.store} className={classes.header} />
          <CardContent
            style={{
              fontFamily: "sans-serif",
              lineHeight: "1.4em",
              maxHeight: 180,
              overflow: "scroll",
            }}
          >
            <div
              style={{
                fontSize: "12px",
              }}
            >
              Product - <em>Size</em> / <strong>Quantity</strong>
            </div>
            <Divider />
            <div
              style={{
                maxHeight: "100px",
                overflowY: "scroll",
                fontSize: "12px",
              }}
            >
              {combinedSales.map((b, ind) => (
                <div key={`${ind}-${b.PRODUCT_ID}`}>
                  {`${b.PRODUCTNAME} `}
                  {selectedQuantity === "unit_sold"
                    ? b[selectedQuantity]
                    : b[selectedQuantity].toFixed(2)}{" "}
                  <strong>({quantityName})</strong>
                </div>
              ))}
            </div>
            <div
              style={{
                margin: "8px 0",
              }}
            >
              <strong>Total sales ({quantityName}): </strong>
              {selectedQuantity === "unit_sold" ? tot : tot.toFixed(2)}
            </div>
          </CardContent>
          <CardActions>
            <Button color="secondary" variant="contained" onClick={close}>
              Close
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default MapAccountCard;
