import { useEffect, useState } from "react";
import _ from "lodash";
const DistributorBrandGrid = ({
  data,
  setActiveBrand,
  activeBrand,
  externalBrandList,
  stateNameObj = {},
  state = null,
}) => {
  const [hoverBrand, setHoverBrand] = useState("");
  const [hoverDistributor, setHoverDistributor] = useState("");
  const [brands, setBrands] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [mergedData, setMergedData] = useState([]);

  useEffect(() => {
    let processedData = data;
    if (state && data.length) {
      console.log({
        state,
        dataZero: data[0],
      });
      processedData = processedData.filter((pd) => pd.state === state);
    }
    setBrands(
      _.uniq([
        ...processedData.map((d) => d.product_brand),
        ...externalBrandList.map((cb) => cb.brand),
      ]).sort()
    );
    setBrandData([
      ...processedData,
      ...externalBrandList.map((el) => ({ ...el, product_brand: el.brand })),
    ]);
    setDistributors(
      _.uniq(
        [...processedData, ...externalBrandList].map((d) => d.dist_displayname)
      ).sort()
    );
  }, [data, externalBrandList, state]);
  const distributorHasBrand = (distributor, brand, object) => {
    return (
      object.dist_displayname === distributor && object.product_brand === brand
    );
  };
  const hoverOn = ({ distributor, brand }) => {
    setHoverBrand(brand);
    setHoverDistributor(distributor);
  };

  const hoverOff = () => {
    setHoverBrand("");
    setHoverDistributor("");
  };

  const toggleBrand = (brand) => {
    if (activeBrand.includes(brand)) {
      setActiveBrand(activeBrand.filter((b) => b !== brand));
    } else {
      setActiveBrand([...activeBrand, brand]);
    }
  };

  return (
    <div>
      <div>
        <div className={"x_cols"}>
          <div className={"x_col"}>
            <div className={"x_row"}>
              <div className={"x_cell_head"}></div>
            </div>

            {brands.map((b, i) => (
              <div
                className={`x_row ${hoverBrand === b ? "active" : ""}`}
                key={i}
                style={{ padding: 10, marginTop: "2px" }}
              >
                <div className="textBox">{b}</div>
              </div>
            ))}
          </div>
          {distributors.map((d, i) => (
            <div className={"x_col"} key={i}>
              <div className={"x_row"}>
                <div className={"x_cell_head"}>
                  <div
                    className={`x_cell_head_label ${
                      hoverDistributor === d ? "active" : ""
                    }`}
                  >
                    <span title={d}>{d}</span>
                  </div>
                </div>
              </div>

              {brands.map((b, i) => (
                <div className={"x_row"} key={i}>
                  <div
                    className={"x_cell"}
                    onMouseOver={() =>
                      hoverOn({
                        distributor: d,
                        brand: b,
                      })
                    }
                    onMouseOut={hoverOff}
                  >
                    {brandData.findIndex((r) => distributorHasBrand(d, b, r)) >
                    -1 ? (
                      <div
                        className="checkCircle"
                        style={
                          i == 0
                            ? { borderTop: "1px solid black", padding: "10px" }
                            : { padding: "10px" }
                        }
                      >
                        <div
                          className={
                            activeBrand.includes(b)
                              ? "activeCircle"
                              : "inactiveCircle"
                          }
                          onClick={() => toggleBrand(b)}
                        />
                      </div>
                    ) : (
                      <div
                        className="checkCircle"
                        style={
                          i == 0
                            ? { borderTop: "1px solid black", padding: "10px" }
                            : { padding: "10px" }
                        }
                        onClick={() => toggleBrand(b)}
                      >
                        <div className={"noCircle"} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DistributorBrandGrid;
