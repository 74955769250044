import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import distributorsAPI from "../../../api/distributors.js";
import { useStore } from "../../../stores/StoreContext";
import TopExternalBrandsList from "../../../components/brands/TopExternalBrandsList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TopExternalBrands = () => {
  const classes = useStyles();
  const { userInfo } = useStore();

  const [brands, setBrands] = useState([]);
  useEffect(async () => {
    trackEvent(userInfo.uid, "PageView:Top-External-Brands");
    const data = await distributorsAPI
      .getTopExternalBrands(userInfo.manufacturer_id)
      .then(({ results }) => {
        setBrands(results.map((r, i) => ({ ...r, id: i })));
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <Page className={classes.root} title="Accounts Dashboard">
      <Container maxWidth={true}>
        <Card>
          <CardHeader title={"Top External Brands"} />
          <CardContent>
            <TopExternalBrandsList brands={brands} />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default TopExternalBrands;
