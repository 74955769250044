import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, Card, CardContent, Divider, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { useNavigate } from "react-router";
import { useStore } from "../../../stores/StoreContext";
import { useObserver } from "mobx-react-lite";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { autorun } from "mobx";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 64,
    padding: "0px 4% 0px 1%",
    border: "1px solid #e1e1e1",
    backgroundColor: "#ffffff",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "600",
  },
  tableHeaderCell: {
    fontWeight: "500",
  },
  tableCell: {
    flexBasis: "30%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  content: {
    "&.MuiAccordionSummary-content": {
      display: "block",
    },
  },
  collapseSection: {
    padding: "20px",
    display: "flex",
  },
}));

const ProductTable = () => {
  const store = useStore();
  const [products, setProdects] = useState([]);
  const [expandedBrand, setExpandedBrand] = useState("");
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    autorun(() => {
      setProdects([...store.productsList]);
    });
  }, [store.productsList]);

  useEffect(() => {
    if (products.length === 1) {
      setExpandedBrand(products[0][0]);
    } else {
      setExpandedBrand("");
    }
  }, [products]);

  const onHandleEdit = (productId) => {
    navigate(`edit/${productId}`);
  };
  return useObserver(() => (
    <div>
      <Box className={classes.header}>
        <Typography
          className={[classes.tableHeaderCell, classes.tableCell].join(" ")}
        >
          Brand
        </Typography>
        <Typography
          className={[classes.tableHeaderCell, classes.tableCell].join(" ")}
        >
          Products
        </Typography>
      </Box>
      {products.map((product, index) => {
        return (
          <TableRow
            key={index}
            product={product}
            expandedBrand={expandedBrand}
            setExpandedBrand={setExpandedBrand}
            onHandleEdit={onHandleEdit}
          />
        );
      })}
    </div>
  ));
};

export default ProductTable;

const TableRow = ({
  product,
  onHandleEdit,
  expandedBrand,
  setExpandedBrand,
}) => {
  const classes = useStyles();
  const accordingToggle = () => {
    if (expandedBrand === product[0]) {
      setExpandedBrand("");
    } else {
      setExpandedBrand(product[0]);
    }
  };
  return (
    <Accordion
      expanded={expandedBrand === product[0]}
      style={{ margin: 0 }}
      onChange={accordingToggle}
    >
      <AccordionSummary
        classes={{ content: classes.content }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.tableCell}>{product[0]}</Typography>
          <AvatarGroup max={3}>
            {product[1].map((prod, ind) => (
              <Avatar
                alt="Remy Sharp"
                src={awsS3Image(
                  prod.product_image,
                  prod.product_imagekey,
                  "50x50"
                )}
                style={{ background: "#f6f6f6" }}
                key={ind}
              />
            ))}
          </AvatarGroup>
          <Typography className={classes.tableCell}>
            {product.product_category}
          </Typography>
          <Typography style={{ width: "6%" }}>{product.product_id}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Card style={{ width: "100%", backgroundColor: "#85858521" }}>
          <CardContent>
            <Box className={classes.collapseSection}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Card>
                    <CardContent>
                      <Typography className={classes.heading}></Typography>
                      {product[1].map((prodDetail, index) => {
                        return (
                          <Box key={index}>
                            <Box
                              p={1}
                              style={{
                                margin: "5px 0px 5px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                onHandleEdit(prodDetail.product_id)
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  src={awsS3Image(
                                    prodDetail.product_image,
                                    prodDetail.product_imagekey,
                                    "50x50"
                                  )}
                                  style={{ width: 30, height: 30 }}
                                />
                                <Box>
                                  {prodDetail.product_displayname}
                                  {"    "}
                                </Box>
                                <Box>{prodDetail.product_unit_size}ml</Box>
                              </div>
                            </Box>
                            <Divider variant="inset" />
                          </Box>
                        );
                      })}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};
