import React from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip,
} from "@material-ui/core";

const FormOptions = ({ value, propName, changeFormProp, label, tip }) => {
  const Wrap = tip ? Tooltip : React.Fragment;
  const elProps = tip ? { title: tip } : {};

  return (
    <Box mt={1} ml={2} display="flex" alignItems="center">
      <Wrap {...elProps}>
        <FormControl component="fieldset" fullWidth>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormLabel component="legend" style={{ marginRight: "auto" }}>
              {label}
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(event) =>
                    changeFormProp({ [propName]: event.target.checked })
                  }
                  name={propName}
                />
              }
              label=""
            />
          </div>
        </FormControl>
      </Wrap>
    </Box>
  );
};

export default FormOptions;
