import React, { useEffect, useRef, useMemo } from "react";
import {
  Grid,
  Container,
  makeStyles,
  useMediaQuery,
  CardHeader,
  Card,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import "chartjs-adapter-moment";

import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import AllTimeBar from "../../reports/ChartsView/AllTimeBar";
import HeatMap from "../../reports/ChartsView/HeatMap";
import Splash from "../../../components/Splash";
import { useStore } from "../../../stores/StoreContext";
import HomeTops from "../../../components/dataGrids/HomeTops";
import SummaryTotalComparison from "../../../components/widgets/SummaryTotalComparison";
import STHeatMap from "../../../components/visualisation/brands/STHeatMap";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const HomeView = observer(() => {
  const store = useStore();
  const {
    userInfo,
    manufacturer,
    filteringProducts,
    selectedStates,
    selectedDistributors,
    selectedBrands,
    regions,
    dateRange,
    premiseTypes,
    productSold,
    setDepletionListCount,
  } = store;
  const classes = useStyles();
  const scrollPreserver = useRef(null);

  const longCodeRegionMap = useMemo(() => {
    return Object.fromEntries(regions.map(({ long, code }) => [long, code]));
  }, [regions]);

  const filterArgs = useMemo(
    () => ({
      brands: new Set(selectedBrands),
      states: new Set(selectedStates.map((state) => longCodeRegionMap[state])),
      distributors: new Set(selectedDistributors),
      products: new Set(filteringProducts.map((fp) => fp.product_id)),
      dateRange,
      premiseTypes,
      productSold,
    }),
    [
      selectedBrands,
      selectedStates,
      selectedDistributors,
      filteringProducts,
      longCodeRegionMap,
      dateRange,
      premiseTypes,
      productSold,
    ]
  );

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Home");
  }, [userInfo.uid]);

  const showSplash = setDepletionListCount === 0;
  let splashMessage = "Counting Bottles";
  if (setDepletionListCount > 0) splashMessage = "99 Bottles on the Wall..";

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const headerStates =
    filterArgs.states.size >= 6
      ? [`${filterArgs.states.size}/${store.allStates.length} States`]
      : Array.from(filterArgs.states).toString();

  return (
    <Page className={classes.root} title="Home">
      <Container maxWidth="xl" className={classes.root}>
        {showSplash && (
          <Grid container spacing={3}>
            <Splash
              message={splashMessage}
              concise={setDepletionListCount !== 0}
            />
          </Grid>
        )}
        <Grid
          container
          spacing={3}
          direction={smallScreen ? "column" : "row"}
          style={{
            minHeight: 0,
          }}
        >
          <Grid item xs={12} ref={scrollPreserver}>
            <SummaryTotalComparison filterArgs={filterArgs} />
          </Grid>
          <Grid item xs={12} ref={scrollPreserver}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <AllTimeBar filterArgs={filterArgs} />
              </Grid>
              <HomeTops
                userInfo={userInfo}
                manufacturer={manufacturer}
                filterArgs={filterArgs}
              />
              <Grid item lg={12} md={12} xs={12}>
                <Card style={{ position: "relative" }}>
                  <CardHeader
                    title={`${headerStates} Heat Map`}
                    className="st-card-header"
                  />
                  <STHeatMap
                    filterArgs={filterArgs}
                    manufacturer={manufacturer}
                    showTitle={true}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
});

export default HomeView;
