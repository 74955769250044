import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import {
  CardHeader,
  Typography,
  Card,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  CardContent,
  Tooltip,
  Switch,
} from "@material-ui/core";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { useStore } from "../../../stores/StoreContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  statbox: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  statcontent: {
    height: "100%",
  },
  listItemText: {
    fontSize: "1.2em",
  },
  typography: {
    textAlign: "center",
  },
  list: {
    maxHeight: "70%",
    overflow: "auto",
  },
}));

const AccountMetrics = ({ className, checkin, setCheckin, ...rest }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [lastSale, setLastSale] = useState("");
  const [checkinList, setCheckinList] = useState([]);
  const [isToggle, setIsToggle] = useState(true);
  const store = useStore();
  const { userInfo } = store;
  useEffect(() => {
    getAccountMetrics();
  }, []);

  useEffect(() => {
    getLastCheckin();
  }, [checkin]);
  async function getAccountMetrics() {
    try {
      const data = await API.get("backendGateway", "/accounts/stats/", {
        queryStringParameters: {
          mid: userInfo.mid,
          id: id,
        },
      });
      // console.log("data from Lambda REST API: ", data);
      createProducts(data.results);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }

  async function getLastCheckin() {
    try {
      const data = await API.get("backendGateway", "/accounts/checkin", {
        queryStringParameters: {
          mid: userInfo.mid,
          id: id,
        },
      });
      // console.log("Checkin data from Lambda REST API: ", data);
      if (data.results[0]?.checkin_date) {
        setCheckin(
          DateTime.fromISO(data.results[0]?.checkin_date).toFormat(
            "dd MMM yyyy"
          )
        );
        setCheckinList(
          data.results.sort((a, b) => {
            return a.checkin_date > b.checkin_date
              ? -1
              : a.checkin_date < b.checkin_date
              ? 1
              : 0;
          })
        );
      } else {
        setCheckin("No Checkins");
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }
  function createProducts(data) {
    let dates = new Array();
    let _data = data.map((item) => {
      dates.push(item.account_sales_date);
      return {
        ...item,
        image_src: awsS3Image(
          item.product_image,
          item.product_imagekey,
          "0x100"
        ),
      };
    });
    dates.sort((a, b) => new Date(b) - new Date(a));
    if (dates.length > 0) {
      setLastSale(DateTime.fromISO(dates[0]).toFormat("dd MMM yyyy"));
    }
    setProducts(_data);
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            style={{
              marginBottom: "16px",
            }}
          ></Grid>
        </Grid>

        <Grid item lg={4} md={4} xs={12}>
          <Card className={classes.statcontent}>
            <CardHeader title={"Products"} className="st-card-header" />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Typography>Toggle</Typography>
              <Switch
                checked={isToggle}
                onChange={() => setIsToggle(!isToggle)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <CardContent>
              {!isToggle && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography style={{ flex: 1, fontWeight: "bold" }}>
                      Brand
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ flex: 1, fontWeight: "bold" }}>
                      Product Name
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {isToggle ? (
                <>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    {products.map((prod, index) => (
                      <Grid item xs={3} sm={2} md={3} key={prod.product_id}>
                        <Tooltip title={prod.product_displayname} key={index}>
                          <img
                            src={prod.image_src}
                            style={{
                              height: "100%",
                              maxWidth: "100%",
                            }}
                            alt=""
                          />
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  {products.map((prod, index) => (
                    <Grid
                      style={{
                        maxHeight: 300,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Typography style={{ flex: 1 }}>
                            {prod.product_brand || ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={{ flex: 1 }}>
                            {prod.product_displayname}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={4} md={4} xs={12}>
          <Card className={classes.statcontent}>
            <CardHeader title={"Account Orders"} className="st-card-header" />
            <CardContent style={{ maxHeight: 380, overflowY: "auto" }}>
              <List className={classes.list}>
                {products.map((prod) => (
                  <ListItem
                    key={prod.product_id}
                    style={{
                      height: "auto",
                      padding: 0,
                    }}
                  >
                    <ListItemText
                      primary={`${
                        prod.product_displayname +
                        " " +
                        prod.product_size +
                        " (" +
                        prod.PHYSICAL_SOLD +
                        " Physical Sold) "
                      }`}
                      style={{
                        padding: "2px",
                        height: "auto",
                        margin: 0,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}></Grid>
    </>
  );
};

AccountMetrics.propTypes = {
  className: PropTypes.string,
};

export default AccountMetrics;
