const manufacturer = {
  manufacturer: {
    state_codes: [],
    flags: [],
  },
  setManufacturer(manu) {
    this.manufacturer = manu;
  },
  setManufacturerStates(state_codes) {
    this.manufacturer.state_codes = state_codes;
  },
  setFlags(flags) {
    this.manufacturer.flags = flags;
  },
};

export default manufacturer;
